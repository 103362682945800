/* eslint-disable eqeqeq */
import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import Header from './Header';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import MorningIMG from '../assets/Images/Morning.svg';
import NoonIMG from '../assets/Images/Noon.svg';
import EveningIMG from '../assets/Images/Evening.svg';
import likes from '../assets/Images/likes.svg';
import comments from '../assets/Images/Comment.svg';
import Flag from '../assets/Images/Flag.svg';
import share from '../assets/Images/share.svg';
import PostCommentLike from './PostCommentLike';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import Checked from '../assets/Images/checkVerified.svg';
import api from '../api/index';
import IconHeartLike from '../assets/Images/IconHeartLike.svg';
import RightTop from '../assets/Images/RightTop.svg';
import IntensityComponentTwo from './IntensityGraphTwo';
import { analyticsPages, errorLogCountAnalyticsEvent } from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */

function FeedReviewModal({ setShowFeedReview, commentsReplyText }) {
  const { reviewId } = useParams();
  const songRef = useRef(null);
  const [render, setRender] = useState(false);
  const [comment, setComment] = useState('');
  const [commentById, setCommentById] = useState();
  const [rate, setRate] = useState(4);
  const [reviewById, setReviewById] = useState('');
  const userProfileImg = localStorage.getItem('profilePicLink');
  const nav = useNavigate();

  const outSideHandler = () => {
    nav('/dashboard');
    setShowFeedReview(false);
    setRender(false);
  };

  const fetchReviewBySongId = async () => {
    try {
      const response = await api('get', `/reviews/${reviewId}/`);
      setReviewById(response?.data);
      setRate(response?.data?.ratings);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };

  const fetchReviewCommentsById = async () => {
    try {
      const response = await api('get', `/reviews/${reviewId}/comments/`);
      setCommentById(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };

  const handleCloseClick = () => {
    outSideHandler();
    setShowFeedReview(false);
  };

  const handleComment = (targetId, com) => {
    if (com !== '') {
      api('post', `/review-comments/`, { text: com, review: targetId })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          errorLogCountAnalyticsEvent();
          console.log(err);
        });
    }
  };

  const handleKeyPress = (event, reviewID) => {
    console.log('in');
    if (event.keyCode === 13) {
      handleComment(reviewID, comment);
      setComment('');
      fetchReviewCommentsById();
    }
  };

  useEffect(() => {
    fetchReviewCommentsById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);

  useEffect(() => {
    const handleClick = (event) => {
      if (songRef.current) {
        if (!songRef.current?.contains(event.target)) {
          if (render) {
            outSideHandler();
          }
          setRender(true);
        } else {
          setShowFeedReview(true);
        }
      }
    };
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [songRef, outSideHandler]);

  useEffect(() => {
    if (reviewId) {
      fetchReviewBySongId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);

  usePageViewAnalyticsHook({
    title: analyticsPages.feedReviewModal,
  });

  return (
    <div className="feedReview_popup">
      <Header />
      <div className="leftbottomColor leftbottomColor_dashboard_feedreview">
        <img src={RightTop} alt="x" />
      </div>
      <div className="feedReview">
        <div className="feedReveiw_inner_data" ref={songRef}>
          <div className="feedReveiw_data">
            <div className="feedReview_profileANDcloseicon">
              <div className="feedReview_profile_dp">
                <img src={reviewById?.song?.image || reviewById?.album?.image} alt="Profile Pic" />
              </div>
              <div className="feedReview_closeIcon">
                <img
                  src={CloseIcon}
                  alt="Cross"
                  onClick={() => {
                    handleCloseClick();
                  }}
                />
              </div>
            </div>
            <Rating
              name="custom-rating"
              value={rate}
              precision={1}
              readOnly
              sx={{
                '& .MuiRating-icon': {
                  fontSize: '15px',
                  '&:hover': {
                    fontSize: '15px',
                  },
                },
              }}
            />
            <p className="profile_name_txt">
              {reviewById?.song?.title || reviewById?.album?.title}
            </p>
            <p className="profile_disc_txt">
              {reviewById?.song?.artist_name || reviewById?.album?.artist_name}
            </p>
            <div className="post_comments_data">
              <div className="postTopData">
                <div className="postTopLeft">
                  <div className="postPicHolder">
                    <img src={reviewById?.song?.image || reviewById?.album?.image} alt="pic" />
                  </div>
                  <div className="posterNameDiv">
                    <p className="nameTxtt">
                      {reviewById?.song?.title || reviewById?.album?.title}
                    </p>
                    <p className="nameTxtt2">
                      {reviewById?.song?.artist_name || reviewById?.album?.artist_name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="titleAndReview">
                <p className="tittleTxts">{reviewById?.song?.title || reviewById?.album?.title}</p>
                <div className="feedStarsAndNumber">
                  <p className="noOfStarsOnRatings"> {reviewById?.ratings}</p>
                  <Rating
                    name="custom-rating"
                    value={rate}
                    precision={1}
                    readOnly
                    sx={{
                      '& .MuiRating-icon': {
                        fontSize: '15px',
                        '&:hover': {
                          fontSize: '15px',
                        },
                      },
                    }}
                  />
                </div>
              </div>
              {reviewById?.images?.length === 1 && (
                <div className="reviewImagesDivHolder">
                  {reviewById?.images[0] && <img src={reviewById?.images[0]} alt="reviewImg" />}
                </div>
              )}
              {reviewById?.images?.length === 2 && (
                <div className="reviewImagesDivHolder2">
                  {reviewById?.images[0] && <img src={reviewById?.images[0]} alt="reviewImg" />}
                  {reviewById?.images[1] && <img src={reviewById?.images[1]} alt="reviewImg" />}
                </div>
              )}
              {reviewById?.images?.length === 3 && (
                <div className="reviewImagesDivHolder3">
                  <div className="reviewImagesDivHolder3in1">
                    {reviewById?.images[0] && <img src={reviewById?.images[0]} alt="reviewImg" />}
                  </div>
                  <div className="reviewImagesDivHolder3in2">
                    {reviewById?.images[1] && <img src={reviewById?.images[1]} alt="reviewImg" />}
                    {reviewById?.images[2] && <img src={reviewById?.images[2]} alt="reviewImg" />}
                  </div>
                </div>
              )}
              {reviewById?.images?.length === 4 && (
                <div className="reviewImagesDivHolder3" id="reviewImagesDivHolder3id">
                  <div className="reviewImagesDivHolder3in1">
                    {reviewById?.images[0] && <img src={reviewById?.images[0]} alt="reviewImg" />}
                  </div>
                  <div className="reviewImagesDivHolder3in33">
                    {reviewById?.images[1] && <img src={reviewById?.images[1]} alt="reviewImg" />}
                    {reviewById?.images[2] && <img src={reviewById?.images[2]} alt="reviewImg" />}
                    {reviewById?.images[3] && <img src={reviewById?.images[3]} alt="reviewImg" />}
                  </div>
                </div>
              )}

              <div className="postDescription">
                <p>{reviewById?.body}</p>
              </div>
              <div className="comments_btns">
                <div className="btns_color_intestivity">
                  <button type="button">Color Intensity</button>
                  <div className="graph_color_btn">
                    <IntensityComponentTwo
                      color1={reviewById?.intensity?.color_one_rgb}
                      color2={reviewById?.intensity?.color_two_rgb}
                      frequency={reviewById?.intensity?.intensity}
                    />
                  </div>
                </div>
                {reviewById?.evening === true ? (
                  <div className="btn_morningEvening">
                    <div className="morning_image_btn">
                      <img src={EveningIMG} alt="morning" />
                    </div>
                    <button type="button">Mainly listened in the Evening</button>
                  </div>
                ) : (
                  ''
                )}
                {reviewById?.morning === true ? (
                  <div className="btn_morning">
                    <div className="morning_image_btn">
                      <img src={MorningIMG} alt="morning" />
                    </div>
                    <button type="button">Mainly listened in the Morning</button>
                  </div>
                ) : (
                  ''
                )}
                {reviewById?.noon === true ? (
                  <div className="btn_morning">
                    <div className="morning_image_btn">
                      <img src={NoonIMG} alt="morning" />
                    </div>
                    <button type="button">Mainly listened in the Afternoon</button>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="postedBy">
                <div className="posterSinfos">
                  <div className="postByUserImg">
                    <img src={reviewById?.user?.avatar} alt="pic" />
                  </div>
                  <div>
                    <p className="postedbyUsername">
                      {reviewById?.user?.username || reviewById?.user?.name}
                    </p>
                  </div>
                  {reviewById?.user?.is_verified === true && (
                    <div className="checked">
                      <img src={Checked} alt="pic" />
                    </div>
                  )}
                </div>
                <div>
                  <p className="hoursAgo" />
                </div>
              </div>
              {reviewById?.liked_by?.length > 0 && (
                <div className="likedBy">
                  {reviewById?.liked_by.slice(0, 3).map((item) => (
                    <div className="likedByImgWrap">
                      <img src={item.avatar ? item.avatar : dummyProfileImage} alt="pic" />
                    </div>
                  ))}
                  <p className="likedbyTxt">
                    liked by&nbsp;
                    <span className="boldTxt">
                      {reviewById?.liked_by?.[0]?.username ||
                        reviewById?.liked_by?.[0]?.name ||
                        'Username'}{' '}
                    </span>
                    {reviewById?.liked_by.length > 1 && (
                      <>
                        <span> and</span>
                        <span className="boldTxt">
                          &nbsp;{reviewById?.liked_by ? reviewById.liked_by.length - 1 : ''}
                          &nbsp;others
                        </span>
                      </>
                    )}
                  </p>
                </div>
              )}
              <div className="likeCommentAndShare">
                <div className="likesAndComments">
                  <div className="likeCommentImg">
                    <img src={likes} alt="pic" />
                  </div>
                  <div className="likeCommentImg">
                    <img src={comments} alt="pic" />
                  </div>
                </div>
                <div className="shareIconDiv">
                  <img src={share} alt="pic" />
                </div>
              </div>

              <div>
                <p className="previousCommentsTxt">Comments</p>
              </div>
              {commentById?.map((com) => (
                <PostCommentLike
                  com={com}
                  Checked={Checked}
                  Flag={Flag}
                  IconHeartLike={IconHeartLike}
                  likes={likes}
                  commentsReplyText={commentsReplyText}
                />
              ))}
              <div className="addCommentDivInput">
                <div className="AddCommentProfileImg">
                  <img src={userProfileImg || dummyProfileImage} alt="ss" />
                </div>
                <div className="addCommentInput">
                  <input
                    value={comment}
                    name="comment"
                    className="addCommentInputField"
                    type="text"
                    placeholder="Add comment..."
                    onKeyDown={(e) => {
                      handleKeyPress(e, reviewId);
                    }}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedReviewModal;
