import React from 'react';
import FriendProfile from '../components/FriendProfile';
import { analyticsPages } from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';

function FriendsProfile() {
  usePageViewAnalyticsHook({
    title: analyticsPages.friendsProfile,
  });
  return <FriendProfile />;
}

export default FriendsProfile;
