import moment from 'moment';

function formatTimeAgo(createdAt) {
  if (!createdAt) return 'Unknown';

  const now = moment();
  const then = moment(createdAt);

  const duration = moment.duration(now.diff(then));
  const days = duration.asDays();
  const hours = duration.asHours();
  const minutes = duration.asMinutes();
  const seconds = duration.asSeconds();

  if (seconds < 60) {
    return `${Math.floor(seconds)}s`;
  }
  if (minutes < 60) {
    return `${Math.floor(minutes)}m`;
  }
  if (hours < 24) {
    return `${Math.floor(hours)}h`;
  }
  return `${Math.floor(days)}d`;
}

export default formatTimeAgo;
