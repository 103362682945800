import React from 'react';
import { Box, Modal } from '@material-ui/core';
import BackArrow from '../assets/Images/BackArrow.svg';
import RemovePlaylist from '../assets/Images/RemovefromPlaylist.svg';
import ShareOniMessage from '../assets/Images/editaddsong_ShareOnIMessageIcon.svg';
import CopyLinkIcon from '../assets/Images/CopyLinkIcon.svg';
import ShareIcon from '../assets/Images/ShareOption.svg';
import RemovalConfirmation from './RemovalConfirmation';

function SongsOption({
  editSong,
  removePlaylistOpen,
  editaddedSongsClose,
  singleSong,
  removePlaylist,
  removePlaylistClose,
  playlistId,
  handleDeleteFromPlaylist,
}) {
  const style = {
    position: 'absolute',
    top: '20%',
    left: '20%',
    transform: 'translate(-0%, -0%)',
    width: 400,
    bgcolor: 'white',
    border: 'none',
    boxShadow: 24,
  };

  return (
    <>
      <RemovalConfirmation
        removePlaylist={removePlaylist}
        style={style}
        removePlaylistClose={removePlaylistClose}
        playlistId={playlistId}
        songId={singleSong?.id}
        handleDeleteFromPlaylist={handleDeleteFromPlaylist}
        singleSong={singleSong}
      />
      <Modal
        open={editSong}
        onClose={editaddedSongsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="edit_AddedSong">
          <div className="edit_addSong_popup">
            <div
              role="button"
              tabIndex={0}
              className="createPlaylist_backArrow"
              onKeyDown={() => console.log('nothing')}
              onClick={editaddedSongsClose}
            >
              <img src={BackArrow} alt="back" />
            </div>
            <div className="edit_addsong_image_outer">
              <div className="edit_addsong_image">
                <img src={singleSong?.image} alt="dp" />
              </div>
              <div className="edit_addsong_txt_data">
                <p className="edit_addsong_title_txt">{singleSong?.title}</p>
                <p className="edit_addsong_track_txt">{singleSong?.artist_name}</p>
              </div>
              <div
                role="button"
                tabIndex={0}
                className="edit_addSongs_ListData"
                onKeyDown={() => console.log('nothing')}
                onClick={() => removePlaylistOpen()}
              >
                <div>
                  <img src={RemovePlaylist} alt="remove" />
                </div>
                <p className="edit_ListData_txt">Remove from this playlist</p>
              </div>
              <div className="edit_addSongs_ListData">
                <div>
                  <img src={ShareOniMessage} alt="share" />
                </div>
                <p className="edit_ListData_txt">Share on iMessage</p>
              </div>
              <div className="edit_addSongs_ListData">
                <div>
                  <img src={CopyLinkIcon} alt="copy" />
                </div>
                <p className="edit_ListData_txt">Copy link</p>
              </div>
              <div className="edit_addSongs_ListData">
                <div>
                  <img src={ShareIcon} alt="share" />
                </div>
                <p className="edit_ListData_txt">Share via musotic</p>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default SongsOption;
