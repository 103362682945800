/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Init
import React, { useState, useEffect } from 'react';
import 'typeface-quicksand';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Divider from '@material-ui/core/Divider';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import PreLoginSliders from '../components/PreLoginSliders';
import api from '../api/index';
import check from '../assets/Images/VectorcheckMark.svg';
import eyeCon from '../assets/Images/GroupEye.svg';
import ClosedEyeCon from '../assets/Images/GroupClosedEye.svg';
import logox from '../assets/Images/LOGO.svg';
import spotifyIcon from '../assets/Images/SpotifyIcon.svg';
// import appleIcon from '../assets/Images/AppleIcon.svg';
import usePageLoader from '../components/PageLoader/usePageLoader';
import PageLoader from '../components/PageLoader';
import {
  signupAnalyticsEvent,
  analyticsPages,
  errorLogCountAnalyticsEvent,
} from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';
import AppleSocialLogin from '../components/AppleSocialLogin';

export default function CreateAccount() {
  const navigate = useNavigate();
  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();

  const handleNextClick = () => {
    navigate('/spotifyConfirmation');
  };

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const { hash } = window.location;
    let tToken = window.localStorage.getItem('token');

    if (hash && hash) {
      // eslint-disable-next-line prefer-destructuring
      tToken = hash
        .substring(1)
        .split('&')
        .find((elem) => elem.startsWith('access_token'))
        .split('=')[1];
      window.location.hash = '';
      window.localStorage.setItem('token', tToken);
    }
  }, []);

  const ResetFields = () => {
    setEmail('');
    setUsername('');
    setPassword('');
    setConfirmPassword('');
  };
  const handleSignup = async (e) => {
    e.preventDefault();

    if (email === '') {
      toast.error('Enter email or phone number');
    } else if (username === '') {
      toast.error('Enter username');
    } else if (password === '') {
      toast.error('Password is required');
    } else if (confirmPassword === '') {
      toast.error('Confirm Password is required');
      return;
    }

    const signupData = {
      email,
      username,
      password,
      confirm_password: confirmPassword,
    };

    startPageLoader();
    await api('post', '/signup/', signupData)
      .then((res) => {
        localStorage.setItem('email', email);
        console.log('---->', res);
        toast.success('Email Verification e-mail has been sent.');
        ResetFields();
        signupAnalyticsEvent();
        navigate('/accountVerification');
      })

      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);

        if (err?.response?.data.email) {
          toast.error(err.response.data.email[0]);
        } else if (err?.response?.data?.username) {
          toast.error(err.response.data.username[0]);
        } else if (err?.response?.data?.password) {
          toast.error(err.response.data.password[0]);
        } else {
          toast.error('something went wrong');
        }
      })
      .finally(() => {
        stopPageLoader();
      });
  };

  const handleAlreadyRegistered = () => {
    navigate('/login');
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  usePageViewAnalyticsHook({
    title: analyticsPages.signup,
  });

  return (
    <div>
      <PageLoader isLoading={isPageLoading} />
      <div className="SignupScreenContainer">
        <div className="preloginWrapper">
          <PreLoginSliders />
        </div>
        <div className="signinRightPart">
          <div className="rightSideInnerWrapper">
            <div className="SignUpimgLogoNameWrap">
              <img src={logox} alt="logo" />
            </div>
            <p className="loginHeading">Create an Account</p>
            <div className="fieldsContainer">
              <div className="inputFields">
                <p className="lables">Email or Phone Number</p>
                <div className="cont">
                  <input
                    className="input_field_su"
                    type="email"
                    placeholder="johanmike@mail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <span>
                  <img className="emailImg" src={check} alt="pic" />
                </span>
              </div>
              <div className="inputFields">
                <p className="lables">Username</p>
                <div className="cont">
                  <input
                    className="input_field_su"
                    type="text"
                    placeholder="johanmike"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <span>
                  <img className="emailImg" src={check} alt="pic" />
                </span>
              </div>
              <div className="inputFields">
                <p className="lables">Password</p>
                <div className="cont">
                  <input
                    className="input_field_su"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="cursorPointer">
                  <img
                    className="SignuppasswordlImg"
                    onClick={handleShowPassword}
                    src={showPassword ? ClosedEyeCon : eyeCon}
                    alt="pic"
                  />
                </div>
              </div>
              <div className="inputFields">
                <p className="lables">Repeat Password</p>
                <div className="cont">
                  <input
                    className="input_field_su"
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div className="cursorPointer">
                  <img
                    className="SignuppasswordlImg"
                    onClick={handleShowConfirmPassword}
                    src={showConfirmPassword ? ClosedEyeCon : eyeCon}
                    alt="pic"
                  />
                </div>
              </div>
              <div>
                <button type="submit" className="NextButton" onClick={handleSignup}>
                  Next
                </button>
                <p className="signUpoptn" onClick={handleAlreadyRegistered}>
                  I Already Have An Account
                </p>
              </div>
              <div className="loginwithOptn">
                <Divider
                  style={{
                    flexGrow: 1,
                    backgroundColor: 'grey',
                    opacity: '0.1',
                  }}
                />
                <span className="SignupOptionstxt">&nbsp; &nbsp;or Sign up with&nbsp;&nbsp;</span>
                <Divider style={{ flexGrow: 1, backgroundColor: 'grey', opacity: '0.1' }} />
              </div>
              <div className="loginThroughthirdParty">
                <button type="submit" className="SpotifyBtn" onClick={handleNextClick}>
                  <span className="iconSS">
                    <img src={spotifyIcon} alt="icon" />
                  </span>
                  Spotify
                </button>

                <AppleSocialLogin />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
