import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import BackArrow from '../../assets/Images/BackArrow.svg';
import CloseIcon from '../../assets/Images/CloseIcon.svg';
import WhiteDotIcon from '../../assets/Images/WhieDotsSetting.svg';
import playlistCoverImage from '../../assets/Images/AddCover.svg';
import Checked from '../../assets/Images/checkVerified.svg';
import api from '../../api';
import dummyProfileImage from '../../assets/Images/dummyProfileImg.jpg';
import { generateUniqueFileName } from '../../utils/helpers';
import { errorLogCountAnalyticsEvent } from '../../service/analyticsService';

function AddPlaylistPopup({
  createPlaylist,
  handleClose,
  style,
  userData,
  fetchPanelData,
  //   playlistOptionOpen,
}) {
  const [playlistName, setPlaylistName] = useState('');
  const [playlistDesc, setPlaylistDesc] = useState('');
  const [createPlaylistPopup, setCreatePlaylistPopup] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [studentProfile, setStudentProfile] = useState({ logo: null });
  const [playlistImg, setPlaylistImg] = useState('');
  const profileUsername = localStorage.getItem('profileUsername');

  const popup2Open = () => {
    setCreatePlaylistPopup(true);
  };
  const popup2Close = () => {
    setCreatePlaylistPopup(false);
  };

  const handleCrossClickPlaylist = () => {
    popup2Close();
    setPreviewImage('');
    setImageUrl('');
  };
  const handleAddPlaylistData = () => {
    if (!playlistName) {
      toast.error('Playlist Name Required');
      return;
    }
    if (!playlistDesc) {
      toast.error('Playlist Description Required');
    } else {
      popup2Open();
    }
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage.type !== 'image/png' && selectedImage.type !== 'image/jpeg') {
      toast.error('Only image file ( JPEG & PNG ) is allowed.');
      return;
    }
    setImageUrl('');
    setStudentProfile({ ...studentProfile, logo: selectedImage });
    setPlaylistImg(selectedImage);

    if (selectedImage) {
      setPreviewImage(URL.createObjectURL(selectedImage));
    }
  };
  const imgaeUrlFromApi = () => {
    const formData = new FormData();
    formData.append('image', playlistImg, generateUniqueFileName(playlistImg));
    if (playlistImg) {
      api('post', '/upload-image/', formData)
        .then((res) => {
          console.log('imgggUrl?', res?.data?.image_url);
          if (res?.data?.image_url) {
            setImageUrl(res?.data?.image_url);
          }
        })
        .catch((err) => {
          errorLogCountAnalyticsEvent();
          console.log('Error fetching user reviews:', err);
        });
    }
  };

  const handleAddPLaylistClick = () => {
    if (playlistImg && (imageUrl === '' || !imageUrl)) {
      toast.error('image being uploaded');
      return;
    }
    console.log('imageUrlimageUrl', imageUrl);
    const obj = {
      image: imageUrl,
      name: playlistName,
      desc: playlistDesc,
      is_private: false,
    };
    api('post', '/playlists/', obj)
      .then((res) => {
        console.log('playlistAdd', res);
        toast.success('Playlist created');
        handleClose();
        fetchPanelData();
        setImageUrl('');
        popup2Close();
        setPreviewImage(null);
        setPlaylistName('');
        setPlaylistDesc('');
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log('Error creating playlist', err);
      });
  };
  useEffect(() => {
    if (playlistImg) {
      imgaeUrlFromApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistImg]);
  return (
    <>
      <Modal
        open={createPlaylist}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="createPlaylist">
            <div className="createPlaylist_first_main">
              <div className="createPlaylist_backArrow">
                {!createPlaylist && <img src={BackArrow} alt="back" />}
              </div>
              <p className="createPlaylist_heading_txt">Create Playlist</p>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={(e) => console.log(e)}
                onClick={handleClose}
                className="createPlaylist_Close"
              >
                <img src={CloseIcon} alt="close" />
              </div>
            </div>
            <div className="createPlaylist_second_section">
              <p className="createPlaylist_name_txt">Playlist Name</p>
              <input
                type="text"
                placeholder="My Top Songs"
                onChange={(e) => setPlaylistName(e.target.value)}
              />
            </div>
            <div className="createPlaylist_third_section">
              <p className="createPlaylist_name_txt">Add a Description</p>
              <textarea
                className="textAreaCreateReview"
                onChange={(e) => setPlaylistDesc(e.target.value)}
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis"
                id="addPreview"
                name="addPreview"
                style={{ resize: 'none' }}
              />
            </div>
            <div className="createPlaylist_button">
              <button type="button" onClick={handleAddPlaylistData}>
                Add Playlist
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={createPlaylistPopup}
        onClose={popup2Close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="playList_model" id="addPlaylistModal">
          <div className="profile_playList_popup">
            <div className="profile_playList_popup_first_sction" id="userProfileCustomPadding">
              <div className="back_close_icon">
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => console.log(e)}
                  onClick={popup2Close}
                  className="createPlaylist_backArrow"
                >
                  <img src={BackArrow} alt="back" />
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => console.log(e)}
                  onClick={handleCrossClickPlaylist}
                  className="createPlaylist_Close"
                >
                  <img src={CloseIcon} alt="close" />
                </div>
              </div>
              <div className="HPimgprofiile">
                <label className="upload-pic-label" htmlFor="filePicker">
                  <input
                    type="file"
                    hidden
                    id="filePicker"
                    name="file"
                    onChange={handleImageChange}
                    accept="image/png, image/jpeg"
                  />
                  <div style={{ cursor: 'pointer' }} className="playList_Cover_img">
                    {previewImage ? (
                      <img src={previewImage} type="file" alt="i" className="studentprofilepic" />
                    ) : (
                      <img src={playlistCoverImage} alt="i" className="studentprofilepic" />
                    )}
                  </div>
                </label>
              </div>
              <div className="playList_topSong_data">
                <p className="playList_topSong_txt">{playlistName || 'Your Playlist Name'}</p>
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => console.log(e)}
                  className="whiteDot_icon"
                  id="displayNone"
                  //   onClick={playlistOptionOpen}
                >
                  <img src={WhiteDotIcon} alt="edit" />
                </div>
              </div>
              <div className="MyReviews_PlayList_txtData">
                <div className="postedBy">
                  <div className="posterSinfos">
                    <div className="postByUserImg">
                      <img
                        src={userData?.avatarUrl || dummyProfileImage}
                        alt="pic"
                        style={{ objectFit: 'cover', borderRadius: '50%' }}
                      />
                    </div>
                    <div>
                      <p className="postedbyUsername">{profileUsername || 'username'}</p>
                    </div>
                    <div className="checked">
                      {userData && userData?.is_verified === true ? (
                        <img src={Checked} alt="pic" />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div>{/* <p className="hoursAgo">2 hours ago</p> */}</div>
                </div>
                <div className="postDescription">
                  <p>{playlistDesc || 'lorem lipsum dollar istum pistum lisgub jstpis cetfix'}</p>
                </div>
              </div>
              <button
                id="playList_addSongs_btn_id"
                className={
                  playlistImg && !imageUrl
                    ? 'playList_addSongs_btn_disable'
                    : 'playList_addSongs_btn'
                }
                type="button"
                onClick={handleAddPLaylistClick}
              >
                Create Playlist
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AddPlaylistPopup;
