/* eslint-disable react-hooks/exhaustive-deps */
// GeolocationDisplay.js
import React, { useState, useEffect } from 'react';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

function GeolocationDisplay({ ipAddress }) {
  const [geolocationInfo, setGeolocationInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchGeolocationForIP = async () => {
    if (!ipAddress) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`https://ipapi.co/${ipAddress}/json/`);
      if (!response.ok) {
        throw new Error('Unable to fetch geolocation data');
      }
      const data = await response.json();
      setGeolocationInfo(data);
    } catch (error) {
      errorLogCountAnalyticsEvent();
      console.error('Error fetching geolocation:', error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchGeolocationForIP();
  }, [ipAddress]);

  return (
    <div>
      {loading ? (
        <p>...</p>
      ) : (
        geolocationInfo && (
          <div>
            {/* <p>IP Address: {ipAddress}</p> */}
            {/* <p>{geolocationInfo.country_name}</p> */}
            <p
              style={{
                fontSize: 18,
                fontWeight: 500,
                fontFamily: 'Quicksand',
                color: 'white',
              }}
            >
              {geolocationInfo.city}
            </p>
            {/* <p>Latitude: {geolocationInfo.latitude}</p>
            <p>Longitude: {geolocationInfo.longitude}</p> */}
          </div>
        )
      )}
    </div>
  );
}

export default GeolocationDisplay;
