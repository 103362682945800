/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import BackArrow from '../assets/Images/BackArrow.svg';
import CloseIcon from '../assets/Images/CloseIcon.svg';

import api from '../api';
import usePageLoader from './PageLoader/usePageLoader';
import PageLoader from './PageLoader';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

const Popupstyle = {
  position: 'absolute',
  // top: '55%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: 450,
  height: 450,
  bgcolor: '#2D3447',
  border: 'none',
  boxShadow: 24,
  borderRadius: '8px',
  pt: 2,
};

function MusoticSongPopularThisWeek({ open, onClose }) {
  const [topSongs, setTopSongs] = useState({
    first: null,
    second: null,
    third: null,
  });
  const [data, setData] = useState([]);
  const offset = useRef(0);
  const limit = 9;
  const [hasMore, setHasMore] = useState(false);
  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();

  function fetchData() {
    console.log('fetching populat this week');
    startPageLoader();
    api(
      'get',
      `/songs/popular_this_week_song/?limit=${limit}&offset=${offset.current}`,
      undefined,
      undefined,
      true,
    )
      .then((res) => {
        console.log(res.data, 'response');
        if (res.data.success === true) {
          if (offset.current === 0) {
            // page 1
            setTopSongs({
              first: res.data.payload.results[0],
              second: res.data.payload.results[1],
              third: res.data.payload.results[2],
            });
            setData(res.data.payload.results);
          } else {
            // next page
            setData((prevState) => {
              return [...prevState, ...res.data.payload.results];
            });
          }

          setHasMore(!!res.data.payload.next);
        }
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
      })
      .finally(() => {
        stopPageLoader();
      });
  }

  const handleLoaderMoreData = () => {
    if (hasMore) {
      offset.current += limit;
      fetchData();
    }
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      fetchData();
    } else {
      setData([]);
      offset.current = 0;
    }
  }, [open]);

  const navigate = useNavigate();

  const handlePopularThisWeekSongsClick = async (id) => {
    navigate(`/songDetails/${id}`);
  };
  return (
    <div>
      <PageLoader isLoading={isPageLoading} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box className="main_topDiv" sx={Popupstyle}>
            <div className="topDiv">
              <div className="topInnerDiv topInnerDivPopup">
                <div onClick={() => handleClose()} className="backArrowIMG">
                  <img src={BackArrow} alt="" />
                </div>
                <div className="heading_text heading_textPopup">
                  <p>Popular this week</p>
                  <p className="heading_two">Most reviewed</p>
                </div>
                <div onClick={() => handleClose()} className="CloseIMG CloseIMGPopup">
                  <img src={CloseIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="three_images_main_div">
              <div className="three_images_sub-Div">
                {topSongs.second && (
                  <div className="first_img_main_div">
                    <div className="popupimg_sub_div">
                      <img src={topSongs.second.image} alt=".." />
                    </div>
                    <div className="star_div">
                      <div className="star_image">
                        <p>
                          <strong>{topSongs.second.total_ratings}</strong>
                        </p>
                      </div>
                    </div>
                    <div className="popup_div_text1">
                      <h3>{topSongs.second.title}</h3>
                      <p>{topSongs.second.artist_name}</p>
                    </div>
                  </div>
                )}

                {topSongs.first && (
                  <div className="first_img_main_div sec_img_main_div">
                    <div className="popupimg_sub_div popupimg_sub_div_2">
                      <img src={topSongs.first.image} alt=".." />
                    </div>
                    <div className="star_div star_div_2">
                      <div className="star_image">
                        <p>
                          <strong>{topSongs.first.total_ratings}</strong>
                        </p>
                      </div>
                    </div>
                    <div className="popup_div_text1 popup_div_text1_2">
                      <h3>{topSongs.first.title}</h3>
                      <p>{topSongs.first.artist_name}</p>
                    </div>
                  </div>
                )}

                {topSongs.third && (
                  <div className="first_img_main_div first_img_main_div_3">
                    <div className="popupimg_sub_div popupimg_sub_div_3">
                      <img src={topSongs.third.image} alt=".." />
                    </div>
                    <div className="star_div star_div_3">
                      <div className="star_image">
                        <p>
                          <strong>{topSongs.third.total_ratings}</strong>
                        </p>
                      </div>
                    </div>
                    <div className="popup_div_text1 popup_div_text1_3">
                      <h3>{topSongs.third.title}</h3>
                      <p>{topSongs.third.artist_name}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="innerDiv innerDivpopup">
              <div className="InnerData">
                <div className="Most_Popular_Text ">
                  <p>Most Popular</p>
                </div>

                {data.length === 0 ? (
                  <p className="item-description">No Data Found</p>
                ) : (
                  <Grid sx={{ flexGrow: 1 }} container spacing={2} className="my-component">
                    {data.map((item) => (
                      <Grid
                        item
                        xs={4}
                        key={item.id}
                        onClick={() => {
                          handlePopularThisWeekSongsClick(item.id);
                        }}
                      >
                        <img className="item-image" src={item.image} alt={item.title} />
                        <div className="item_handle">
                          <h2 className="item-heading">{item.title}</h2>
                          <p className="item-description">{item.artist_name}</p>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                )}

                {hasMore && (
                  <div className="playList_showMore_data prelogin_showMore_data">
                    <button
                      className="playList_showMore_btn"
                      type="button"
                      onClick={() => handleLoaderMoreData()}
                    >
                      Show More
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default MusoticSongPopularThisWeek;
