import ReactGA from 'react-ga4';
import {
  GOOGLE_ANALYTICS_KEY,
  // GOOGLE_ANALYTICS_KEY_TEST,
} from '../utils/constant';

export function initilizeAnalytics() {
  ReactGA.initialize([
    { trackingId: GOOGLE_ANALYTICS_KEY },
    // { trackingId: GOOGLE_ANALYTICS_KEY_TEST }, // TODO: remove in prod
  ]);

  // ReactGA.set({ custom_map: { metric1: 'page_load_time' } });
}

let isLoadTimeLogged = false;
export function logLoadTimeAnalytics() {
  if (window.performance && window.performance.getEntriesByType && isLoadTimeLogged == false) {
    const entries = window.performance.getEntriesByType('navigation');
    if (entries.length > 0) {
      const navTiming = entries[0];
      const pageLoadTime = navTiming.loadEventEnd - navTiming.startTime;
      const ms = Math.round(((pageLoadTime + Number.EPSILON) * 100) / 100);
      ReactGA.event('load_time_analytics', {
        page_load_time: ms,
      });
      isLoadTimeLogged = true;
    }
  }
}

export const analyticsPages = {
  dashboard: 'Home',
  search: 'Search',
  resetPassword: 'ResetPassword',
  profile: 'Profile',
  settings: 'Settings',
  songDetails: 'SongDetails',
  albumDetails: 'AlbumDetails',
  chatScreen: 'ChatScreen',
  friendsProfile: 'FriendsProfile',
  feedReviewModal: 'FeedReviewModal',
  login: 'Login',
  signup: 'Signup',
  forgotPassword: 'ForgotPassword',
  spotifyConfirmation: 'SpotifyConfirmation',
  accountVerification: 'AccountVerification',
  preloginscreen: 'Preloginscreen',
  appleLogin: 'AppleLogin',
};

export function pageViewAnalyticsEvent(title) {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
  logLoadTimeAnalytics();
}

export function reviewSubmitAnalyticsEvent() {
  ReactGA.event({
    category: 'review_submitted',
    action: 'review_submitted_successfully',
    label: 'review submitted', // optional
    // value: 99, // optional, must be a number
    // nonInteraction: true, // optional, true/false
    // transport: "xhr", // optional, beacon/xhr/image
  });
}

function replaceUnderscoresWithSpace(str) {
  return str.replace(/_/g, ' ');
}

export const socialMediaHandles = {
  IMessage: 'immessage',
  Whatsaap: 'whatsapp',
  Instagram: 'instagram',
  Facebook: 'facebook',
  Twitter: 'twitter',
  link: 'link',
  Musotic: 'musotic',
};

export function reviewShareAnalyticsEvent(action) {
  ReactGA.event({
    category: 'review_share',
    action,
    label: replaceUnderscoresWithSpace(action),
  });
}

export function postShareAnalyticsEvent() {
  ReactGA.event({
    category: 'post_share',
    action: 'post_share',
    label: 'post share',
  });
}

export function songShareAnalyticsEvent() {
  ReactGA.event({
    category: 'song_share',
    action: 'song_share',
    label: 'song share',
  });
}

export function albumShareAnalyticsEvent() {
  ReactGA.event({
    category: 'album_share',
    action: 'album_share',
    label: 'album share',
  });
}

export function playlistShareAnalyticsEvent() {
  ReactGA.event({
    category: 'playlist_share',
    action: 'playlist_share',
    label: 'playlist share',
  });
}

export function friendProfileShareAnalyticsEvent() {
  ReactGA.event({
    category: 'profile_share',
    action: 'profile_share',
    label: 'profile share',
  });
}

export function songSearchAnalyticsEvent(query) {
  ReactGA.event('song_search_submit', {
    category: 'song_search',
    action: 'song_search_Submit',
    search_query: query,
  });
}

export function albumSearchAnalyticsEvent(query) {
  ReactGA.event('album_search_submit', {
    category: 'album_search',
    action: 'album_search_submit',
    search_query: query,
  });
}

export function playlistSearchAnalyticsEvent(query) {
  ReactGA.event('playlist_search_submit', {
    category: 'playlist_search',
    action: 'playlist_search_Submit',
    search_query: query,
  });
}

export function profileSearchAnalyticsEvent(query) {
  ReactGA.event('profile_search_submit', {
    category: 'profile_search',
    action: 'profile_search_Submit',
    search_query: query,
  });
}

export function signupAnalyticsEvent() {
  ReactGA.event({
    category: 'user_signup',
    action: 'user_signup',
    label: 'successful_signup',
  });
}

export function errorLogCountAnalyticsEvent() {
  ReactGA.event('error_log_count');
}
