/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import IconComments from '../assets/Images/IconComments.svg';
import api from '../api';
import likes from '../assets/Images/likes.svg';
import IconHeartLike from '../assets/Images/IconHeartLike.svg';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

export default function TopSongs({
  createdOn,
  description,
  playlistName,
  likesCount,
  commentsCount,
  data,
}) {
  const [colorLike, setColorLike] = useState(false);
  const [likeCount, setLikeCount] = useState(likesCount);
  const [imageData, setImageData] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const handleGetPlaylistSongs = () => {
    api('get', `/playlists/${data?.id}/songs/`)
      .then((res) => {
        console.log(res?.data?.results, 'playlist res>');
        setImageData(res?.data?.results);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
      });
  };

  useEffect(() => {
    if (data?.id) {
      handleGetPlaylistSongs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id]);

  useEffect(() => {
    if (data?.is_liked === true) {
      setColorLike(true);
    }
    setLikeCount(likesCount);
  }, [data, likesCount]);

  const handleLikeClick = async () => {
    setColorLike((prevState) => !prevState);
    setLikeCount((prevCount) => (colorLike ? prevCount - 1 : prevCount + 1));
    const id = data?.id;

    try {
      const response = await api('post', `/playlists/${id}/like/`);
      console.log('resss', response);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };

  return (
    <div className="topSongsDetailsDiv">
      <div className="imgListRow">
        <div id="onDashboard">
          {imageData?.slice(0, 6).map((delta, index) => (
            <img src={delta?.image} alt={`pic ${index + 1}`} />
          ))}
        </div>
      </div>
      <div className="myTopSongsTxt">
        <p className="topsongsTxts">{playlistName}</p>
        <p className="createdonTxts">Created on {moment(createdOn).format('DD MMMM yyyy')}</p>
        <p className="topSongsDescrib" id="breakWordsWrap">
          {showFullDescription ? description : description.split(' ').slice(0, 10).join(' ')}
          {description.split(' ').length > 10 && (
            <span className="showMoreLess">
              {showFullDescription ? <span> ... </span> : <span> ...</span>}
              <a onClick={toggleDescription}>{showFullDescription ? 'Show Less' : 'Show More'}</a>
            </span>
          )}
        </p>
        <div className="dashedDivider"> </div>
        <div className="likeAndCommentsCountDiv">
          <div className="likesDiv">
            <div className="likesHolderPlaylistes">
              <img src={colorLike ? IconHeartLike : likes} alt="." onClick={handleLikeClick} />
            </div>
            <p className="commentsTxt">
              {likeCount} <span /> {likeCount <= 1 ? 'Like' : 'Likes'}
            </p>
          </div>
          <div className="commentsDiv">
            <div>
              <img src={IconComments} alt="comments" />
            </div>
            <p className="commentsTxt">
              {commentsCount} <span /> Comments
            </p>
          </div>
        </div>
        <div className="dividerDivs">
          <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 1)', opacity: '0.2' }} />
        </div>
      </div>
    </div>
  );
}
