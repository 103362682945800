/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '../assets/Images/SearchInput.svg';
import api from '../api/index';
import { debounce } from '../utils/helpers';
import usePageLoader from './PageLoader/usePageLoader';
import PageLoader from './PageLoader';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

function SearchInput({
  inputBackgroundColor,
  inputPlaceholder,
  setSongObj,
  setSelectedSong,
  selected,
  setSelected,
  noIcon,
  pdClass,
  noPdLeft,
  type,
}) {
  const autocomplete = useRef(null);
  const [optionsData, setOptionsData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();

  const handleInputChange = async (inputValue) => {
    if (!inputValue) return;
    setSelected(inputValue);
    startPageLoader();
    if (type === 'track') {
      try {
        const response = await api('get', `/songs/search/?q=${inputValue}&type=${type}`);
        console.log(response, 'album');
        setOptionsData({
          items: response?.data?.tracks?.items || [],
        });
        console.log(response?.data?.tracks?.items, 'running');
      } catch (err) {
        errorLogCountAnalyticsEvent();
        // handle error
      }
    } else {
      try {
        // setSelected(inputValue);
        const albumResponse = await api('get', `/songs/search/?q=${inputValue}&type=album`);
        const songResponse = await api('get', `/songs/search/?q=${inputValue}&type=track`);
        if (albumResponse.status === 200 && songResponse.status === 200) {
          setOptionsData({
            items: [...albumResponse.data.albums.items, ...songResponse.data.tracks.items],
          });
        } else {
          // Handle errors for both or either API calls
        }
      } catch (err) {
        errorLogCountAnalyticsEvent();
        // Handle errors for both or either API calls
      }
    }
    stopPageLoader();
  };
  useEffect(() => {
    if (optionsData?.items?.length > 0) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }, [selected, optionsData]);

  const liRef = useRef(null);

  const handleClickOutSide = () => {
    console.log('Running =>');
    setIsShow(false);
  };

  useEffect(() => {
    const listener = (event) => {
      if (!autocomplete.current || autocomplete.current.contains(event.target)) {
        return;
      }
      handleClickOutSide();
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [autocomplete]);

  const handleSong = (item) => {
    console.log('Songitem=> ', item);
    setSelectedSong(item);
    const artistNameNew = item && item.artists && item.artists[0] ? item.artists[0].name : '';
    const spotifySongId = item && item.id ? item.id : '';
    const artistImage = item && item.images ? item.images[0].url : item.album.images[0].url;
    const artistSpotifyId = item && item.artists ? item.artists?.[0].id : '';
    const spotifyUrl = item && item.external_urls ? item.external_urls?.spotify : '';
    const songTitle = item && item.name ? item.name : '';
    const songImage = item && item.images ? item.images[0].url : item.album.images[0].url;
    const songYear = item && item.release_date ? item.release_date : item.album.release_date;
    setSongObj((prevState) => ({
      ...prevState,
      artist_name: artistNameNew,
      spotify_id: spotifySongId,
      artist_image: artistImage,
      artist_spotify_id: artistSpotifyId,
      spotify_url: spotifyUrl,
      title: songTitle,
      image: songImage,
      year: songYear,
    }));
  };

  console.log('Set show ', isShow);

  const debouncedSearch = debounce((term) => {
    // Your logic for handling the search term
    handleInputChange(term);
  }, 1500); // 1.5 seconds

  return (
    <div ref={autocomplete} style={{ position: 'relative', width: '100%' }}>
      {!noIcon && (
        <div className="searchIconInput">
          <img src={SearchIcon} alt="icon" />
        </div>
      )}
      <PageLoader isLoading={isPageLoading} />

      <input
        className="SearchInput-input"
        style={{
          position: 'relative',
          width: '100%',
          padding: pdClass || '10px',
          paddingLeft: noPdLeft ? pdClass : '34px',
          color: 'black',
          borderRadius: '12px',
          outline: 'none',
          fontSize: '12px',
          backgroundColor: inputBackgroundColor,
        }}
        type="search"
        placeholder={inputPlaceholder}
        defaultValue={selected}
        onChange={(e) => {
          debouncedSearch(e.target.value);
        }}
      />

      {isShow ? (
        <ul ref={liRef} id="hide_Scrollbar">
          {optionsData?.items?.map((item) => (
            <div className="overAllsearchDivWrap" key={item?.id}>
              <li
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  gap: '12px',
                  cursor: 'pointer',
                  width: '100%',
                  margin: 'auto',
                  textAlign: 'left',
                  backgroundColor: '#434854',
                  color: 'white',
                  padding: '6px 8px 6px 8px',
                }}
                onClick={() => handleSong(item)}
              >
                <div className="searchListImgsDivHold">
                  <img
                    alt="pic"
                    src={item?.images ? item?.images[2]?.url : item?.album?.images[2]?.url}
                    className="object-cover rounded-full w-12 h-12 ml-2 2xl:w-13 2xl:h-13"
                  />
                </div>
                <div className="nameAndSubNameInSearch">
                  <div className="songNameInSearchList">{item?.name}</div>
                  <span>|</span>
                  <span style={{ fontFamily: 'Quicksand', fontSize: '12px' }}>
                    {item?.artists[0]?.name}
                  </span>
                </div>
              </li>
            </div>
          ))}
        </ul>
      ) : (
        ''
      )}
    </div>
  );
}

export default SearchInput;
