/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setAccessToken, getRecentlyPlayedTracks } from '../Spotify';
import refreshToker from '../refreshToken';
import ShuffleIcon from '../assets/Images/ShuffleIconSongs.svg';

import WhiteStar from '../assets/Images/WhiteStarIcon.svg';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';
import api from '../api';
import usePageLoader from './PageLoader/usePageLoader';
import PageLoader from './PageLoader';

function shuffleArray(array) {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 8,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 8,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function RecentlyPlayedSongs({ setSpotifySong, setAppleSong, setWriteReview }) {
  try {
    const isUserLoggedInWithAppleSSO = localStorage.getItem('appleSSO');
    if (isUserLoggedInWithAppleSSO) {
      const isLoggedIn = JSON.parse(isUserLoggedInWithAppleSSO);
      if (isLoggedIn === true) {
        return (
          <AppleMusicRecentlyPlayedSongs setSong={setAppleSong} setWriteReview={setWriteReview} />
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
  return <SpotifyRecentlyPlayedSongs setSong={setSpotifySong} setWriteReview={setWriteReview} />;
}

function SpotifyRecentlyPlayedSongs({ setSong, setWriteReview }) {
  const spotifyToken = localStorage.getItem('spotifyAccessToken');
  const [recentlyPlayed, setRecentlyPlayed] = useState();
  const navigate = useNavigate();

  const handleRateClick = (item) => {
    setSong(item);
    console.log(item);
    setWriteReview(true);
  };

  const handleConnectSpotifyClick = () => {
    navigate('/spotifyConfirmation');
  };

  function handleShuffleClick() {
    const shuffledData = shuffleArray(recentlyPlayed);
    setRecentlyPlayed(shuffledData);
  }

  useEffect(() => {
    const spotifyRefreshToken = localStorage.getItem('refresh_token');
    const refreshToken = spotifyRefreshToken;
    async function fetchRefreshToken() {
      const newAccessToken = await refreshToker(refreshToken);
      localStorage.setItem('spotifyAccessToken', newAccessToken);
    }
    fetchRefreshToken();
  }, []);

  useEffect(() => {
    const accessToken = spotifyToken;
    setAccessToken(accessToken);
    getRecentlyPlayedTracks()
      .then((response) => {
        setRecentlyPlayed(response?.items);
        console.log(response, 'qwertyuio');
      })
      .catch((error) => {
        errorLogCountAnalyticsEvent();
        console.error('Error fetching recently played tracks:', error);
      });
  }, [spotifyToken]);
  console.log('recentlyPlayedrecentlyPlayed', recentlyPlayed);
  return (
    <>
      <div className="Musotic_Spotify_txt_shuffle">
        <p className="Musotic_Spotify_recent_txt">Recently Played on spotify</p>
        {!!recentlyPlayed?.length && (
          <div className="zIndexClass" onClick={handleShuffleClick}>
            <img src={ShuffleIcon} alt="shuffle" />
          </div>
        )}
      </div>
      {!!recentlyPlayed?.length && (
        <div className="Musotic_Spotify_track_data">
          <Carousel infinite sx={{ height: '100%', width: '100%' }} responsive={responsive}>
            {recentlyPlayed?.map((item) => (
              <div className="Musotic_Spotify_recent_track" key={item?.id}>
                <div className="Musotic_Spotify_track_rate">
                  <img src={item?.track?.album?.images[0]?.url} alt="img not found" />
                  <div className="Musotic_Spotify_rate_star" onClick={() => handleRateClick(item)}>
                    <img src={WhiteStar} alt="" />
                    <p className="Musotic_Spotify_ratenow_txt">Rate Now</p>
                  </div>
                </div>
                <div className="track_artisit_txt">
                  <p className="track_name_txt"> A {item?.track?.name}</p>
                  <p className="artist_name_txt">{item?.track?.artists[0]?.name}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )}
      {!spotifyToken && !recentlyPlayed && (
        <div className="noDataForRecentlyPlayed">
          <p className="noDatayetText">Please connect spotify to access recently played tracks.</p>
          <button className="connectSpotifyBtn" type="submit" onClick={handleConnectSpotifyClick}>
            Connect Spotify
          </button>
        </div>
      )}
    </>
  );
}

function AppleMusicRecentlyPlayedSongs({ setSong, setWriteReview }) {
  const musicKitRef = useRef(null);
  const [isAppleMusicConnected, setIsAppleMusicConnected] = useState(false);
  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();

  const [recentlyPlayed, setRecentlyPlayed] = useState();
  function handleShuffleClick() {
    const shuffledData = shuffleArray(recentlyPlayed);
    setRecentlyPlayed(shuffledData);
  }
  function formatAppleMusicImageUrl(url = '') {
    if (url) {
      return url.replace('{w}', 100).replace('{h}', 100);
    }
    return '';
  }

  const handleRateClick = (item) => {
    setSong({
      ...item,
      attributes: {
        ...item.attributes,
        artwork: {
          ...item.attributes.artwork,
          url: formatAppleMusicImageUrl(item.attributes.artwork.url),
        },
      },
    });
    console.log(item);
    setWriteReview(true);
  };

  async function fetchAppleRecentlyPlayedSong() {
    startPageLoader();
    try {
      if (musicKitRef.current) {
        const music = await musicKitRef.current.getInstance();
        if (!music.isAuthorized) {
          toast.error('Apple music is not connected.');
          stopPageLoader();
          // setIsAppleMusicConnected(false);
          return;
        }
        // setIsAppleMusicConnected(true);
        const response = await music.api.music('v1/me/recent/played/tracks');
        console.log(response, 'tracks');
        if (response?.data?.data?.length) {
          setRecentlyPlayed(response.data.data);
        } else {
          toast.error('No songs found for recently played on apple music.');
        }
      }
    } catch (error) {
      console.log(error);
      toast.error('Unable to fetch recently played song.');
      setIsAppleMusicConnected(false);
      stopPageLoader();
    }
    stopPageLoader();
  }

  const handleConnectAppleMusicClick = async () => {
    startPageLoader();
    try {
      if (musicKitRef.current) {
        const music = musicKitRef.current.getInstance();
        console.log('getInstance', music);
        if (!music.isAuthorized && !isAppleMusicConnected) {
          console.log('calling music.authorize()');
          const authorizeResult = await music.authorize();
          console.log('authorizeResult', authorizeResult);
          setIsAppleMusicConnected(true);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error('Unable to login Apple Music.');
      stopPageLoader();
      setIsAppleMusicConnected(false);
    }
    stopPageLoader();
  };

  async function onMusicKitLoad() {
    startPageLoader();
    try {
      if (musicKitRef.current) {
        const res = await api('get', '/apple-developer-token/', undefined, undefined, true);
        if (res?.data?.payload?.developer_token) {
          const appleDeveloperToken = res?.data?.payload?.developer_token;
          await musicKitRef.current.configure({
            developerToken: appleDeveloperToken,
            app: {
              name: 'Musotic Web App',
              // build: '1978.4.1',
            },
          });
          const music = musicKitRef.current.getInstance();
          if (music.isAuthorized) {
            console.log('Authorized');
            setIsAppleMusicConnected(true);
          } else {
            console.log('not Authorized');
            setIsAppleMusicConnected(false);
            // await music.unauthorize();
          }
        }
      }
    } catch (error) {
      console.log(error);
      stopPageLoader();
    }
    stopPageLoader();
  }

  console.log('recentlyPlayedrecentlyPlayed', recentlyPlayed);

  useEffect(() => {
    if (isAppleMusicConnected) {
      fetchAppleRecentlyPlayedSong();
    }
  }, [isAppleMusicConnected]);
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = 'https://js-cdn.music.apple.com/musickit/v3/musickit.js';
    script.async = true;

    // Define an onLoad callback to store the MusicKit global object in the ref
    const onLoad = () => {
      if (window.MusicKit) {
        musicKitRef.current = window.MusicKit;
        console.log('musicKitRef.current', musicKitRef.current);
        onMusicKitLoad();
        // Now you can use musicKitRef.current to interact with MusicKit
      }
    };

    // Attach the onLoad callback to the script's onload event
    script.onload = onLoad;
    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
      musicKitRef.current = null;
    };
  }, []);
  return (
    <>
      <PageLoader isLoading={isPageLoading} />
      <div className="Musotic_Spotify_txt_shuffle">
        <p className="Musotic_Spotify_recent_txt">Recently Played on apple music</p>
        {!!recentlyPlayed?.length && (
          <div className="zIndexClass" onClick={handleShuffleClick}>
            <img src={ShuffleIcon} alt="shuffle" />
          </div>
        )}
      </div>
      {isAppleMusicConnected && recentlyPlayed?.length && (
        <div className="Musotic_Spotify_track_data">
          <Carousel infinite sx={{ height: '100%', width: '100%' }} responsive={responsive}>
            {recentlyPlayed?.map((item) => (
              <div className="Musotic_Spotify_recent_track" key={item?.id}>
                <div className="Musotic_Spotify_track_rate">
                  <img
                    src={formatAppleMusicImageUrl(item?.attributes?.artwork?.url)}
                    alt="img not found"
                  />
                  <div className="Musotic_Spotify_rate_star" onClick={() => handleRateClick(item)}>
                    <img src={WhiteStar} alt="" />
                    <p className="Musotic_Spotify_ratenow_txt">Rate Now</p>
                  </div>
                </div>
                <div className="track_artisit_txt">
                  <p className="track_name_txt"> {item?.albumName}</p>
                  <p className="artist_name_txt">{item?.artistName}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )}
      {!isAppleMusicConnected && !recentlyPlayed && (
        <div className="noDataForRecentlyPlayed">
          <p className="noDatayetText">
            Please connect apple music to access recently played tracks.
          </p>
          <button
            className="connectSpotifyBtn"
            type="submit"
            onClick={handleConnectAppleMusicClick}
          >
            Connect Apple Music
          </button>
        </div>
      )}
    </>
  );
}

export default RecentlyPlayedSongs;
