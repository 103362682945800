/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Modal, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import BackIcon from '../../assets/Images/back.svg';
import dummyProfileImage from '../../assets/Images/dummyProfileImg.jpg';
import api from '../../api';
import usePageLoader from '../PageLoader/usePageLoader';
import PageLoader from '../PageLoader';
import { errorLogCountAnalyticsEvent } from '../../service/analyticsService';

function EditProfilePopup({
  openEditProfile,
  handleCloseEditModal,
  style2,
  userData,
  TabPanel,
  fetchUserData,
}) {
  const [profileTab, setProfileTab] = useState(0);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [isImageLoading, setImageLoading] = useState(false);
  const [profileImg, setProfileImg] = useState('');
  const [previewImageProfileDp, setPreviewImageProfileDp] = useState(null);
  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();

  const [user, setUser] = useState({
    userLink: userData?.link ?? '',
    userBio: userData?.bio ?? '',
    userPhone: userData?.phone ?? '',
    userGender: userData?.gender ?? '',
    userDob: userData?.birthday ?? 'YYYY-MM-DD',
    location: userData?.country ?? '',
    email: userData?.email ?? '',
    name: userData?.name ?? '',
    username: userData?.username ?? '',
  });
  console.log(user, 'sdfdsasdsas');
  const handleChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  console.log(user, 'statttttes');
  const handleProfileTabs = (event, newValue) => {
    setProfileTab(newValue);
  };
  const handleChangeUserInfo = async () => {
    if (!isImageLoading) {
      // eslint-disable-next-line camelcase
      const avatar_url = profileImageUrl || userData?.profile?.avatar_url;
      const bio = user?.userBio;
      const link = user?.userLink;
      const gender = user?.userGender;
      const dob = user?.userDob;
      const country = user?.location;
      const phone = user?.userPhone;
      const userEmail = user?.email;
      const name = user?.name;
      const username = user?.username;
      // eslint-disable-next-line camelcase
      if (!userEmail) {
        toast.error('Email is required');
        return;
      }
      const profile = { avatar_url, bio, link, gender, dob, country, phone, userEmail };
      startPageLoader();
      try {
        const { data } = await api('put', '/users/update-profile/', {
          username,
          name,
          profile,
          email: userEmail,
        });
        if (data) {
          fetchUserData();
          handleCloseEditModal();
          toast.success('Profile Updated successfully');
        }
      } catch (err) {
        errorLogCountAnalyticsEvent();
        if (err?.response?.data?.error?.name) {
          toast.error(`name: ${err?.response?.data?.error?.name?.[0]}`);
        }
        if (err?.response?.data?.error?.username) {
          toast.error(`username: ${err?.response?.data?.error?.username?.[0]}`);
        }
        if (err?.response?.data?.error?.profile?.link) {
          toast.error(`link: ${err?.response?.data?.error?.profile?.link?.[0]}`);
        }

        if (err?.response?.data?.error?.profile?.country) {
          toast.error(`location: ${err?.response?.data?.error?.profile?.country?.[0]}`);
        }

        if (err?.response?.data?.error?.profile?.bio) {
          toast.error(`bio: ${err?.response?.data?.error?.profile?.bio?.[0]}`);
        }

        if (err?.response?.data?.error?.email) {
          toast.error(`email: ${err?.response?.data?.error?.email?.[0]}`);
        }

        if (err?.response?.data?.error?.profile?.phone) {
          toast.error(err?.response?.data?.error?.profile?.phone?.[0]);
        }
        if (err?.response?.data?.error?.profile?.gender) {
          toast.error(`gender: ${err?.response?.data?.error?.profile?.gender?.[0]}`);
        }
        if (err?.response?.data?.error?.profile?.dob) {
          toast.error(err?.response?.data?.error?.profile?.dob?.[0]);
        }
      }
      stopPageLoader();
    } else {
      toast.error('image being uploaded');
    }
  };

  const profileImageUrlFromApi = async () => {
    const formData = new FormData();
    formData.append('image', profileImg);
    console.log('form', formData);
    startPageLoader();
    try {
      const response = await api('post', '/upload-image/', formData);
      setProfileImageUrl(response?.data?.image_url);
      setImageLoading(false);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
    stopPageLoader();
  };
  console.log('profileImageUrl', profileImageUrl);
  const handleProfileImageChange = (e) => {
    const selectedProfileImage = e.target.files[0];
    if (!selectedProfileImage.type.includes('image')) {
      toast.error('Only JPEG and PNG files are allowed.');
      return;
    }
    setProfileImg(selectedProfileImage);
    if (selectedProfileImage) {
      setImageLoading(true);
      setPreviewImageProfileDp(URL.createObjectURL(selectedProfileImage));
    }
  };
  useEffect(() => {
    if (userData) {
      setUser((data) => ({
        ...data,
        userLink: userData?.link,
        userBio: userData?.bio,
        userPhone: userData?.phone,
        userGender: userData?.gender,
        userDob: userData?.birthday,
        location: userData?.country,
        email: userData?.email,
        name: userData?.name,
        username: userData?.username,
      }));
    }
  }, [userData]);

  useEffect(() => {
    if (profileImg) profileImageUrlFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileImg]);

  return (
    <Modal
      open={openEditProfile}
      onClose={handleCloseEditModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="basic_tabs"
    >
      <Box className="edit_profile_popup" sx={style2}>
        <PageLoader isLoading={isPageLoading} />
        <div className="HandleContainers">
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => console.log('first')}
            className="backIcon"
            onClick={handleCloseEditModal}
          >
            <img src={BackIcon} alt="" />
          </div>
          <div className="HPimgprofiile">
            <label className="upload-pic-label" htmlFor="filePicker">
              <input
                type="file"
                hidden
                id="filePicker"
                name="file"
                onChange={handleProfileImageChange}
                accept="image/png, image/jpeg"
              />
              <div style={{ cursor: 'pointer' }} className="handleImg">
                {previewImageProfileDp ? (
                  <img src={previewImageProfileDp} type="file" alt="i" />
                ) : (
                  <img src={userData?.avatarUrl || dummyProfileImage} alt="i" />
                )}
              </div>
              <div className="para">Change profile photo</div>
            </label>
          </div>
          <div className="doneText" onClick={!isImageLoading ? handleChangeUserInfo : null}>
            <p className={`doneText ${isImageLoading ? 'inactiveBtn' : ''}`}>Done</p>
          </div>
        </div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <div className="handleContainers2">
            <Tabs value={profileTab} onChange={handleProfileTabs} aria-label="basic tabs example">
              <Tab
                label="Profile"
                value={0}
                style={{
                  width: '48%',
                  textTransform: 'capitalize',
                  color: 'white',
                  fontSize: '13px',
                  fontFamily: 'Quicksand',
                }}
              />
              <Tab
                label="Personal Details"
                value={1}
                style={{
                  width: '52%',
                  textTransform: 'capitalize',
                  color: 'white',
                  fontSize: '13px',
                  fontFamily: 'Quicksand',
                }}
              />
            </Tabs>
          </div>
        </Box>
        <TabPanel value={profileTab} index={0} style={{ width: '100%' }}>
          <div className="fieldsContainer">
            <div className="inputFields">
              <p className="lable">Name</p>
              <input
                className="input_field3"
                placeholder="name"
                name="name"
                value={user?.name}
                onChange={handleChange}
              />
            </div>
            <div className="inputFields">
              <p className="lable">Username</p>

              <input
                className="input_field3"
                placeholder="johanmike"
                name="username"
                value={user?.username}
                onChange={handleChange}
              />
            </div>
            <div className="inputFields">
              <p className="lable">Link</p>

              <input
                className="input_field3"
                value={user?.userLink}
                placeholder="Add Link"
                name="userLink"
                onChange={handleChange}
              />
            </div>

            <div className="inputFields">
              <p className="lable">Location</p>
              <input
                className="input_field3"
                placeholder="NY, USA"
                onChange={handleChange}
                value={user?.location}
                name="location"
              />
            </div>

            <div className="inputFields">
              <p className="lable">Bio</p>

              <input
                className="input_field3"
                onChange={handleChange}
                value={user?.userBio}
                name="userBio"
                placeholder="your bio will be visible to users on your profile."
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={profileTab} index={1}>
          <div className="fieldsContainer">
            <div className="inputFields">
              <p className="lable">Email</p>

              <input
                className="input_field3"
                type="text"
                placeholder="abc@gmail.com"
                onChange={handleChange}
                value={user?.email}
                name="email"
              />
            </div>
            <div className="inputFields">
              <p className="lable">Phone Number</p>

              <input
                className="input_field3"
                value={user?.userPhone}
                name="userPhone"
                placeholder="+123456789"
                onChange={handleChange}
              />
            </div>
            <div className="inputFields">
              <p className="lable">Gender</p>

              <input
                className="input_field3"
                placeholder="Gender"
                value={user?.userGender}
                name="userGender"
                onChange={handleChange}
              />
            </div>

            <div className="inputFields">
              <p className="lable">Birthday</p>

              <input
                className="input_field3"
                value={user?.userDob}
                name="userDob"
                placeholder="YYYY-MM-DD"
                onChange={handleChange}
              />
            </div>
          </div>
        </TabPanel>
      </Box>
    </Modal>
  );
}

export default EditProfilePopup;
