/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import axios from 'axios';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import morningIMG from '../assets/Images/Morning.svg';
import editIcon from '../assets/Images/EditOption.svg';
import api from '../api/index';
import addSongs from '../assets/Images/VectoraddSongs.svg';
import shareIcon from '../assets/Images/ShareOption.svg';
import spotifylogo from '../assets/Images/spotifyLogoBlue.svg';
import appleMusicIcon from '../assets/Images/appleMusicIcon.png';
import PostComponent from '../components/PostComponent';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
// import OpenSetting from '../assets/Images/OpenSetting.svg';
import arrowRound from '../assets/Images/arrowRound.svg';
import shareMini from '../assets/Images/shareMini.svg';
import starMini from '../assets/Images/starMini.svg';
import starMiniYellow from '../assets/Images/starMiniYellow.svg';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import { ReactComponent as RayOne } from '../assets/Images/colorRay1.svg';
import { ReactComponent as RayTwo } from '../assets/Images/colorRay2.svg';
import CreateReviewModal2 from '../components/CreateReviewModel2';
import RightTop from '../assets/Images/RightTop.svg';
import refreshToker from '../refreshToken';
import BackArrow from '../assets/Images/BackArrow.svg';
import SearchIcon from '../assets/Images/SearchIcon.svg';
import PageLoader from '../components/PageLoader';
import usePageLoader from '../components/PageLoader/usePageLoader';
import {
  analyticsPages,
  errorLogCountAnalyticsEvent,
  songShareAnalyticsEvent,
} from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';
import { doesUserLoginFromAppleSSO } from '../utils/helpers';

export default function SongDetails() {
  const navigate = useNavigate();
  const { songId } = useParams();
  const [selectedValue, setSelectedValue] = useState('top');
  const [searchClick, setSearchClick] = useState();
  const [spotifyAuthError, setSpotifyAuthError] = useState('');
  const [Song, setSong] = useState('');
  const [popularSong, setPopularSong] = useState();
  const [reviewPosts, setReviewPosts] = useState([]);
  const [relatedAlbums, setRelatedAlbums] = useState([]);
  const [spotifySongLink, setSpotifySongLink] = useState();
  const id = songId;
  const [songDeatile, setSongDeatile] = useState(false);
  const [writeReview, setWriteReview] = useState(false);
  const [openn, setOpenn] = React.useState(false);
  const [searchValue, setSearchValue] = useState();
  const [selectedPlaylists, setSelectedPlaylists] = useState();
  const [playlistsName, setPlaylistsName] = useState([]);
  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();
  const profileImg = localStorage.getItem('profilePicLink');
  const handleOpen = () => {
    setSongDeatile(true);
  };
  const handleClosee = () => setOpenn(false);
  const style2 = {
    position: 'absolute',
    top: '20%',
    left: '36%',
    width: 400,
    bgcolor: 'transparent',
    border: 'none',
    '@media (max-width: 1100px)': {
      top: '24%',
      left: '32%',
    },
    '@media (max-width:1024px)': {
      top: '29%',
    },
  };
  const handleAddPlaylistsApi = async () => {
    if (!selectedPlaylists) {
      toast.error('please select atleast 1 playlist');
      return;
    }
    const body = {
      song: {
        artist_name: Song?.artist_name,
        artist_spotify_id: Song?.artist_spotify_id,
        title: Song?.title,
        year: Song?.year,
        image: Song?.image,
        spotify_id: Song?.spotify_id,
      },
      playlists: selectedPlaylists,
    };
    try {
      const response = await api('post', `/playlists/add-song/`, body);
      console.log(response, 'resss');
      setOpenn(false);
      if (selectedPlaylists?.length > 1) {
        toast.success('Song added to the playlists');
      } else {
        toast.success('Song added to the playlist');
      }
      setSelectedPlaylists('');
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  const handleCheckboxChange = (playlist) => {
    setSelectedPlaylists((prevSelected) => {
      if (!Array.isArray(prevSelected)) {
        // eslint-disable-next-line no-param-reassign
        prevSelected = [];
      }
      if (prevSelected.includes(playlist.id)) {
        return prevSelected.filter((PlaylistId) => PlaylistId !== playlist.id);
      }
      return [...prevSelected, playlist.id];
    });
  };
  const handlePlaylistSearch = () => {
    setSearchClick((prevState) => !prevState);
  };
  const handlePlaylistSearchValue = async (value) => {
    setOpenn(true);
    setSearchValue(value);
    if (value?.length > 1) {
      try {
        const response = await api('get', `/users/my-playlists/?q=${value}`);
        setPlaylistsName(response?.data?.results);
      } catch (err) {
        errorLogCountAnalyticsEvent();
        console.log('Error fetching user reviews:', err);
      }
    } else {
      try {
        const response = await api('get', `/users/my-playlists/`);
        setPlaylistsName(response?.data?.results);
      } catch (err) {
        errorLogCountAnalyticsEvent();
        console.log('Error fetching user reviews:', err);
      }
    }
  };
  console.log('data12345', searchValue);
  const handleShareSong = async () => {
    // const urll = window.location + window.location.pathname;
    try {
      const data1 = {
        title: 'Sharing Song ',
        text: 'Hey, sharing this song with you !',
        url: `https://musotic-dev.falconweb.app/songDetails/${id}`,
      };
      songShareAnalyticsEvent();
      await navigator.share(data1);
    } catch (error) {
      errorLogCountAnalyticsEvent();
      console.error('Copy failed:', error);
    }
  };

  const handleClose = () => {
    setSongDeatile(false);
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const fetchUserReviews = async () => {
    try {
      const response = await api('get', `/songs/${id}/`);
      console.log('resSongi', response?.data);
      setSong(response?.data);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };

  const fetchAlbumsFromSpotify = async () => {
    await axios
      .get(`https://api.spotify.com/v1/albums/${Song?.spotify_id}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('spotifyAccessToken')}`,
        },
      })
      .then((trackResponse) => {
        setSpotifySongLink(trackResponse?.data?.external_urls?.spotify);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log('fetchAlbumsFromSpotify', err?.response?.data?.error?.message);
        setSpotifyAuthError(true);
      });
  };

  const fetchTracksFromSpotify = async () => {
    await axios
      .get(`https://api.spotify.com/v1/tracks/${Song?.spotify_id}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('spotifyAccessToken')}`,
        },
      })
      .then((trackResponse) => {
        setSpotifySongLink(trackResponse?.data?.external_urls?.spotify);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log('fetchTracksFromSpotify', err?.response?.data?.error?.message);
        fetchAlbumsFromSpotify();
        setSpotifyAuthError(true);
      });
  };

  const fetchRelatedAlbums = async () => {
    try {
      const response = await api('get', `/albums/${Song?.album_id}/related-albums/`);
      console.log('albumssRelated', response?.data?.results);
      setRelatedAlbums(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  useEffect(() => {
    if (Song) {
      fetchRelatedAlbums();
      fetchTracksFromSpotify();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Song?.album_id]);
  useEffect(() => {
    const spotifyRefreshToken = localStorage.getItem('refresh_token');
    const refreshToken = spotifyRefreshToken;
    async function fetchRefreshToken() {
      const newAccessToken = await refreshToker(refreshToken);
      localStorage.setItem('spotifyAccessToken', newAccessToken);
    }
    fetchRefreshToken();
    fetchAlbumsFromSpotify();
    fetchTracksFromSpotify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spotifyAuthError]);
  useEffect(() => {
    if (id) {
      fetchUserReviews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchPopularReleases = async () => {
    try {
      const response = await api(
        'get',
        `/songs/popular-releases/?artist_id=${Song?.artist_spotify_id}`,
      );
      setPopularSong(response?.data);
      console.log('resssxx', response?.data);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  useEffect(() => {
    fetchPopularReleases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Song?.album_spotify_id]);

  const fetchReviewData = async () => {
    try {
      const response = await api('get', `/songs/${id}/reviews/?filters=${selectedValue}`);
      const reviewResults = response?.data?.results;

      if (reviewResults && reviewResults?.length > 0) {
        const promises = reviewResults?.map(async (item) => {
          const commentsResponse = await api('get', `/reviews/${item?.id}/comments/`);
          const comments = commentsResponse?.data?.results;

          return {
            review: item,
            review_comments: comments,
          };
        });

        const results = await Promise.all(promises);
        console.log('loopx2', results);
        setReviewPosts(results);
      } else {
        setReviewPosts([]);
      }
    } catch (error) {
      errorLogCountAnalyticsEvent();
      console.error(error);
    }
  };

  useEffect(() => {
    fetchReviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const handleComment = (targetId, comment) => {
    if (comment !== '') {
      api('post', `/review-comments/`, { text: comment, review: targetId })
        .then((res) => {
          fetchReviewData();
          console.log(res);
        })
        .catch((err) => {
          errorLogCountAnalyticsEvent();
          console.log(err);
        });
    }
  };

  const fetchSongReviews = async () => {
    startPageLoader();
    try {
      const response = await api('get', `/songs/${id}/reviews/`);
      console.log('songReviews', response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
    stopPageLoader();
  };
  useEffect(() => {
    fetchSongReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const handleCloseReview = () => {
    setWriteReview(false);
  };

  const handleOpenRelatedAlbum = (albumId) => {
    navigate(`/albumDetails/${albumId}`);
  };
  const handleOpenPopularSong = (url) => {
    window.open(url, '_blank');
  };

  usePageViewAnalyticsHook({
    title: analyticsPages.songDetails,
  });

  return (
    <div className="songDeatilsContainer">
      <PageLoader isLoading={isPageLoading} />
      <div className="leftbottomColor leftbottomColor_dashboard_song_details">
        <img src={RightTop} alt="x" />
      </div>
      <div className="songDetailsInsideDiv">
        <div className="songBannerDiv">
          <img src={Song?.album_image || Song?.image} alt="bannerHere" />
        </div>
        <div className="songImgDp">
          <img src={Song?.image} alt="bannerHere" />
        </div>
        <div className="bannerDetailsDiv">
          <div className="songBannerNameEtc">
            <p className="songNameWithImg">{Song?.title}</p>
            <p className="songArtistWithImg">
              Track | {Song?.title} | {Song?.year || '--'}
            </p>
            <p className="songBeatlesTxt">{Song?.artist_name}</p>
          </div>
          <div className="songBannerRatingsEtc">
            <div className="ratingsBanner1Div">
              <div className="ratings1Insider">
                <p>{Song?.total_ratings}</p>
                <p className="opacityClass">Total Ratings</p>
              </div>
              <div className="dividerClass">|</div>
              <div className="ratings1Insider">
                <div className="displayFlex">
                  <span className="iconSS">
                    <img src={starMiniYellow} alt="icon" />
                  </span>

                  <p>{Song?.ratings_average}</p>
                </div>
                <p className="opacityClass">Average</p>
              </div>
              <div className="dividerClass">|</div>
              <div className="ratings1Insider" id="ratings33Insider">
                <div className="displayFlex">
                  <span className="iconSS">
                    <img src={starMiniYellow} alt="icon" />
                  </span>

                  <p className="yourRatingFigure">{Song?.your_ratings || '00'}</p>
                </div>

                <p className="opacityClass">Your Rating</p>
              </div>
            </div>
            <div className="reviewAndShareBtnsDiv">
              <button className="shareSongBtnx" type="submit" onClick={handleShareSong}>
                <span className="iconSS">
                  <img src={shareMini} alt="icon" />
                </span>
                Share Song
              </button>
              <button
                className="writeReviewBtnx"
                onClick={() => setWriteReview(true)}
                type="submit"
              >
                <span className="iconSS">
                  <img src={starMini} alt="icon" />
                </span>
                Write a Review
              </button>
            </div>
          </div>
        </div>
        <div className="songIntensityAndTimeDiv">
          <div className="intensityBarSongInside">
            <div className="colorIntensityTxt">Color Intensity</div>
            <div className="raysWrapperSongDetails">
              <RayOne
                className="raysWrapSongDetails"
                style={{ color: Song?.color_intensity?.color_one_rgb }}
              />
              <RayTwo
                className="raysWrapSongDetails"
                style={{ color: Song?.color_intensity?.color_two_rgb }}
              />
            </div>
          </div>
          <div className="listeningTimeBarInsider">
            <div className="colorsImgDivDetails2">
              <img src={morningIMG} alt="colors" />
            </div>
            <div className="colorIntensityTxt">Mostly listened in mornings</div>
          </div>
        </div>
        <div className="topReviewsAndActions">
          <div className="topHeadingsDivReviewAndActions">
            <div className="topReviewsHeadingHolder">
              <p className="topReviewTxtP">Top Reviews</p>
              <p className="filterTxtP" onClick={handleOpen}>
                Filter
              </p>
            </div>
            <div className="optionsHeadingsTxt">
              <p>Options</p>
            </div>
          </div>
          <div className="secondDivInsiderSongDetails">
            <div className="postComponentHolderOnSongDetails" style={{ width: '60%' }}>
              {reviewPosts?.length > 0 &&
                reviewPosts?.map((data) => (
                  <PostComponent
                    key={data?.review?.id}
                    ProfilePic={data?.review?.song?.image}
                    posterName={data?.review?.song?.title}
                    ProfilePicTop={data?.review?.user?.avatar}
                    ProfilePic2={data?.review?.user?.avatar || dummyProfileImage}
                    ProfilePicx={data?.review?.song?.image}
                    ProfilePicy={data?.review?.song?.image}
                    ProfilePicz={data?.review?.song?.image}
                    ProfilePicInComments2={data?.review?.song?.image}
                    morning={data?.review?.morning}
                    noon={data?.review?.noon}
                    evening={data?.review?.evening}
                    posterSubtitle={data?.review?.song?.artist_name}
                    title={data?.review?.title}
                    description={data?.review?.body}
                    data={data}
                    postedByUsername={data.review?.user?.username || data?.review?.user?.name}
                    hoursAgo={formatDistanceToNow(new Date(data?.review?.created_at), {
                      addSuffix: true,
                      includeSeconds: false,
                    })}
                    likedByText="Dummy"
                    likedByOthers="others"
                    commentsText="sample comment text here"
                    ratings={data?.review?.ratings}
                    ratingsNumber={data?.review?.ratings}
                    likedHours="1h"
                    usernameComments={data?.review?.user?.username}
                    likedHoursUnderComments="0.5h"
                    commentNoOfLikes={data?.review?.like_count}
                    reviewCommentsArray={data?.review_comments}
                    handleComment={handleComment}
                    feedReviewSongDetails={data?.review}
                    backgroundColor="#5A6276"
                    fetchReviewData={fetchReviewData}
                    userProfileImage={profileImg}
                  />
                ))}

              {reviewPosts?.length < 1 && (
                <div className="noReviewFoundDiv" style={{ width: '100%' }}>
                  <p className="noReviewYetTxt">
                    Seems like no one has gotten to this song yet. Be the first to write a review
                    for {Song?.title}.
                  </p>
                  <button
                    className="writeReviewBtnx"
                    onClick={() => setWriteReview(true)}
                    type="submit"
                  >
                    <span className="iconSS">
                      <img src={starMini} alt="icon" />
                    </span>
                    Write a Review
                  </button>
                </div>
              )}
            </div>

            <div className="optionsBoxContainer">
              <div className="optionsBoxDiv">
                <div className="optionsCategorydiv">
                  <div className="optionsCategoryImgHolder">
                    <img src={editIcon} alt="pic" />
                  </div>
                  <div onClick={() => setWriteReview(true)} className="optionsCategoryNameTxt">
                    Write Review
                  </div>
                </div>
                <div className="optionsCategorydiv">
                  <div className="optionsCategoryImgHolder">
                    <img src={addSongs} alt="pic" />
                  </div>
                  <div onClick={handlePlaylistSearchValue} className="optionsCategoryNameTxt">
                    Add Song To Playlist
                  </div>
                </div>
                <div className="optionsCategorydiv" onClick={handleShareSong}>
                  <div className="optionsCategoryImgHolder">
                    <img src={shareIcon} alt="pic" />
                  </div>
                  <div className="optionsCategoryNameTxt">Share</div>
                </div>
                <ListenMusic
                  appleMusicUrl={Song.apple_music_url}
                  spotifyMusicUrl={spotifySongLink || Song.spotify_url}
                />
              </div>
              <div className="albumlistdiv">
                <div className="albumheading">
                  <h2>Album Name</h2>
                  {/* <div>
                    <img src={OpenSetting} alt="." />
                  </div> */}
                </div>
                {relatedAlbums?.map((item) => {
                  return (
                    <div
                      key={item?.id}
                      className="albumdata"
                      onClick={() => handleOpenRelatedAlbum(item.id)}
                    >
                      <div className="albumpic">
                        <img src={item?.image} alt="img not found" />
                      </div>
                      <div className="albumtext">
                        <h2>{item?.title}</h2>
                        <p>
                          Album | {item?.year?.substring(0, 4)} | {item?.songs?.length} Tracks
                        </p>
                      </div>
                      <div className="albumlastarrow">
                        <img src={arrowRound} alt="." />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="tracklistDiv">
                <div>
                  <div className="tracklistHeadingDiv" id="marginTop">
                    <div className="traklistFont">Popular Releases</div>
                    {/* <div>
                      <img src={OpenSetting} alt="." />
                    </div> */}
                  </div>

                  {!popularSong ? (
                    <p id="marginTop" className="item-description">
                      No Popular Releases found for this Artist
                    </p>
                  ) : (
                    <div className="popularReleasesWrapper">
                      {popularSong?.map((song) => (
                        <div
                          className="overviewContainer"
                          key={song.id}
                          onClick={() => handleOpenPopularSong(song.url)}
                        >
                          <div className="popularSongSingleDiv">
                            <div className="popularSongImg">
                              <img src={song?.image} alt="." />
                            </div>
                            <div className="popularSongSingleDivTxt">
                              <div className="trackPopularName">{song?.name}</div>
                              <div className="trackPopularNameArtist">{song?.artist}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openn}
        onClose={handleClosee}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <div className="addPLaylist">
            <div className="inner_addPlaylist">
              <div className="playlist_first_Section">
                <div className={searchClick ? 'playlist_search' : 'playlist_txt_search'}>
                  <img src={BackArrow} alt="back" />
                  {!searchClick && <p className="playlist_txt">Add to Playlist</p>}
                </div>

                {searchClick && (
                  <input
                    className="searchPlaylistInput"
                    placeholder="Search Playlist..."
                    onChange={(e) => handlePlaylistSearchValue(e.target.value)}
                  />
                )}

                <img
                  className="cursorPointer"
                  src={SearchIcon}
                  alt="search"
                  onClick={handlePlaylistSearch}
                />
              </div>
              <div className="playlist_second_Section">
                {playlistsName?.map((playlist) => (
                  <div className="playlist_type_checkbox" key={playlist?.id}>
                    <p className="playlist_type_p">{playlist?.name}</p>
                    <Checkbox
                      style={{ color: '#57BBEB' }}
                      checked={selectedPlaylists?.includes(playlist?.id)}
                      onChange={() => handleCheckboxChange(playlist)}
                    />
                  </div>
                ))}
              </div>
              <button className="addPlaylistsBtn" type="submit" onClick={handleAddPlaylistsApi}>
                Add to Playlist
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={songDeatile} onClose={handleClose}>
        <Box className="filtersPopup">
          <div className="child-Container child-Container2">
            <h2 className="child-modal-title child-modal-title2">Filter</h2>
            <div className="clear" onClick={handleClose}>
              <img src={CloseIcon} alt="" srcSet="" />
            </div>
          </div>

          <div className="mainSideMenu2">
            <div className="sidemenu3">
              <span>Top Review</span>
              <RadioGroup value={selectedValue} onChange={handleChange}>
                <FormControlLabel value="top" control={<Radio style={{ color: '#57BBEB' }} />} />
              </RadioGroup>
            </div>
            <div className="sidemenu3">
              <span>Lowest Review</span>
              <RadioGroup value={selectedValue} onChange={handleChange}>
                <FormControlLabel value="lowest" control={<Radio style={{ color: '#57BBEB' }} />} />
              </RadioGroup>
            </div>
            <div className="sidemenu3">
              <span>My Review</span>
              <RadioGroup value={selectedValue} onChange={handleChange}>
                <FormControlLabel
                  value="my_reviews"
                  control={<Radio style={{ color: '#57BBEB' }} />}
                />
              </RadioGroup>
            </div>
            <div className="sidemenu3">
              <span>Friends Activity</span>
              <RadioGroup value={selectedValue} onChange={handleChange}>
                <FormControlLabel
                  value="friends_reviews"
                  control={<Radio style={{ color: '#57BBEB' }} />}
                />
              </RadioGroup>
            </div>
          </div>
        </Box>
      </Modal>
      <CreateReviewModal2
        open={writeReview}
        handleClose={handleCloseReview}
        Song={Song}
        fetchReviewData={fetchReviewData}
      />
    </div>
  );
}

function ListenMusic({ spotifyMusicUrl, appleMusicUrl }) {
  const handleListenOnSpotify = () => {
    if (!spotifyMusicUrl) {
      toast.error('Your spotify account is not linked yet!');
    }
    window.open(spotifyMusicUrl, '_blank');
  };

  const handleListenOnAppleMusic = () => {
    if (appleMusicUrl) {
      window.open(appleMusicUrl, '_blank');
    }
  };
  const _doesUserLoginFromAppleSSO = doesUserLoginFromAppleSSO();
  if ((_doesUserLoginFromAppleSSO && appleMusicUrl) || (!spotifyMusicUrl && appleMusicUrl)) {
    return (
      <div className="optionsCategorydivLast">
        <div className="optionsCategoryImgHolder">
          <img src={appleMusicIcon} alt="appleMusicIcon" />
        </div>
        <div className="optionsCategoryNameTxt" onClick={handleListenOnAppleMusic}>
          Listen On Apple Music
        </div>
      </div>
    );
  }

  if (spotifyMusicUrl) {
    return (
      <div className="optionsCategorydivLast">
        <div className="optionsCategoryImgHolder">
          <img src={spotifylogo} alt="pic" />
        </div>
        <div className="optionsCategoryNameTxt" onClick={handleListenOnSpotify}>
          Listen On Spotify
        </div>
      </div>
    );
  }
  return null;
}
