import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import BackIcon from '../assets/Images/PathBlockBack.svg';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';

const style = {
  position: 'absolute',
  top: '20%',
  left: '30%',
  width: 500,
  bgcolor: 'transparent',
  border: 'none',
};

function BlockUserPopup({ setOpenOption, openOption, handleBlock, blocked, profileData }) {
  const handleClose = () => {
    setOpenOption(false);
  };
  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable react-hooks/exhaustive-deps */
  return (
    <Modal
      open={openOption}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="option_review_section">
          <div className="inner_option_review">
            <div className="options_txt_close">
              <div className="options_text_p">
                <img onClick={() => setOpenOption(false)} src={BackIcon} alt="" />
              </div>
            </div>

            <div className="userProfileImageOnBlockPopup">
              <img src={profileData?.profile?.avatar_url || dummyProfileImage} alt="." />
            </div>

            <div className="options_icon_txt_section">
              <div className="blockPopupTxt">
                <p>
                  Are you sure you want to{' '}
                  <span>{blocked === 'blocked' ? 'unblock' : 'block'}</span> {profileData?.name}
                  &nbsp;@
                  {profileData?.username}?
                </p>
              </div>
              <p className="blockPopupSecondTxt">Dont worry you can unblock the user anytime!</p>
            </div>
            <div className="blockPopupBtnHolders">
              <button onClick={handleBlock} className="userBlockingBtn" type="submit">
                {blocked === 'blocked' ? 'Unblock' : 'Block'}
              </button>
              <button
                className="userBlockingCancelBtn"
                type="submit"
                onClick={() => setOpenOption(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default BlockUserPopup;
