import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import BackArrow from '../assets/Images/BackArrow.svg';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import api from '../api';
import EditIcon from '../assets/Images/EditOption.svg';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

function EditPlaylist({
  style,
  playlistDetailsById,
  fetchPanelData,
  optionsModelClose,
  playlistDetailsClose,
}) {
  const [playlistname, setPlaylistName] = useState(playlistDetailsById?.name);
  const [playlistDesc, setPlaylistDesc] = useState(playlistDetailsById?.desc);
  const [editPlaylist, setEditPlaylist] = useState(false);

  const handleEditClose = () => {
    setEditPlaylist(false);
  };

  const handleEdit = () => {
    api('patch', `/playlists/${playlistDetailsById?.id}/`, {
      name: playlistname,
      desc: playlistDesc,
    })
      .then(() => {
        handleEditClose();
        optionsModelClose();
        playlistDetailsClose();
        fetchPanelData();
      })
      .catch(() => {
        errorLogCountAnalyticsEvent();
        handleEditClose();
      });
  };

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(e) => console.log(e)}
        onClick={() => setEditPlaylist(true)}
        className="playlist_options_list_data"
      >
        <div className="options_icons">
          <img src={EditIcon} alt="edit" />
        </div>
        <p className="playlist_options__txt">Edit</p>
      </div>
      <Modal
        open={editPlaylist}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="createPlaylist">
            <div className="createPlaylist_first_main">
              <div className="createPlaylist_backArrow">
                {!editPlaylist && <img src={BackArrow} alt="back" />}
              </div>
              <p className="createPlaylist_heading_txt">Edit Playlist</p>
              <div
                role="button"
                onKeyDown={() => console.log('first')}
                onClick={handleEditClose}
                tabIndex={0}
                className="createPlaylist_Close"
              >
                <img src={CloseIcon} alt="close" />
              </div>
            </div>
            <div className="createPlaylist_second_section">
              <p className="createPlaylist_name_txt">Playlist Name</p>
              <input
                type="text"
                placeholder="My Top Songs"
                onChange={(e) => setPlaylistName(e.target.value)}
                value={playlistname}
              />
            </div>
            <div className="createPlaylist_third_section">
              <p className="createPlaylist_name_txt">Add a Description</p>
              <textarea
                className="textAreaCreateReview"
                onChange={(e) => setPlaylistDesc(e.target.value)}
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis"
                id="addPreview"
                name="addPreview"
                value={playlistDesc}
                style={{ resize: 'none' }}
              />
            </div>
            <div className="createPlaylist_button">
              <button onClick={handleEdit} type="button">
                Done
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default EditPlaylist;
