/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
import axios from 'axios';
import { errorLogCountAnalyticsEvent } from './service/analyticsService';

async function refreshAccessToken(refreshToken) {
  const clientID = '462f95dedc8e4c2388d7beccb4151206';
  const clientSecret = '302c413066aa44e5b7ffd16934e4075d';
  const credentialsBase64 = btoa(`${clientID}:${clientSecret}`);
  const authOptions = {
    method: 'post',
    url: 'https://accounts.spotify.com/api/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + credentialsBase64,
    },
    data: `grant_type=refresh_token&refresh_token=${refreshToken}`,
  };

  try {
    const response = await axios(authOptions);
    if (response?.status === 200) {
      return response?.data?.access_token;
    }
  } catch (error) {
    errorLogCountAnalyticsEvent();
    console.error('Error refreshing access token:', error);
    throw error;
  }
}

export default refreshAccessToken;
