/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { useParams } from 'react-router-dom';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import IconHeartLike from '../assets/Images/IconHeartLike.svg';
import Checked from '../assets/Images/checkVerified.svg';
import likes from '../assets/Images/likes.svg';
import comments from '../assets/Images/Comment.svg';
import share from '../assets/Images/share.svg';
import api from '../api';
import SinglePlayListDetail from './SinglePlayListDetail';
import { copyPlaylistUrl } from '../utils/helpers';
import {
  errorLogCountAnalyticsEvent,
  playlistShareAnalyticsEvent,
} from '../service/analyticsService';

function SinglePlayList({ playlist, userData, index, fetchPanelData, style }) {
  const [playlistSongs, setPlaylistSongs] = useState([]);
  const [colorLike, setColorLike] = useState('');
  const [playlistDetails, setPlaylistDetails] = useState(false);
  const [playlistComments, setPlaylistComments] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState([]);
  const [wait, setWait] = useState('pointer');
  const [showFullDescription, setShowFullDescription] = useState(false);
  const pathParams = useParams();

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const playlistDetailsClose = () => {
    setPlaylistDetails(false);
    fetchPanelData();
  };

  const handleGetPlaylistSongs = (id) => {
    setWait('wait');
    api('get', `/playlists/${id}/songs/`)
      .then((res) => {
        console.log(res?.data?.results, 'playlist res>');
        setPlaylistSongs(res?.data?.results);
        setWait('pointer');
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
        setWait('pointer');
      });
  };
  console.log('userData', userData);
  const fetchPlaylistComments = (id) => {
    setWait('wait');
    api('get', `/playlists/${id}/comments/`)
      .then((res) => {
        console.log(res?.data?.results, 'playlist comments>');
        setPlaylistComments(res?.data?.results);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
        setWait('pointer');
      });
  };

  const handleLikeClick = async (id) => {
    setColorLike((prevState) => !prevState);
    try {
      const response = await api('post', `/playlists/${id}/like/`);
      console.log('resss', response);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  const playlistDetailsOpen = async (item) => {
    setPlaylistDetails(true);
    handleGetPlaylistSongs(item);
    fetchPlaylistComments(item);
    await api('get', `/playlists/${item}/`)
      .then((res) => {
        setSelectedPlaylist(res?.data);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log('Error fetching user reviews:', err);
      });
  };

  const handleSharePlaylist = () => {
    playlistShareAnalyticsEvent();
    copyPlaylistUrl(pathParams, playlist.id);
  };

  useEffect(() => {
    if (playlist?.is_liked === true) {
      setColorLike(true);
    } else {
      setColorLike(false);
    }
  }, [playlist?.is_liked]);

  useEffect(() => {
    handleGetPlaylistSongs(playlist.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlist.id]);
  console.log(playlist, 'playlist');
  return (
    <div style={{ cursor: wait }} className="MyReviews_PlayList_data">
      {playlistSongs?.length > 0 && (
        <div className="imgPLayListRow" onClick={() => playlistDetailsOpen(playlist?.id)}>
          <div className="Profile_playlist_Img">
            {playlistSongs?.slice(0, 6).map((data) => (
              <img src={data?.image} alt={`pic ${index + 1}`} />
            ))}
          </div>
        </div>
      )}
      <p className="playList_topSong_txt" onClick={() => playlistDetailsOpen(playlist?.id)}>
        {playlist?.name}
      </p>
      <div className="MyReviews_PlayList_txtData">
        <div className="postedBy">
          <div className="posterSinfos">
            <div className="postByUserImg">
              <img
                src={playlist?.user?.avatar || dummyProfileImage}
                alt="pic"
                style={{ objectFit: 'cover', borderRadius: '50%' }}
              />
            </div>
            <div>
              <p className="postedbyUsername">{playlist?.user?.name || playlist?.user?.username}</p>
            </div>
            <div className="checked">
              {playlist?.user?.is_verified ? <img src={Checked} alt="pic" /> : null}
            </div>
          </div>
          <div>
            <p className="hoursAgo">
              created&nbsp;
              {formatDistanceToNow(new Date(playlist?.created_at), {
                addSuffix: true,
                includeSeconds: false,
              })}
            </p>
          </div>
        </div>
        <div className="postDescription" id="breakWordsWrap">
          <p>
            {showFullDescription
              ? playlist?.desc
              : playlist?.desc.split(' ').slice(0, 25).join(' ')}
            {playlist?.desc.split(' ').length > 25 && (
              <span className="showMoreLess">
                {showFullDescription ? <span> ... </span> : <span> ...</span>}
                <a onClick={toggleDescription}>{showFullDescription ? 'Show Less' : 'Show More'}</a>
              </span>
            )}
          </p>
        </div>
        {playlist?.liked_by?.length > 0 && (
          <div className="likedBy">
            {playlist?.liked_by.slice(0, 3).map((item) => (
              <div className="likedByImgWrap">
                <img src={item?.avatar ? item?.avatar : dummyProfileImage} alt="pic" />
              </div>
            ))}
            <p className="likedbyTxt">
              Liked by&nbsp;
              <span className="boldTxt">
                {playlist?.liked_by?.[0]?.username || playlist?.liked_by?.[0]?.name || 'username'}
              </span>
              {playlist?.liked_by.length > 1 && (
                <>
                  <span> and</span>
                  <span className="boldTxt">
                    &nbsp;{playlist?.liked_by ? playlist.liked_by.length - 1 : ''}&nbsp;others
                  </span>
                </>
              )}
            </p>
          </div>
        )}
        <div className="likeCommentAndShare">
          <div className="likesAndComments">
            <div
              className="likeCommentImg"
              id="heartIconForLike"
              onClick={() => handleLikeClick(playlist?.id)}
            >
              {colorLike ? <img src={IconHeartLike} alt="." /> : <img src={likes} alt="." />}
            </div>
            <div className="likeCommentImg">
              <img src={comments} alt="pic" />
            </div>
          </div>
          <div className="shareIconDiv" onClick={handleSharePlaylist}>
            <img src={share} role="presentation" alt="pic" />
          </div>
        </div>
        <div onClick={() => playlistDetailsOpen(playlist?.id)}>
          <p className="previousCommentsTxt">View All comments</p>
        </div>
      </div>
      <SinglePlayListDetail
        playlistDetails={playlistDetails}
        playlistDetailsClose={playlistDetailsClose}
        selectedPlaylist={selectedPlaylist}
        fetchPanelData={fetchPanelData}
        playlistSongs={playlistSongs}
        handleGetPlaylistSongs={handleGetPlaylistSongs}
        playlistComments={playlistComments}
        playlistDetailsById={selectedPlaylist}
        fetchPlaylistComments={fetchPlaylistComments}
        style={style}
      />
    </div>
  );
}

export default SinglePlayList;
