import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// import api from '../api/index';
import LOGO from '../assets/Images/LOGO.svg';
import PreLoginSliders from '../components/PreLoginSliders';
import check from '../assets/Images/VectorcheckMark.svg';
import { analyticsPages } from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';

export default function ForgotPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const Navigate = useNavigate();

  //   ----------------

  //   const handleSubmitSend = async (e) => {
  //     e.preventDefault();
  //     if (!email) {
  //       toast.error('Please enter your email');
  //       return;
  //     }

  //     await api('get', `/forgot-password/?email=${email}`)
  //       .then((res) => {
  //         console.log(res);
  //         setEmail('');
  //         toast.success('Password reset e-mail has been sent.');
  //         Navigate('/accountVerification', {
  //           state: {
  //             forgotPassword: true,
  //             email,
  //           },
  //         });
  //       })
  //       .catch((err) => {
  //         console.log('error', err?.response?.data);
  //       });
  //   };
  const handleSubmit = () => {
    // setShowCreateReviewPopup(!showCreateReviewPopup);
    if (!password) {
      toast.error('Enter Password');
    } else if (!confirmPassword) {
      toast.error('Enter Repeat Password');
    } else if (password !== confirmPassword) {
      toast.error('Both passwords should match');
    } else {
      toast.success('Password updated');
      Navigate('/dashboard');
    }
  };

  usePageViewAnalyticsHook({
    title: analyticsPages.resetPassword,
  });

  return (
    <div className="accountVerificationContainer">
      <div className="preloginWrapper">
        <PreLoginSliders />
      </div>
      <div className="accountVerifyRightPartWrap">
        <div className="rightSideInsiderWrap">
          <div className="logoClassTop">
            <img src={LOGO} alt="logo" />
          </div>
          <div className="verificationTxtsWrap">
            {/* <p className="topHeading_verification" id="forgotTxt">
              Forgot Password
            </p> */}
          </div>

          <div className="inputFields" id="resetPasswordField">
            <p className="lables">New Password</p>
            <input
              className="input_field"
              type="text"
              placeholder="password..."
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span>
              <img className="emailImg" src={check} alt="pic" />
            </span>
          </div>
          <div className="inputFields">
            <p className="lables">Repeat Password</p>
            <input
              className="input_field"
              type="text"
              placeholder="password..."
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <span>
              <img className="emailImg" src={check} alt="pic" />
            </span>
          </div>
          <button type="submit" className="SendButton" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
