import React from 'react';
import MusoticLogo from '../assets/Images/MusoticLogo.svg';
import BGBlueImg from '../assets/Images/BGMusoticLogo.svg';

function MusoticLogoScreen() {
  return (
    <div className="Musotic_logo_screen">
      <img className="top_bg_img" src={BGBlueImg} alt="" />
      <div className="musotic_logo_img">
        <img src={MusoticLogo} alt="Musotic Logo" />
      </div>
      <img className="bottom_bg_img" src={BGBlueImg} alt="" />
    </div>
  );
}

export default MusoticLogoScreen;
