/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/no-array-index-key */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import { formatDistanceToNow } from 'date-fns';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import PostComponent from '../components/PostComponent';
import api from '../api/index';
import messaging from '../firebase';
import ReviewList from '../components/MyReviewsItems';
import ProfilePic from '../assets/Images/SugarImg.svg';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import VerifiedTick from '../assets/Images/verified.svg';
import shades from '../assets/Images/ShadesDash.svg';
import Review1 from '../assets/Images/review1.png';
import Review2 from '../assets/Images/review2.png';
import Review3 from '../assets/Images/review3.png';
import Review4 from '../assets/Images/review4.png';
import sprinkles from '../assets/Images/Sprinkle.svg';
import SearchIcon from '../assets/Images/SearchInput.svg';
import VectorPen from '../assets/Images/VectorPen.svg';
import TopSongs from '../components/TopSongs';
import ChatsComponent from '../components/ChatUserList';
import NoSongYet from '../assets/Images/NoSongYet.svg';
import CreateReviewModal from '../components/CreateReviewModal';
import ScoreLevelBarr from '../components/ScoreLevelBar';
import RightTop from '../assets/Images/RightTop.svg';
import FeedReviewModal from '../components/FeedReviewModal';
import SearchInput from '../components/SearchInput';
import EditReviewModal from '../components/EditReviewModal';
import { doesUserLoginFromAppleSSO, generateUniqueFileName } from '../utils/helpers';
import usePageLoader from '../components/PageLoader/usePageLoader';
import PageLoader from '../components/PageLoader';
import {
  analyticsPages,
  errorLogCountAnalyticsEvent,
  reviewSubmitAnalyticsEvent,
} from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';

export default function Dashboard() {
  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();
  const [open, setOpen] = useState(false);
  const [selectedPlaylists, setSelectedPlaylists] = useState([]);
  const [specificReviews, setSpecificReviews] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [showLess, setShowLess] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showFeedReview, setShowFeedReview] = useState(false);
  const [selectedSongPost, setSelectedSongPost] = useState(null);
  const [playlists, setPlaylists] = useState([]);
  const [reviewPosts, setReviewPosts] = useState([]);
  const [selectedSong, setSelectedSong] = useState({});
  const [selected, setSelected] = useState('');
  const [showLoading, setShowLoading] = useState(true);
  const [previewImage, setPreviewImage] = useState([]);
  const [userChatData, setUserChatData] = useState([]);
  const [seeAllBool, setSeeAllBool] = useState(false);
  const [userReviews, setUserReviews] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const [selectedColor, setSelectedColor] = useState('rgb(211, 14, 227)');
  const [frequency, setFrequency] = useState(5.18);
  const [selectedColorTwo, setSelectedColorTwo] = useState('rgb(71, 202, 195)');
  const PAGINATION_LIMIT = 20;
  const [paginationMeta, setPaginationMeta] = useState({
    offset: 0,
    hasMore: false,
  });
  const navigate = useNavigate();

  const id = localStorage.getItem('userID');
  const fetchUserReviews = async () => {
    // startPageLoader();
    api('get', `/users/${id}/reviews/`)
      .then((response) => {
        const userReviewsData = response?.data?.results;
        if (userReviewsData && userReviewsData?.length > 0) {
          setUserReviews(userReviewsData);
        }
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log('Error fetching user reviews:', err);
      })
      .finally(() => {
        // stopPageLoader();
      });
  };

  useEffect(() => {
    const hasSeenModal = localStorage.getItem('hasSeenModal');
    const accessSpotifyToken = localStorage.getItem('spotifyAccessToken');
    if (!hasSeenModal && !accessSpotifyToken && !doesUserLoginFromAppleSSO()) {
      setIsModalOpen(true);
      localStorage.setItem('hasSeenModal', 'true');
    }
  }, []);

  useEffect(() => {
    const requestNotificationPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          console.log('Notification permission granted');
          const token = await messaging.getToken();
        } else {
          console.warn('Notification permission denied');
        }
      } catch (error) {
        errorLogCountAnalyticsEvent();
        console.error('Error requesting notification permission:', error);
      }
    };

    requestNotificationPermission();
  }, []);
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleConnectSpotify = () => {
    navigate('/spotifyConfirmation');
    closeModal();
  };

  useEffect(() => {
    if (id) {
      fetchUserReviews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSelectedImgClick = (e) => {
    setPreviewImage('');
  };
  const handleChatAllClick = (e) => {
    window.location = '/chat';
  };

  const handleUserNameClicked = (e) => {
    navigate('/profile');
  };
  const handleSeeAllReviews = (e) => {
    navigate('/profile');
  };

  const handleSeeAllClick = (e) => {
    navigate('/profile');
  };
  const handleShowLessClick = (e) => {
    setShowLess(true);
    setShowAll(false);
  };

  const [imageLinks, setImageLinks] = useState();
  const [formData, setFormData] = useState({
    body: '',
    title: '',
    type: '',
    morning: false,
    noon: false,
    evening: false,
    images: [],
    ratings: 0,
  });
  const handleImageChange = (e) => {
    const selectedImg = e.target.files;
    const selectedImgArray = Array.from(selectedImg).filter(
      (file) => !!file.type.includes('image'),
    );
    setPreviewImage(selectedImgArray);
  };

  useEffect(() => {
    const uploadImages = async () => {
      if (previewImage?.length === 0) return;

      const links = [];
      for (const img of previewImage) {
        // img.name = 'newname.png';
        const formDatanew = new FormData();
        formDatanew.append('image', img, generateUniqueFileName(img));

        startPageLoader();
        try {
          const response = await api('post', '/upload-image/', formDatanew);
          console.log('imgggUrl?', response?.data?.image_url);
          if (response?.data?.image_url) {
            links.push(response?.data?.image_url);
          }
          setImageLinks(links);
          setFormData((prevFormData) => ({
            ...prevFormData,
            images: links,
          }));
        } catch (error) {
          errorLogCountAnalyticsEvent();
          console.error('Error occurred while uploading images', error);
        }
        stopPageLoader();
      }
    };

    uploadImages();
  }, [previewImage]);

  const selectedSongImageToShow = (selectedSong && selectedSong?.images?.[2]?.url) || '';
  const [songObj, setSongObj] = useState({
    artist_name: '',
    artist_image: '',
    artist_spotify_id: '',
    title: '',
    year: '',
    image: '',
    spotify_id: '',
    spotify_url: '',
    spotify_preview_url: '',
    apple_id: '',
    apple_url: '',
    apple_preview_url: '',
  });

  const handleTitleChange = (e) => {
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   title: e.target.value,
    // }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      title: e.target.value,
      spotify_preview_url: '',
      apple_id: '',
      apple_url: '',
      apple_preview_url: '',
    }));
  };
  useEffect(() => {
    const fetchData = async () => {
      // startPageLoader();
      try {
        const response = await api('get', '/users/my-playlists/');
        const { data } = response;
        if (data?.results?.length) {
          setPlaylists(data?.results);
          console.log(response, 'okigujbhy');
        }
      } catch (err) {
        errorLogCountAnalyticsEvent();
        // toast.error('PlayList API ERROR');
      }
      // stopPageLoader();
    };
    fetchData();
  }, []);

  console.log('plsssssiioos', playlists);

  const fetchReviewData = async () => {
    if (paginationMeta.offset != 0 && paginationMeta.hasMore === false) {
      return;
    }
    startPageLoader();
    try {
      const response = await api(
        'get',
        `/reviews/?limit=${PAGINATION_LIMIT}&offset=${paginationMeta.offset}&ordering=-created_at`,
      );
      const reviewResults = response?.data?.results;

      if (reviewResults && reviewResults?.length > 0) {
        setShowLoading(false);

        const promises = reviewResults?.map(async (item) => {
          const commentsResponse = await api('get', `/reviews/${item?.id}/comments/`);
          const comments = commentsResponse?.data?.results;

          return {
            review: item,
            review_comments: comments,
          };
        });

        const results = await Promise.all(promises);
        console.log('loopistive', results);
        if (paginationMeta.offset === 0) {
          // first page
          setReviewPosts(results);
        } else {
          // next page
          setReviewPosts((prevState) => [...prevState, ...results]);
        }
        setPaginationMeta((prevState) => {
          return {
            hasMore: !!response?.data?.next,
            offset: prevState.offset + PAGINATION_LIMIT,
          };
        });
      } else {
        setReviewPosts([]);
        setPaginationMeta({
          hasMore: false,
          offset: 0,
        });
      }
    } catch (error) {
      errorLogCountAnalyticsEvent();
      console.error(error);
      setShowLoading(false);
    }
    stopPageLoader();
  };

  useEffect(() => {
    fetchReviewData();
  }, []);

  const [userData, setUserData] = useState({
    score: null,
    name: '',
    avatarUrl: '',
    bio: '',
    isVerified: false,
  });
  const fetchUserData = async () => {
    // startPageLoader();
    try {
      const response = await api('get', '/users/me/');
      console.log('userdataei', response);
      const { data } = response;
      setUserData({
        score: data?.score,
        name: data?.name,
        avatarUrl: data?.profile?.avatar_url,
        bio: data?.profile?.bio,
        isVerified: data?.profile?.is_verified,
      });
      console.log('data', data);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log(err);
    }
    // stopPageLoader();
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  const [showCreateReviewPopup, setShowCreateReviewPopup] = React.useState(false);
  const [selectedOptionslist, setSelectedOptionslist] = useState('');
  const [edit, setEdit] = useState(false);
  const [postComponentData, sePostComponentData] = useState({
    body: '',
  });

  const handleOptionsslick = (className) => {
    setSelectedOptionslist(className);
    if (className === 'Noon') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        noon: true,
        morning: false,
        evening: false,
      }));
    }
    if (className === 'morning') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        noon: false,
        morning: true,
        evening: false,
      }));
    }
    if (className === 'Evening') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        noon: false,
        morning: false,
        evening: true,
      }));
    }
  };
  const handleInputChange = (event) => {
    const { name, type, value } = event.target;

    if (type === 'file') {
      const file = event.target.files[0];
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: file,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const toggleNotification = () => {
    setShowCreateReviewPopup(!showCreateReviewPopup);
    setOpen(true);
    setSelectedSong({});
    setFormData({
      body: '',
      title: '',
      type: '',
      morning: false,
      noon: false,
      evening: false,
      images: [],
      ratings: 0,
    });
  };
  const handleClose = () => {
    setOpen(false);
    setShowCreateReviewPopup(false);
    setSelectedPlaylists([]);
    setFormData({
      body: '',
      title: '',
      type: '',
      morning: false,
      noon: false,
      evening: false,
      images: [],
      ratings: 0,
    });
    setSelectedSong({});
    setSelectedOptionslist('');
    setSongObj({
      artist_name: '',
      artist_image: '',
      artist_spotify_id: '',
      title: '',
      year: '',
      image: '',
      spotify_id: '',
      spotify_url: '',
      spotify_preview_url: '',
      apple_id: '',
      apple_url: '',
      apple_preview_url: '',
    });
    setPreviewImage([]);
    setSelectedColor('rgb(211, 14, 227)');
    setSelectedColorTwo('rgb(71, 202, 195)');
    setFrequency(5.18);
  };
  const handleSelectedSongClick = () => {
    setSelectedSong({});
  };
  useEffect(() => {
    if (selectedSong?.name) {
      setShowCreateReviewPopup(!showCreateReviewPopup);
      setOpen(true);
    }
  }, [selectedSong?.name]);

  // useEffect(() => {
  //   if (songObj.title) {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       title: songObj.title,
  //     }));
  //   }
  // }, [songObj.title]);
  console.log(songObj, '1234567890');
  const handleReviewSubmit = () => {
    console.log('songObj', songObj);
    const fetchData = async () => {
      if (!formData?.title) {
        toast.error('Title required');
        return;
      }
      // if (!formData.body) {
      //   toast.error('Review Required');
      //   return;
      // }
      if (!formData?.ratings) {
        toast.error('Add Ratings');
        return;
      }

      try {
        const updatedPlaylists = [];
        for (const playlistId of selectedPlaylists) {
          updatedPlaylists.push(playlistId);
        }
        const data = {
          // album: songObj,
          intensity: {
            color_one: 8,
            color_two: 10,
            intensity: parseInt(frequency, 10),
            color_one_rgb: selectedColor,
            color_two_rgb: selectedColorTwo,
          },
          playlists: updatedPlaylists,
          body: '',
          ...formData,
        };
        if (formData?.type === 'song') {
          data.song = songObj;
        } else {
          data.album = songObj;
        }

        console.log('CheckData', updatedPlaylists);

        if (previewImage?.length > 0) {
          if (imageLinks?.length === previewImage?.length) {
            startPageLoader();
            const response = await api('post', '/reviews/', data);
            const newReview = {
              review: response.data,
              review_comments: [],
            };

            setReviewPosts((prevState) => [newReview, ...prevState]);
            stopPageLoader();
            console.log('Check response ', response);
          } else {
            toast.error('Images are uplaoding');
            return;
          }
        } else {
          startPageLoader();
          const response = await api('post', '/reviews/', data);
          const newReview = {
            review: response.data,
            review_comments: [],
          };
          setReviewPosts((prevState) => [newReview, ...prevState]);

          stopPageLoader();
          console.log('Check response ', response);
        }
        setSongObj({
          artist_name: '',
          artist_image: '',
          artist_spotify_id: '',
          title: '',
          year: '',
          image: '',
          spotify_id: '',
          spotify_url: '',
          spotify_preview_url: '',
          apple_id: '',
          apple_url: '',
          apple_preview_url: '',
        });
        setFormData({
          body: '',
          title: '',
          type: '',
          morning: false,
          noon: false,
          evening: false,
          images: [],
          ratings: 0,
        });
        setPreviewImage([]);
        setSelectedSong({});
        setSelectedPlaylists([]);
        setSelectedOptionslist(null);
        toast.success('Review added successfully');
        reviewSubmitAnalyticsEvent();
        setShowCreateReviewPopup(!showCreateReviewPopup);
        // await fetchUserReviews();
        // await fetchReviewData();

        // const response = await api('get', `/reviews/?limit=1&offset=0&ordering=-created_at`);
      } catch (err) {
        errorLogCountAnalyticsEvent();
        stopPageLoader();
        console.log(err?.response, 'wewewew');
        // eslint-disable-next-line eqeqeq
        if (err?.response?.status == '500') {
          toast.error(err?.response?.statusText);
        }
        toast.error(err?.response?.data?.non_field_errors[0]);
        console.log('errrrrrr', err?.response?.data?.non_field_errors, err);
      }
    };
    console.log(previewImage?.length, 'len');
    console.log(imageLinks?.length, 'len2');
    fetchData();
  };
  useEffect(() => {
    console.log(selectedSong, 'selectedSong');
    if (selectedSong?.album_type === 'single') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        type: 'song',
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        type: 'album',
      }));
    }
  }, [selectedSong]);

  const userNameForProfile = localStorage.getItem('profileUsername');
  const userProfileLink = localStorage.getItem('profilePicLink');
  const userProfileBio = localStorage.getItem('profileBioTxt');
  const ProfileScore = localStorage.getItem('idScore');

  const topSongsData = [
    {
      createdOn: '22 May 2023',
      description:
        'This is a sample random text, here you will see the description about the top Songs',
      likesCount: '112',
      commentsCount: '122',
      images: [Review1, Review2, Review3, ProfilePic, Review4, ProfilePic],
    },
    {
      createdOn: '15 May 2022',
      description:
        'This is a sample random text, here you will see the description about the top Songs',
      likesCount: '50',
      commentsCount: '250',
      images: [Review1, Review2, Review3, ProfilePic, Review4, ProfilePic],
    },
  ];

  const postComponentSetter = (info, bool) => {
    setSelectedSongPost(info);
    setShowFeedReview(bool);
  };
  console.log('okkk', selectedSongPost);

  const handleComment = async (targetId, comment) => {
    if (comment !== '') {
      startPageLoader();
      await api('post', `/review-comments/`, { text: comment, review: targetId })
        .then(async (res) => {
          // fetchReviewData();
          const commentsResponse = await api('get', `/reviews/${targetId}/comments/`);
          const comments = commentsResponse?.data?.results;

          const updatedReviewPosts = reviewPosts.map((result) => {
            if (result.review.id === targetId) {
              return {
                ...result,
                review_comments: comments,
              };
            }
            return result;
          });
          setReviewPosts(updatedReviewPosts);
        })
        .catch((err) => {
          errorLogCountAnalyticsEvent();
          console.log(err);
        })
        .finally(() => {
          stopPageLoader();
        });
    }
  };

  console.log('reviewwwss', reviewPosts);
  const getChatUsersData = async () => {
    // startPageLoader();
    try {
      const response = await api('get', '/channels/');
      console.log('channelsss', response);
      const { data } = response;
      if (data?.channels?.length > 0) {
        setUserChatData(data?.channels);
      }
    } catch (err) {
      errorLogCountAnalyticsEvent();
      toast.error(err);
    }
    // stopPageLoader();
  };
  useEffect(() => {
    getChatUsersData();
  }, []);
  console.log(userChatData, 'setUserChatData');
  const handleEdit = (obj) => {
    console.log(obj, 'aaaaaaa');
    setEdit(true);
    setOpen(true);
    sePostComponentData(obj?.review);
  };
  const handleEditclose = () => {
    setEdit(false);
  };
  const handleSelectedPlaylistsChange = (updatedSelectedPlaylists) => {
    setSelectedPlaylists(updatedSelectedPlaylists);
  };
  console.log('selectsss', frequency);

  const createReviewModal = showCreateReviewPopup && (
    <CreateReviewModal
      selectedPlaylists={selectedPlaylists}
      setSelectedPlaylists={handleSelectedPlaylistsChange}
      open={open}
      handleClose={handleClose}
      selectedSongImageToShow={selectedSongImageToShow}
      NoSongYet={NoSongYet}
      toggleNotification={toggleNotification}
      handleReviewSubmit={handleReviewSubmit}
      handleSelectedSongClick={handleSelectedSongClick}
      handleOptionsslick={handleOptionsslick}
      selectedOptionslist={selectedOptionslist}
      selectedSong={selectedSong}
      setSelectedSong={setSelectedSong}
      setSongObj={setSongObj}
      SearchIcon={SearchIcon}
      songObj={songObj}
      handleImageChange={handleImageChange}
      previewImage={previewImage}
      handleSelectedImgClick={handleSelectedImgClick}
      handleInputChange={handleInputChange}
      formData={formData}
      setFormData={setFormData}
      handleTitleChange={handleTitleChange}
      selectedColor={selectedColor}
      setSelectedColor={setSelectedColor}
      selectedColorTwo={selectedColorTwo}
      setSelectedColorTwo={setSelectedColorTwo}
      setFrequency={setFrequency}
      frequency={frequency}
    />
  );
  const feedReviewModal = showFeedReview && (
    <FeedReviewModal
      song={selectedSongPost}
      setShowFeedReview={setShowFeedReview}
      handleComment={handleComment}
    />
  );
  const editReviewModal = edit && (
    <EditReviewModal
      open={open}
      handleClose={handleEditclose}
      postComponentData={postComponentData}
      fetchReviewData={fetchReviewData}
      sePostComponentData={sePostComponentData}
    />
  );

  usePageViewAnalyticsHook({
    title: analyticsPages.dashboard,
  });

  return (
    <div id="MainPage" className="dashboardContainer">
      <PageLoader isLoading={isPageLoading} />
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#2D3447',
            width: '400px',
            padding: '20px',
            borderRadius: '8px',
          }}
        >
          <p className="firstPopupHeading">Welcome to the Musotic !</p>
          <p className="firstPopuptxt">
            Please connect your spotify account for better experience.
          </p>
          <div className="firstPopupButtons">
            <button className="firstPopupBtn" onClick={handleConnectSpotify}>
              Connect Spotify
            </button>
            <button className="firstPopupBtn2" onClick={closeModal}>
              Skip
            </button>
          </div>
        </div>
      </Modal>

      <div className="headerHolder">{/* <Header /> */}</div>
      <div className="shadeHolderDash">
        <img src={shades} alt="s" />
      </div>
      <div className="shadeHolderDashTopRight">
        <img src={shades} alt="s" />
      </div>
      <div className="dashboardBelowHeader">
        <button
          type="button"
          className="FlyingButtonAddreview"
          onClick={() => {
            toggleNotification();
          }}
        >
          <div className="addreviewIcon">
            <img src={VectorPen} alt="pic" />
          </div>
        </button>
        <div className="dashboardInsider">
          <div className="leftbottomColor leftbottomColor_dashboard_bottom">
            <img src={RightTop} alt="x" />
          </div>
          <div className="dashboardLeft">
            <div className="leftinsideTop">
              <p className="mYplaylistTxt">My Playlists</p>
              {showLess ? (
                <p className="seeAllTxt" onClick={handleSeeAllClick}>
                  see all
                </p>
              ) : (
                ' '
              )}
              {showAll ? (
                <p className="seeAllTxt" onClick={handleShowLessClick}>
                  see less
                </p>
              ) : (
                ' '
              )}
            </div>
            <div className="testingClass">
              {playlists?.map((data, index) => {
                console.log('playlistsIo', playlists);
                if (!showAll && index < 2) {
                  return (
                    <TopSongs
                      key={data?.id}
                      images={topSongsData[0]?.images}
                      playlistName={data?.name}
                      createdOn={data?.created_at}
                      description={data?.desc}
                      likesCount={data?.like_count}
                      commentsCount={data?.comment_count}
                      data={data}
                    />
                  );
                }
                if (showAll)
                  return (
                    <TopSongs
                      key={data?.id}
                      images={topSongsData[0]?.images}
                      createdOn={data?.created_at}
                      playlistName={data?.name}
                      description={data?.desc}
                      likesCount={data?.like_count}
                      commentsCount={data?.comment_count}
                      data={data}
                    />
                  );
                return null;
              })}
            </div>
            <div className="ChatsDiv">
              <div className="chatheading">
                <p className="ChatHeadTxt">Chat</p>
                <div onClick={handleChatAllClick}>
                  <p className="chatSeeAll">See All</p>
                </div>
              </div>
              {userChatData?.length < 1 ? (
                <p className="noDatayetText">No data found</p>
              ) : (
                userChatData
                  ?.slice(0, 5)
                  ?.map((chat, index) => (
                    <ChatsComponent
                      key={index?.id}
                      ProfilePic={chat?.other_user?.profile_url}
                      UserName={
                        chat?.other_user?.metadata?.name || chat?.other_user?.metadata?.username
                      }
                      isChecked
                      msgCounterValue={chat?.unread_message_count}
                      chat={chat}
                    />
                  ))
              )}
            </div>
          </div>
          <div className="dashboardMiddle">
            {selectedSong?.name ? (
              <div className="overAllsearchDivWrapSelected" onClick={handleSelectedSongClick}>
                <div className="searchIconInputSelected">
                  <img src={SearchIcon} alt="icon" />
                </div>
                <div className="selectedSongImgHolder">
                  <img
                    src={selectedSong?.images?.[0]?.url || selectedSong?.album?.images?.[0]?.url}
                    alt="Selected Song"
                  />
                </div>
                <div className="selectedSongnameTxt">{selectedSong?.name}</div>
                <span className="colorWhite">|</span>
                <div className="selectedSongSubNameTxt">{selectedSong?.artists?.[0]?.name}</div>
              </div>
            ) : (
              <SearchInput
                inputBackgroundColor="#434854"
                inputPlaceholder="Search for a song..."
                selectedSong={selectedSong}
                setSongObj={setSongObj}
                setSelectedSong={setSelectedSong}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {showLoading && (
              <div className="loadingHolderDiv" style={{ marginTop: '35vh' }}>
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress sx={{ color: '#57BBEB' }} />
                </Box>
              </div>
            )}

            <div
              style={{
                marginTop: '20px',
              }}
            >
              <InfiniteScroll
                dataLength={reviewPosts.length} // This is important field to render the next data
                next={fetchReviewData}
                hasMore={paginationMeta.hasMore}
                height={2000}
              >
                {reviewPosts?.map((data, index) => (
                  <PostComponent
                    key={data?.review?.id}
                    ProfilePic={data?.review?.song?.image || data?.review?.album?.image}
                    posterName={data.review?.song?.title || data?.review?.album?.title}
                    ProfilePicTop={data?.review?.user?.avatar}
                    ProfilePic2={data?.review?.user?.avatar || dummyProfileImage}
                    ProfilePicx={data?.review?.song?.image}
                    ProfilePicy={dummyProfileImage}
                    ProfilePicz={data?.review?.song?.image}
                    ProfilePicInComments2={data.review?.song?.image}
                    morning={data?.review?.morning}
                    noon={data?.review?.noon}
                    evening={data?.review?.evening}
                    posterSubtitle={
                      data?.review?.song?.artist_name || data?.review?.album?.artist_name
                    }
                    title={
                      data?.review?.title || data?.review?.song?.title || data?.review?.album?.title
                    }
                    description={data?.review?.body}
                    postComponentSetter={postComponentSetter}
                    data={data}
                    postedByUsername={data?.review?.user?.username || data?.review?.user?.name}
                    hoursAgo={formatDistanceToNow(new Date(data?.review?.created_at), {
                      addSuffix: true,
                      includeSeconds: false,
                    })}
                    likedByText="Dummy"
                    likedByOthers="others"
                    commentsText="sample comment text here"
                    ratings={data?.review?.ratings}
                    ratingsNumber={data?.review?.ratings}
                    likedHours="1h"
                    usernameComments={data?.review?.user?.username}
                    likedHoursUnderComments="0.5h"
                    commentNoOfLikes={data?.review?.like_count}
                    reviewCommentsArray={data?.review_comments}
                    handleComment={handleComment}
                    feedReviewSongDetails={data?.review}
                    fetchReviewData={fetchReviewData}
                    handleEdit={handleEdit}
                    userProfileImage={userData?.avatarUrl}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </div>
          <div className="dashboardRight">
            <div className="profileInfoDiv">
              <div className="rightProfilePicHolder">
                <img
                  src={userData?.avatarUrl ? userData?.avatarUrl : dummyProfileImage}
                  alt="profileImg"
                />
              </div>
              <div className="verifiedRelative">
                <div className="verifiedTickHolder">
                  <img src={VerifiedTick} alt="tick" />
                </div>
              </div>
              <p className="rightProfileNametxt" onClick={handleUserNameClicked}>
                {userData?.name || ''}
              </p>
              <p className="rightProfileNametxt2">@{userNameForProfile || 'username'}</p>
              <p className="rightProfileNametxt3">
                {userProfileBio || 'This profile dont have bio, thats why you are seeing this'}
              </p>
              <div className="dividerDiv">
                <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 1)', opacity: '0.2' }} />
              </div>
              <div className="sprinkles">
                <img src={sprinkles} alt="pic" />
              </div>
              <p className="counterDashboard">{userData?.score || '00'}</p>
              <p className="counterPoints">Points</p>
              <div className="scoreLevelBarHolder">
                <ScoreLevelBarr score={userData?.score} />
              </div>
              <p className="certifiedTtxt">
                {userData?.score >= 340 ? 'You have been certified' : ''}
              </p>
              <p className="levelTxt_dashboard">
                You have reached{' '}
                <span className="blueColored">
                  {userData?.score > 0 && userData?.score < 60
                    ? 'Level 1'
                    : userData?.score >= 60 && userData?.score < 140
                    ? 'Level 2'
                    : userData?.score >= 140 && userData?.score < 240
                    ? 'Level 3'
                    : userData?.score >= 240 && userData?.score < 340
                    ? 'Level 4'
                    : userData?.score >= 340
                    ? 'Level 5'
                    : 'Level 0'}
                </span>
              </p>
            </div>
            <div className="myReviewsDivClass">
              <div className="myReviewsheadingTxts">
                <p className="leftMyReviewsTxt">My Reviews</p>
                <p onClick={handleSeeAllReviews} className="seeAllTxt">
                  See All
                </p>
              </div>
              <div className="reviewListofItemsWrap">
                <div className="reviewListofItemsWrap">
                  {seeAllBool
                    ? userReviews?.map((review, index) => {
                        return <ReviewList review={review} index={index} />;
                      })
                    : userReviews?.slice(0, 4)?.map((review, index) => {
                        return <ReviewList review={review} index={index} />;
                      })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {feedReviewModal}
      {createReviewModal}
      {editReviewModal}
    </div>
  );
}
