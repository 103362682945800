/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import api from '../api';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import Checked from '../assets/Images/checkVerified.svg';
import likes from '../assets/Images/likes.svg';
import IconHeartLike from '../assets/Images/IconHeartLike.svg';
import Flag from '../assets/Images/Flag.svg';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

export default function PlaylistCommentReplies({ com }) {
  const [commentLike, setCommentLike] = useState(false);
  const [likeCount, setLikeCount] = useState(com?.like_count);

  useEffect(() => {
    if (com?.is_liked === true) {
      setCommentLike(true);
    }
    setLikeCount(com?.like_count);
  }, [com]);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const handleCommentsLiked = async (id) => {
    setCommentLike((prevState) => !prevState);
    setLikeCount((prevCount) => (commentLike ? prevCount - 1 : prevCount + 1));

    try {
      await api('post', `/playlist-comment-replies/${id}/like/`);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user commentLikes:', err);
    }
  };
  const musoticID = localStorage.getItem('musoticID');
  const navigate = useNavigate();
  const handleUserNameClicked = () => {
    const userId = com?.user?.id;
    console.log('feddId', userId, musoticID);
    // eslint-disable-next-line eqeqeq
    if (userId == musoticID) {
      navigate(`/profile`);
    } else {
      navigate(`/user/${userId}`);
    }
  };

  return (
    <div className="repliesToCommentsDic">
      <div className="posterSinfos" id="posterSinfosReplies" onClick={handleUserNameClicked}>
        <div className="postByUserImg">
          <img src={com?.user?.avatar || dummyProfileImage} alt="pic" />
        </div>
        <div>
          <p className="postedbyUsername">{com?.user?.name || com?.user?.username}</p>
        </div>

        {com?.user?.is_verified === true && (
          <div className="checked">
            <img src={Checked} alt="pic" />
          </div>
        )}
      </div>
      <div className="commentReplyLikesDiv">
        {/* <p className="CommentsTxtByuser">{com?.text}</p> */}
        <p className="CommentsTxtByuser">
          {showFullDescription ? com?.text : com?.text.split(' ').slice(0, 25).join(' ')}
          {com?.text.split(' ').length > 25 && (
            <span className="showMoreLess">
              {showFullDescription ? <span> ... </span> : <span> ...</span>}
              <a onClick={toggleDescription}>{showFullDescription ? 'Show Less' : 'Show More'}</a>
            </span>
          )}
        </p>
        <div className="comentTimeAndLikes">
          <div className="minorImgsFlag">
            <img src={Flag} alt="pic" />
          </div>
          <p className="likedHours">
            {formatDistanceToNow(new Date(com?.created_at), {
              addSuffix: true,
              includeSeconds: false,
            })}
          </p>
          <div
            onKeyDown={() => console.log('none')}
            tabIndex={0}
            role="button"
            className="minorImgsDiv"
            onClick={() => handleCommentsLiked(com?.id)}
          >
            {commentLike ? <img src={IconHeartLike} alt="." /> : <img src={likes} alt="." />}
          </div>
          <p className="commentNoOfLikes">
            {likeCount} <span /> {likeCount <= 1 ? 'Like' : 'Likes'}
          </p>
        </div>
      </div>
    </div>
  );
}
