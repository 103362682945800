/* eslint-disable react/jsx-props-no-spreading */
// /* eslint-disable react/no-unstable-nested-components */
// /* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

const activeStepLabels = ['', '', '', '', ''];

export default function HorizontalLinearStepper({ score }) {
  const [activeStep, setActiveStep] = React.useState(0);

  React.useEffect(() => {
    if (score > 0 && score < 70) {
      setActiveStep(0);
    } else if (score >= 70 && score < 150) {
      setActiveStep(1);
    } else if (score >= 150 && score < 250) {
      setActiveStep(2);
    } else if (score >= 250 && score < 350) {
      setActiveStep(3);
    } else if (score >= 350) {
      setActiveStep(4);
    }
  }, [score]);

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 6,
      left: 'calc(-50% + 6px)',
      right: 'calc(50% + 6px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'rgba(87, 187, 235, 1)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'rgba(87, 187, 235, 1)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === 'rgba(87, 187, 235, 1)' ? theme.palette.grey[800] : 'gray',
      borderTopWidth: 1,
      borderRadius: 1,
    },
  }));

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
        {activeStepLabels.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (index > activeStep) {
            labelProps.sx = {
              '& .MuiStepIcon-root': {
                width: 12,
                height: 12,
                color: 'gray',
              },
            };
          } else {
            labelProps.sx = {
              '.Mui-completed': {
                color: 'rgba(87, 187, 235, 1)',
              },
              '& .MuiStepIcon-root': {
                width: 12,
                height: 12,
                color: 'rgba(87, 187, 235, 1)',
              },
            };
          }

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
