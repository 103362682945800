import React from 'react';
import AllChats from '../components/AllChats';
import { analyticsPages } from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';

function Chat() {
  usePageViewAnalyticsHook({
    title: analyticsPages.chatScreen,
  });
  return <AllChats />;
}

export default Chat;
