/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Heart from '../assets/Images/HeartIcon.svg';
import IconHeartLike from '../assets/Images/IconHeartLike.svg';
import api from '../api/index';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

export default function NotificationLikes({ reviewId, reviewIsLiked }) {
  const [colorLike, setColorLike] = useState(false);
  const handleLikeReply = async (id) => {
    setColorLike((prevState) => !prevState);
    try {
      const response = await api('post', `/reviews/${id}/like/`);
      console.log('resss', response);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  useEffect(() => {
    if (reviewIsLiked) {
      setColorLike(true);
    }
  }, [reviewIsLiked]);
  return (
    <div className="heartImg2" id="opacity8" onClick={() => handleLikeReply(reviewId)}>
      {colorLike ? <img src={IconHeartLike} alt="." /> : <img src={Heart} alt="." />}
    </div>
  );
}
