/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable consistent-return */
import React, {
  useEffect,
  useRef,
  // useMemo,
  useState,
} from 'react';
import { toast } from 'react-toastify';
// import SendbirdChat from '@sendbird/chat';
// import { GroupChannelModule } from '@sendbird/chat/groupChannel';
// import { GroupChannelModule } from '@sendbird/chat/groupChannel';

import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import ChatsComponent from './ChatUserList';
// import Review1 from '../assets/Images/review1.png';
import Options from '../assets/Images/optionDots.svg';
import Add from '../assets/Images/AddIcon.svg';
import Frame from '../assets/Images/Frame.svg';
import search from '../assets/Images/SearchIcon2.svg';
import addIcon from '../assets/Images/AddIcon2.svg';
import api from '../api';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import Block from '../assets/Images/block1.svg';
import Delete from '../assets/Images/DeleteOption.svg';
import RightTop from '../assets/Images/RightTop.svg';
import initSendbird from '../utils/useSandbird';
import PageLoader from './PageLoader';
import usePageLoader from './PageLoader/usePageLoader';
import { canShowAppleMusicLogo, debounce, isUrlValid } from '../utils/helpers';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import Checked from '../assets/Images/checkVerified.svg';
import heartIconFilled from '../assets/Images/heartIconFilled.png';
import CommentIconFilled from '../assets/Images/CommentIconFilled.png';
import shareIconFilled from '../assets/Images/shareIconFilled.png';

import { chatMessageType } from '../utils/constant';
import spotifyIcon from '../assets/Images/Spotify.png';
import appleMusicIcon from '../assets/Images/appleMusicIcon.png';

import musoticSongIcon from '../assets/Images/musoticSongIcon.png';
import starIcon from '../assets/Images/Star.svg';
import ShareSocial from './ShareSocial';

function AllChats() {
  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();
  const sendbirdObjRef = useRef(null);

  const [searchedUsers, setSearchedUsers] = useState([]);
  const [textValue, setTextValue] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [allChannels, setAllChannels] = useState([]);
  const [singleChannel, setSingleChannel] = useState();
  const [selectedItems, setSelectedItems] = useState(null);
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const username = localStorage.getItem('profileUsername');
  const nav = useNavigate();
  const { state } = useLocation();
  // const AppId = '46A447BB-8931-40ED-931D-2BF3C2BEF600';
  const userId = localStorage.getItem('userID');
  const msgsDivRef = React.createRef();
  const hasMoreMessagesRef = useRef(true);
  const [scrollToMessageId, setScrollToMessageId] = useState(0);
  const [userBlocked, setUserBlocked] = useState();
  const [showSendSong, setShowSendSong] = useState(false);
  const [searchedSongs, setSearchedSongs] = useState({
    search: '',
    offset: 0,
    hasMore: false,
    results: [],
  });
  const [shareReviewId, setShareReviewId] = useState(0);

  const PAGINATION_LIMIT = 20;
  const toggleShowSendSong = () => {
    setShowSendSong((prevState) => !prevState);
  };

  const getSendbirdObj = async () => {
    let sendbird;
    if (sendbirdObjRef.current === null) {
      sendbird = await initSendbird();
      sendbirdObjRef.current = sendbird;
    } else {
      sendbird = sendbirdObjRef.current;
    }
    return sendbird;
  };
  const handleMessage = async (event) => {
    setTextValue(event.target.value);
    // sendMessage(event.target.value);
  };
  const handleClick = async (obj) => {
    setSingleChannel(obj);
    setSelectedItems(obj?.channel_url);
  };

  const getAllChannels = async () => {
    startPageLoader();
    try {
      const response = await api('get', '/channels/');
      const { data } = response;
      if (data?.channels?.length > 0) {
        setAllChannels(data.channels);
      }
    } catch (err) {
      toast.error(err);
    }
    stopPageLoader();
  };

  const createChannel = async (receiver) => {
    startPageLoader();
    await api('post', '/channels/', {
      sender: username,
      receiver,
    })
      .then((res) => {
        setOpenSearch(false);
        handleClick(res?.data?.channel);
      })
      .catch((err) => {
        console.log(err, '123123');
      });
    stopPageLoader();
  };
  // console.log(singleChannel, 'rtyuioiuytred');
  const getChannelMessages = async (lastMsgId = 0, includeLastMessage = true) => {
    startPageLoader();
    try {
      const response = await api(
        'get',
        `/chats/?channel_url=${singleChannel?.channel_url}&message_id=${lastMsgId}&include_last_message=${includeLastMessage}`,
      );
      const { data } = response;
      if (response && data?.messages && data?.messages?.length) {
        hasMoreMessagesRef.current = true;
        setMessages((prevState) => {
          return [...data.messages, ...prevState];
        });
      } else {
        hasMoreMessagesRef.current = false;
      }
    } catch (err) {
      toast.error(err);
      hasMoreMessagesRef.current = false;
    }
    stopPageLoader();
  };

  function isValidMusoticUrl(url) {
    if (isUrlValid(url)) {
      const splitURL = url.trim().split('/');
      if (splitURL.includes(window.location.host)) {
        if (splitURL.includes(chatMessageType.reviews)) {
          return true;
        }
        if (splitURL.includes(chatMessageType.songDetails)) {
          return true;
        }
        if (splitURL.includes(chatMessageType.albumDetails)) {
          return true;
        }
      }
    }
    return false;
  }

  async function handleSendUrlSongOrAlbum(url) {
    console.log(url);
    const splitURL = url.trim().split('/');
    console.log(splitURL);
    startPageLoader();
    try {
      let data;
      let customType;

      // songDetails url
      if (splitURL.includes(chatMessageType.songDetails)) {
        const id = splitURL[splitURL.indexOf(chatMessageType.songDetails) + 1];

        const response = await api('get', `/songs/${id}/`);

        if (response && response.data) {
          data = response.data;
          customType = chatMessageType.songDetails;
        }
      }

      // albumDetails url
      if (splitURL.includes(chatMessageType.albumDetails)) {
        const id = splitURL[splitURL.indexOf(chatMessageType.albumDetails) + 1];

        const response = await api('get', `/albums/${id}/`);

        if (response && response.data) {
          data = response.data;
          customType = chatMessageType.albumDetails;
        }
      }

      // review url
      if (splitURL.includes(chatMessageType.reviews)) {
        const id = splitURL[splitURL.indexOf(chatMessageType.reviews) + 1];

        const response = await api('get', `/reviews/${id}/`);

        if (response && response.data) {
          data = response.data;
          customType = chatMessageType.reviews;
        }
      }

      if (data && customType) {
        await api('post', '/chats/', {
          channel_url: singleChannel?.channel_url,
          message_type: 'MESG',
          user_id: userId,
          message: textValue,
          custom_type: customType,
          data: JSON.stringify(data),
        })
          .then((res) => {
            console.log(res);
            if (messages.length === 0) {
              // if new chat is initialize then load channel list
              getAllChannels();
            }
            setTextValue('');
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (error) {
      stopPageLoader();
      console.log(error);
    }
    stopPageLoader();
  }

  const handleSend = async () => {
    if (!textValue || userBlocked) {
      return;
    }
    if (isValidMusoticUrl(textValue)) {
      handleSendUrlSongOrAlbum(textValue);
      return;
    }

    startPageLoader();
    await api('post', '/chats/', {
      channel_url: singleChannel?.channel_url,
      message_type: 'MESG',
      user_id: userId,
      message: textValue,
      custom_type: '',
      data: '',
    })
      .then((res) => {
        console.log(res);
        if (messages.length === 0) {
          // if new chat is initialize then load channel list
          getAllChannels();
        }
        setTextValue('');
      })
      .catch((err) => {
        console.log(err);
      });
    stopPageLoader();
  };

  const handleSendSongClick = async (song) => {
    if (!song || userBlocked) {
      return;
    }
    startPageLoader();
    try {
      await api('post', '/chats/', {
        channel_url: singleChannel?.channel_url,
        message_type: 'MESG',
        user_id: userId,
        message: textValue,
        custom_type: chatMessageType.songDetails,
        data: JSON.stringify(song),
      })
        .then((res) => {
          console.log(res);
          if (messages.length === 0) {
            // if new chat is initialize then load channel list
            getAllChannels();
          }
          setSearchedSongs({
            search: '',
            offset: 0,
            hasMore: false,
            results: [],
          });
          setTextValue('');
          setShowSendSong(false);
          const songSearchInput = document.getElementById('songSearchInput');
          if (songSearchInput) {
            songSearchInput.value = '';
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }

    stopPageLoader();
  };

  const handleSeeProfile = () => {
    // nav(`/user/${singleChannel?.last_message?.user?.user_id}`);
    nav(`/user/${singleChannel?.other_user?.user_id}`);
  };

  const fetchUserData = async () => {
    startPageLoader();
    try {
      const response = await api('get', '/users/blocked/');
      const blockedUsers = response?.data?.results?.map((user) => user?.id);
      const selectedUserId = Number(singleChannel?.other_user?.user_id);
      if (blockedUsers?.includes(selectedUserId)) {
        setUserBlocked(true);
      } else {
        console.log(`User with ID ${selectedUserId} is not blocked.`);
        setUserBlocked(false);
      }
    } catch (error) {
      console.log('ERROR --->', error);
    }
    stopPageLoader();
  };

  const handleBlockUser = async () => {
    const id = singleChannel?.other_user?.user_id;
    startPageLoader();
    try {
      const sendbird = await getSendbirdObj();
      if (userBlocked) {
        await sendbird.unblockUserWithUserId(id);
      } else {
        await sendbird.blockUserWithUserId(id);
      }
      const response = await api('get', `/users/${id}/block-unblock/`);
      setUserBlocked((prevState) => !prevState);
      toast.success(response?.data);
      setOpen(false);
    } catch (error) {
      console.log('ERROR --->', error);
    }
    stopPageLoader();
  };

  const handleDeleteChat = async () => {
    const url = singleChannel?.channel_url;
    startPageLoader();
    try {
      const response = await api('delete', `/channels/delete-channel/?channel_url=${url}`);
      console.log('deletechatChannel', response);
      toast.success('Chat Deleted');
      setOpen(false);
      setSingleChannel('');
      getAllChannels();
    } catch (error) {
      console.log('ERROR --->', error);
      toast.error('unable to delete');
    }
    stopPageLoader();
  };

  let debounceTimer = null;

  const debounceSearchApi = (event, delay) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(async () => {
      startPageLoader();
      try {
        const response = await api('get', `/users/?search=${event.target.value}`);
        const { results } = response.data;
        if (results.length > 0) setSearchedUsers(results);
      } catch (error) {
        console.log('ERROR --->', error);
      }
      stopPageLoader();
    }, delay);
  };

  const handleRemoveSuggestedUser = (index) => {
    setSearchedUsers((prevState) => {
      return prevState.filter((user, i) => i !== index);
    });
  };

  const handleLoadChatOnScroll = () => {
    // Check if the user has scrolled to the top
    if (
      msgsDivRef.current.scrollTop === 0 &&
      messages.length &&
      hasMoreMessagesRef.current === true
    ) {
      // Call your function here when the user scrolls to the top
      console.log('User scrolled to the top of the chat wrapper');
      setScrollToMessageId(messages[0]?.message_id);

      getChannelMessages(messages[0]?.message_id || 0, false);
      // Add your logic or function call here
    }
  };

  const searchSongApi = () => {
    if (searchedSongs.offset != 0 && searchedSongs.hasMore === false) {
      return;
    }
    console.log(searchedSongs);
    const endpoint = `/songs/?search=${searchedSongs.search}&limit=${PAGINATION_LIMIT}&offset=${searchedSongs.offset}`;
    api('get', endpoint)
      .then((res) => {
        console.log(res);
        if (searchedSongs.offset === 0) {
          // page 1
          setSearchedSongs((prevState) => {
            return {
              ...prevState,
              hasMore: !!res.data.next,
              offset: prevState.offset + PAGINATION_LIMIT,
              results: res.data.results,
            };
          });
        } else {
          // next page
          setSearchedSongs((prevState) => {
            return {
              ...prevState,
              hasMore: !!res.data.next,
              offset: prevState.offset + PAGINATION_LIMIT,
              results: [...prevState.results, ...res.data.results],
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setSearchedSongs((prevState) => {
          return {
            ...prevState,
            hasMore: false,
          };
        });
      });
  };

  const debouncedSongSearch = debounce((searchInputValue) => {
    // Your logic for handling the search term
    // console.log(searchInputValue);
    setSearchedSongs(() => {
      return {
        results: [],
        offset: 0,
        hasMore: false,
        search: searchInputValue,
      };
    });
  }, 1500); // 1.5 seconds

  useEffect(() => {
    if (searchedSongs.search) {
      searchSongApi();
    } else {
      setSearchedSongs((prevState) => {
        return {
          ...prevState,
          results: [],
          offset: 0,
          hasMore: false,
        };
      });
    }
  }, [searchedSongs.search]);

  useEffect(() => {
    if (msgsDivRef.current) {
      const specificMessageElement = document.getElementById(`message_item_${scrollToMessageId}`);
      if (specificMessageElement) {
        specificMessageElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        // msgsDivRef.current.scrollTop = specificMessageElement.offsetTop;
      }
    }
  }, [messages]);

  useEffect(() => {
    getAllChannels();
  }, []);

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleChannel, singleChannel?.other_user?.user_id]);

  useEffect(() => {
    (async () => {
      if (singleChannel?.channel_url) {
        console.log('init chat');
        setMessages([]);

        // The USER_ID below should be unique to your Sendbird application.
        try {
          const sendbird = await getSendbirdObj();

          setScrollToMessageId(singleChannel?.last_message?.message_id);
          getChannelMessages(singleChannel?.last_message?.message_id, true);
          const channelHandler = new sendbird.ChannelHandler();
          channelHandler.onMessageReceived = (_channel, _message) => {
            console.log(_channel, _message);
            if (_channel.url === singleChannel.channel_url) {
              console.log(_channel, _message);
              setScrollToMessageId(_message.messageId);

              setMessages((prevState) => {
                return [
                  ...prevState,
                  {
                    message_id: _message.messageId,
                    user: {
                      user_id: _message.sender.userId,
                      profile_url: _message.sender.profileUrl,
                    },
                    message: _message.message,
                    data: _message.data,
                    custom_type: _message.customType,
                  },
                ];
              });
            }
          };
          sendbird.addChannelHandler('CHANNEL_HANDLER_ID', channelHandler);

          // The user is connected to the Sendbird server.
        } catch (err) {
          // Handle error.
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleChannel?.channel_url]);

  useEffect(() => {
    if (state?.id) {
      const filteredChannels = allChannels.filter(
        (item) => item?.last_message?.user?.user_id === state?.id,
      );
      const singleChannelUser = filteredChannels?.map((item) => item);
      handleClick(singleChannelUser[0]);
    }
  }, [state?.id, allChannels]);
  useEffect(() => {
    if (state?.chat) {
      handleClick(state?.chat);
    }
  }, [state?.chat]);

  return (
    <div className="chat_background">
      <PageLoader isLoading={isPageLoading} />
      <ShareSocial
        handleClosshare={() => setShareReviewId(0)}
        shareModal={!!shareReviewId}
        reviewId={shareReviewId}
      />
      <div className="leftbottomColor leftbottomColor_dashboard_bottom">
        <img src={RightTop} alt="x" />
      </div>
      <div className="chat_box">
        <div className="chat_div">
          <div className="chatheading">
            <p className="ChatHeadTxt">Messages</p>
            <div className="img_div">
              <button
                type="button"
                style={{ cursor: 'pointer', background: 'none', border: 'none' }}
                onClick={() => setOpenSearch(!openSearch)}
              >
                <img src={Add} alt="" />
              </button>
            </div>
          </div>
          {openSearch ? (
            <>
              <div className="search_div">
                <input
                  className="searchBar"
                  type="text"
                  onKeyUp={(e) => {
                    debounceSearchApi(e, 500);
                  }}
                  placeholder="Search for someone..."
                />
                <img className="search_img" src={search} alt="" />
              </div>
              <p style={{ fontWeight: 400 }} className="ChatHeadTxt">
                Suggested
              </p>
              <div className="allSuggested">
                {React.Children.toArray(
                  searchedUsers?.length > 0 &&
                    searchedUsers?.map((chat, index) => (
                      <ChatsComponent
                        chatScreen
                        key={chat?.id}
                        ProfilePic={chat?.profile?.avatar_url}
                        UserName={chat?.name || chat?.username}
                        isChecked={chat?.isChecked}
                        msgCounterValue={chat?.msgCounterValue}
                        cross
                        onPress={() => createChannel(chat?.username)}
                        onCrossClick={() => handleRemoveSuggestedUser(index)}
                      />
                    )),
                )}
              </div>
            </>
          ) : (
            <div className="allSuggested">
              {allChannels?.map((chat) => (
                <ChatsComponent
                  chatScreen
                  key={chat?.channel_url}
                  ProfilePic={chat?.other_user?.profile_url || chat?.cover_url}
                  UserName={
                    chat?.other_user?.metadata?.name || chat?.other_user?.metadata?.username
                  }
                  isChecked={chat?.isChecked}
                  msgCounterValue={chat?.unread_message_count}
                  message={chat?.last_message}
                  chat={chat}
                  parentClass="selected_tab"
                  pd
                  selectedItems={selectedItems}
                  handleClick={handleClick}
                  clickable
                  clickTab
                />
              ))}
            </div>
          )}
        </div>

        <div className="divider" />
        <div className="rightSide_chat">
          {!!singleChannel?.channel_url && (
            <button type="button" className="option_div" onClick={handleOpen}>
              <img src={Options} alt="" />
            </button>
          )}
          {!singleChannel?.channel_url ? (
            <div className="empty_msgs_content">
              <div className="img_div">
                <img src={Frame} alt="" />
              </div>
              <h2 className="yourMessage_title">Your Messages</h2>
              <p className="yourMessage_p">
                Open one of the chats or send a message to someone new
              </p>
              <button type="button" className="sendMsg_btn" onClick={() => setOpenSearch(true)}>
                Send Message
              </button>
            </div>
          ) : (
            <div className="message_container">
              <div className="profile_content">
                <div className="profile_img_div">
                  <img
                    className="profile_img"
                    src={singleChannel?.other_user?.profile_url || singleChannel?.cover_url}
                    alt="profile_pic"
                  />
                </div>
                <p className="profile_text">@{singleChannel?.name}</p>
                <p className="profile_text">2.4K followers | 909 following</p>
                <p className="profile_text">Lorem ipsum dolor sit amet, consectetur adipiscin.</p>
                <button type="button" onClick={handleSeeProfile} className="seeProfile_btn">
                  See Profile
                </button>
                <p className="profile_time">MAY 14, 12:10 pm</p>
              </div>

              <div
                className="msg_section"
                id="msgsss"
                ref={msgsDivRef}
                onScroll={handleLoadChatOnScroll}
              >
                {messages?.length > 0 &&
                  messages?.map((item) => (
                    <ChatMsg
                      item={item}
                      key={item.message_id}
                      userId={userId}
                      setShareReviewId={setShareReviewId}
                    />
                  ))}
              </div>
              {!userBlocked &&
                (showSendSong ? (
                  <div>
                    {!!searchedSongs.results.length && (
                      <InfiniteScroll
                        dataLength={searchedSongs.results.length} // This is important field to render the next data
                        next={searchSongApi}
                        hasMore={searchedSongs.hasMore}
                        height={150}
                        loader={<div style={{ textAlign: 'center' }}>Loading..</div>}
                        style={{
                          padding: '10px',
                          borderTop: '1px solid gray',
                        }}
                      >
                        {searchedSongs.results.map((item) => {
                          return (
                            <div
                              key={item.id}
                              className="songSuggestedList"
                              onClick={() => handleSendSongClick(item)}
                            >
                              <div className="suggestSongName">
                                <div className="songSuggImg">
                                  <img src={item?.image || dummyProfileImage} alt="." />
                                </div>
                                <div>
                                  <p className="songNameSuggested">
                                    {item?.title || 'no text here'}
                                  </p>
                                </div>
                                <p id="opacitytxt">|</p>
                                <p id="opacitytxt">{item?.artist_name}</p>
                                <div>
                                  <p className="songNameSuggestedDet">{item?.songDetail}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </InfiniteScroll>
                    )}

                    <div className="input_div">
                      <input
                        className="typing_input"
                        placeholder="Search for song"
                        type="text"
                        onChange={(e) => debouncedSongSearch(e.target.value)}
                        id="songSearchInput"
                      />

                      <div className="img_div_add">
                        <button className="addBtn" type="button" onClick={toggleShowSendSong}>
                          <img src={CloseIcon} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="input_div">
                    <input
                      className="typing_input"
                      value={textValue}
                      onChange={(e) => handleMessage(e)}
                      placeholder="Type Message...or, Paste musotic song, album or review page url."
                      type="text"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSend();
                        }
                      }}
                    />

                    <button onClick={handleSend} className="send_btn" type="button">
                      Send
                    </button>
                    <div className="img_div_add">
                      <button className="addBtn" type="button" onClick={toggleShowSendSong}>
                        <img src={addIcon} alt="" />
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="message_popup">
            <div>
              <div className="popup_heading">
                <h2>Options</h2>
                <button
                  onClick={() => {
                    setOpen(false);
                  }}
                  className="closeIconAddReviews popup_btn_option"
                >
                  <img src={CloseIcon} alt="..." />
                </button>
              </div>
              <div className="Dashed_line Dashed_line1" />
              <div className="popuptext-main-div" onClick={handleBlockUser}>
                <img src={Block} alt="." />
                <h2>
                  {userBlocked ? 'unblock' : 'Block'} {singleChannel?.other_user?.nickname}
                </h2>
              </div>
              <div className="popuptext-main-div" onClick={handleDeleteChat}>
                <img src={Delete} alt="." />
                <h2>Delete Chat</h2>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

function SongShare({ data, type }) {
  const musoticUrl = `${window.location.origin}/${
    type === 'song' ? 'songDetails' : 'albumDetails'
  }/${data.id}`;

  const _canShowAppleMusicLogo = canShowAppleMusicLogo(data?.apple_music_url, data?.spotify_url);
  console.log('_canShowAppleMusicLogo', _canShowAppleMusicLogo);
  return (
    <div className="SongShareWrapper">
      <div className="SongShareImageWrapper">
        <img src={data.image} alt={data.title} />
      </div>
      <div className="pl-5">
        <p className="songNameSuggested">{data?.title || 'no text here'}</p>
        <p id="opacitytxt">
          {data?.artist_name} | {type === 'song' ? 'Track' : 'Album'}
        </p>
      </div>
      <div className="pl-5">
        {_canShowAppleMusicLogo && (
          <div
            className="spotifyIconWrapper cursorPointer"
            onClick={() => window.open(data.apple_music_url, '_blank')}
          >
            <img src={appleMusicIcon} alt="appleMusicIcon" width={25} />
          </div>
        )}
        {!_canShowAppleMusicLogo && data?.spotify_url && (
          <div
            className="spotifyIconWrapper cursorPointer"
            onClick={() => window.open(data.spotify_url, '_blank')}
          >
            <img src={spotifyIcon} alt="spotifyIcon" width={25} />
          </div>
        )}

        {!!musoticUrl && (
          <div className="cursorPointer" onClick={() => window.open(musoticUrl, '_blank')}>
            <img src={musoticSongIcon} alt="musoticSongIcon" width={25} />
          </div>
        )}
      </div>
    </div>
  );
}

function ReviewShare({ data, setShareReviewId }) {
  const musoticUrl = `${window.location.origin}/reviews/${data.id}`;
  let type;
  let image;
  let title;
  let artistName;
  if (data.song) {
    type = 'Track';
    image = data.song.image;
    title = data.song.title;
    artistName = data.song.artist_name;
  } else {
    type = 'Album';
    image = data.album.image;
    title = data.album.title;
    artistName = data.album.artist_name;
  }

  return (
    <div>
      <div
        className="SongShareWrapper cursorPointer"
        onClick={() => window.open(musoticUrl, '_blank')}
      >
        <div className="SongShareImageWrapper">
          <img src={image} alt={title} />
        </div>
        <div className="pl-5">
          <p className="songNameSuggested">{title || 'no text here'}</p>
          <p id="opacitytxt">
            {artistName} | {type}
          </p>
        </div>
        <div className="pl-20">
          {data.ratings} <img src={starIcon} alt="ratings" />
        </div>
      </div>
      <div>
        <p className="songNameSuggested mt-10">{data.title}</p>
        <div className="posterSinfos mt-10">
          <div className="postByUserImg">
            <img
              src={data.user.avatar || dummyProfileImage}
              alt="pic"
              style={{ objectFit: 'cover', borderRadius: '50%' }}
            />
          </div>
          <div>
            <p className="postedbyUsername">{data.user.username}</p>
          </div>
          <div className="checked">
            {data?.user?.is_verified === true && <img src={Checked} alt="pic" />}
          </div>
        </div>
        <div className="mt-10 d-flex-sb">
          <div className="d-flex-ac">
            <span className="d-flex-ac">
              <img src={heartIconFilled} alt="HeartIcon" />{' '}
              <span id="opacitytxt"> &nbsp; {data.like_count} Likes</span>
            </span>
            <span className="pl-5 d-flex-ac">
              <img src={CommentIconFilled} alt="CommentIconFilled" />{' '}
              <span id="opacitytxt">&nbsp;{data.comment_count} Comments</span>
            </span>
          </div>
          <div>
            {' '}
            <img
              src={shareIconFilled}
              alt="shareIconFilled"
              className="cursorPointer"
              onClick={() => setShareReviewId(data.id)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function ChatMsg({ item, userId, setShareReviewId }) {
  console.log(item);

  let message;

  switch (item.custom_type) {
    case chatMessageType.reviews: {
      const data = JSON.parse(item.data);
      message = <ReviewShare data={data} setShareReviewId={setShareReviewId} />;
      // message = '___review____';
      console.log(data, 'review');
      break;
    }

    case chatMessageType.songDetails: {
      const data = JSON.parse(item.data);
      console.log(data);
      message = <SongShare data={data} type="song" />;
      // message = '_____songDetails_______';
      console.log(data, 'songDetails');
      break;
    }

    case chatMessageType.albumDetails: {
      const data = JSON.parse(item.data);
      console.log(data);
      message = <SongShare data={data} type="album" />;
      // message = '___albumDetails____';
      console.log(data, 'albumDetails');
      break;
    }

    default:
      message = item?.message;
      break;
  }

  if (!message) {
    return null;
  }
  return (
    <div
      key={item?.message_id}
      style={{ width: item?.user?.user_id == userId ? 'auto' : '100%' }}
      id={`message_item_${item.message_id}`}
    >
      {item?.user?.user_id !== userId ? (
        <div>
          <div className="sender_section">
            <div className="chat_img_div">
              <img className="profile_img2" src={item?.user?.profile_url} alt="" />
            </div>
            <div className="message_box">{message}</div>
          </div>
        </div>
      ) : (
        <div className="receiver_section">
          <div className="receiver_message_box">{message}</div>
        </div>
      )}
    </div>
  );
}

export default AllChats;
