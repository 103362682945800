/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Cross from '../assets/Images/Cross.svg';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import VerifiedTick from '../assets/Images/verified.svg';
import { chatMessageType } from '../utils/constant';

export default function ChatsComponent({
  ProfilePic,
  UserName,
  isChecked,
  msgCounterValue,
  chatScreen,
  cross,
  message,
  chat,
  parentClass,
  pd,
  selectedItems,
  handleClick,
  onPress,
  onCrossClick,
}) {
  const useLessFunc = () => {
    console.log('onkeyDown pressed');
  };
  const nav = useNavigate();
  const handleCrossClick = (e) => {
    e.stopPropagation();
    if (cross && onCrossClick) {
      onCrossClick();
    }
  };

  let lastMsg = '';
  if (message) {
    if (message.custom_type === chatMessageType.reviews) {
      lastMsg = 'Shared Review.';
    } else if (message.custom_type === chatMessageType.songDetails) {
      lastMsg = 'Shared Song.';
    } else if (message.custom_type === chatMessageType.albumDetails) {
      lastMsg = 'Shared Album.';
    } else if (message.message.length > 10) {
      lastMsg = `${message.message.substring(0, 10)}...`;
    } else {
      lastMsg = message.message;
    }
  }

  return (
    <div
      style={{ cursor: 'pointer' }}
      tabIndex={0}
      onKeyDown={useLessFunc}
      role="button"
      onClick={
        onPress ||
        (handleClick
          ? () => handleClick(chat)
          : () => {
              nav('/chat', { state: { chat } });
            })
      }
      className={selectedItems === chat?.channel_url ? parentClass : ''}
    >
      <div style={pd && { padding: '10px' }} className="userProflieForChat">
        <div className="userChatleftDiv">
          <div className="ProfileImgUserChat">
            <img src={ProfilePic || dummyProfileImage} alt="pic" />
          </div>
          <div className="chat_messages">
            <div style={{ display: 'flex' }}>
              <p className="chatUsersNametxt">{UserName}</p>
              {isChecked && (
                <div className="chatVerifiedLogo">
                  <img src={VerifiedTick} alt="pic" />
                </div>
              )}
            </div>
            {/* {chatScreen && <p className="user_messages">{message}</p>} */}
            {chatScreen && (
              <p className="user_messages">
                {/* {message
                  ?.split(' ')
                  .slice(0, 8)
                  .map((word, index) => (
                    <span key={index.id}>{word} </span>
                  ))} */}
                <span>{lastMsg}</span>
              </p>
            )}
          </div>
        </div>

        {cross ? (
          <div className="img_div" onClick={handleCrossClick} role="button">
            <img src={Cross} alt="" />
          </div>
        ) : (
          msgCounterValue > 0 && (
            <div className="msgCounterDiv">
              <p className="chatMsgCounter">{msgCounterValue}</p>
            </div>
          )
        )}
      </div>
    </div>
  );
}
