const baseUrl = `${window.location.origin}/api/v1`;
const baseUrlV2 = `${window.location.origin}/api/v2`;
// alert(window.location.origin);
// if (window.location.host === 'http://localhost:3000' && false) {
//   baseUrl = 'https://musotic-37073-staging.botics.co/api/v1';
// }
// if (window.location.host === 'reactapp-dev.falconweb.app') {
//   baseUrl = 'https://reactapp-api-dev.falconweb.app/api';
// } else if (window.location.host === 'reactapp-qa.falconweb.app') {
//   baseUrl = 'https://reactapp-api-qa.falconweb.app/api';
// } else if (window.location.host === 'reactapp-staging.falconweb.app') {
//   baseUrl = 'https://reactapp-api-staging.falconweb.app/api';
// } else if (window.location.host === 'reactapp-prod.falconweb.app/') {
//   baseUrl = 'https://reactapp-api-prod.falconweb.app/api';
// } else if (window.location.host === 'musotic-37073-staging.botics.co') {
//   baseUrl = 'https://musotic-37073-staging.botics.co/api/v1';
// }
module.exports = { baseUrl, baseUrlV2 };
