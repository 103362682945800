/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ReactApexChart from 'react-apexcharts';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import IntensityComponent from './IntensityGraphTest';
import ModalFollowers from './ModalFollowers';
import api from '../api/index';
import SearchInput from './SearchInput';
import BlockUserPopup from './BlockUserPopup';
import ProfilePic from '../assets/Images/SugarImg.svg';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import Score from './Score';
import TrophyIcon from '../assets/Images/trophyIcon.svg';
import BlueStar from '../assets/Images/StarIcon.svg';
import PinkHeart from '../assets/Images/HeartPinkIcon.svg';
import ReviewList from './MyReviewsItems';
import wave from '../assets/Images/Wave.svg';
import StarIcon from '../assets/Images/YellowStarIcon.svg';
import Arrow from '../assets/Images/rightarrowIcon.svg';
import CopyLinkIcon from '../assets/Images/CopyLinkIcon.svg';
import ShareIcon from '../assets/Images/ShareOption.svg';
import RemovePlaylist from '../assets/Images/RemovefromPlaylist.svg';
import ShareOniMessage from '../assets/Images/editaddsong_ShareOnIMessageIcon.svg';
import EditIcon from '../assets/Images/EditOption.svg';
import DeleteIcon from '../assets/Images/DeleteOption.svg';
import PrivateIcon from '../assets/Images/PrivateIcon.svg';
import AddSongsIcon from '../assets/Images/AddSongsIcon.svg';
import SearchIcon from '../assets/Images/SearchInput.svg';
import Checked from '../assets/Images/checkVerified.svg';
import likes from '../assets/Images/likes.svg';
import comments from '../assets/Images/Comment.svg';
import share from '../assets/Images/share.svg';
import BackArrow from '../assets/Images/BackArrow.svg';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import BackIcon from '../assets/Images/back.svg';
import dots from '../assets/Images/dots.svg';
import block1 from '../assets/Images/block1.svg';
import report1 from '../assets/Images/report1.svg';
import url1 from '../assets/Images/url1.svg';
import share2 from '../assets/Images/share2.svg';
import SinglePlayList from './SinglePlayList';
import SinglePlayListDetail from './SinglePlayListDetail';
import RightTop from '../assets/Images/RightTop.svg';
import Reviewdetailepopup from './Reviewdetailpopup';
import { queryParamsKey } from '../utils/constant';
import {
  errorLogCountAnalyticsEvent,
  friendProfileShareAnalyticsEvent,
} from '../service/analyticsService';

const SuggestedSongData = [
  {
    id: 1,
    songTitle: 'Leave the door open',
    songDescription: 'track | bruno mars, Anderson paak',
    ProfilePic,
  },
  {
    id: 2,
    songTitle: 'Silk Sonic',
    songDescription: 'Album | bruno mars, Anderson paak',
    ProfilePic,
  },
  {
    id: 3,
    songTitle: 'Leave the door open',
    songDescription: 'track | bruno mars, Anderson paak',
    ProfilePic,
  },
  {
    id: 4,
    songTitle: 'Silk Sonic',
    songDescription: 'Album | bruno mars, Anderson paak',
    ProfilePic,
  },
  {
    id: 5,
    songTitle: 'Leave the door open',
    songDescription: 'track | bruno mars, Anderson paak',
    ProfilePic,
  },
  {
    id: 6,
    songTitle: 'Silk Sonic',
    songDescription: 'Album | bruno mars, Anderson paak',
    ProfilePic,
  },
];

const style = {
  position: 'absolute',
  top: '20%',
  left: '20%',
  transform: 'translate(-0%, -0%)',
  width: 400,
  bgcolor: 'white',
  border: 'none',
  boxShadow: 24,
};

const editstyle = {
  position: 'absolute',
  top: '20%',
  left: '30%',
  width: 500,
  bgcolor: 'transparent',
  border: 'none',
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function UserProfile() {
  const { userId } = useParams();
  const [panelDataNew, setPanelDataNew] = useState([]);
  const [playlistName, setPlaylistName] = useState('');
  const [playlistDesc, setPlaylistDesc] = useState('');
  const [playlistImg, setPlaylistImg] = useState('');
  const [selectedSong, setSelectedSong] = useState({});
  const [selected, setSelected] = useState('');
  const [createPlaylist, setCreatePlaylist] = useState(false);
  const [playlistTopSongs, setPlaylistTopSongs] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [addedSongs, setAddedSongs] = useState(false);
  const [editSong, setEditAddedSong] = useState(false);
  const [removePlaylist, setRemovePlaylist] = useState(false);
  const [playlistOption, setPlaylistOption] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [selectedPlaylist, setSelectedPlaylist] = useState({});
  const [email, setEmail] = useState('');
  const [username, setUserName] = useState('');
  const [userLink, setUserLink] = useState('');
  const [playlistComments, setPlaylistComments] = useState([]);
  const [userBio, setUserBio] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userGender, setUserGender] = useState('');
  const [userDob, setUserDob] = useState('');
  const [location, setLocation] = useState('');
  const [namee, setNamee] = useState('');
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [specificUserId, setSpecificUserId] = useState('');
  const [specificUserName, setSpecificUserName] = useState('');
  const [wait, setWait] = useState('pointer');
  const [playlistSongs, setPlaylistSongs] = useState([]);
  const [playlistDetails, setPlaylistDetails] = useState(false);
  const [playlistID, setPlaylistID] = useState();
  const [stats, setStats] = useState();
  const [value, setValue] = React.useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [userReviews, setUserReviews] = useState([]);
  const [blocked, setBlocked] = useState();
  const url = window.location.host;
  const loc = useLocation();
  const completeUrl = url + loc.pathname;
  const [profileData, setProfileData] = useState();
  const [editProfile, setEditProfile] = useState();
  const [openOption, setOpenOption] = useState(false);
  const [userData, setUserData] = useState({
    score: null,
    name: '',
    avatarUrl: '',
    bio: '',
    isVerified: false,
    follower: null,
    following: null,
    username: '',
    email: '',
    phone: '',
    gender: '',
    birthday: '',
    link: '',
    country: '',
  });
  const nav = useNavigate();
  const profileUsername = localStorage.getItem('profileUsername');
  const [queryString] = useSearchParams();

  const playlistDetailsClose = () => {
    setPlaylistDetails(false);
  };
  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleGetPlaylistSongs = (playlistId) => {
    setWait('wait');
    api('get', `/playlists/${playlistId}/songs/`)
      .then((res) => {
        setPlaylistSongs(res?.data?.results);
        setPlaylistDetails(true);
        setWait('pointer');
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
        setWait('pointer');
      });
  };
  const fetchPlaylistComments = (playlistId) => {
    api('get', `/playlists/${playlistId}/comments/`)
      .then((res) => {
        setPlaylistComments(res?.data?.results);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
      });
  };

  const playlistDetailsOpen = async (item) => {
    handleGetPlaylistSongs(item);
    fetchPlaylistComments(item);

    try {
      const response = await api('get', `/playlists/${item}/`);
      setSelectedPlaylist(response?.data);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };

  const handleFollowersButtonClick = (data) => {
    setSpecificUserId(data?.id);
    setSpecificUserName(data);
    setShowFollowersModal(true);
  };
  const handleFollowersModalClose = () => {
    setShowFollowersModal(false);
  };
  const getStats = () => {
    api('get', `/users/${userId}/stats/`)
      .then((res) => {
        setStats(res?.data);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
      });
  };
  const fetchUserReviews = async () => {
    try {
      const response = await api('get', `/users/${userId}/reviews/`);
      setUserReviews(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  const imgaeUrlFromApi = async () => {
    const formData = new FormData();
    formData.append('image', playlistImg);
    try {
      const response = await api('post', '/upload-image/', formData);
      const imgUrl = response?.data?.image_url;
      setImageUrl(imgUrl);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };

  const handleSelectedSongClick = () => {
    setSelectedSong({});
  };
  const fetchPanelData = async () => {
    try {
      const response = await api('get', `/users/${userId}/playlists/`);
      setPanelDataNew(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching panel data:', err);
    }
  };

  const handleAddPLaylistClick = async () => {
    const image = imageUrl;
    const name = playlistName;
    const desc = playlistDesc;
    const is_private = false;
    const data = { image, name, desc, is_private };
    try {
      const response = await api('post', '/playlists/', data);
      console.log('playlistAdd', response);
      toast.success('Playlist created');
      setCreatePlaylist(false);
      fetchPanelData();
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error creating playlist', err);
    }
  };

  const topSongsClose = () => {
    setPlaylistTopSongs(false);
  };
  const handleCrossClickPlaylist = () => {
    topSongsClose(false);
    handleAddPLaylistClick();
  };

  const fetchUserData = async () => {
    try {
      const response = await api('get', '/users/me/');
      const { data } = response;
      setUserData({
        score: data?.score,
        name: data?.name,
        avatarUrl: data?.profile?.avatar_url,
        bio: data?.profile.bio,
        isVerified: data?.profile?.is_verified,
        follower: data?.follower,
        following: data?.following,
        username: data?.username,
        email: data?.email,
        phone: data?.profile?.phone,
        gender: data?.profile?.gender,
        birthday: data?.profile?.dob,
        link: data?.profile?.link,
        country: data?.profile?.country,
      });
      console.log('data', data);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log(err);
    }
  };
  const getUserData = () => {
    api('get', `/users/${userId}/`)
      .then((res) => {
        setProfileData(res?.data);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
      });
  };

  const series = [
    {
      name: 'series1',
      data: stats?.rating_history.map((item) => item?.count),
      color: '#57BBEB',
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    grid: {
      borderColor: '#5A6B73',
      strokeDashArray: [2],
    },
    xaxis: {
      type: 'datetime',
      categories: stats?.rating_history.map((item) => item?.year),

      labels: {
        style: {
          colors: '#828F96',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#828F96',
        },
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenBlockPopup = () => {
    setOpenOption(true);
  };

  const handleEditProfile = () => {
    setEditProfile(true);
  };

  const handleFollow = () => {
    api('get', `/users/${userId}/followership/`)
      .then((res) => {
        getUserData();
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
      });
  };

  const handleClose = () => {
    setCreatePlaylist(false);
    setEditProfile(false);
    setEditProfile(false);
  };

  const topSongsOpen = (item) => {
    setPlaylistTopSongs(true);
    setSelectedPlaylist(item);
  };
  const addedSongsOpen = () => {
    setAddedSongs(true);
  };
  const addedSongsClose = () => {
    setAddedSongs(false);
  };
  const editaddedSongsOpen = () => {
    setEditAddedSong(true);
  };
  const editaddedSongsClose = () => {
    setEditAddedSong(false);
  };
  const removePlaylistOpen = () => {
    setRemovePlaylist(true);
  };
  const removePlaylistClose = () => {
    setRemovePlaylist(false);
  };
  const playlistOptionOpen = () => {
    setPlaylistOption(true);
  };
  const playlistOptionClose = () => {
    setPlaylistOption(false);
  };
  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  const handleSendMessage = () => {
    nav('/chat', { state: { id: userId } });
  };
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(completeUrl);
      toast.success('Link copied to clipboard!');
    } catch (error) {
      errorLogCountAnalyticsEvent();
      console.error('Copy failed:', error);
    }
  };

  const handleReportClick = () => {
    const user = userId;
    const body = { user };
    api('post', `/report-user/`, body)
      .then((res) => {
        toast.success('user reported');
        setOpenOption(false);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
      });
  };
  const handleShareProfileClick = async (Url) => {
    try {
      const data1 = {
        title: 'Sharing User Profile',
        text: 'Hey, sharing this user profile with you !',
        url: `https://musotic-dev.falconweb.app/user/${userId}`,
      };
      friendProfileShareAnalyticsEvent();
      await navigator.share(data1);
    } catch (error) {
      errorLogCountAnalyticsEvent();
      console.error('Copy failed:', error);
    }
  };
  const handleBlock = () => {
    api('get', `/users/${userId}/block-unblock/`)
      .then((res) => {
        setBlocked(res?.data);
        toast.success(res?.data);
        setOpenOption(false);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
      });
  };
  useEffect(() => {
    setUserGender(userData?.gender || '');
    setUserDob(userData?.birthday || 'YYYY-MM-DD');
    setUserPhone(userData?.phone || '');
    setEmail(userData?.email || '');
    setUserBio(userData?.bio || '');
    setLocation(userData?.country || '');
    setUserLink(userData?.link || '');
    setUserName(userData?.name || '');
    setNamee(profileUsername || '');
  }, [userData]);
  useEffect(() => {
    getStats();
  }, [userId]);
  useEffect(() => {
    if (userId) {
      fetchUserReviews();
    }
  }, [userId]);
  useEffect(() => {
    imgaeUrlFromApi();
  }, [playlistImg]);
  useEffect(() => {
    fetchPanelData();
    getUserData();
  }, [userId]);
  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    // when playlist url is shared open respective playlist
    const queryAsObject = Object.fromEntries(new URLSearchParams(queryString));

    const tab = queryAsObject[queryParamsKey.friendProfile.openTabKey];
    const tabId = Number(queryAsObject[queryParamsKey.friendProfile.tabIdKey]);

    if (tab === queryParamsKey.friendProfile.openTabValues.playlist) {
      setValue(1); // playlist tab index
      if (tabId) {
        playlistDetailsOpen(tabId);
      }
    }
  }, [queryString]);

  return (
    <>
      <div className="userProfile_Section">
        <div className="leftbottomColor leftbottomColor_dashboard_bottom">
          <img src={RightTop} alt="x" />
        </div>
        <div className="userProfile">
          <div className="userProfile_inner">
            <div className="profile_inner_firstSection">
              <div className="profile_image">
                <img src={profileData?.profile?.avatar_url || dummyProfileImage} alt="profile" />
              </div>
              <div className="profile_data_Section">
                <div className="profile_first_data" id="userFriendProfileFirstRow">
                  <p className="profile_name">{profileData?.name || profileData?.username}</p>
                  <div className="friendProfileBtnsDiv">
                    {profileData?.is_following ? (
                      <button
                        className="followUserBtnOnfrndProfile"
                        id="followUserBtnOnfrndProfile2"
                        onClick={handleFollow}
                        type="submit"
                      >
                        Following
                      </button>
                    ) : (
                      <button
                        className="followUserBtnOnfrndProfile"
                        onClick={handleFollow}
                        type="submit"
                      >
                        Follow
                      </button>
                    )}

                    <button
                      onClick={handleSendMessage}
                      className="MessageUserBtnOnfrndProfile"
                      type="submit"
                    >
                      Message
                    </button>
                    <div
                      onClick={() => {
                        handleEditProfile();
                      }}
                      className="edit_img_div"
                    >
                      <img src={dots} alt="." />
                    </div>
                  </div>
                </div>
                <p className="profile_user_name">@{profileData?.username || 'UserName'}</p>
                <p className="profile_disc">
                  {profileData?.profile?.bio ||
                    'Lorem ipsum dolor sit amet, consectetur adipiscin.'}
                </p>
                <Score open={modalOpen} handleClose={handleCloseModal} userData={profileData} />

                <div className="profile_follow_data">
                  <div className="profile_numbers_data" onClick={handleOpenModal}>
                    <p className="profile_number_txt">{profileData?.score || '0'}</p>
                    <div className="profile_txt_image">
                      <div className="profile_follow_icon">
                        <img src={TrophyIcon} alt="score" />
                      </div>
                      <p className="profile_character_txt">Score</p>
                    </div>
                  </div>
                  <div className="profile_follow_data_line" />
                  <div className="profile_numbers_data">
                    <p className="profile_number_txt">{profileData?.follower}</p>
                    <div
                      className="profile_txt_image"
                      onClick={() => handleFollowersButtonClick(profileData)}
                    >
                      <div className="profile_follow_icon">
                        <img src={BlueStar} alt="followers" />
                      </div>
                      <p className="profile_character_txt">Followers</p>
                    </div>
                  </div>
                  {showFollowersModal && (
                    <ModalFollowers
                      specificUserId={specificUserId}
                      specificUserName={specificUserName}
                      closeModal={handleFollowersModalClose}
                    />
                  )}

                  <div className="profile_follow_data_line" />
                  <div className="profile_numbers_data">
                    <p className="profile_number_txt">{profileData?.following}</p>
                    <div
                      className="profile_txt_image"
                      onClick={() => handleFollowersButtonClick(profileData)}
                    >
                      <div className="profile_follow_icon">
                        <img src={PinkHeart} alt="following" />
                      </div>
                      <p className="profile_character_txt">Following</p>
                    </div>
                  </div>
                </div>
                <div className="likedBy" id="friendsProfileDivForFollowedby">
                  {profileData?.followed_by.slice(0, 3).map((item) => (
                    <div className="likedByImgWrap">
                      <img src={item.avatar ? item.avatar : dummyProfileImage} alt="pic" />
                    </div>
                  ))}
                  {profileData?.followed_by?.length > 0 && (
                    <p className="likedbyTxt">
                      Followed by&nbsp;{' '}
                      <span className="boldTxt">
                        {' '}
                        {profileData?.followed_by[0]?.username ||
                          profileData?.followed_by[0]?.name ||
                          'user'}
                      </span>
                      {profileData?.followed_by?.length > 1 && (
                        <>
                          <span> and</span>
                          <span className="boldTxt">
                            &nbsp;{' '}
                            {profileData?.followed_by ? profileData.followed_by.length - 1 : ''}
                            &nbsp;others
                          </span>
                        </>
                      )}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="profile_filters">
              <Box sx={{ width: '100%', height: '100vh' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    className="tabsPanelButton_main"
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab className="tabs_button" label="Home" value={0} />
                    <Tab className="tabs_button" label="Playlists" value={1} />
                    <Tab className="tabs_button" label="Stats" value={2} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0} style={{ width: '100%' }}>
                  <div className="reviewHolderDiv">
                    <div className="MyReviews_items">
                      <div className="myReviews_txt_arrow">
                        <p className="myReviews_txt">Reviews</p>
                        <div className="myReviews_arrow">
                          <img src={Arrow} alt="icon" />
                        </div>
                      </div>
                      <div className="reviewListofItemsWrap">
                        <div
                          className={
                            userReviews?.length < 4
                              ? 'reviewListofItemsWrapProfile2'
                              : 'reviewListofItemsWrapProfile'
                          }
                        >
                          {userReviews?.length === 0 ? (
                            <p className="noDatayetText">No reviews yet</p>
                          ) : (
                            userReviews?.map((review, index) => (
                              <ReviewList
                                review={review}
                                index={index}
                                className="profileReviewPics"
                                onReviewClick={handleOpenPopup}
                              />
                            ))
                          )}
                          {showPopup && (
                            <Reviewdetailepopup
                              setShowFeedReview={setShowPopup}
                              selectedReview={userReviews}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="MyReviews_items_PlayList">
                    <div className="myReviews_txt_arrow">
                      <p className="myReviews_txt">Playlists</p>
                      <Modal
                        open={createPlaylist}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <div className="createPlaylist">
                            <div className="createPlaylist_first_main">
                              <div className="createPlaylist_backArrow">
                                <img src={BackArrow} alt="back" />
                              </div>
                              <p className="createPlaylist_heading_txt">Create Playlist</p>
                              <div className="createPlaylist_Close">
                                <img
                                  onClick={() => {
                                    setCreatePlaylist(false);
                                  }}
                                  src={CloseIcon}
                                  alt="close"
                                />
                              </div>
                            </div>
                            <div className="createPlaylist_second_section">
                              <p className="createPlaylist_name_txt">Playlist Name</p>
                              <input
                                type="text"
                                placeholder="My Top Songs"
                                onChange={(e) => setPlaylistName(e.target.value)}
                              />
                            </div>
                            <div className="createPlaylist_third_section">
                              <p className="createPlaylist_name_txt">Add a Description</p>
                              <textarea
                                className="textAreaCreateReview"
                                onChange={(e) => setPlaylistDesc(e.target.value)}
                                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis"
                                id="addPreview"
                                name="addPreview"
                                style={{ resize: 'none' }}
                              />
                            </div>
                            <div className="createPlaylist_button">
                              <button type="button" onClick={() => topSongsOpen()}>
                                Add Playlist
                              </button>
                            </div>
                          </div>
                        </Box>
                      </Modal>
                    </div>
                    {panelDataNew?.length === 0 ? (
                      <p className="noDatayetText">No playlists found.</p>
                    ) : (
                      panelDataNew?.map((playlist, index) => (
                        <SinglePlayList
                          playlist={playlist}
                          userData={userData}
                          index={index}
                          playlistDetailsOpen={playlistDetailsOpen}
                          Checked={Checked}
                          likes={likes}
                          comments={comments}
                          share={share}
                          playlistDetails={playlistDetails}
                          playlistDetailsClose={playlistDetailsClose}
                          selectedPlaylist={selectedPlaylist}
                          playlistOptionOpen={playlistOptionOpen}
                          addedSongsOpen={addedSongsOpen}
                          editaddedSongsOpen={editaddedSongsOpen}
                          handleShowMore={handleShowMore}
                          showMore={showMore}
                          playlistSongs={playlistSongs}
                          wait={wait}
                          playlistID={playlistID}
                        />
                      ))
                    )}
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className="store_section_data">
                    <div className="store_first_Section">
                      <div
                        className={
                          stats?.artists_songs[0]?.song_image
                            ? 'store_profile_pic'
                            : 'store_profile_pic2'
                        }
                      >
                        <img
                          src={stats?.artists_songs[0]?.song_image ?? dummyProfileImage}
                          alt="profile"
                        />
                      </div>
                      <div className="yellow_star_Icon">
                        <img src={StarIcon} alt="star" />
                      </div>
                      <p className="store_nametxt">{stats?.artists_songs[0]?.song_name}</p>
                      <p className="store_usernametxt">{stats?.artists_songs[0]?.artist_name}</p>
                    </div>
                    <div className="store_second_Section">
                      <div className="store_second_headings">
                        <div className="store_score_artist_data">
                          <p className="your_score_txt">User Score</p>
                          <p className="score_number_txt">{stats?.score}</p>
                          <p className="top_artist_txt">Top Artists</p>
                          {stats?.artists_songs?.map((artists) => (
                            <div className="top_Artists_data" id="gapClass">
                              <div className="store_topArtist_img">
                                <img
                                  src={
                                    artists?.artist_image
                                      ? artists?.artist_image
                                      : dummyProfileImage
                                  }
                                  alt="artist"
                                />
                              </div>
                              <p className="artisit_name_txt">{artists?.artist_name}</p>
                            </div>
                          ))}
                        </div>
                        <div className="store_genre_songs_data">
                          <p className="top_gentre_txt your_score_txt">Time of day</p>
                          <p className="popRock_txt score_number_txt" id="fontColor">
                            {stats?.time_of_day ?? 'Genre'}
                          </p>
                          <p className="top_Songs_txt top_artist_txt">Top Songs</p>
                          {stats?.artists_songs?.map((songs) => (
                            <div className="top_Songs_data top_Artists_data" id="gapClass">
                              <div className="store_topSongs_img">
                                <img
                                  src={songs?.song_image ? songs?.song_image : dummyProfileImage}
                                  alt="artist"
                                />
                              </div>
                              <p className="songs_title_txt artisit_name_txt">{songs?.song_name}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="vibe_Intensity_graph">
                        <p className="vibe_Intensity_txt">Your Vibe Intensity</p>
                        <div className="u">
                          <div
                            style={{
                              background: `linear-gradient(to bottom right, ${
                                stats?.average_intensity?.color_one_rgb
                                  ? stats?.average_intensity?.color_one_rgb
                                  : '#D30EE3'
                              }, ${
                                stats?.average_intensity?.color_two_rgb
                                  ? stats?.average_intensity?.color_two_rgb
                                  : '#47CAC3'
                              })`,
                            }}
                            className="waveOnStats"
                          >
                            <img src={wave} alt="" />
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '60%',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div className="intensityGrapghOnStats">
                            {stats?.average_intensity?.intensity > 0 && (
                              <IntensityComponent
                                color1={stats?.average_intensity?.color_one_rgb}
                                color2={stats?.average_intensity?.color_two_rgb}
                                frequency={stats?.average_intensity?.intensity}
                              />
                            )}
                            {stats?.average_intensity?.intensity < 1 && (
                              <p className="zeroIntensityValue">
                                your vibe intensity is less than 1
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="rating_history">
                        <p className="rating_history_txt">Rating History</p>
                      </div>
                      <div id="chart">
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="area"
                          height={350}
                        />
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Box>
            </div>
          </div>
        </div>
      </div>
      {openOption && (
        <BlockUserPopup
          handleBlock={handleBlock}
          openOption={openOption}
          setOpenOption={setOpenOption}
          blocked={blocked}
          profileData={profileData}
        />
      )}
      <SinglePlayListDetail
        playlistDetails={playlistDetails}
        playlistDetailsClose={playlistDetailsClose}
        selectedPlaylist={selectedPlaylist}
        playlistOptionOpen={playlistOptionOpen}
        addedSongsOpen={addedSongsOpen}
        playlistSongs={playlistSongs}
        editaddedSongsOpen={editaddedSongsOpen}
        handleShowMore={handleShowMore}
        showMore={showMore}
        playlistDetailsById={selectedPlaylist}
        playlistComments={playlistComments}
        fetchPlaylistComments={fetchPlaylistComments}
        removePlaylistClose={removePlaylistClose}
        handleGetPlaylistSongs={handleGetPlaylistSongs}
        setPlaylistID={setPlaylistID}
      />
      <Modal
        open={addedSongs}
        onClose={addedSongsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="playList_model_suggest">
          <div className="suggest_addSongs_popup">
            <div className="back_close_icon">
              <div className="createPlaylist_backArrow">
                <img
                  src={BackArrow}
                  alt="back"
                  onClick={() => {
                    addedSongsClose(false);
                  }}
                />
              </div>
              <div className="createPlaylist_Close">
                <img
                  src={CloseIcon}
                  alt="close"
                  onClick={() => {
                    addedSongsClose(false);
                  }}
                />
              </div>
            </div>
            {selectedSong?.name ? (
              <div className="overAllsearchDivWrapSelected" onClick={handleSelectedSongClick}>
                <div className="searchIconInputSelected">
                  <img src={SearchIcon} alt="icon" />
                </div>
                <div className="selectedSongImgHolder">
                  <img src={selectedSong && selectedSong?.images[2]?.url} alt="Selected Song" />
                </div>
                <div className="selectedSongnameTxt">{selectedSong?.name}</div>
                <span className="colorWhite">|</span>
                <div className="selectedSongSubNameTxt">{selectedSong?.artists[0]?.name}</div>
              </div>
            ) : (
              <SearchInput
                inputBackgroundColor="#434854"
                inputPlaceholder="Search for a song..."
                selectedSong={selectedSong}
                setSelectedSong={setSelectedSong}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            <div className="suggested_addSong">
              <h1 className="suggested_txt">Suggested</h1>
            </div>
            <div className="suggestSongs_data">
              {SuggestedSongData?.map((suggestdata) => (
                <div className="songs_added_data">
                  <div className="songs_added_data_left">
                    <div className="added_song_img">
                      <img src={suggestdata?.ProfilePic} alt="songs" />
                    </div>
                    <div className="added_song_data_txt">
                      <p className="added_song_title_txt">{suggestdata?.songTitle}</p>
                      <p className="added_song_disc_txt">{suggestdata?.songDescription}</p>
                    </div>
                  </div>
                  <div className="blueDots_icon">
                    <img src={CloseIcon} alt="setting" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={editSong}
        onClose={editaddedSongsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="edit_AddedSong">
          <div className="edit_addSong_popup">
            <div
              className="createPlaylist_backArrow"
              onClick={() => {
                editaddedSongsClose(false);
              }}
            >
              <img src={BackArrow} alt="back" />
            </div>
            <div className="edit_addsong_image_outer">
              <div className="edit_addsong_image">
                <img src={ProfilePic} alt="dp" />
              </div>
              <div className="edit_addsong_txt_data">
                <p className="edit_addsong_title_txt">Here comes the sun</p>
                <p className="edit_addsong_track_txt">track | The beatles</p>
              </div>
              <div className="edit_addSongs_ListData" onClick={() => removePlaylistOpen()}>
                <div>
                  <img src={RemovePlaylist} alt="remove" />
                </div>
                <p className="edit_ListData_txt">Remove from this playlist</p>
              </div>
              <div className="edit_addSongs_ListData">
                <div>
                  <img src={ShareOniMessage} alt="share" />
                </div>
                <p className="edit_ListData_txt">Share on iMessage</p>
              </div>
              <div className="edit_addSongs_ListData">
                <div>
                  <img src={CopyLinkIcon} alt="copy" />
                </div>
                <p className="edit_ListData_txt">Copy link</p>
              </div>
              <div className="edit_addSongs_ListData">
                <div>
                  <img src={ShareIcon} alt="share" />
                </div>
                <p className="edit_ListData_txt">Share via musotic</p>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={removePlaylist}
        onClose={removePlaylistClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="Remove_PlayList">
          <div>
            <div
              className="createPlaylist_backArrow"
              onClick={() => {
                removePlaylistClose(false);
              }}
            >
              <img src={BackArrow} alt="back" />
            </div>
            <div className="edit_addsong_image_outer">
              <div className="edit_addsong_image">
                <img src={ProfilePic} alt="dp" />
              </div>
              <div className="removePlaylist_txtData">
                <p className="removePlaylist_Heading_txt">
                  Are you sure you want to delete from the playlist ?
                </p>
                <p className="removePlaylist_disc_txt">
                  After deletion, the playlist cannot be retrieved.
                </p>
              </div>
              <button className="playList_Remove_btn" type="button">
                Delete
              </button>
              <div
                className="playList_cancel"
                onClick={() => {
                  removePlaylistClose(false);
                }}
              >
                <p className="playList_cancel_txt">Cancel</p>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={playlistOption}
        onClose={playlistOptionClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="playList_option">
          <div>
            <div className="playList_option_header">
              <div className="playList_option_back_txt">
                <div
                  className="createPlaylist_backArrow"
                  onClick={() => {
                    playlistOptionClose(false);
                  }}
                >
                  <img src={BackArrow} alt="back" />
                </div>
                <p className="playlist_option_txt">Playlist Options</p>
              </div>
              <div
                className="playlist_option_close"
                onClick={() => {
                  playlistOptionClose(false);
                }}
              >
                <img src={CloseIcon} alt="setting" />
              </div>
            </div>
            <div className="playlist_options_list_data">
              <div className="options_icons">
                <img src={AddSongsIcon} alt="add" />
              </div>
              <p className="playlist_options__txt">Add Songs</p>
            </div>
            <div className="playlist_options_list_data">
              <div className="options_icons">
                <img src={EditIcon} alt="edit" />
              </div>
              <p className="playlist_options__txt">Edit</p>
            </div>
            <div className="playlist_options_list_data">
              <div className="options_icons">
                <img src={ShareIcon} alt="sahre" />
              </div>
              <p className="playlist_options__txt">Share</p>
            </div>
            <div className="playlist_options_list_data">
              <div className="options_icons">
                <img src={PrivateIcon} alt="private" />
              </div>
              <p className="playlist_options__txt">Make it private</p>
            </div>
            <div className="playlist_options_list_data playlistOptions_del">
              <div className="options_icons">
                <img src={DeleteIcon} alt="delete" />
              </div>
              <p className="playlist_options__txt">Delete</p>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={editProfile}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={editstyle}>
          <div className="option_review_section">
            <div className="inner_option_review">
              <div className="options_txt_close">
                <p className="options_text_p">Options</p>
                <img onClick={handleClose} src={CloseIcon} alt="" />
              </div>
              <div className="options_icon_txt_section">
                <div
                  tabIndex={0}
                  role="button"
                  className="options_icon_txt"
                  onClick={() => {
                    handleOpenBlockPopup();
                  }}
                >
                  <img src={block1} alt="edit" />
                  <p className="options_icon_text_p">Block</p>
                </div>
                <div className="options_icon_txt" onClick={handleReportClick}>
                  <img src={report1} alt="share" />
                  <p className="options_icon_text_p">Report</p>
                </div>
                <div
                  onKeyDown="nothing"
                  tabIndex={0}
                  onClick={handleCopyClick}
                  role="button"
                  className="options_icon_txt"
                >
                  <img src={url1} alt="delete" />
                  <p className="options_icon_text_p">Copy Profile Url</p>
                </div>
                <div
                  onKeyDown="nothing"
                  tabIndex={0}
                  role="button"
                  className="options_icon_txt"
                  onClick={() => handleShareProfileClick(completeUrl)}
                >
                  <img src={share2} alt="delete" />
                  <p className="options_icon_text_p">Share</p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default UserProfile;
