import React from 'react';
import './index.css'; // Add your styling for the loader

function PageLoader({ isLoading }) {
  return isLoading ? (
    <div className="loader-overlay">
      <div className="loader-spinner" />
    </div>
  ) : null;
}

export default PageLoader;
