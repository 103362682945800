/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import BackArrow from '../assets/Images/BackArrow.svg';
import CloseIcon from '../assets/Images/CloseIcon.svg';

import api from '../api';
import usePageLoader from './PageLoader/usePageLoader';
import PageLoader from './PageLoader';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

const Popupstyle = {
  // position: 'absolute',
  // top: '5%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: 450,
  height: 450,
  bgcolor: '#2D3447',
  border: 'none',
  boxShadow: 24,
  borderRadius: '8px',
  pt: 2,
};

function MusoticSongOrAlbumListPopUp({ open, onClose, title, apiEndpoint, openItemfrom }) {
  const [data, setData] = useState([]);
  const offset = useRef(0);
  const limit = 9;
  const [hasMore, setHasMore] = useState(false);
  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();

  function fetchData() {
    console.log('fetching populat this week');
    startPageLoader();
    api(
      'get',
      `${apiEndpoint}/?limit=${limit}&offset=${offset.current}`,
      undefined,
      undefined,
      true,
    )
      .then((res) => {
        console.log(res.data, 'response');
        console.log('My Response :', res.data.payload.results[0]);
        if (res.data.success === true) {
          if (offset.current === 0) {
            // page 1
            setData(res.data.payload.results);
          } else {
            // next page
            setData((prevState) => {
              return [...prevState, ...res.data.payload.results];
            });
          }

          setHasMore(!!res.data.payload.next);
        }
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
      })
      .finally(() => {
        stopPageLoader();
      });
  }

  const handleLoaderMoreData = () => {
    if (hasMore) {
      offset.current += limit;
      fetchData();
    }
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      fetchData();
    } else {
      setData([]);
      offset.current = 0;
    }
  }, [open]);

  const navigate = useNavigate();
  const handleSongOrAlbumFromListClick = async (id) => {
    navigate(`/${openItemfrom}/${id}`);
  };
  return (
    <div>
      <PageLoader isLoading={isPageLoading} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box className="main_topDiv" sx={Popupstyle}>
            <div className="topDiv">
              <div className="topInnerDiv">
                <div onClick={() => handleClose()} className="backArrowIMG">
                  <img src={BackArrow} alt="" />
                </div>
                <div className="heading_text heading_textPopup">
                  <p>{title}</p>
                </div>
                <div onClick={() => handleClose()} className="CloseIMG CloseIMGPopup">
                  <img src={CloseIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="innerDiv innerDivpopup">
              <div className="InnerData">
                <div className="Most_Popular_Text ">
                  <p>{title}</p>
                </div>

                {data.length === 0 ? (
                  <p className="item-description">No Data Found</p>
                ) : (
                  <Grid sx={{ flexGrow: 1 }} container spacing={2} className="my-component">
                    {data.map((item) => (
                      <Grid
                        item
                        xs={4}
                        key={item.id}
                        onClick={() => handleSongOrAlbumFromListClick(item.id)}
                      >
                        <img className="item-image" src={item.image} alt={item.title} />
                        <div className="item_handle">
                          <h2 className="item-heading">{item.title}</h2>
                          <p className="item-description">{item.artist_name}</p>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                )}

                {/* <Grid sx={{ flexGrow: 1 }} container spacing={2} className="my-component">
                  {data.map((item) => (
                    <Grid className="item" item xs={4} key={item.id}>
                      <img className="item-image" src={item.image} alt={item.title} />
                      <div className="item_handle">
                        <h2 className="item-heading">{item.title}</h2>
                        <p className="item-description">{item.artist_name}</p>
                      </div>
                    </Grid>
                  ))}
                </Grid> */}
                {hasMore && (
                  <div className="playList_showMore_data prelogin_showMore_data">
                    <button
                      className="playList_showMore_btn"
                      type="button"
                      onClick={() => handleLoaderMoreData()}
                    >
                      Show More
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default MusoticSongOrAlbumListPopUp;
