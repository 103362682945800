import { Box, Modal } from '@material-ui/core';
import React from 'react';
import BackArrow from '../assets/Images/BackArrow.svg';

function RemovalConfirmation({
  removePlaylist,
  removePlaylistClose,
  style,
  playlistId,
  songId,
  handleDeleteFromPlaylist,
  singleSong,
}) {
  return (
    <Modal
      open={removePlaylist}
      onClose={removePlaylistClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="Remove_PlayList">
        <div style={{ padding: '5%' }}>
          <div
            role="button"
            tabIndex={0}
            className="createPlaylist_backArrow"
            onKeyDown={() => console.log('nothing')}
            onClick={removePlaylistClose}
          >
            <img src={BackArrow} alt="back" />
          </div>
          <div className="edit_addsong_image_outer">
            <div className="edit_addsong_image">
              <img src={singleSong?.image} alt="dp" />
            </div>
            <div className="removePlaylist_txtData">
              <p className="removePlaylist_Heading_txt">Are you sure you want to delete ?</p>
              <p className="removePlaylist_disc_txt">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit
              </p>
            </div>
            <button
              onClick={() => handleDeleteFromPlaylist(playlistId, songId)}
              className="playList_Remove_btn"
              type="button"
            >
              Delete
            </button>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => console.log('nothing')}
              className="playList_cancel"
              onClick={removePlaylistClose}
            >
              <p className="playList_cancel_txt">Cancel</p>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default RemovalConfirmation;
