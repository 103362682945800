/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import 'react-multi-carousel/lib/styles.css';
// import axios from 'axios';
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
// import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import api from '../api/index';
// import MusoticSpotifyReviewd from './MostReViewed';
import BGBlueImg from '../assets/Images/BGMusoticLogo.svg';
// import CloseIcon from '../assets/Images/CloseIcon.svg';
import SearchIcon from '../assets/Images/SearchInput.svg';
import FireIcon from '../assets/Images/FireIcon.svg';
import StarIcon from '../assets/Images/StarCartIcon.svg';
import ListFirst from '../assets/Images/ListFirstImg.svg';
import ListSecond from '../assets/Images/ListSecondImg.svg';
// import BackArrow from '../assets/Images/BackArrow.svg';
import ListThird from '../assets/Images/ListThirdImg.svg';
import ListFourth from '../assets/Images/ListFourthImg.svg';
import WhiteBlur from '../assets/Images/WhiteBlurtopListCart.png';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import AlbumsSeeMore from './MusoticSpotifyPopup';
import RightTop from '../assets/Images/RightTop.svg';
import CreateReviewModal from './CreateReviewModal3';
// import SongsModalComponent from './SongsModals';
import { debounce } from '../utils/helpers';
import { queryParamsKey } from '../utils/constant';
import usePageLoader from './PageLoader/usePageLoader';
import PageLoader from './PageLoader';
import MusoticSongPopularThisWeek from './MusoticSongPopularThisWeek';
import MusoticSongOrAlbumListPopUp from './MusoticSongOrAlbumListPopUp';
import {
  albumSearchAnalyticsEvent,
  analyticsPages,
  errorLogCountAnalyticsEvent,
  playlistSearchAnalyticsEvent,
  profileSearchAnalyticsEvent,
  songSearchAnalyticsEvent,
} from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';
import RecentlyPlayedSongs from './RecentlyPlayedSongs';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 500,
//   height: 600,
//   bgcolor: '#2D3447',
//   border: 'none',
//   boxShadow: 24,
//   borderRadius: '8px',
//   pt: 2,
// };

function MusoticSpotify() {
  // const [open, setOpen] = React.useState(false);
  const [writeReview, setWriteReview] = useState(false);
  // const [openModalOne, setOpenModalOne] = useState(false);
  // const [songArrayDataOne, setSongArrayDataOne] = useState([]);
  const [Song, setSong] = useState();
  const [appleSong, setAppleSong] = useState();
  const [searchType, setSearchType] = React.useState('song');
  const [isMusoticSpotifyOpen, setIsMusoticSpotifyOpen] = React.useState(false);
  // const [isPopupOpen, setPopupOpen] = useState(false);
  // const [popularAlbumsData, setPopularAlbumsData] = React.useState();
  const [userProfilesData, setUserProfilesData] = React.useState();
  const [searchResultArray, setSearchResultArray] = React.useState();
  const [searchAlbumArray, setSearchAlbumArray] = React.useState();
  const [searchPlaylistArray, setSearchPlaylistArray] = React.useState();
  const [searchInputValue, setSearchInputValue] = useState();
  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();
  const [popUpModalsOpenState, setPopUpModalsOpenState] = useState({
    hotNewReleases: false,
    popularThisWeek: false,
    top50Albums: false,
    top50Tracks: false,
    mostPopularAlbums: false,
    mostPopularTracks: false,
  });
  const navigate = useNavigate();

  console.log(userProfilesData, 'dfghj');

  const handleCloseReview = () => {
    setWriteReview(false);
  };
  // useEffect(() => {
  //   const fetchTop50SongsData = () => {
  //     try {
  //       axios
  //         .get('https://api.spotify.com/v1/me/top/tracks', {
  //           headers: {
  //             Authorization: `Bearer ${spotifyToken}`,
  //           },
  //           params: {
  //             limit: 50,
  //           },
  //         })
  //         .then((res) => {
  //           console.log('ressss', res?.data?.items);
  //           setSongArrayDataOne(res?.data?.items);
  //         })
  //         .catch((err) => console.log('err', err));
  //     } catch (err) {
  //       console.log('userDataerr', err);
  //     }
  //   };
  //   fetchTop50SongsData();
  // }, [spotifyToken]);

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  // const handleCloseModalOne = () => {
  //   setOpenModalOne(false);
  // };
  // const handleOpenModalOne = () => {
  //   setOpenModalOne(true);
  // };
  // const openMusoticSpotify = () => {
  //   setIsMusoticSpotifyOpen(true);
  // };
  // const handlePopularNameClick = (spotifyLink) => {
  //   window.open(spotifyLink, '_blank');
  // };

  const closeMusoticSpotify = () => {
    setIsMusoticSpotifyOpen(false);
  };
  const handleUserClick = async (id) => {
    navigate(`/user/${id}`);
  };

  const handleAlbumClickOg = async (id) => {
    navigate(`/albumDetails/${id}`);
  };

  const handlePlaylistClick = async (id, playlistId) => {
    navigate(
      `/user/${id}?${queryParamsKey.friendProfile.openTabKey}=${queryParamsKey.friendProfile.openTabValues.playlist}&${queryParamsKey.friendProfile.tabIdKey}=${playlistId}`,
    );
  };
  const handleSongClick = async (id) => {
    navigate(`/songDetails/${id}`);
  };

  // const fetchPopularAlbums = async () => {
  //   try {
  //     const response = await api('get', '/albums/most-popular/');
  //     console.log('sdfdddddwexcd', response);
  //     setPopularAlbumsData(response?.data);
  //   } catch (err) {
  //     // handle error
  //   }
  // };
  // useEffect(() => {
  //   fetchPopularAlbums();
  // }, []);
  const handleInputChange = async () => {
    if (searchInputValue?.length <= 1) {
      return;
    }
    startPageLoader();
    try {
      let endpoint;
      if (searchType === 'playlist') {
        playlistSearchAnalyticsEvent(searchInputValue);
        endpoint = `/playlists/?search=${searchInputValue}`;
      } else if (searchType === 'song') {
        if (searchInputValue) {
          songSearchAnalyticsEvent(searchInputValue);
        }
        endpoint = `/songs/?search=${searchInputValue}`;
      } else if (searchType === 'album') {
        albumSearchAnalyticsEvent(searchInputValue);
        endpoint = `/albums/?search=${searchInputValue}`;
      } else if (searchType === 'profile') {
        profileSearchAnalyticsEvent(searchInputValue);
        endpoint = `/users/?search=${searchInputValue}`;
      }
      const response = await api('get', endpoint);

      if (searchType === 'song') {
        setSearchResultArray(response?.data?.results);
      }
      if (searchType === 'album') {
        setSearchAlbumArray(response?.data?.results);
      }
      if (searchType === 'playlist') {
        setSearchPlaylistArray(response?.data?.results);
      }

      if (searchType === 'profile') {
        setUserProfilesData(response?.data?.results);
      }
    } catch (err) {
      // handle error
      errorLogCountAnalyticsEvent();
    }
    stopPageLoader();
  };
  console.log(searchResultArray, 'sdfdsasd');

  useEffect(() => {
    handleInputChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType, searchInputValue]);
  // const handleOpenPopup = () => {
  //   setPopupOpen(true);
  // };

  // const handleClosePopup = () => {
  //   setPopupOpen(false);
  // };

  const handleSearchOptionClick = (e) => {
    setSearchType(e.target.innerHTML);
  };

  const debouncedSearch = debounce((term) => {
    // Your logic for handling the search term
    setSearchInputValue(term);
  }, 1500); // 1.5 seconds

  const toggleHotNewReleasesPopUp = (toggle) => {
    setPopUpModalsOpenState({
      ...popUpModalsOpenState,
      hotNewReleases: toggle,
    });
  };

  const togglePopularThisWeekPopUp = (toggle) => {
    setPopUpModalsOpenState({
      ...popUpModalsOpenState,
      popularThisWeek: toggle,
    });
  };

  const toggleTop50AlbumsPopUp = (toggle) => {
    setPopUpModalsOpenState({
      ...popUpModalsOpenState,
      top50Albums: toggle,
    });
  };

  const toggleTop50TracksPopUp = (toggle) => {
    setPopUpModalsOpenState({
      ...popUpModalsOpenState,
      top50Tracks: toggle,
    });
  };

  const toggleMostPopularAlbumsPopUp = (toggle) => {
    setPopUpModalsOpenState({
      ...popUpModalsOpenState,
      mostPopularAlbums: toggle,
    });
  };

  const toggleMostPopularTracksPopUp = (toggle) => {
    setPopUpModalsOpenState({
      ...popUpModalsOpenState,
      mostPopularTracks: toggle,
    });
  };

  usePageViewAnalyticsHook({
    title: analyticsPages.search,
  });

  return (
    <div className="Musotic_Spotify_Main_Screen">
      <PageLoader isLoading={isPageLoading} />
      <div className="Musotic_Spotify">
        <img className="top_bg_img" src={BGBlueImg} alt="" />
        <div className="leftbottomColor leftbottomColor_dashboard_bottom">
          <img src={RightTop} alt="x" />
        </div>
        <div className="Musotic_Spotify_inner">
          <div className="Musotic_Spotify_search">
            <div className="overAllsearchDivWrapSelected" id="transparent">
              <div className="searchIconInputSelected">
                <img src={SearchIcon} alt="icon" />
              </div>
              <input
                className="UsersOnSearchScreen"
                placeholder="Search for songs, albums, profiles, and playlists"
                type="search"
                // value={searchInputValue}
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="searchFilterOptionBtns">
            <div>
              <button
                className={searchType === 'song' ? 'btnForSearchesSelected' : 'btnForSearches'}
                type="button"
                onClick={handleSearchOptionClick}
              >
                song
              </button>
            </div>
            <div>
              <button
                className={searchType === 'album' ? 'btnForSearchesSelected' : 'btnForSearches'}
                type="button"
                onClick={handleSearchOptionClick}
              >
                album
              </button>
            </div>
            <div>
              <button
                className={searchType === 'playlist' ? 'btnForSearchesSelected' : 'btnForSearches'}
                type="button"
                onClick={handleSearchOptionClick}
              >
                playlist
              </button>
            </div>
            <div>
              <button
                className={searchType === 'profile' ? 'btnForSearchesSelected' : 'btnForSearches'}
                type="button"
                onClick={handleSearchOptionClick}
              >
                profile
              </button>
            </div>
          </div>

          {searchType === 'profile' && (
            <div className="parentSearch">
              {userProfilesData?.length > 0 && searchInputValue?.length > 1
                ? userProfilesData?.map((userData) => (
                    <div
                      key={userData?.id}
                      className="songSuggestedList"
                      onClick={() => handleUserClick(userData?.id)}
                    >
                      <div className="suggestSongName">
                        <div className="songSuggImgProfile">
                          <img src={userData?.profile?.avatar_url || dummyProfileImage} alt="." />
                        </div>
                        <div>
                          <p className="songNameSuggested">
                            {userData?.name || userData?.username}
                          </p>
                        </div>
                        <div>
                          <p className="songNameSuggestedDet">{userData?.songDetail}</p>
                        </div>
                      </div>
                    </div>
                  ))
                : searchType === 'profile' &&
                  userProfilesData?.length < 1 &&
                  searchInputValue?.length > 1 && <p className="noDatayetText">No data found</p>}
            </div>
          )}
          {searchType === 'playlist' && (
            <div className="parentSearch">
              {searchPlaylistArray?.length > 0 && searchInputValue?.length > 1
                ? searchPlaylistArray?.map((item) => (
                    <div
                      key={item.id}
                      className="songSuggestedList"
                      onClick={() => handlePlaylistClick(item?.user?.id, item.id)}
                    >
                      <div className="suggestSongName">
                        <div className="songSuggImg">
                          <img src={item?.image || dummyProfileImage} alt="." />
                        </div>
                        <div>
                          <p className="songNameSuggested">{item?.name || 'no text here'}</p>
                        </div>
                        <p id="opacitytxt">|</p>
                        <p id="opacitytxt">{item?.user?.username || item?.user?.name}</p>
                        <div>
                          <p className="songNameSuggestedDet">{item?.songDetail}</p>
                        </div>
                      </div>
                    </div>
                  ))
                : searchType === 'playlist' &&
                  searchPlaylistArray?.length < 1 &&
                  searchInputValue?.length > 1 && <p className="noDatayetText">No data found</p>}
            </div>
          )}
          {searchType === 'song' && (
            <div className="parentSearch">
              {searchResultArray?.length > 0 && searchInputValue?.length > 1
                ? searchResultArray?.map((item) => (
                    <div
                      key={item.id}
                      className="songSuggestedList"
                      onClick={() => handleSongClick(item?.id)}
                    >
                      <div className="suggestSongName">
                        <div className="songSuggImg">
                          <img src={item?.image || dummyProfileImage} alt="." />
                        </div>
                        <div>
                          <p className="songNameSuggested">{item?.title || 'no text here'}</p>
                        </div>
                        <p id="opacitytxt">|</p>
                        <p id="opacitytxt">{item?.artist_name}</p>
                        <div>
                          <p className="songNameSuggestedDet">{item?.songDetail}</p>
                        </div>
                      </div>
                    </div>
                  ))
                : searchType === 'song' &&
                  searchResultArray?.length < 1 &&
                  searchInputValue?.length > 1 && <p className="noDatayetText">No data found</p>}
            </div>
          )}
          {searchType === 'album' && (
            <div className="parentSearch">
              {searchAlbumArray?.length > 0 && searchInputValue?.length > 1
                ? searchAlbumArray?.map((item) => (
                    <div
                      key={item.id}
                      className="songSuggestedList"
                      onClick={() => handleAlbumClickOg(item?.id)}
                    >
                      <div className="suggestSongName">
                        <div className="songSuggImg">
                          <img src={item?.image || dummyProfileImage} alt="." />
                        </div>
                        <div>
                          <p className="songNameSuggested">{item?.title || 'no text here'}</p>
                        </div>
                        <p id="opacitytxt">|</p>
                        <p id="opacitytxt">{item?.artist_name || 'no text'}</p>

                        <div>
                          <p className="songNameSuggestedDet">{item?.songDetail}</p>
                        </div>
                      </div>
                    </div>
                  ))
                : searchType === 'album' &&
                  searchAlbumArray?.length < 1 &&
                  searchInputValue?.length > 1 && <p className="noDatayetText">No data found</p>}
            </div>
          )}

          {!searchInputValue && (
            <>
              <RecentlyPlayedSongs
                setSpotifySong={setSong}
                setWriteReview={setWriteReview}
                setAppleSong={setAppleSong}
              />
              {/* {recentlyPlayed?.length < 4 && (
                <div className="Musotic_Spotify_track_data2">
                  {recentlyPlayed?.map((item) => (
                    <div className="Musotic_Spotify_recent_track2" key={item?.id}>
                      <div className="Musotic_Spotify_track_rate2">
                        <img src={item?.track?.album?.images[0]?.url} alt="img not found" />
                        <div className="Musotic_Spotify_rate_star">
                          <img src={WhiteStar} alt="" />
                          <p className="Musotic_Spotify_ratenow_txt">Rate Now</p>
                        </div>
                      </div>
                      <div className="track_artisit_txt">
                        <p className="track_name_txt">B {item?.track?.name}</p>
                        <p className="artist_name_txt">{item?.track?.artists[0]?.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )} */}

              <div className="Dashed_line" />
              <div className="Musotic_Spotify_ternding_toplsit">
                <div className="Musotic_Spotify_ternding_cart">
                  <div className="Musotic_Spotify_ternding_txt">
                    <p>Trending</p>
                    {/* <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <div className="topDiv">
                          <div className="topInnerDiv">
                            <div onClick={() => handleClose()} className="backArrowIMG">
                              <img src={BackArrow} alt="" />
                            </div>
                            <div className="heading_text">
                              <p>Most Popular</p>
                            </div>
                            <div onClick={() => handleClose()} className="CloseIMG">
                              <img src={CloseIcon} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="innerDiv">
                          <div className="InnerData" id="InnerData">
                            <div className="Most_Popular_Text">
                              <p>Most Popular</p>
                            </div>

                            <Grid
                              sx={{ flexGrow: 1 }}
                              container
                              spacing={2}
                              className="my-component"
                            >
                              {popularAlbumsData?.map((item) => (
                                <Grid className="item" item xs={4} key={item?.id}>
                                  <div
                                    className="wrraaaap"
                                    onClick={() =>
                                      handlePopularNameClick(item?.external_urls?.spotify)
                                    }
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <div className="wrapppper2">
                                      <img
                                        className="item-image"
                                        src={item?.images?.[0]?.url}
                                        alt="."
                                      />
                                    </div>
                                    <div className="item_handle" id="dddd">
                                      <h2 className="item-heading" id="popularTxts1">
                                        {item?.name}
                                      </h2>
                                      <p className="item-description" id="popularTxts">
                                        {item?.album_type}
                                      </p>
                                    </div>
                                  </div>
                                </Grid>
                              ))}
                            </Grid>
                          </div>
                        </div>
                      </Box>
                    </Modal> */}
                    {/* <SongsModalComponent
                      open={openModalOne}
                      handleClose={handleCloseModalOne}
                      SongsArrayData={songArrayDataOne}
                    /> */}
                  </div>
                  <div className="Musotic_Spotify_ternding_carts_data">
                    <div
                      className="Musotic_Spotify_ternding_data"
                      onClick={() => toggleHotNewReleasesPopUp(true)}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <img src={FireIcon} alt="Trending" />
                      <p>Hot New Releases</p>
                    </div>
                    <div
                      className="Musotic_Spotify_ternding_2nddata Musotic_Spotify_ternding_data"
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => togglePopularThisWeekPopUp(true)}
                    >
                      <img src={StarIcon} alt="Trending" />
                      <p>Popular This Week</p>
                    </div>
                  </div>
                </div>
                <div className="Musotic_Spotify_topLists_cart">
                  <div
                    className="Musotic_Spotify_topLists_txt"
                    // onClick={handleOpenPopup}
                  >
                    <p>Top Lists</p>
                  </div>
                  <div className="Musotic_Spotify_topLists_carts_data">
                    <div
                      className="Musotic_Spotify_topLists_data"
                      onClick={() => toggleTop50AlbumsPopUp(true)}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <img className="WhiteBlur" src={WhiteBlur} alt="" />
                      <p>
                        Top 50 <br /> Albums
                      </p>
                      <img className="topListsImg" src={ListFirst} alt="Top Lists" />
                    </div>
                    <div
                      className="Musotic_Spotify_topLists_2nddata Musotic_Spotify_topLists_data"
                      // onClick={handleOpenModalOne}
                      onClick={() => toggleTop50TracksPopUp(true)}
                      id="cursorPointer"
                    >
                      <img className="WhiteBlur" src={WhiteBlur} alt="" />
                      <p>
                        Top 50 <br /> tracks
                      </p>
                      <img className="topListsImg" src={ListSecond} alt="Top Lists" />
                    </div>
                    <div
                      className="Musotic_Spotify_topLists_3rddata Musotic_Spotify_topLists_data"
                      onClick={() => toggleMostPopularAlbumsPopUp(true)}
                    >
                      <img className="WhiteBlur" src={WhiteBlur} alt="" />
                      <p>Most popular albums</p>
                      <img className="topListsImg" src={ListThird} alt="Top Lists" />
                    </div>
                    <div
                      className="Musotic_Spotify_topLists_4thdata Musotic_Spotify_topLists_data"
                      onClick={() => toggleMostPopularTracksPopUp(true)}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <img className="WhiteBlur" src={WhiteBlur} alt="" />
                      <p>Most popular tracks</p>
                      <img className="topListsImg" src={ListFourth} alt="Top Lists" />
                    </div>
                  </div>
                </div>
                {/* {isPopupOpen && <MusoticSpotifyReviewd onClose={handleClosePopup} />} */}
              </div>
            </>
          )}

          <AlbumsSeeMore onClose={closeMusoticSpotify} open={isMusoticSpotifyOpen} />
          <CreateReviewModal
            open={writeReview}
            handleClose={handleCloseReview}
            Song={Song}
            appleSong={appleSong}
          />
          <MusoticSongPopularThisWeek
            onClose={() => togglePopularThisWeekPopUp(false)}
            open={popUpModalsOpenState.popularThisWeek}
          />
          <MusoticSongOrAlbumListPopUp
            open={popUpModalsOpenState.hotNewReleases}
            onClose={() => toggleHotNewReleasesPopUp(false)}
            apiEndpoint="/songs/hot_new_releases"
            openItemfrom="songDetails"
            title="Hot New Releases"
          />

          <MusoticSongOrAlbumListPopUp
            open={popUpModalsOpenState.top50Albums}
            onClose={() => toggleTop50AlbumsPopUp(false)}
            apiEndpoint="/albums/top50"
            openItemfrom="albumDetails"
            title="Top 50 Albums"
          />

          <MusoticSongOrAlbumListPopUp
            open={popUpModalsOpenState.top50Tracks}
            onClose={() => toggleTop50TracksPopUp(false)}
            apiEndpoint="/songs/top50"
            openItemfrom="songDetails"
            title="Top 50 Tracks"
          />

          <MusoticSongOrAlbumListPopUp
            open={popUpModalsOpenState.mostPopularAlbums}
            onClose={() => toggleMostPopularAlbumsPopUp(false)}
            apiEndpoint="/albums/most_popular_albums"
            openItemfrom="albumDetails"
            title="Most Popular Albums"
          />

          <MusoticSongOrAlbumListPopUp
            open={popUpModalsOpenState.mostPopularTracks}
            onClose={() => toggleMostPopularTracksPopUp(false)}
            apiEndpoint="/songs/most_popular_song"
            openItemfrom="songDetails"
            title="Most Popular Tracks"
          />
        </div>
      </div>
    </div>
  );
}

export default MusoticSpotify;
