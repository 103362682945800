import React, { useEffect, useState } from 'react';
import PlusIcon from '../../assets/Images/PlusIcon.svg';
import api from '../../api';
import SinglePlayList from '../SinglePlayList';
import AddPlaylistPopup from './AddPlaylistPopup';
import { errorLogCountAnalyticsEvent } from '../../service/analyticsService';

function MyPlaylist({ TabPanel, value, style, userData }) {
  const [createPlaylist, setCreatePlaylist] = useState(false);
  const [panelDataNew, setPanelDataNew] = useState([]);

  const handleOpen = () => {
    setCreatePlaylist(true);
  };
  const handleClose = () => {
    setCreatePlaylist(false);
  };
  const fetchPanelData = async () => {
    try {
      const response = await api('get', '/users/my-playlists/');
      console.log('responsxx', response);
      setPanelDataNew(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error', err);
    }
  };
  useEffect(() => {
    fetchPanelData();
  }, []);

  return (
    <TabPanel value={value} index={1}>
      <div className="MyReviews_items_PlayList">
        <div className="playlist_txt_arrow">
          <p className="myReviews_txt">My Playlists</p>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={(e) => console.log(e)}
            className="myReviews_plusIcon"
            onClick={handleOpen}
          >
            <img src={PlusIcon} alt="icon" />
          </div>
          <AddPlaylistPopup
            createPlaylist={createPlaylist}
            handleClose={handleClose}
            style={style}
            userData={userData}
            fetchPanelData={fetchPanelData}
          />
        </div>
        {panelDataNew.length === 0 ? (
          <p className="noDatayetText">No playlists found.</p>
        ) : (
          panelDataNew.map((playlist, index) => (
            <SinglePlayList
              playlist={playlist}
              userData={userData}
              index={index}
              fetchPanelData={fetchPanelData}
              style={style}
            />
          ))
        )}
      </div>
    </TabPanel>
  );
}

export default MyPlaylist;
