import React from 'react';
import ReactApexChart from 'react-apexcharts';
import dummyProfileImage from '../../assets/Images/dummyProfileImg.jpg';
import StarIcon from '../../assets/Images/YellowStarIcon.svg';
import wave from '../../assets/Images/Wave.svg';

import IntensityComponent from '../IntensityGraphTest';

function Stats({ TabPanel, value, stats, userReviews }) {
  const series = [
    {
      name: 'series1',
      data: stats?.rating_history?.map((item) => item?.count),
      color: '#57BBEB',
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    grid: {
      borderColor: '#5A6B73',
      strokeDashArray: [2],
    },
    xaxis: {
      type: 'datetime',
      // categories: stats?.rating_history.map((item) => item?.year),
      categories: (stats?.rating_history || []).map((item) => item?.year).slice(0, -1),

      labels: {
        style: {
          colors: '#828F96',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#828F96',
        },
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  };
  console.log(userReviews, 'sdsdsds');
  return (
    <TabPanel value={value} index={2}>
      <div className="store_section_data">
        <div className="store_first_Section">
          <div
            className={
              stats?.artists_songs[0]?.song_image ? 'store_profile_pic' : 'store_profile_pic2'
            }
          >
            <img src={stats?.artists_songs[0]?.song_image || dummyProfileImage} alt="profile" />
          </div>
          <div className="yellow_star_Icon">
            <img src={StarIcon} alt="star" />
          </div>
          <p className="store_nametxt">{stats?.artists_songs[0]?.song_name}</p>
          <p className="store_usernametxt">{stats?.artists_songs[0]?.artist_name}</p>
        </div>
        <div className="store_second_Section">
          <div className="store_second_headings">
            <div className="store_score_artist_data">
              <p className="your_score_txt">Your Score</p>
              <p className="score_number_txt">{stats?.score}</p>
              <p className="top_artist_txt">Top Artists</p>
              {stats?.artists_songs?.map((artists) => (
                <div className="top_Artists_data" id="gapClass">
                  <div className="store_topArtist_img">
                    <img src={artists?.artist_image ?? dummyProfileImage} alt="artist" />
                  </div>
                  <p className="artisit_name_txt">{artists?.artist_name}</p>
                </div>
              ))}
            </div>
            <div className="store_genre_songs_data">
              <p className="top_gentre_txt your_score_txt">Time of day</p>
              <p className="popRock_txt score_number_txt" id="fontColor">
                {stats?.time_of_day ?? 'Genre'}
              </p>
              <p className="top_Songs_txt top_artist_txt">Top Songs</p>
              {stats?.artists_songs?.map((songs) => (
                <div className="top_Songs_data top_Artists_data" id="gapClass">
                  <div className="store_topSongs_img">
                    <img src={songs?.song_image} alt="artist" />
                  </div>
                  <p className="songs_title_txt artisit_name_txt">{songs?.song_name}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="vibe_Intensity_graph">
            <p className="vibe_Intensity_txt">Your Vibe Intensity</p>
            <div className="u">
              <div
                style={{
                  background: `linear-gradient(to bottom right, ${
                    stats?.average_intensity?.color_one_rgb
                      ? stats?.average_intensity?.color_one_rgb
                      : '#D30EE3'
                  }, ${
                    stats?.average_intensity?.color_two_rgb
                      ? stats?.average_intensity?.color_two_rgb
                      : '#47CAC3'
                  })`,
                }}
                className="waveOnStats"
              >
                <img src={wave} alt="" />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                width: '60%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div className="intensityGrapghOnStats">
                {stats?.average_intensity?.intensity > 0 && (
                  <IntensityComponent
                    color1={stats?.average_intensity?.color_one_rgb}
                    color2={stats?.average_intensity?.color_two_rgb}
                    frequency={stats?.average_intensity?.intensity}
                  />
                )}
                {stats?.average_intensity?.intensity < 1 && (
                  <p className="zeroIntensityValue">your vibe intensity is less than 1</p>
                )}
              </div>
            </div>
          </div>
          <div className="rating_history">
            <p className="rating_history_txt">Rating History</p>
          </div>
          <div id="chart">
            <ReactApexChart options={options} series={series} type="area" height={350} />
          </div>
        </div>
      </div>
    </TabPanel>
  );
}

export default Stats;
