/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import 'typeface-quicksand';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import check from '../assets/Images/VectorcheckMark.svg';
import eyeCon from '../assets/Images/GroupEye.svg';
import ClosedEyeCon from '../assets/Images/GroupClosedEye.svg';
import LOGO from '../assets/Images/LOGO.svg';
import PreLoginSliders from '../components/PreLoginSliders';
import { baseUrl } from '../config';
import PageLoader from '../components/PageLoader/index';
import usePageLoader from '../components/PageLoader/usePageLoader';
import { analyticsPages, errorLogCountAnalyticsEvent } from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';
import AppleSocialLogin from '../components/AppleSocialLogin';

export default function LoginScreen() {
  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');
  const navigate = useNavigate();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (username === '') {
      toast.error('Enter username');
    } else if (password === '') {
      toast.error('Please enter password');
      return;
    }

    const loginData = {
      username,
      password,
    };

    try {
      startPageLoader();
      const res = await axios.post(`${baseUrl}/login/`, loginData);
      console.log(res?.data, 'loginRes');

      if (res?.data?.tokens?.accessToken) {
        toast.success('Login successfully');
        setUsername('');
        setPassword('');
        localStorage.setItem('profileUsername', res?.data?.username);
        localStorage.setItem('profilePicLink', res?.data?.profile?.avatar_url);
        localStorage.setItem('profileBioTxt', res?.data?.profile.bio);
        localStorage.setItem('musoticID', res?.data?.profile.id);
        localStorage.setItem('idToken', res?.data?.tokens?.accessToken);
        localStorage.setItem('musoticRefreshToken', res?.data?.tokens?.refreshToken);
        localStorage.setItem('userID', res?.data?.id);
        localStorage.setItem('idScore', res?.data?.profile.score);
        localStorage.setItem('appleSSO', JSON.stringify(false));
        window.location = '/dashboard';
      }
      stopPageLoader();
    } catch (err) {
      errorLogCountAnalyticsEvent();
      stopPageLoader();
      console.error(err?.response?.data?.error);
      toast.error(err?.response?.data?.error);
    }
  };

  const handleForgotPassword = () => {
    navigate('/ForgotPassword');
  };

  const handleSignupClick = () => {
    navigate('/signup');
  };

  usePageViewAnalyticsHook({
    title: analyticsPages.login,
  });

  return (
    <div>
      <PageLoader isLoading={isPageLoading} />
      <div className="loginScreenContainer">
        <div className="preloginWrapper">
          <PreLoginSliders />
        </div>
        <div className="loginRightPart">
          <div className="rightSideInnerWrapper">
            <div className="imgLogoNameWrap">
              <img src={LOGO} alt="logo" />
            </div>
            <p className="loginHeading">Login</p>
            <div className="fieldsContainer">
              <div className="inputFields">
                <p className="lables">Username</p>
                <div className="cont">
                  <input
                    className="input_field"
                    type="text"
                    placeholder="johanmike"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <span>
                  <img className="emailImg" src={check} alt="pic" />
                </span>
              </div>
              <div className="inputFields">
                <p className="lables">Password</p>
                <div className="cont">
                  <input
                    className="input_field"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="cursorPointer" onClick={handleShowPassword}>
                  <img
                    className="passwordlImg"
                    src={showPassword ? ClosedEyeCon : eyeCon}
                    alt="pic"
                  />
                </div>
                <span className="forgotPassowrdtxt" onClick={handleForgotPassword}>
                  <p className="cursorPointer">Forgot Password?</p>
                </span>
              </div>
              <div>
                <div style={{ marginBottom: '10px' }}>
                  {' '}
                  <button type="submit" className="loginButton" onClick={handleLogin}>
                    Login
                  </button>{' '}
                </div>
                <AppleSocialLogin />
                <p className="signUpoptnx">
                  Don&apos;t Have An Account?
                  <span
                    className="coloredtxt"
                    onClick={() => {
                      handleSignupClick();
                    }}
                  >
                    SignUp
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
