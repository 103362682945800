import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import ShareIcon from '../assets/Images/ShareOption.svg';
import IMessageIcon from '../assets/Images/iMessageIcon.svg';
import WhatsappIcon from '../assets/Images/WhatsappIcon.svg';
import InstagramIcon from '../assets/Images/InstagramIcon.svg';
import FacebookIcon from '../assets/Images/FacebookIcon.svg';
import TwitterIcon from '../assets/Images/TwitterIcon.svg';
import CopyLinkIcon from '../assets/Images/CopyLinkIcon.svg';
import {
  errorLogCountAnalyticsEvent,
  reviewShareAnalyticsEvent,
  socialMediaHandles,
} from '../service/analyticsService';

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function ShareSocial({ handleClosshare, shareModal, reviewId }) {
  const url = window.location.host;
  const completeUrl = `${url}/reviews/${reviewId}`;
  const message = `Hey! checkout this post ${completeUrl}`;

  const handleWhatsAppShare = () => {
    reviewShareAnalyticsEvent(`share_via_${socialMediaHandles.Whatsaap}`);
    handleClosshare();
    const whatsAppurl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsAppurl, '_blank');
  };

  const handleFacebookShare = () => {
    reviewShareAnalyticsEvent(`share_via_${socialMediaHandles.Facebook}`);
    handleClosshare();
    const faceBookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      completeUrl,
    )}`;
    window.open(faceBookUrl, '_blank');
  };

  const handleTwitterShare = () => {
    reviewShareAnalyticsEvent(`share_via_${socialMediaHandles.Twitter}`);
    handleClosshare();
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(completeUrl)}`;
    window.open(twitterUrl, '_blank');
  };

  const handleImessageShare = () => {
    reviewShareAnalyticsEvent(`share_via_${socialMediaHandles.IMessage}`);
    handleClosshare();
    toast.info(`To share via iMessage, copy this link: ${completeUrl}`);
  };
  const handleInstaShare = () => {
    reviewShareAnalyticsEvent(`share_via_${socialMediaHandles.Instagram}`);
    handleClosshare();
    const instaUrl = 'https://www.instagram.com/';
    window.open(instaUrl, '_blank');
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(completeUrl);
      toast.success('Link copied to clipboard!');
      reviewShareAnalyticsEvent(`share_via_${socialMediaHandles.link}`);
    } catch (error) {
      errorLogCountAnalyticsEvent();
      toast.error(error);
      console.error('Copy failed:', error);
    }
    handleClosshare();
  };

  return (
    <div>
      <Modal
        open={shareModal}
        onClose={handleClosshare}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="share_review_section">
            <div className="inner_share_review">
              <div className="shares_txt_close">
                <p className="shares_text_p">Share</p>
                <img src={CloseIcon} alt="" role="presentation" onClick={handleClosshare} />
              </div>
              <div className="dashedDividers"> </div>
              <div className="shares_icon_txt_section">
                <button type="button" className="shares_icon_txt">
                  <img src={ShareIcon} alt="edit" />
                  <p className="shares_icon_text_p">Share via musotic</p>
                </button>
                <button onClick={handleImessageShare} type="button" className="shares_icon_txt">
                  <img src={IMessageIcon} alt="share" />
                  <p className="shares_icon_text_p">Share on iMessage</p>
                </button>
                <button type="button" onClick={handleWhatsAppShare} className="shares_icon_txt">
                  <img src={WhatsappIcon} alt="delete" />
                  <p className="shares_icon_text_p">Share on whatsapp</p>
                </button>
                <button type="button" onClick={handleInstaShare} className="shares_icon_txt">
                  <img src={InstagramIcon} alt="delete" />
                  <p className="shares_icon_text_p">Share on instagram</p>
                </button>
                <button onClick={handleFacebookShare} type="button" className="shares_icon_txt">
                  <img src={FacebookIcon} alt="delete" />
                  <p className="shares_icon_text_p">Share on Facebook</p>
                </button>
                <button type="button" onClick={handleTwitterShare} className="shares_icon_txt">
                  <img src={TwitterIcon} alt="delete" />
                  <p className="shares_icon_text_p">Share on Twitter</p>
                </button>
                <button type="button" onClick={handleCopyClick} className="shares_icon_txt">
                  <img src={CopyLinkIcon} alt="delete" />
                  <p className="shares_icon_text_p">Copy link</p>
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ShareSocial;
