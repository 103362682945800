import { useEffect } from 'react';
import { pageViewAnalyticsEvent } from '../service/analyticsService';

const usePageViewAnalyticsHook = ({ title }) => {
  useEffect(() => {
    pageViewAnalyticsEvent(title);
  }, [title]);
};

export default usePageViewAnalyticsHook;
