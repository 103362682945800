/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import Rating from '@mui/material/Rating';
import axios from 'axios';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toast } from 'react-toastify';
import morningIMG from '../assets/Images/Morning.svg';
import editIcon from '../assets/Images/EditOption.svg';
import appleMusicIcon from '../assets/Images/appleMusicIcon.png';
import api from '../api/index';
// import addSongs from '../assets/Images/VectoraddSongs.svg';
import shareIcon from '../assets/Images/ShareOption.svg';
import spotifylogo from '../assets/Images/spotifyLogoBlue.svg';
import PostComponent from '../components/PostComponent';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
// import OpenSetting from '../assets/Images/OpenSetting.svg';
import arrowRound from '../assets/Images/arrowRound.svg';
import shareMini from '../assets/Images/shareMini.svg';
import starMini from '../assets/Images/starMini.svg';
import starMiniYellow from '../assets/Images/starMiniYellow.svg';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import { ReactComponent as RayOne } from '../assets/Images/colorRay1.svg';
import { ReactComponent as RayTwo } from '../assets/Images/colorRay2.svg';
import CreateReviewModal2 from '../components/CreateReviewModel2';
import RightTop from '../assets/Images/RightTop.svg';
import refreshToker from '../refreshToken';
import usePageLoader from '../components/PageLoader/usePageLoader';
import PageLoader from '../components/PageLoader';
import {
  albumShareAnalyticsEvent,
  analyticsPages,
  errorLogCountAnalyticsEvent,
} from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';
import { canShowAppleMusicLogo } from '../utils/helpers';

export default function SongDetails() {
  const navigate = useNavigate();
  const { albumId } = useParams();
  const [selectedValue, setSelectedValue] = useState('top');
  const [spotifyAuthError, setSpotifyAuthError] = useState('');
  const [album, setAlbum] = useState('');
  const [popularSong, setPopularSong] = useState();
  const [reviewPosts, setReviewPosts] = useState([]);
  // const [relatedAlbums, setRelatedAlbums] = useState([]);
  const [trackListsArray, setTrackListsArray] = useState([]);
  const [spotifySongLink, setSpotifySongLink] = useState();
  const id = albumId;
  const [songDeatile, setSongDeatile] = useState(false);
  const [writeReview, setWriteReview] = useState(false);
  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();
  const profileImg = localStorage.getItem('profilePicLink');
  const handleOpen = () => {
    setSongDeatile(true);
  };

  const handleShareSong = async () => {
    // const urll = window.location + window.location.pathname;
    try {
      const data1 = {
        title: 'Sharing Song ',
        text: 'Hey, sharing this song with you !',
        url: `https://musotic-dev.falconweb.app/songDetails/${id}`,
      };
      albumShareAnalyticsEvent();
      await navigator.share(data1);
    } catch (error) {
      errorLogCountAnalyticsEvent();
      console.error('Copy failed:', error);
    }
  };
  const handleTracklistItemClick = (reviewId) => {
    navigate(`/reviews/${reviewId}`);
  };
  // const handleListenOnSpotify = () => {
  //   if (!spotifySongLink && !album?.spotify_url) {
  //     toast.error('Your spotify account is not linked yet!');
  //   }
  //   window.open(spotifySongLink || album.spotify_url, '_blank');
  // };

  const handleClose = () => {
    setSongDeatile(false);
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const fetchUserReviews = async () => {
    startPageLoader();
    try {
      const response = await api('get', `/albums/${id}/`);
      console.log('resAlbum', response?.data);
      setAlbum(response?.data);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
    stopPageLoader();
  };

  const fetchAlbumsFromSpotify = async () => {
    await axios
      .get(`https://api.spotify.com/v1/albums/${album?.spotify_id}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('spotifyAccessToken')}`,
        },
      })
      .then((trackResponse) => {
        setSpotifySongLink(trackResponse?.data?.external_urls?.spotify);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log('fetchAlbumsFromSpotify', err?.response?.data?.error?.message);
        setSpotifyAuthError(true);
      });
  };

  const fetchTracksFromSpotify = async () => {
    await axios
      .get(`https://api.spotify.com/v1/tracks/${album?.spotify_id}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('spotifyAccessToken')}`,
        },
      })
      .then((trackResponse) => {
        setSpotifySongLink(trackResponse?.data?.external_urls?.spotify);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log('fetchTracksFromSpotify', err?.response?.data?.error?.message);
        fetchAlbumsFromSpotify();
        setSpotifyAuthError(true);
      });
  };

  const fetchTrackLists = async () => {
    try {
      const response = await api('get', `/albums/${album?.id}/tracks/`);
      console.log('resSnnongi', response?.data);
      setTrackListsArray(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  // const fetchRelatedAlbums = async () => {
  //   try {
  //     const response = await api('get', `/albums/${album?.id}/related-albums/`);
  //     console.log('albumssRelated', response?.data?.results);
  //     setRelatedAlbums(response?.data?.results);
  //   } catch (err) {
  //     console.log('Error fetching user reviews:', err);
  //   }
  // };
  useEffect(() => {
    if (album) {
      fetchTrackLists();
      // fetchRelatedAlbums();
      fetchTracksFromSpotify();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [album?.id]);
  useEffect(() => {
    const spotifyRefreshToken = localStorage.getItem('refresh_token');
    const refreshToken = spotifyRefreshToken;
    async function fetchRefreshToken() {
      const newAccessToken = await refreshToker(refreshToken);
      localStorage.setItem('spotifyAccessToken', newAccessToken);
    }
    fetchRefreshToken();
    fetchAlbumsFromSpotify();
    fetchTracksFromSpotify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spotifyAuthError]);
  useEffect(() => {
    if (id) {
      fetchUserReviews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchPopularReleases = async () => {
    try {
      const response = await api(
        'get',
        `/songs/popular-releases/?artist_id=${album?.artist_spotify_id}`,
      );
      setPopularSong(response?.data);
      console.log('resssxx', response?.data);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  useEffect(() => {
    fetchPopularReleases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [album?.artist_spotify_id]);

  const fetchReviewData = async () => {
    try {
      const response = await api('get', `/albums/${id}/reviews/?filters=${selectedValue}`);
      const reviewResults = response?.data?.results;

      if (reviewResults && reviewResults?.length > 0) {
        const promises = reviewResults?.map(async (item) => {
          const commentsResponse = await api('get', `/reviews/${item?.id}/comments/`);
          const comments = commentsResponse?.data?.results;

          return {
            review: item,
            review_comments: comments,
          };
        });

        const results = await Promise.all(promises);
        console.log('loopx2', results);
        setReviewPosts(results);
      } else {
        setReviewPosts([]);
      }
    } catch (error) {
      errorLogCountAnalyticsEvent();
      console.error(error);
    }
  };

  useEffect(() => {
    fetchReviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const handleComment = (targetId, comment) => {
    if (comment !== '') {
      api('post', `/review-comments/`, { text: comment, review: targetId })
        .then((res) => {
          fetchReviewData();
          console.log(res);
        })
        .catch((err) => {
          errorLogCountAnalyticsEvent();
          console.log(err);
        });
    }
  };

  const handleCloseReview = () => {
    setWriteReview(false);
  };

  const handleOpenRelatedTrack = (songId) => {
    navigate(`/songDetails/${songId}`);
  };

  const handleOpenPopularSong = (url) => {
    window.open(url, '_blank');
  };

  usePageViewAnalyticsHook({
    title: analyticsPages.albumDetails,
  });

  return (
    <div className="songDeatilsContainer">
      <PageLoader isLoading={isPageLoading} />
      <div className="leftbottomColor leftbottomColor_dashboard_song_details">
        <img src={RightTop} alt="x" />
      </div>
      <div className="songDetailsInsideDiv">
        <div className="songBannerDiv">
          <img src={album?.album_image || album?.image} alt="bannerHere" />
        </div>
        <div className="songImgDp">
          <img src={album?.image} alt="bannerHere" />
        </div>
        <div className="bannerDetailsDiv">
          <div className="songBannerNameEtc">
            <p className="songNameWithImg">{album?.title}</p>
            <p className="songArtistWithImg">
              Album | {album?.title} | {album?.year || '--'}
            </p>
            <p className="songBeatlesTxt">{album?.artist_name}</p>
          </div>
          <div className="songBannerRatingsEtc">
            <div className="ratingsBanner1Div">
              <div className="ratings1Insider">
                <p>{album?.total_ratings}</p>
                <p className="opacityClass">Total Ratings</p>
              </div>
              <div className="dividerClass">|</div>
              <div className="ratings1Insider">
                <div className="displayFlex">
                  <span className="iconSS">
                    <img src={starMiniYellow} alt="icon" />
                  </span>

                  <p>{album?.ratings_average}</p>
                </div>
                <p className="opacityClass">Average</p>
              </div>
              <div className="dividerClass">|</div>
              <div className="ratings1Insider" id="ratings33Insider">
                <div className="displayFlex">
                  <span className="iconSS">
                    <img src={starMiniYellow} alt="icon" />
                  </span>

                  <p className="yourRatingFigure">{album?.your_ratings || '00'}</p>
                </div>

                <p className="opacityClass">Your Rating</p>
              </div>
            </div>
            <div className="reviewAndShareBtnsDiv">
              <button className="shareSongBtnx" type="submit" onClick={handleShareSong}>
                <span className="iconSS">
                  <img src={shareMini} alt="icon" />
                </span>
                Share Song
              </button>
              <button
                className="writeReviewBtnx"
                onClick={() => setWriteReview(true)}
                type="submit"
              >
                <span className="iconSS">
                  <img src={starMini} alt="icon" />
                </span>
                Write a Review
              </button>
            </div>
          </div>
        </div>
        <div className="songIntensityAndTimeDiv">
          <div className="intensityBarSongInside">
            <div className="colorIntensityTxt">Color Intensity</div>
            <div className="raysWrapperSongDetails">
              <RayOne
                className="raysWrapSongDetails"
                style={{ color: album?.color_intensity?.color_one_rgb }}
              />
              <RayTwo
                className="raysWrapSongDetails"
                style={{ color: album?.color_intensity?.color_two_rgb }}
              />
            </div>
          </div>
          <div className="listeningTimeBarInsider">
            <div className="colorsImgDivDetails2">
              <img src={morningIMG} alt="colors" />
            </div>
            <div className="colorIntensityTxt">Mostly listened in mornings</div>
          </div>
        </div>
        <div className="topReviewsAndActions">
          <div className="topHeadingsDivReviewAndActions">
            <div className="topReviewsHeadingHolder">
              <p className="topReviewTxtP">Top Reviews</p>
              <p className="filterTxtP" onClick={handleOpen}>
                Filter
              </p>
            </div>
            <div className="optionsHeadingsTxt">
              <p>Options</p>
            </div>
          </div>
          <div className="secondDivInsiderSongDetails">
            <div className="postComponentHolderOnSongDetails" style={{ width: '60%' }}>
              {reviewPosts?.length > 0 &&
                reviewPosts?.map((data) => (
                  <PostComponent
                    key={data?.review?.id}
                    ProfilePic={data?.review?.song?.image || data?.review?.album?.image}
                    posterName={data?.review?.song?.title || data?.review?.album?.title}
                    ProfilePicTop={data?.review?.user?.avatar}
                    ProfilePic2={data?.review?.user?.avatar || dummyProfileImage}
                    ProfilePicx={data?.review?.song?.image}
                    ProfilePicy={data?.review?.song?.image}
                    ProfilePicz={data?.review?.song?.image}
                    ProfilePicInComments2={data?.review?.song?.image}
                    morning={data?.review?.morning}
                    noon={data?.review?.noon}
                    evening={data?.review?.evening}
                    posterSubtitle={
                      data?.review?.song?.artist_name || data?.review?.album?.artist_name
                    }
                    title={data?.review?.title}
                    description={data?.review?.body}
                    // postComponentSetter={postComponentSetter}
                    data={data}
                    postedByUsername={data.review?.user.username || data?.review?.user?.name}
                    hoursAgo={formatDistanceToNow(new Date(data?.review?.created_at), {
                      addSuffix: true,
                      includeSeconds: false,
                    })}
                    likedByText="Dummy"
                    likedByOthers="others"
                    commentsText="sample comment text here"
                    ratings={data?.review?.ratings}
                    ratingsNumber={data?.review?.ratings}
                    likedHours="1h"
                    usernameComments={data?.review?.user.username}
                    likedHoursUnderComments="0.5h"
                    commentNoOfLikes={data?.review?.like_count}
                    // shareModal={shareModal}
                    // setShareModal={setShareModal}
                    // openOption={openOption}
                    // setOpenOption={setOpenOption}
                    reviewCommentsArray={data?.review_comments}
                    handleComment={handleComment}
                    feedReviewSongDetails={data?.review}
                    backgroundColor="#5A6276"
                    fetchReviewData={fetchReviewData}
                    userProfileImage={profileImg}
                  />
                ))}

              {reviewPosts?.length < 1 && (
                <div className="noReviewFoundDiv" style={{ width: '100%' }}>
                  <p className="noReviewYetTxt">
                    Seems like no one has gotten to this song yet. Be the first to write a review
                    for {album?.title}.
                  </p>
                  <button
                    className="writeReviewBtnx"
                    onClick={() => setWriteReview(true)}
                    type="submit"
                  >
                    <span className="iconSS">
                      <img src={starMini} alt="icon" />
                    </span>
                    Write a Review
                  </button>
                </div>
              )}
            </div>

            <div className="optionsBoxContainer">
              <div className="optionsBoxDiv">
                <div className="optionsCategorydiv">
                  <div className="optionsCategoryImgHolder">
                    <img src={editIcon} alt="pic" />
                  </div>
                  <div onClick={() => setWriteReview(true)} className="optionsCategoryNameTxt">
                    Write Review
                  </div>
                </div>
                {/* <div className="optionsCategorydiv">
                  <div className="optionsCategoryImgHolder">
                    <img src={addSongs} alt="pic" />
                  </div>
                  <div className="optionsCategoryNameTxt">Add Song To Playlist</div>
                </div> */}
                <div className="optionsCategorydiv" onClick={handleShareSong}>
                  <div className="optionsCategoryImgHolder">
                    <img src={shareIcon} alt="pic" />
                  </div>
                  <div className="optionsCategoryNameTxt">Share</div>
                </div>
                <ListenMusic
                  appleMusicUrl={album.apple_music_url}
                  spotifyMusicUrl={spotifySongLink || album.spotify_url}
                />
              </div>
              {/* <div className="albumlistdiv">
                <div className="albumheading">
                  <h2>Album Name</h2>
                  <div>
                    <img src={OpenSetting} alt="." />
                  </div>
                </div>
                {relatedAlbums?.map((item, index) => (
                  <div key={index?.id} className="albumdata">
                    <div className="albumpic">
                      <img src={item?.image} alt="img not found" />
                    </div>
                    <div className="albumtext">
                      <h2>{item?.title}</h2>
                      <p>
                        Album | {item?.year?.substring(0, 4)} | {item?.songs?.length} Tracks
                      </p>
                    </div>
                    <div className="albumlastarrow">
                      <img src={arrowRound} alt="." />
                    </div>
                  </div>
                ))}
              </div> */}
              <div className="tracklistDiv">
                <div className="tracklistHeadingDiv">
                  <div className="traklistFont">TrackList</div>
                  {/* <div>
                    <img src={OpenSetting} alt="." />
                  </div> */}
                </div>

                {trackListsArray?.length > 0 &&
                  trackListsArray?.map((item) => (
                    <div
                      className="songsOfTrackList"
                      key={item.id}
                      onClick={() => handleOpenRelatedTrack(item.id)}
                    >
                      <div
                        className="trackSongAndName"
                        id="cursorPointer"
                        onClick={() => handleTracklistItemClick(item?.id)}
                      >
                        <div className="trackSongImgHolder">
                          <img src={item?.song?.image} alt="." />
                        </div>
                        <div>
                          <div className="trackSongNameTxt">{item?.song?.title} </div>
                          <div className="trackAlbumNameTxt">
                            Album | {item?.song?.album_name || item?.song?.artist_name}
                          </div>
                        </div>
                      </div>
                      <div className="ratingAndArrows">
                        <div className="ratingTrackWithFig">
                          <p className="counterTxt">{item?.song?.ratings_average}</p>
                          <Rating
                            name="custom-rating"
                            defaultValue={item?.song?.ratings_average}
                            precision={0.5}
                            readOnly
                            sx={{
                              '& .MuiRating-icon': {
                                fontSize: '15px',
                                '&:hover': {
                                  fontSize: '15px',
                                },
                              },
                            }}
                          />
                        </div>
                        <div id="cursorPointer" onClick={() => handleTracklistItemClick(item?.id)}>
                          <img src={arrowRound} alt="." />
                        </div>
                      </div>
                    </div>
                  ))}
                {trackListsArray?.length < 1 && <p className="noTracksFound">No Track found</p>}
                <div>
                  <div className="tracklistHeadingDiv" id="marginTop">
                    <div className="traklistFont">Popular Releases</div>
                    {/* <div>
                      <img src={OpenSetting} alt="." />
                    </div> */}
                  </div>
                  {!popularSong ? (
                    <p id="marginTop" className="item-description">
                      No Popular Releases found for this Artist
                    </p>
                  ) : (
                    <div className="popularReleasesWrapper">
                      {popularSong?.map((song) => (
                        <div
                          className="overviewContainer"
                          key={song.id}
                          onClick={() => handleOpenPopularSong(song.url)}
                        >
                          <div className="popularSongSingleDiv">
                            <div className="popularSongImg">
                              <img src={song?.image} alt="." />
                            </div>
                            <div className="popularSongSingleDivTxt">
                              <div className="trackPopularName">{song?.name}</div>
                              <div className="trackPopularNameArtist">{song?.artist}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal open={songDeatile} onClose={handleClose}>
        <Box className="filtersPopup">
          <div className="child-Container child-Container2">
            <h2 className="child-modal-title child-modal-title2">Filter</h2>
            <div className="clear" onClick={handleClose}>
              <img src={CloseIcon} alt="" srcSet="" />
            </div>
          </div>

          <div className="mainSideMenu2">
            <div className="sidemenu3">
              <span>Top Review</span>
              <RadioGroup value={selectedValue} onChange={handleChange}>
                <FormControlLabel value="top" control={<Radio style={{ color: '#57BBEB' }} />} />
              </RadioGroup>
            </div>
            <div className="sidemenu3">
              <span>Lowest Review</span>
              <RadioGroup value={selectedValue} onChange={handleChange}>
                <FormControlLabel value="lowest" control={<Radio style={{ color: '#57BBEB' }} />} />
              </RadioGroup>
            </div>
            <div className="sidemenu3">
              <span>My Review</span>
              <RadioGroup value={selectedValue} onChange={handleChange}>
                <FormControlLabel
                  value="my_reviews"
                  control={<Radio style={{ color: '#57BBEB' }} />}
                />
              </RadioGroup>
            </div>
            <div className="sidemenu3">
              <span>Friends Activity</span>
              <RadioGroup value={selectedValue} onChange={handleChange}>
                <FormControlLabel
                  value="friends_reviews"
                  control={<Radio style={{ color: '#57BBEB' }} />}
                />
              </RadioGroup>
            </div>
          </div>
        </Box>
      </Modal>
      <CreateReviewModal2
        open={writeReview}
        handleClose={handleCloseReview}
        Song={album}
        fetchReviewData={fetchReviewData}
      />
    </div>
  );
}

function ListenMusic({ spotifyMusicUrl, appleMusicUrl }) {
  const handleListenOnSpotify = () => {
    if (!spotifyMusicUrl) {
      toast.error('Your spotify account is not linked yet!');
    }
    window.open(spotifyMusicUrl, '_blank');
  };

  const handleListenOnAppleMusic = () => {
    if (appleMusicUrl) {
      window.open(appleMusicUrl, '_blank');
    }
  };
  if (canShowAppleMusicLogo(appleMusicUrl, spotifyMusicUrl)) {
    return (
      <div className="optionsCategorydivLast">
        <div className="optionsCategoryImgHolder">
          <img src={appleMusicIcon} alt="appleMusicIcon" />
        </div>
        <div className="optionsCategoryNameTxt" onClick={handleListenOnAppleMusic}>
          Listen On Apple Music
        </div>
      </div>
    );
  }

  if (spotifyMusicUrl) {
    return (
      <div className="optionsCategorydivLast">
        <div className="optionsCategoryImgHolder">
          <img src={spotifylogo} alt="pic" />
        </div>
        <div className="optionsCategoryNameTxt" onClick={handleListenOnSpotify}>
          Listen On Spotify
        </div>
      </div>
    );
  }
  return null;
}
