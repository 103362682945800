/* eslint-disable no-bitwise */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Rating from '@mui/material/Rating';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import { HuePicker } from 'react-color';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import MorningIMG from '../assets/Images/Morning.svg';
import NoonIMG from '../assets/Images/Noon.svg';
import api from '../api/index';
import EveningIMG from '../assets/Images/Evening.svg';
import CameraIcon from '../assets/Images/CameraIcon.svg';
import DropDpwnIcon from '../assets/Images/DropDown.svg';
import BackArrow from '../assets/Images/BackArrow.svg';
import SearchIcon from '../assets/Images/SearchIcon.svg';
import IntensityComponent from './IntensityGraphTest';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

const style = {
  position: 'absolute',
  top: '1%',
  bottom: '1%',
  left: '25%',
  right: '25%',
  // width: 700,
  // height:'99%',
  overflow: 'scroll',
  bgcolor: 'transparent',
  border: 'none',
  '@media (max-width: 1100px)': {
    // top: '14%',
    left: '19%',
    right: '19%',
  },
  '@media (max-width: 1024px)': {
    // top: '20%',
    left: '16%',
    right: '16%',
  },
};
const style2 = {
  position: 'absolute',
  top: '20%',
  left: '36%',
  width: 400,
  bgcolor: 'transparent',
  border: 'none',
  '@media (max-width: 1100px)': {
    top: '24%',
    left: '32%',
  },
  '@media (max-width:1024px)': {
    top: '29%',
  },
};

function CreateReviewModal2({ open, handleClose, Song, fetchReviewData }) {
  const [previewImage, setPreviewImage] = useState([]);
  const [selectedColor, setSelectedColor] = useState('rgb(211, 14, 227)');
  const [selectedColorTwo, setSelectedColorTwo] = useState('rgb(71, 202, 195)');
  const [frequency, setFrequency] = useState(5.18);
  const [selectedPlaylists, setSelectedPlaylists] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [imageLinks, setImageLinks] = useState();
  const [searchClick, setSearchClick] = useState();

  const [formData, setFormData] = useState({
    body: '',
    title: '',
    type: '',
    morning: false,
    noon: false,
    evening: false,
    images: [],
    ratings: 0,
  });
  const [songObj, setSongObj] = useState({
    artist_name: '',
    // title: '',
    image: '',
    spotify_id: '',
    artist_spotify_id: '',
    year: '',
    artist_image: '',
    spotify_preview_url: '',
    spotify_url: '',
    apple_id: '',
    apple_url: '',
    apple_preview_url: '',
  });
  console.log(Song, 'revissssewSong');
  const settingData = useCallback(() => {
    setSongObj({
      artist_name: Song?.artist_name,
      title: Song?.title,
      image: Song?.image,
      spotify_id: Song?.spotify_id,
      artist_spotify_id: Song?.artist_spotify_id,
      year: Song?.year,
      artist_image: Song?.artist_image,
      spotify_url: Song?.spotify_url,
      spotify_preview_url: Song?.spotify_preview_url,
      apple_id: Song?.apple_id,
      apple_url: Song?.apple_url,
      apple_preview_url: Song?.apple_preview_url,
    });
    // setFormData((prevState) => {
    //   return {
    //     ...prevState,
    //     title: Song?.title,
    //   };
    // });
  }, [Song]);

  useEffect(() => {
    settingData();
  }, [settingData, Song]);

  const [selectedOptionslist, setSelectedOptionslist] = useState('');
  const [selectedPlaylistNames, setSelectedPlaylistNames] = useState([]);

  const handlePlaylistSearch = () => {
    setSearchClick((prevState) => !prevState);
  };

  function rgbaToRgb(obj) {
    if (obj && typeof obj === 'object' && 'r' in obj && 'g' in obj && 'b' in obj) {
      const { r } = obj;
      const { g } = obj;
      const { b } = obj;
      return `rgb(${r}, ${g}, ${b})`;
    }
    console.error('Invalid object format. Object should have "r," "g," and "b" properties.');
    return '';
  }

  const [rgbColor1, setRgbColor1] = useState('rgb(211, 14, 227)');
  const [rgbColor2, setRgbColor2] = useState('rgb(71, 202, 195');
  const handleColorChange = (color) => {
    console.log('loveee', color.rgb);
    setSelectedColor(color.rgb);
    setRgbColor1(rgbaToRgb(color.rgb));
  };
  console.log(rgbColor1, rgbColor2, 'dfghjk');
  const handleColorChangeTwo = (color) => {
    console.log(color, 'lovee');
    setSelectedColorTwo(color.rgb);
    setRgbColor2(rgbaToRgb(color.rgb));
  };
  const handleOptionsslick = (className) => {
    setSelectedOptionslist(className);
    if (className === 'Noon') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        noon: true,
        morning: false,
        evening: false,
      }));
    }
    if (className === 'morning') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        noon: false,
        morning: true,
        evening: false,
      }));
    }
    if (className === 'Evening') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        noon: false,
        morning: false,
        evening: true,
      }));
    }
  };

  const handleSelectedImgClick = () => {
    setPreviewImage('');
  };

  const handleInputChange = (event) => {
    const { name, type, value } = event.target;

    if (type === 'file') {
      const file = event.target.files[0];
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: file,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleCheckboxChange = (playlist) => {
    setSelectedPlaylistNames((previousPlaylist) => {
      if (previousPlaylist.some((item) => item.id === playlist.id)) {
        return previousPlaylist.filter((item) => item.id !== playlist.id);
      }
      return [...previousPlaylist, playlist];
    });
    setSelectedPlaylists((prevSelected) => {
      if (prevSelected.includes(playlist.id)) {
        return prevSelected.filter((id) => id !== playlist.id);
      }
      return [...prevSelected, playlist.id];
    });
  };
  const handleRatingChange = (e, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: newValue,
    }));
  };
  const [openn, setOpenn] = React.useState(false);
  const [playlistsName, setPlaylistsName] = useState([]);
  const handlePlaylistPopupOpen = async () => {
    setOpenn(true);
    try {
      const response = await api('get', '/users/my-playlists/');
      setPlaylistsName(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  const handlePlaylistSearchValue = async (value) => {
    setSearchValue(value);
    try {
      const response = await api('get', `/users/my-playlists/?q=${value}`);
      setPlaylistsName(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  const handleClosee = () => setOpenn(false);
  const playlistNames = selectedPlaylistNames.map((item) => item.name);

  const handleImageChange = (e) => {
    const selectedImg = e.target.files;
    const selectedImgArray = Array.from(selectedImg);
    setPreviewImage(selectedImgArray);
  };

  const uploadImages = async () => {
    if (previewImage.length === 0) return;

    const links = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const img of previewImage) {
      const formDatanew = new FormData();
      formDatanew.append('image', img);

      api('post', '/upload-image/', formDatanew)
        .then((res) => {
          if (res?.data?.image_url) {
            links.push(res?.data?.image_url);
          }
          setImageLinks(links);
          setFormData((prevFormData) => ({
            ...prevFormData,
            images: links,
          }));
        })
        .catch((error) => {
          errorLogCountAnalyticsEvent();
          console.error('Error occurred while uploading images', error);
        });
    }
  };

  useEffect(() => {
    uploadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewImage]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath.startsWith('/albumDetails/')) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        type: 'album',
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        type: 'song',
      }));
    }
  }, [Song]);

  const handleReviewSubmit = () => {
    const fetchData = async () => {
      if (!formData.title) {
        toast.error('Title required');
        return;
      }
      //  else if (!formData.body) {
      //   toast.error('Review Required');
      //   return;
      // }
      if (formData?.ratings === 0) {
        toast.error('Add Ratings');
      }
      try {
        const updatedPlaylists = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const playlistId of selectedPlaylists) {
          updatedPlaylists.push(playlistId);
        }
        const data = {
          // song: songObj,
          intensity: {
            color_one: 21,
            color_two: 14,
            intensity: 11,
            color_one_rgb: rgbColor1,
            color_two_rgb: rgbColor2,
          },
          playlists: updatedPlaylists,
          ...formData,
        };
        if (formData?.type === 'song') {
          data.song = songObj;
        } else {
          data.album = songObj;
        }

        if (previewImage?.length > 0) {
          if (imageLinks?.length === previewImage?.length) {
            const response = await api('post', '/reviews/', data);
            console.log('Check response ', response);
          } else {
            toast.error('Images are uplaoding');
            return;
          }
        } else {
          const response = await api('post', '/reviews/', data);
          console.log('Check response ', response);
        }
        setSongObj({
          artist_name: '',
          title: '',
          image: '',
          spotify_id: '',
          artist_spotify_id: '',
          year: '',
        });
        setFormData({
          body: '',
          type: '',
          morning: false,
          noon: false,
          evening: false,
          images: [],
          ratings: 0,
          title: '',
        });
        setPreviewImage([]);
        setSelectedPlaylists([]);
        setSelectedOptionslist(null);
        toast.success('Review added successfully');
        handleClose();
        fetchReviewData();
      } catch (err) {
        errorLogCountAnalyticsEvent();
        console.log(err, 'errrrrrrrrrr');
        // eslint-disable-next-line eqeqeq
        if (err?.response?.status == 500) {
          toast.error('internal server error');
        }
      }
    };
    fetchData();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="option_review_sectionNew">
          <div className="inner_option_reviewNew">
            <div className="reviewModalTopHeadingContainer">
              <div className="noSongSampleImgDiv">
                <img src={songObj?.image} alt="." />
              </div>
              <div className="createReviewTxtWrapperClass">
                <p className="createReviewText1">Create review</p>
                <p className="createReviewText2">Welcome To the</p>

                <p className="createReviewText3">Reviewer Cockpit</p>
                <p className="createReviewText4">lorem Lipsum dollar text istmp pistum</p>
              </div>
              <div className="buttonsAndCloseIcon">
                <div className="closeIconAddReviews" onClick={handleClose}>
                  <img src={CloseIcon} alt="." />{' '}
                </div>
                <div>
                  <button className="cancelReviewAdd" type="submit" onClick={handleClose}>
                    Cancel
                  </button>
                  <button className="submitReviewAdd" type="submit" onClick={handleReviewSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <div className="addReviewInputFieldsClass">
              <div className="songAndMorningHolderDiv">
                <div className="createReviewInputholderDiv" id="doubleInputFieldWithRating">
                  <div
                    className="overAllsearchDivWrapSelected"
                    style={{ padding: '16px', backgroundColor: '#2D3447' }}
                  >
                    <div className="selectedSongImgHolder">
                      <img src={songObj?.image} alt="Selected Song" />
                    </div>
                    <div className="selectedSongnameTxt">{songObj?.title}</div>
                    <span className="colorWhite">|</span>
                    <div className="selectedSongSubNameTxt">{songObj?.artist_name}</div>
                  </div>
                </div>
                <div className="createReviewInputholderDiv" id="divMorningDetailsOnAddReview">
                  <div
                    onClick={() => handleOptionsslick('morning')}
                    className={
                      selectedOptionslist === 'morning'
                        ? 'createReview__second_secoondData_btnsColored'
                        : 'songTimeOptions'
                    }
                  >
                    <div className="imgHolderAddReviewIn" id="morningImgReviews">
                      <img className="weather_img" src={MorningIMG} alt="." />
                    </div>
                    <div className="timeOptnTxtt">Morning</div>
                  </div>
                  <div
                    onClick={() => handleOptionsslick('Noon')}
                    className={
                      selectedOptionslist === 'Noon'
                        ? 'createReview__second_secoondData_btnsColored'
                        : 'songTimeOptions'
                    }
                  >
                    <div className="imgHolderAddReviewIn">
                      <img className="weather_img" src={NoonIMG} alt="." />
                    </div>
                    <div className="timeOptnTxtt">Afternoon</div>
                  </div>
                  <div
                    onClick={() => handleOptionsslick('Evening')}
                    className={
                      selectedOptionslist === 'Evening'
                        ? 'createReview__second_secoondData_btnsColored'
                        : 'songTimeOptions'
                    }
                  >
                    <div className="imgHolderAddReviewIn">
                      <img className="weather_img" src={EveningIMG} alt="." />
                    </div>
                    <div className="timeOptnTxtt">evening</div>
                  </div>
                </div>
              </div>
              <div className="">
                <Box
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Rating
                    value={formData.ratings}
                    size="large"
                    name="ratings"
                    precision={0.1}
                    onChange={(e, newValue) => handleRatingChange(e, newValue)}
                    sx={{
                      '& .MuiRating-icon': {
                        fontSize: '35px',
                        '&:hover': {
                          fontSize: '15px',
                        },
                      },
                      '& .MuiRating-iconEmpty': {
                        color: 'white',
                      },
                      marginRight: '10px',
                    }}
                  />
                  <p className="colorWhite text-xl">
                    {formData?.ratings == 0 ? null : formData?.ratings}
                  </p>
                </Box>
              </div>
              <div className="songAndMorningHolderDiv">
                <div className="createReviewInputholderDiv">
                  <input
                    className="createReviewsActualInput"
                    placeholder="Artist Name"
                    value={songObj.artist_name}
                    disabled
                  />
                </div>
                <div className="createReviewInputholderDiv">
                  <label
                    style={{ width: '100%' }}
                    className="upload-pic-label"
                    htmlFor="filePicker"
                  >
                    <input
                      type="file"
                      hidden
                      id="filePicker"
                      multiple
                      name="image"
                      onChange={handleImageChange}
                    />
                    {previewImage?.length !== 0 ? (
                      <div className="addReviewPhotoSelectorDiv">
                        <div className="imagesHolderReviewAddd">
                          {previewImage?.map((image, index) => (
                            <div
                              className="imgHolderForReviews"
                              onClick={handleSelectedImgClick}
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                            >
                              <img src={URL.createObjectURL(image)} alt="Selected" />
                            </div>
                          ))}
                        </div>
                        <div className="imgHolderForReviewsIcon" onClick={handleSelectedImgClick}>
                          <img src={CameraIcon} alt="icon" />
                        </div>
                      </div>
                    ) : (
                      <div className="addReviewPhotoSelectorDiv2">
                        <div>
                          <p className="addPhotoTxt">Add photo</p>
                        </div>
                        <div className="imgHolderForReviewsIcon">
                          <img src={CameraIcon} alt="icon" />
                        </div>
                      </div>
                    )}
                  </label>
                </div>
              </div>
              <div className="songAndMorningHolderDiv">
                <div className="createReviewInputholderDiv">
                  <input
                    className="createReviewsActualInput"
                    placeholder="Title"
                    value={formData?.title}
                    // disabled
                    name="title"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="createReviewInputholderDiv" id="relativeToCamIcon2">
                  <input
                    className="createReviewsActualInput"
                    id="title"
                    name="title"
                    onChange={handleInputChange}
                    placeholder="Add Playlist"
                    onClick={handlePlaylistPopupOpen}
                    value={playlistNames.join(', ')}
                  />
                  <div className="cameraIconDiver2" onClick={handlePlaylistPopupOpen}>
                    <img src={DropDpwnIcon} alt="dropdown" />
                  </div>
                </div>
              </div>
              <div className="songAndMorningHolderDiv">
                <div className="createReviewInputholderDiv">
                  <textarea
                    name="body"
                    value={formData?.body}
                    onChange={handleInputChange}
                    className="createReviewsActualInput"
                    id="addreviewTxtAreas"
                    placeholder="Add Review..."
                    style={{ resize: 'none' }}
                  />
                </div>
                <div className="createReviewInputholderDiv">
                  <div id="songIntensity" className="createReviewsActualInput">
                    <div className="intensityWrapperClass">
                      <IntensityComponent
                        color1={rgbColor1}
                        color2={rgbColor2}
                        frequency={frequency}
                      />
                    </div>
                    <div className="colorPickerWrapper">
                      <HuePicker
                        color={selectedColor}
                        onChange={handleColorChange}
                        className="colorPickerSlider"
                      />
                      <HuePicker
                        color={selectedColorTwo}
                        onChange={handleColorChangeTwo}
                        className="colorPickerSlider"
                        // id="colorPickerTwo"
                      />
                      <input
                        type="range"
                        id="frequency"
                        min="5.33"
                        max="5.66"
                        step="0.01"
                        value={frequency}
                        className="intensitySlider"
                        onChange={(e) => setFrequency(parseFloat(e.target.value))}
                      />
                    </div>
                    <div>{/* <label htmlFor="frequency">intensity:</label> */}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={openn}
          onClose={handleClosee}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="addPLaylist">
              <div className="inner_addPlaylist">
                <div className="playlist_first_Section">
                  <div className={searchClick ? 'playlist_search' : 'playlist_txt_search'}>
                    <img src={BackArrow} alt="back" />
                    {!searchClick && <p className="playlist_txt">Add to Playlist</p>}
                  </div>
                  {searchClick && (
                    <input
                      className="searchPlaylistInput"
                      placeholder="Search Playlist..."
                      onChange={(e) => handlePlaylistSearchValue(e.target.value)}
                    />
                  )}
                  <img
                    className="cursorPointer"
                    src={SearchIcon}
                    alt="search"
                    onClick={handlePlaylistSearch}
                  />
                </div>
                <div className="playlist_second_Section">
                  {playlistsName?.map((playlist) => (
                    <div className="playlist_type_checkbox" key={playlist.id}>
                      <p className="playlist_type_p">{playlist.name}</p>
                      <Checkbox
                        style={{ color: '#57BBEB' }}
                        checked={selectedPlaylists.includes(playlist.id)}
                        onChange={() => handleCheckboxChange(playlist)}
                      />
                    </div>
                  ))}
                </div>
                {/* <div className="playlist_btn"></div> */}
              </div>
            </div>
          </Box>
        </Modal>
      </Box>
    </Modal>
  );
}

export default CreateReviewModal2;
