/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import Arrow from '../../assets/Images/rightarrowIcon.svg';
import ReviewList from '../MyReviewsItems';
import ReviewDetail from '../Reviewdetailpopup';

function MyReviews({ TabPanel, value, userReviews, onOpenScoreModal }) {
  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };
  return (
    <TabPanel value={value} index={0} style={{ width: '80%', background: 'red' }}>
      <div className="reviewHolderDiv">
        <div className="MyReviews_items">
          <div className="myReviews_txt_arrow">
            <p className="myReviews_txt">My Reviews</p>
            <div className="myReviews_arrow" onClick={onOpenScoreModal}>
              <img src={Arrow} alt="icon" />
            </div>
          </div>
          <div className="reviewListofItemsWrap">
            <div
              className={
                userReviews?.length < 4
                  ? 'reviewListofItemsWrapProfile2'
                  : 'reviewListofItemsWrapProfile'
              }
            >
              {userReviews?.length > 0 &&
                userReviews?.map((review, index) => {
                  return (
                    <ReviewList
                      review={review}
                      index={index}
                      className="profileReviewPics"
                      onReviewClick={handleOpenPopup}
                    />
                  );
                })}
              {showPopup && (
                <ReviewDetail setShowFeedReview={setShowPopup} selectedReview={userReviews} />
              )}
            </div>
            {userReviews?.length < 1 && <p className="noDatayetText">no reviews found</p>}
          </div>
        </div>
      </div>
    </TabPanel>
  );
}

export default MyReviews;
