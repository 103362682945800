/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { formatDistanceToNow } from 'date-fns';
import Rating from '@mui/material/Rating';
import api from '../api/index';
import leftarrow from '../assets/Images/leftarrow.svg';
import heart from '../assets/Images/redheart.svg';
import ScoreLevelBarr from './ScoreLevelBar';
import comment from '../assets/Images/IconblueCom.svg';
import share from '../assets/Images/shareblueCom.svg';
import twostar from '../assets/Images/littlestar.svg';
import imgstar from '../assets/Images/Page-1blueCom.svg';
import close from '../assets/Images/CloseIcon.svg';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

export default function Score({ open, handleClose, userData }) {
  const [userReviews, setUserReviews] = useState();

  const fetchUserReviews = async () => {
    try {
      const response = await api('get', `/users/${userData?.id}/reviews/`);
      setUserReviews(response?.data?.results);
      console.log('sssse', response);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  console.log('xzxzxzx', userReviews);

  useEffect(() => {
    if (userData?.id) {
      fetchUserReviews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.id]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="score_main_div">
          <div>
            <div className="sub_main_div_score">
              <div className="top_imgs_score" id="cursorPointer" onClick={handleClose}>
                <img src={leftarrow} alt=".." />
              </div>
              <div className="top_heading_score">
                <h2>My Score</h2>
              </div>
              <div id="cursorPointer" onClick={handleClose}>
                <img src={close} alt=".." />
              </div>
            </div>
            <div className="header_img_score">
              <img
                src={userData?.avatarUrl || userData?.profile?.avatar_url || dummyProfileImage}
                alt=".."
              />
            </div>
            <img className="blue_tick_img" src={imgstar} alt=".." />

            <div className="main_header_score">
              <h1 className="scoreTxtOnModal">{userData?.score || '00'}</h1>
              <div>
                <ScoreLevelBarr score={userData?.score} />
              </div>
              <div>
                <div className="score_first_text">
                  <h3 className="hearder_heading">You have reached</h3>
                  <span className="blueColored">
                    {userData?.score > 0 && userData?.score < 60
                      ? 'Level 1'
                      : userData?.score >= 60 && userData?.score < 140
                      ? 'Level 2'
                      : userData?.score >= 140 && userData?.score < 240
                      ? 'Level 3'
                      : userData?.score >= 240 && userData?.score < 340
                      ? 'Level 4'
                      : userData?.score >= 340
                      ? 'Level 5'
                      : 'Level 0'}
                  </span>
                </div>
                {userData?.score > 340 && <h2>You have been certified </h2>}
              </div>
            </div>
            <div className="userScoreCardMap">
              {userReviews?.map((review, index) => (
                <div className="first_review_main_div" key={index?.id}>
                  <div className="first_review_sub_div">
                    <div className="review_data_div">
                      <div className="review_img_data">
                        <img
                          className="review_img_1"
                          src={review?.song?.image || review?.album?.image}
                          alt=".."
                        />
                      </div>
                      <div className="song_details_review">
                        <div className="score-text">
                          <h4>
                            {review?.song?.title}{' '}
                            <span>| {review?.song?.artist_name || review?.album?.artist_name}</span>
                          </h4>
                          <h5>
                            {formatDistanceToNow(new Date(review?.created_at), {
                              addSuffix: true,
                              includeSeconds: false,
                            })}
                          </h5>
                        </div>
                        <div>
                          <Box>
                            <Rating
                              value={review?.ratings}
                              size="large"
                              name="ratings"
                              sx={{
                                '& .MuiRating-icon': {
                                  fontSize: '15px',
                                  '&:hover': {
                                    fontSize: '15px',
                                  },
                                },
                              }}
                            />
                          </Box>
                        </div>
                        <div className="Dashed_line Dashed_line_score" />

                        <div className="like_comment_share_main_div">
                          <div className="like_div">
                            <img src={heart} alt=".." />
                            <p>{review?.like_count} likes</p>
                          </div>

                          <div className="comment_div">
                            <img src={comment} alt=".." />
                            <p>{review?.comment_count} Comments</p>
                          </div>

                          <div className="share_div">
                            <img src={share} alt=".." />
                            <p>{review?.shares || '0'} Share</p>
                          </div>
                        </div>

                        <div className="last_text_div_score">
                          <div>
                            <img src={twostar} alt=".." />
                          </div>
                          <div>You got {review?.song?.total_ratings || '0'} points! Well done</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
