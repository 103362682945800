import React, { useEffect } from 'react';
import axios from 'axios';
import { baseUrlV2 } from '../config';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

function AppleConnectScreen() {
  const initRef = React.useRef(false);

  const importMusicKit = async () => {
    console.log('[AppleConnectScreen] importMusicKit() called');

    const res = await axios.get(`${baseUrlV2}/apple-developer-token/`);
    console.log('[AppleConnectScreen] Token API Response:', res);

    if (res?.data?.payload?.developer_token) {
      const {
        data: {
          payload: { developer_token },
        },
      } = res;

      // Create a script element to load the MusicKit JS in the document body
      const script = document.createElement('script');
      script.src = 'https://js-cdn.music.apple.com/musickit/v3/musickit.js';
      script.async = true;
      script.onload = () => {
        // window.MusicKit.configure({
        // });
      };
      document.body.appendChild(script);

      // Listen for the loaded event
      document.addEventListener('musickitloaded', async function () {
        console.info('[AppleConnectScreen] musickitloaded event fired');

        // Call configure() to configure an instance of MusicKit on the Web.
        try {
          await window.MusicKit.configure({
            developerToken: developer_token,
            app: {
              name: 'Musotic Web App',
              // build: '1978.4.1',
            },
          });
          console.log('[AppleConnectScreen] MusicKit.configure() called');
        } catch (err) {
          // Handle configuration error
          errorLogCountAnalyticsEvent();
          console.error('[AppleConnectScreen] MusicKit.configure() error:', err);
        }

        // MusicKit instance is available
        const music = window.MusicKit.getInstance();
        console.log('[AppleConnectScreen] music:', music);

        // unauthorize
        await music.unauthorize();
        console.log('[AppleConnectScreen] music.unauthorize() called');

        // You should check authorization before accessing user's iCloud Music Library:
        await music.authorize();
        const { data: result } = await music.api.music('v1/me/recent/played/tracks');
        // User's iCloud Music Library Albums
        console.log('[AppleConnectScreen] /v1/me/recent/played/tracks Response:', result);
      });
    }
  };

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true;
      importMusicKit();
    }
  }, []);

  return null;
}
export default AppleConnectScreen;
