/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import api from '../api/index';
import Score from '../components/Score';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import SettingIcon from '../assets/Images/settingIcon.svg';
import TrophyIcon from '../assets/Images/trophyIcon.svg';
import BlueStar from '../assets/Images/StarIcon.svg';
import PinkHeart from '../assets/Images/HeartPinkIcon.svg';
import ModalFollowers from '../components/ModalFollowers';
import RightTop from '../assets/Images/RightTop.svg';
import EditProfilePopup from '../components/userProfile/EditProfilePopup';
import MyReviews from '../components/userProfile/MyReviews';
import MyPlaylist from '../components/userProfile/MyPlaylist';
import Stats from '../components/userProfile/Stats';
import usePageLoader from '../components/PageLoader/usePageLoader';
import PageLoader from '../components/PageLoader';
import { analyticsPages, errorLogCountAnalyticsEvent } from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';

const style = {
  position: 'absolute',
  top: '22%',
  left: '28%',
  transform: 'translate(0%, 0%)',
  width: '45%',
  backgroundColor: '#2d3447',
  border: 'none',
  boxShadow: '11px 4px 41px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: '30px 25px',
  color: '#ffffff',
};
const style2 = {
  position: 'absolute',
  top: '55%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#2D3447',
  border: 'none',
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function UserProfile() {
  const [userData, setUserData] = useState({
    id: null,
    score: null,
    name: '',
    avatarUrl: '',
    bio: '',
    isVerified: false,
    follower: null,
    following: null,
    username: '',
    email: '',
    phone: '',
    gender: '',
    birthday: '',
    link: '',
    country: '',
  });
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [specificUserName, setSpecificUserName] = useState('');
  const [specificUserId, setSpecificUserId] = useState('');
  const [userReviews, setUserReviews] = useState([]);
  const Userid = localStorage.getItem('userID');
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [stats, setStats] = useState();
  const Navigate = useNavigate();
  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const fetchUserReviews = async () => {
    try {
      const response = await api('get', `/users/${Userid}/reviews/`);
      setUserReviews(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  const handleFollowersButtonClick = (profileData) => {
    setSpecificUserId(profileData?.id);
    setSpecificUserName(profileData);
    setShowFollowersModal(true);
  };
  const handleFollowersModalClose = () => {
    setShowFollowersModal(false);
  };
  const getStats = () => {
    api('get', `/users/${Userid}/stats/`)
      .then((res) => {
        setStats(res?.data);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
      });
  };

  const fetchUserData = async () => {
    startPageLoader();
    try {
      const response = await api('get', '/users/me/');
      console.log(response, 'ssssswe');
      const { data } = response;
      setUserData({
        id: data?.id,
        score: data?.score,
        name: data?.name,
        avatarUrl: data?.profile?.avatar_url,
        bio: data?.profile.bio,
        isVerified: data?.profile?.is_verified,
        follower: data?.follower,
        following: data?.following,
        username: data?.username,
        email: data?.email,
        phone: data?.profile?.phone,
        gender: data?.profile?.gender,
        birthday: data?.profile?.dob,
        link: data?.profile?.link,
        country: data?.profile?.country,
      });
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log(err);
    }
    stopPageLoader();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSettingClick = () => {
    Navigate('/settings');
  };

  const handleOpenEditModal = () => {
    setOpenEditProfile(true);
  };
  const handleCloseEditModal = () => {
    setOpenEditProfile(false);
  };

  useEffect(() => {
    if (Userid) {
      fetchUserReviews();
    }
    getStats();
  }, [Userid]);

  useEffect(() => {
    fetchUserData();
  }, []);
  usePageViewAnalyticsHook({
    title: analyticsPages.profile,
  });

  return (
    <div className="userProfile_Section">
      <PageLoader isLoading={isPageLoading} />
      <div className="leftbottomColor leftbottomColor_dashboard_bottom">
        <img src={RightTop} alt="x" />
      </div>
      <div className="userProfile">
        <div className="userProfile_inner">
          <div className="profile_inner_firstSection">
            <div className="profile_image">
              <img src={userData?.avatarUrl || dummyProfileImage} alt="profile" />
            </div>
            <div className="profile_data_Section">
              <div className="profile_first_data">
                <div className="profile_txt_btn">
                  <p className="profile_name">{userData?.name || userData?.username}</p>
                  <button type="button" onClick={handleOpenEditModal}>
                    Edit Profile
                  </button>
                  <EditProfilePopup
                    openEditProfile={openEditProfile}
                    handleCloseEditModal={handleCloseEditModal}
                    style2={style2}
                    userData={userData}
                    TabPanel={TabPanel}
                    fetchUserData={fetchUserData}
                  />
                </div>
                <div className="profile_setting_img">
                  <img src={SettingIcon} alt="settings" onClick={handleSettingClick} />
                </div>
              </div>
              <p className="profile_user_name">@{userData?.username || 'UserName'}</p>
              <p className="profile_disc">
                {userData?.bio || 'Lorem ipsum dolor sit amet, consectetur adipiscin.'}
              </p>
              {/* <a target="_blank" href={userData?.link} className="profile_disc" id="linkColor">
                {userData?.link || 'link.'}
              </a> */}
              <div className="profile_follow_data">
                <Score open={modalOpen} handleClose={handleCloseModal} userData={userData} />
                <div className="profile_numbers_data " onClick={handleOpenModal}>
                  <p className="profile_number_txt_score">{userData?.score || '000'}</p>
                  <div className="profile_txt_image">
                    <div className="profile_follow_icon">
                      <img src={TrophyIcon} alt="score" />
                    </div>
                    <p className="profile_character_txt">Score</p>
                  </div>
                </div>
                <div className="profile_follow_data_line" />
                <div
                  className="profile_numbers_data"
                  onClick={() => handleFollowersButtonClick(userData)}
                >
                  <p className="profile_number_txt">{userData?.follower}</p>
                  <div className="profile_txt_image">
                    <div className="profile_follow_icon">
                      <img src={BlueStar} alt="followers" />
                    </div>
                    <p className="profile_character_txt">Followers</p>
                  </div>
                </div>
                {showFollowersModal && (
                  <ModalFollowers
                    specificUserId={specificUserId}
                    specificUserName={specificUserName}
                    closeModal={handleFollowersModalClose}
                  />
                )}
                <div className="profile_follow_data_line" />
                <div
                  className="profile_numbers_data"
                  onClick={() => handleFollowersButtonClick(userData)}
                >
                  <p className="profile_number_txt">{userData?.following}</p>
                  <div className="profile_txt_image">
                    <div className="profile_follow_icon">
                      <img src={PinkHeart} alt="following" />
                    </div>
                    <p className="profile_character_txt">Following</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile_filters">
            <Box sx={{ width: '100%', height: '100vh' }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  '@media (min-width: 1200px)': {
                    width: '85%',
                  },
                }}
              >
                <Tabs
                  className="tabsPanelButton_main"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab className="tabs_button" label="Home" value={0} />
                  <Tab className="tabs_button" label="Playlists" value={1} />
                  <Tab className="tabs_button" label="Stats" value={2} />
                </Tabs>
              </Box>
              <MyReviews
                TabPanel={TabPanel}
                value={value}
                userReviews={userReviews}
                onOpenScoreModal={handleOpenModal}
              />
              <MyPlaylist TabPanel={TabPanel} value={value} style={style} userData={userData} />
              <Stats TabPanel={TabPanel} value={value} stats={stats} userReviews={userReviews} />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
