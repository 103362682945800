// import { useEffect, useState } from 'react';
import SendBird from 'sendbird';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import api from '../api/index';

export const SENDBIRD_APP_ID = '3D076B64-0C20-4CE4-901B-B753681B12CD';
// const sendbird = new SendBird({ appId: '46A447BB-8931-40ED-931D-2BF3C2BEF600' }); old id
const sendbird = new SendBird({ appId: SENDBIRD_APP_ID, modules: [new GroupChannelModule()] });

const initSendbird = async () => {
  try {
    const response = await api('get', '/users/me/');
    const { data } = response;
    const sendBirdAccessToken = data?.profile.sendbird_access_token;
    // const name = data?.username;
    const userName = data?.username;
    const id = data?.profile?.id;
    const pic = data?.profile?.avatar_url;

    // await sendbird.connect(id, sendBirdAccessToken);
    await sendbird.connect(id.toString(), sendBirdAccessToken).then((user) => {
      console.log(user);
      sendbird.updateCurrentUserInfo(userName, pic);
    });
    return sendbird;
  } catch (err) {
    console.log(err);
  }
  return null;
};

export default initSendbird;
