/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Init
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Rating from '@mui/material/Rating';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toast } from 'react-toastify';
import formatTimeAgo from '../utils/services';
import LOGO from '../assets/Images/LOGO.svg';
import { ReactComponent as Search } from '../assets/Images/Search.svg';
import { ReactComponent as ProfileIcon } from '../assets/Images/profileHeader.svg';
import { ReactComponent as HomeIcon } from '../assets/Images/homeIcon.svg';
import { ReactComponent as NotifyIcon } from '../assets/Images/NotiCon.svg';
import { ReactComponent as MsgIcon } from '../assets/Images/MsgIcon.svg';
import NotificationLikes from './NotificationReviewLike';
import Close from '../assets/Images/Vector.svg';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import Heart from '../assets/Images/HeartIcon.svg';
// import IconHeartLike from '../assets/Images/IconHeartLike.svg';
import NotificationImgLeft from '../assets/Images/NotificationLeft2.svg';
import BorderStar from '../assets/Images/borderStar.svg';
import api from '../api';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

const style = {
  position: 'absolute',
  top: '0%',
  right: '0%',
  width: 420,
  height: '100vh',
  bgcolor: '#212936',
  boxShadow: 24,
  borderRadius: '10px',
  border: 'none',
  pt: 2,
  px: 3,
  pb: 2,
  '&:focus': {
    outline: 'none',
  },
};
const style2 = {
  position: 'absolute',
  top: '30%',
  right: '40%',
  bgcolor: '#475066',
  boxShadow: 24,
  borderRadius: '10px',
  border: 'none',
};

export default function Header() {
  const location = useLocation();
  const path = location.pathname;
  const musoticID = localStorage.getItem('userID');
  // const [colorLike, setColorLike] = useState();
  const [selectedIcon, setSelectedIcon] = useState('dashboard');
  // eslint-disable-next-line no-unused-vars
  const [checkboxStates, setCheckboxStates] = useState({
    review: false,
    followership: false,
    reply: false,
    checkbox4: false,
  });
  const Navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(false);
  const [showNotificationList, setShowNotificationList] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [earlierNNotifications, setEarlierNotifications] = useState([]);
  const [count, setCount] = useState({ notification: null, message: null });
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [loader, setLoader] = useState(false);

  const handleCheckboxClick = (checkboxName) => {
    setSelectedCheckbox(checkboxName);
  };
  const handleClose = () => {
    setOpen(false);
    setFilter(false);
    setShowNotificationList(false);
    setSelectedIcon('');
  };

  const handleClose2 = () => {
    setFilter(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setFilter(true);
  };
  const handleNotifications = async () => {
    await api('get', '/my-notifications/')
      .then((res) => {
        setNotifications(res?.data?.today);
        setEarlierNotifications(res?.data?.earlier);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err, 'not err');
      });
  };
  const handleIconClick = (iconName) => {
    setSelectedIcon(iconName);
    if (iconName === 'search') {
      Navigate('/search');
    } else if (iconName === 'home') {
      Navigate('/dashboard');
      document.getElementById('MainPage').scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else if (iconName === 'profile') {
      Navigate('/profile');
    } else if (iconName === 'chat') {
      Navigate('/chat');
    } else if (iconName === 'notify') {
      setShowNotificationList(true);
      setOpen(true);
      handleNotifications();
    }
  };

  const getNotifications = () => {
    api('get', '/unread-count/')
      .then((res) => {
        setCount({
          notification: res?.data?.unread_notification_count,
          message: res?.data?.unread_message_count,
        });
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err, 'err');
      });
  };

  const handleClearButtonClick = () => {
    setSelectedCheckbox(null);
    handleNotifications();
  };

  const handleFilterNotifications = async () => {
    await api('get', `/my-notifications/?notification_type=${selectedCheckbox}`)
      .then((res) => {
        setNotifications(res?.data?.today);
        setEarlierNotifications(res?.data?.earlier);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err, 'not err');
      });
  };
  useEffect(() => {
    if (selectedCheckbox) {
      handleFilterNotifications();
    }
  }, [selectedCheckbox]);

  const handleFollowBtnClick = (follow) => {
    const url = follow.follow_url.substring(follow?.follow_url.indexOf('/users'));
    setLoader(true);
    api('get', `${url}`)
      .then(async (res) => {
        if (follow?.following === true) {
          toast.success('Unfollowed');
        } else {
          toast.success('followed');
        }
        await handleNotifications();
        setLoader(false);
        console.log(res);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        setLoader(false);
        console.log(err);
      });
  };
  const handleProfileClick = (id) => {
    if (id == musoticID) {
      Navigate('/profile');
      handleClose();
    } else Navigate(`/user/${id}`);
    handleClose();
  };
  const handleReply = (id) => {
    Navigate(`/reviews/${id}`);
    handleClose();
  };
  useEffect(() => {
    handleNotifications();
    getNotifications();
  }, []);
  return (
    <div className="headerMainClass">
      <div className="headerInsiderClass">
        <div className="leftSideDiv">
          <img src={LOGO} alt="logo" />
        </div>
        <div className="headerMiddleDiv">
          <div className="nacIcons">
            <HomeIcon
              className={path === '/dashboard' ? 'selectedIcon' : 'notSelected'}
              id="navIcons"
              onClick={() => handleIconClick('home')}
            />
          </div>
          <div className="nacIcons">
            <Search
              className={path === '/search' ? 'selectedIcon' : 'notSelected'}
              id="navIcons"
              onClick={() => handleIconClick('search')}
            />
          </div>
          <div className="nacIcons">
            <ProfileIcon
              className={path === '/profile' ? 'selectedIcon' : 'notSelected'}
              id="navIcons"
              onClick={() => handleIconClick('profile')}
            />
          </div>
        </div>
        <div className="headerRightSide">
          <div className="rightSideInsider_header">
            <div className="notification_div">
              <NotifyIcon
                className={selectedIcon === 'notify' ? 'selectedIcon' : 'notSelected'}
                onClick={() => handleIconClick('notify')}
              />
              <div className="notification_dot">
                {count?.notification > 0 && <p className="notifyNumber">{count?.notification}</p>}
              </div>
            </div>
            <div className="notification_div">
              <MsgIcon
                className={path === '/chat' ? 'selectedIcon' : 'notSelected'}
                onClick={() => handleIconClick('chat')}
              />
              {count?.message > 0 && (
                <div className="notification_dot">
                  <p className="notifyNumber">{count?.message}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showNotificationList && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={style}>
            <div className="notification-container">
              <div className="topContainer">
                <div onClick={handleClose} className="imgContainers">
                  <img src={Close} alt="" />
                </div>

                <h3 className="notification-heading">Notifications</h3>
                <div className="filters" onClick={handleOpen}>
                  Filter
                </div>
              </div>
              <div className="todayContainer">
                <div className="today">Today</div>
                <div className="See-All">See All</div>
              </div>
              <div className="NotificationSection">
                {notifications?.length === 0 ? (
                  <p className="noDatayetText">No notifications yet</p>
                ) : (
                  notifications?.map((item) => {
                    return (
                      <div className="container21212" key={item?.id}>
                        {item?.notification_type === 'followership' && (
                          <div className="container212">
                            <div
                              onClick={() => handleProfileClick(item?.user_id)}
                              className="sideImgs"
                              style={{ cursor: 'pointer' }}
                            >
                              <img src={item?.user_avatar} alt="" />
                            </div>
                            <div className="main-user2">
                              <p className="username">
                                {item?.detail}
                                <span className="hour">
                                  &nbsp;{formatTimeAgo(item?.created_at)}
                                </span>
                              </p>
                            </div>
                            <div className="btnContainer">
                              <button
                                onClick={() => handleFollowBtnClick(item?.followership)}
                                type="submit"
                                className="Followbtn"
                                disabled={loader}
                              >
                                {item?.followership?.following ? 'Unfollow' : 'Follow'}
                              </button>
                            </div>
                          </div>
                        )}

                        {item?.notification_type === 'review' && (
                          <>
                            <div className="container200">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  onClick={() => handleProfileClick(item?.user_id)}
                                  style={{ display: 'flex', gap: '12px', cursor: 'pointer' }}
                                >
                                  <div className="notificationImg">
                                    <img src={item?.user_avatar || dummyProfileImage} alt="" />
                                  </div>
                                  <div className="main-user">
                                    <p className="usernameInNotifications">
                                      <p>
                                        <span className="userNameInNotifi">
                                          {item?.user_name}&nbsp;
                                        </span>
                                        {item?.detail}
                                      </p>
                                      <span className="hour">
                                        &nbsp; {formatTimeAgo(item?.created_at)}
                                      </span>
                                    </p>
                                  </div>
                                </div>

                                <div className="sideImgNotifications">
                                  <img src={NotificationImgLeft} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className="heartImg-Container2">
                              <p className="rating" id="opacity8">
                                {item?.review?.ratings}
                              </p>
                              <div className="stardiv">
                                <Rating
                                  name="custom-rating"
                                  defaultValue={item?.review?.ratings}
                                  readOnly
                                  precision={0.5}
                                  sx={{
                                    '& .MuiRating-icon': {
                                      fontSize: '14px',
                                      '&:hover': {
                                        fontSize: '14px',
                                      },
                                    },
                                  }}
                                />
                              </div>
                              <div className="seprator"> </div>
                              <div className="heartImg2" id="opacity8">
                                <img src={BorderStar} alt="" />
                              </div>
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleReply(item?.review?.review_id)}
                                className="review"
                                id="opacity8"
                              >
                                Add Review
                              </div>
                              <div className="seprator"> </div>

                              <NotificationLikes
                                reviewId={item?.review?.review_id}
                                reviewIsLiked={item?.review?.is_liked}
                              />

                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleReply(item?.review?.review_id)}
                                className="reply2"
                                id="opacity8"
                              >
                                Reply
                              </div>
                            </div>
                          </>
                        )}

                        <div className="container200">
                          {item?.notification_type === 'comment' && (
                            <>
                              <div className="container200">
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div
                                    onClick={() => handleProfileClick(item?.user_id)}
                                    style={{ display: 'flex', gap: '12px', cursor: 'pointer' }}
                                  >
                                    <div className="notificationImg">
                                      <img src={item?.user_avatar || dummyProfileImage} alt="" />
                                    </div>
                                    <div className="main-user">
                                      <p className="usernameInNotifications">
                                        <span className="userNameInNotifi">
                                          {item?.user_name}&nbsp;
                                        </span>
                                        <p>{item?.detail}</p>
                                        <span className="hour">
                                          &nbsp; {formatTimeAgo(item?.created_at)}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="sideImgNotifications">
                                    <img src={NotificationImgLeft} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="heartImg-Container2">
                                <div className="heartImg2" id="opacity8">
                                  <img src={Heart} alt="" />
                                </div>
                                <div
                                  style={{ cursor: 'pointer' }}
                                  // onClick={() => handleReply(item?.comment?.review_id)}
                                  className="reply2"
                                  id="opacity8"
                                >
                                  Reply
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="container200">
                          {item?.notification_type === 'review_like' && (
                            <>
                              <div className="container200">
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div style={{ display: 'flex', gap: '12px', cursor: 'pointer' }}>
                                    <div className="notificationImg">
                                      <img src={item?.user_avatar || dummyProfileImage} alt="" />
                                    </div>
                                    <div className="main-user">
                                      <p className="usernameInNotifications">
                                        <span
                                          className="userNameInNotifi"
                                          onClick={() => handleProfileClick(item?.user_id)}
                                        >
                                          {item?.user_name}&nbsp;
                                        </span>
                                        <p
                                          onClick={() => handleReply(item?.review_like?.review_id)}
                                        >
                                          {item?.detail}
                                        </p>
                                        <span className="hour">
                                          &nbsp; {formatTimeAgo(item?.created_at)}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="sideImgNotifications">
                                    <img src={NotificationImgLeft} alt="" />
                                  </div>
                                </div>
                              </div>
                              {/* <div className="heartImg-Container2"></div> */}
                            </>
                          )}
                        </div>

                        <div className="container200">
                          {item?.notification_type === 'reply' && (
                            <>
                              <div className="container200">
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div
                                    onClick={() => handleProfileClick(item?.user_id)}
                                    style={{ display: 'flex', gap: '12px', cursor: 'pointer' }}
                                  >
                                    <div className="notificationImg">
                                      <img src={item?.user_avatar || dummyProfileImage} alt="" />
                                    </div>
                                    <div className="main-user">
                                      <p className="usernameInNotifications">
                                        <span className="userNameInNotifi">
                                          {item?.user_name}&nbsp;
                                        </span>
                                        <p>{item?.detail}</p>
                                        <span className="hour">
                                          &nbsp; {formatTimeAgo(item?.created_at)}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="sideImgNotifications">
                                    <img src={NotificationImgLeft} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="heartImg-Container2">
                                <div className="heartImg2" id="opacity8">
                                  <img src={Heart} alt="" />
                                </div>
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleReply(item?.reply?.review_id)}
                                  className="reply2"
                                  id="opacity8"
                                >
                                  Reply
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })
                )}

                <h1 className="earlier">Earlier</h1>
                {earlierNNotifications?.map((item) => {
                  console.log(item, 'islikeddd');

                  return (
                    <div className="container21212" key={item?.id}>
                      {item?.notification_type === 'followership' && (
                        <div className="container212">
                          <div
                            onClick={() => handleProfileClick(item?.user_id)}
                            className="sideImgs"
                            style={{ cursor: 'pointer' }}
                          >
                            <img src={item?.user_avatar} alt="" />
                          </div>
                          <div className="main-user2">
                            <p className="username">
                              <span className="userNameInNotifi">{item?.user_name}</span>{' '}
                              {item?.detail}
                              <span className="hour">&nbsp;{formatTimeAgo(item?.created_at)}</span>
                            </p>
                          </div>
                          <div className="btnContainer">
                            <button
                              onClick={() => handleFollowBtnClick(item?.followership)}
                              type="submit"
                              className="Followbtn"
                              disabled={loader}
                            >
                              {item?.followership?.following ? 'Unfollow' : 'Follow'}
                            </button>
                          </div>
                        </div>
                      )}

                      {item?.notification_type === 'review' && (
                        <>
                          <div className="container200">
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                onClick={() => handleProfileClick(item?.user_id)}
                                style={{ display: 'flex', gap: '12px', cursor: 'pointer' }}
                              >
                                <div className="notificationImg">
                                  <img src={item?.user_avatar || dummyProfileImage} alt="" />
                                </div>
                                <div className="main-user">
                                  <p className="usernameInNotifications">
                                    <p>
                                      <span className="userNameInNotifi">
                                        {item?.user_name}&nbsp;
                                      </span>
                                      {item?.detail}
                                    </p>
                                    <span className="hour">
                                      &nbsp; {formatTimeAgo(item?.created_at)}
                                    </span>
                                  </p>
                                </div>
                              </div>

                              <div className="sideImgNotifications">
                                <img src={item?.song?.album_image} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="heartImg-Container2">
                            <p className="rating" id="opacity8">
                              {item?.review?.ratings}
                            </p>
                            <div className="stardiv">
                              <Rating
                                name="custom-rating"
                                defaultValue={item?.review?.ratings}
                                readOnly
                                precision={0.5}
                                sx={{
                                  '& .MuiRating-icon': {
                                    fontSize: '14px',
                                    '&:hover': {
                                      fontSize: '14px',
                                    },
                                  },
                                }}
                              />
                            </div>
                            <div className="seprator"> </div>
                            <div className="heartImg2" id="opacity8">
                              <img src={BorderStar} alt="" />
                            </div>
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleReply(item?.review?.review_id)}
                              className="review"
                              id="opacity8"
                            >
                              Add Review
                            </div>
                            <div className="seprator"> </div>

                            <NotificationLikes
                              reviewId={item?.review?.review_id}
                              reviewIsLiked={item?.review?.is_liked}
                            />

                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleReply(item?.review?.review_id)}
                              className="reply2"
                              id="opacity8"
                            >
                              Reply
                            </div>
                          </div>
                        </>
                      )}

                      <div className="container200">
                        {item?.notification_type === 'comment' && (
                          <>
                            <div className="container200">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  onClick={() => handleProfileClick(item?.user_id)}
                                  style={{ display: 'flex', gap: '12px', cursor: 'pointer' }}
                                >
                                  <div className="notificationImg">
                                    <img src={item?.user_avatar || dummyProfileImage} alt="" />
                                  </div>
                                  <div className="main-user">
                                    <p className="usernameInNotifications">
                                      <p>
                                        <span className="userNameInNotifi">
                                          {item?.user_name}&nbsp;
                                        </span>
                                        {item?.detail}
                                      </p>
                                      <span className="hour">
                                        &nbsp;{formatTimeAgo(item?.created_at)}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="sideImgNotifications">
                                  <img src={NotificationImgLeft} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className="heartImg-Container2">
                              <div className="heartImg2" id="opacity8">
                                <img src={Heart} alt="" />
                              </div>
                              <div
                                style={{ cursor: 'pointer' }}
                                // onClick={() => handleReply(item?.comment?.review_id)}
                                className="reply2"
                                id="opacity8"
                              >
                                Reply
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="container200">
                        {item?.notification_type === 'review_like' && (
                          <>
                            <div className="container200">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <div style={{ display: 'flex', gap: '12px', cursor: 'pointer' }}>
                                  <div className="notificationImg">
                                    <img src={item?.user_avatar || dummyProfileImage} alt="" />
                                  </div>
                                  <div className="main-user">
                                    <p className="usernameInNotifications">
                                      <p>
                                        <span
                                          className="userNameInNotifi"
                                          onClick={() => handleProfileClick(item?.user_id)}
                                        >
                                          {item?.user_name}&nbsp;
                                        </span>
                                        <span
                                          onClick={() => handleReply(item?.review_like?.review_id)}
                                        >
                                          {item?.detail}
                                        </span>
                                      </p>
                                      <span className="hour">
                                        &nbsp; {formatTimeAgo(item?.created_at)}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="sideImgNotifications"
                                  onClick={() => handleReply(item?.review_like?.review_id)}
                                >
                                  <img src={NotificationImgLeft} alt="" />
                                </div>
                              </div>
                            </div>
                            {/* <div className="heartImg-Container2"></div> */}
                          </>
                        )}
                      </div>

                      <div className="container200">
                        {item?.notification_type === 'reply' && (
                          <>
                            <div className="container200">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  onClick={() => handleProfileClick(item?.user_id)}
                                  style={{ display: 'flex', gap: '12px', cursor: 'pointer' }}
                                >
                                  <div className="notificationImg">
                                    <img src={item?.user_avatar || dummyProfileImage} alt="" />
                                  </div>
                                  <div className="main-user">
                                    <p className="usernameInNotifications">
                                      <p>
                                        <span className="userNameInNotifi">
                                          {item?.user_name}&nbsp;
                                        </span>
                                        {item?.detail}
                                      </p>
                                      <span className="hour">
                                        &nbsp; {formatTimeAgo(item?.created_at)}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="sideImgNotifications">
                                  <img src={NotificationImgLeft} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className="heartImg-Container2">
                              <div className="heartImg2" id="opacity8">
                                <img src={Heart} alt="" />
                              </div>
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleReply(item?.reply?.review_id)}
                                className="reply2"
                                id="opacity8"
                              >
                                Reply
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
                {earlierNNotifications?.length < 1 && (
                  <p className="noDatayetText">No notifications found</p>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      )}

      <Modal open={filter} onClose={handleClose2}>
        <Box sx={{ ...style2, width: 350, padding: '20px' }}>
          <div className="child-Container">
            <h2 className="child-modal-title">Filter</h2>
            <div className="clear" onClick={handleClearButtonClick}>
              Clear
            </div>
          </div>

          <div className="mainSideMenu2">
            <div className="sidemenu3">
              <span>Reviews</span>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: '#57BBEB' }}
                      checked={selectedCheckbox === 'review'}
                      onClick={() => handleCheckboxClick('review')}
                    />
                  }
                />
              </FormGroup>
            </div>
            <div className="sidemenu3">
              <span>Follows</span>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: '#57BBEB' }}
                      checked={selectedCheckbox === 'followership'}
                      onClick={() => handleCheckboxClick('followership')}
                    />
                  }
                />
              </FormGroup>
            </div>
            <div className="sidemenu3">
              <span>Replies</span>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: '#57BBEB' }}
                      checked={selectedCheckbox === 'reply'}
                      onClick={() => handleCheckboxClick('reply')}
                    />
                  }
                />
              </FormGroup>
            </div>
            <div className="sidemenu3">
              <span>Review Like</span>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: '#57BBEB' }}
                      checked={selectedCheckbox === 'review_like'}
                      onClick={() => handleCheckboxClick('review_like')}
                    />
                  }
                />
              </FormGroup>
            </div>
            <div className="sidemenu3">
              <span>Reply Like</span>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: '#57BBEB' }}
                      checked={selectedCheckbox === 'reply_like'}
                      onClick={() => handleCheckboxClick('reply_like')}
                    />
                  }
                />
              </FormGroup>
            </div>
            <div className="sidemenu3">
              <span>Comment Like</span>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: '#57BBEB' }}
                      checked={selectedCheckbox === 'comment_like'}
                      onClick={() => handleCheckboxClick('comment_like')}
                    />
                  }
                />
              </FormGroup>
            </div>
            <div className="sidemenu3">
              <span>Comment</span>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: '#57BBEB' }}
                      checked={selectedCheckbox === 'comment'}
                      onClick={() => handleCheckboxClick('comment')}
                    />
                  }
                />
              </FormGroup>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
