/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Rating from '@mui/material/Rating';
import { HuePicker } from 'react-color';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import MorningIMG from '../assets/Images/Morning.svg';
import NoonIMG from '../assets/Images/Noon.svg';
import EveningIMG from '../assets/Images/Evening.svg';
import DropDpwnIcon from '../assets/Images/DropDown.svg';
import IntensityComponent from './IntensityGraphTest';
import api from '../api';
import CameraIcon from '../assets/Images/CameraIcon.svg';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

const style = {
  position: 'absolute',
  top: '10%',
  left: '25%',
  width: 700,
  bgcolor: 'transparent',
  border: 'none',
};

function EditReviewModal({ open, handleClose, postComponentData, fetchReviewData }) {
  const [bodyData, setBodyData] = useState('');
  const [formData, setFormData] = useState({
    body: postComponentData?.body,
    title: postComponentData?.title || postComponentData?.song?.title,
    morning: postComponentData?.morning,
    noon: postComponentData?.noon,
    evening: postComponentData?.evening,
    type: postComponentData?.type,
    images: [],
    ratings: postComponentData?.song?.your_ratings || postComponentData?.album?.your_ratings,
  });
  console.log(postComponentData, 'formData');
  const [selectedOptionslist, setSelectedOptionslist] = useState('');
  const [frequency, setFrequency] = useState(5.18);
  const [previewImage, setPreviewImage] = useState([]);
  const [selectedColor, setSelectedColor] = useState('#D30EE3');
  const [selectedColorTwo, setSelectedColorTwo] = useState('#47CAC3');
  const id = localStorage.getItem('userID');
  const [imageLinks, setImageLinks] = useState();

  const handleInputChange = (e) => {
    setBodyData(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      body: e.target.value,
    }));
  };
  console.log(imageLinks, 'sdfgdsdfds');
  const handleImageChange = (e) => {
    const selectedImg = e.target.files;
    const selectedImgArray = Array.from(selectedImg);
    console.log('selectedImgArray', selectedImgArray);
    setPreviewImage([...previewImage, ...selectedImgArray]);
  };
  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
  };
  const handleColorChangeTwo = (color) => {
    setSelectedColorTwo(color.hex);
  };
  const handleSelectedImgClick = () => {
    setPreviewImage('');
  };

  const handleRatingChange = (e, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: newValue,
    }));
  };
  const handleTitleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOptionsslick = (className) => {
    setSelectedOptionslist(className);
    if (className === 'Noon') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        noon: true,
        morning: false,
        evening: false,
      }));
    }
    if (className === 'morning') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        noon: false,
        morning: true,
        evening: false,
      }));
    }
    if (className === 'Evening') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        noon: false,
        morning: false,
        evening: true,
      }));
    }
  };
  const handleEditSubmit = () => {
    api('patch', `/reviews/${postComponentData?.id}/`, {
      ...formData,
    })
      .then(() => {
        handleClose();
        fetchReviewData();
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err, 'erre');
      });
  };
  useEffect(() => {
    setBodyData(postComponentData?.body);
  }, [postComponentData?.body]);
  useEffect(() => {
    const uploadImages = async () => {
      if (previewImage?.length === 0) return;

      const links = [];
      for (const img of previewImage) {
        const formDatanew = new FormData();
        formDatanew.append('image', img);

        try {
          const response = await api('post', '/upload-image/', formDatanew);
          console.log('imgggUrl?', response?.data?.image_url);
          if (response?.data?.image_url) {
            links.push(response?.data?.image_url);
          }
          setImageLinks(links);
          setFormData((prevFormData) => ({
            ...prevFormData,
            images: links,
          }));
        } catch (error) {
          errorLogCountAnalyticsEvent();
          console.error('Error occurred while uploading images', error);
        }
      }
    };

    uploadImages();
  }, [previewImage]);
  useEffect(() => {
    if (postComponentData?.morning === true) {
      setSelectedOptionslist('morning');
    }
    if (postComponentData?.noon === true) {
      setSelectedOptionslist('Noon');
    }
    if (postComponentData?.evening === true) {
      setSelectedOptionslist('Evening');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="option_review_sectionNew">
          <div className="inner_option_reviewNew">
            <div className="reviewModalTopHeadingContainer">
              <div className="noSongSampleImgDiv">
                <img
                  src={postComponentData?.song?.image || postComponentData?.album?.image}
                  alt="."
                />
              </div>
              <div className="createReviewTxtWrapperClass">
                <p className="createReviewText1">Edit review</p>
                <p className="createReviewText2">Welcome To the</p>

                <p className="createReviewText3">Reviewer Cockpit</p>
                <p className="createReviewText4">lorem Lipsum dollar text istmp pistum</p>
              </div>
              <div className="buttonsAndCloseIcon">
                <div className="closeIconAddReviews" onClick={handleClose}>
                  <img src={CloseIcon} alt="." />{' '}
                </div>
                <div>
                  <button className="cancelReviewAdd" type="submit" onClick={handleClose}>
                    Cancel
                  </button>
                  <button className="submitReviewAdd" type="submit" onClick={handleEditSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <div className="addReviewInputFieldsClass">
              <div className="songAndMorningHolderDiv">
                <div className="createReviewInputholderDiv" id="doubleInputFieldWithRating">
                  <div
                    className="overAllsearchDivWrapSelected"
                    style={{ padding: '16px', backgroundColor: '#2D3447' }}
                  >
                    <div className="selectedSongImgHolder">
                      <img
                        src={postComponentData?.song?.image || postComponentData?.album?.image}
                        alt="Selected Song"
                      />
                    </div>
                    <div className="selectedSongnameTxt">
                      {postComponentData?.song?.title || postComponentData?.album?.title}
                    </div>
                    <span className="colorWhite">|</span>
                    <div className="selectedSongSubNameTxt">
                      {postComponentData?.song?.artist_name ||
                        postComponentData?.album?.artist_name}
                    </div>
                  </div>

                  <div className="ratingDivInsideCreateReview">
                    <Box sx={{ display: 'flex' }}>
                      <Rating
                        value={formData?.ratings}
                        size="large"
                        name="ratings"
                        onChange={(e, newValue) => handleRatingChange(e, newValue)}
                        sx={{
                          '& .MuiRating-icon': {
                            fontSize: '15px',
                            '&:hover': {
                              fontSize: '15px',
                            },
                          },
                        }}
                      />
                    </Box>
                  </div>
                </div>
                <div className="createReviewInputholderDiv" id="divMorningDetailsOnAddReview">
                  <div
                    className={
                      selectedOptionslist === 'morning'
                        ? 'createReview__second_secoondData_btnsColored'
                        : 'songTimeOptions'
                    }
                    onClick={() => handleOptionsslick('morning')}
                  >
                    <div className="imgHolderAddReviewIn" id="morningImgReviews">
                      <img className="weather_img" src={MorningIMG} alt="." />
                    </div>
                    <div className="timeOptnTxtt">Morning</div>
                  </div>
                  <div
                    className={
                      selectedOptionslist === 'Noon'
                        ? 'createReview__second_secoondData_btnsColored'
                        : 'songTimeOptions'
                    }
                    onClick={() => handleOptionsslick('Noon')}
                  >
                    <div className="imgHolderAddReviewIn">
                      <img className="weather_img" src={NoonIMG} alt="." />
                    </div>
                    <div className="timeOptnTxtt">Afternoon</div>
                  </div>
                  <div
                    className={
                      selectedOptionslist === 'Evening'
                        ? 'createReview__second_secoondData_btnsColored'
                        : 'songTimeOptions'
                    }
                    onClick={() => handleOptionsslick('Evening')}
                  >
                    <div className="imgHolderAddReviewIn">
                      <img className="weather_img" src={EveningIMG} alt="." />
                    </div>
                    <div className="timeOptnTxtt">evening</div>
                  </div>
                </div>
              </div>
              <div className="songAndMorningHolderDiv">
                <div className="createReviewInputholderDiv">
                  <input
                    className="createReviewsActualInput"
                    placeholder="Artist Name"
                    value={
                      postComponentData?.song?.artist_name || postComponentData?.album?.artist_name
                    }
                    disabled
                  />
                </div>
                {/* <div className="createReviewInputholderDiv">
                  {postComponentData?.images?.length !== 0 ? (
                    <div className="addReviewPhotoSelectorDiv">
                      <div className="imagesHolderReviewAddd">
                        {postComponentData?.images?.map((image, index) => (
                          <div
                            className="imgHolderForReviews"
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                          >
                            <img src={image} alt="Selected" />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="addReviewPhotoSelectorDiv2">
                      <div>
                        <p className="addPhotoTxt">No photos</p>
                      </div>
                    </div>
                  )}
                </div> */}
                <div className="createReviewInputholderDiv">
                  <label
                    style={{ width: '100%' }}
                    className="upload-pic-label"
                    htmlFor="filePicker"
                  >
                    <input
                      type="file"
                      hidden
                      id="filePicker"
                      multiple
                      name="image"
                      onChange={handleImageChange}
                    />

                    {previewImage?.length !== 0 ? (
                      <div className="addReviewPhotoSelectorDiv">
                        <div className="imagesHolderReviewAddd">
                          {previewImage?.map((image, index) => (
                            <div
                              className="imgHolderForReviews"
                              onClick={handleSelectedImgClick}
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                            >
                              <img src={URL.createObjectURL(image)} alt="Selected" />
                            </div>
                          ))}
                        </div>
                        <div className="imgHolderForReviewsIcon" onClick={handleSelectedImgClick}>
                          <img src={CameraIcon} alt="icon" />
                        </div>
                      </div>
                    ) : (
                      <div className="addReviewPhotoSelectorDiv2">
                        <div className="imagesHolderReviewAddd">
                          {postComponentData?.images?.length > 0 &&
                            postComponentData?.images.map((image, index) => (
                              <div
                                className="imgHolderForReviews"
                                onClick={handleSelectedImgClick}
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                              >
                                <img src={image} alt="Selected" />
                              </div>
                            ))}
                          {postComponentData?.images?.length < 1 && (
                            <p className="addPhotoTxt">Add photo</p>
                          )}
                        </div>
                        <div className="imgHolderForReviewsIcon">
                          <img src={CameraIcon} alt="icon" />
                        </div>
                      </div>
                    )}
                  </label>
                </div>
              </div>
              <div className="songAndMorningHolderDiv">
                <div className="createReviewInputholderDiv">
                  <input
                    className="createReviewsActualInput"
                    placeholder="Title"
                    name="title"
                    value={formData?.title}
                    onChange={(e) => handleTitleChange(e)}
                  />
                </div>
                <div className="createReviewInputholderDiv" id="relativeToCamIcon2">
                  <input
                    className="createReviewsActualInput"
                    id="title"
                    name="title"
                    placeholder="Add Playlist"
                  />

                  <div className="cameraIconDiver2">
                    <img src={DropDpwnIcon} alt="." />
                  </div>
                </div>
              </div>
              <div className="songAndMorningHolderDiv">
                <div className="createReviewInputholderDiv">
                  <textarea
                    name="body"
                    value={bodyData}
                    onChange={(e) => handleInputChange(e)}
                    className="createReviewsActualInput"
                    id="addreviewTxtAreas"
                    placeholder="Add Review..."
                    // eslint-disable-next-line eqeqeq
                    disabled={postComponentData?.user?.id != id}
                    style={{ resize: 'none' }}
                  />
                </div>
                <div className="createReviewInputholderDiv">
                  <div
                    id="songIntensity"
                    name="songIntensity"
                    // onChange={handleInputChange}
                    className="createReviewsActualInput"
                  >
                    <div className="intensityWrapperClass">
                      <IntensityComponent
                        color1={selectedColor}
                        color2={selectedColorTwo}
                        frequency={frequency}
                      />
                    </div>
                    <div className="colorPickerWrapper">
                      <HuePicker
                        color={selectedColor}
                        onChange={handleColorChange}
                        className="colorPickerSlider"
                      />
                      <HuePicker
                        color={selectedColorTwo}
                        onChange={handleColorChangeTwo}
                        className="colorPickerSlider"
                        // id="colorPickerTwo"
                      />
                      <input
                        type="range"
                        id="frequency"
                        min="5.33"
                        max="5.66"
                        step="0.01"
                        value={frequency}
                        className="intensitySlider"
                        onChange={(e) => setFrequency(parseFloat(e.target.value))}
                      />
                    </div>
                    <div>{/* <label htmlFor="frequency">intensity:</label> */}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default EditReviewModal;
