/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../api/index';
import LOGO from '../assets/Images/LOGO.svg';
import PreLoginSliders from '../components/PreLoginSliders';
import usePageLoader from '../components/PageLoader/usePageLoader';
import PageLoader from '../components/PageLoader';
import { analyticsPages, errorLogCountAnalyticsEvent } from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';

export default function AccountVerification() {
  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();
  const otpFields = useRef([]);
  const lastOtpField = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const userEmail = localStorage.getItem('email');

  useEffect(() => {
    const handleInputChange = (index, event) => {
      const input = event.target;
      const nextInput = otpFields.current[index + 1];

      if (input.value && nextInput) {
        nextInput.focus();
      }

      if (input === lastOtpField.current && input.value) {
        // eslint-disable-next-line no-use-before-define
        handleSubmit();
      }
    };

    const handleInputKeyDown = (index, event) => {
      if (event.key === 'Backspace') {
        const input = event.target;
        const previousInput = otpFields.current[index - 1];

        if (!input.value && previousInput) {
          previousInput.focus();
        }
      }
    };

    const currentFields = otpFields.current;
    currentFields.forEach((input, index) => {
      input.addEventListener('input', (event) => handleInputChange(index, event));
      input.addEventListener('keydown', (event) => handleInputKeyDown(index, event));
    });

    return () => {
      currentFields.forEach((input, index) => {
        input.removeEventListener('input', (event) => handleInputChange(index, event));
        input.removeEventListener('keydown', (event) => handleInputKeyDown(index, event));
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const email = state?.email ? state?.email : userEmail;
  const maskedEmail = email.replace(/(.{5}).+(@.+)/, '$1*****$2');
  console.log('object', email);

  const handleSubmit = async () => {
    const otp = parseInt(otpFields.current.map((field) => field.value).join(''));
    console.log('otp', otp);
    const data = { otp, email };
    startPageLoader();
    if (state) {
      try {
        await api('post', '/forgot-password/', data);
        toast.success('Account Verified reset password now');
        navigate('/resetpassword');
      } catch (err) {
        errorLogCountAnalyticsEvent();
        toast.error('ERROR');
      }
    } else {
      try {
        await api('post', '/verify-email/', data);
        toast.success('Account Verified');
        window.location = '/login';
      } catch (err) {
        errorLogCountAnalyticsEvent();
        toast.error('Error');
      }
    }
    stopPageLoader();
  };

  const handleResendCode = async () => {
    // eslint-disable-next-line radix
    const otp = parseInt(otpFields.current.map((field) => field.value).join(''));
    console.log('otp', otp);
    const ResendCodedata = { email };
    startPageLoader();
    if (state) {
      try {
        await api('get', `/forgot-password/?email=${ResendCodedata.email}`);
        toast.success('Email resent successfully');
      } catch (err) {
        errorLogCountAnalyticsEvent();
        toast.error('ERROR');
      }
    } else {
      try {
        await api('post', '/resend-verify-email/', ResendCodedata);
        toast.success('Verification e-mail has been sent.');
      } catch (err) {
        errorLogCountAnalyticsEvent();
        toast.error('Email not found');
      }
    }
    stopPageLoader();
  };

  usePageViewAnalyticsHook({
    title: analyticsPages.accountVerification,
  });

  return (
    <div className="accountVerificationContainer">
      <PageLoader isLoading={isPageLoading} />
      <div className="preloginWrapper">
        <PreLoginSliders />
      </div>
      <div className="accountVerifyRightPartWrap">
        <div className="rightSideInsiderWrap">
          <div className="logoClassTop">
            <img src={LOGO} alt="logo" />
          </div>
          <div className="verificationTxtsWrap">
            <p className="topHeading_verification">Account Verification</p>
            <p className="securityTxt">For your security, we want to make sure it’s really you.</p>
          </div>
          <div className="verificationCodeInputWrapper">
            <div className="txtForInputTop">
              <p className="enterCodetxt">Enter Code</p>
              <div className="resendCodeDiv" onClick={handleResendCode}>
                <p className="resendCodetxt">Resend Code</p>
              </div>
            </div>
            <div className="inputFieldsContainer">
              <div className="conts">
                <input
                  className="otpField"
                  type="text"
                  maxLength={1}
                  ref={(ref) => {
                    otpFields.current[0] = ref;
                  }}
                />
              </div>
              <div className="conts">
                <input
                  className="otpField"
                  type="text"
                  maxLength={1}
                  ref={(ref) => {
                    otpFields.current[1] = ref;
                  }}
                />
              </div>
              <div className="conts">
                <input
                  className="otpField"
                  type="text"
                  maxLength={1}
                  ref={(ref) => {
                    otpFields.current[2] = ref;
                  }}
                />
              </div>
              <div className="conts">
                <input
                  className="otpField"
                  type="text"
                  maxLength={1}
                  ref={(ref) => {
                    otpFields.current[3] = ref;
                  }}
                />
              </div>
              <div className="conts">
                <input
                  className="otpField"
                  type="text"
                  maxLength={1}
                  ref={(ref) => {
                    otpFields.current[4] = ref;
                  }}
                />
              </div>
              <div className="conts">
                <input
                  className="otpField"
                  type="text"
                  maxLength={1}
                  ref={(ref) => {
                    otpFields.current[5] = ref;
                    lastOtpField.current = ref;
                  }}
                />
              </div>
            </div>
            <div>
              <p className="mailSentTxt">A code was sent to you on {maskedEmail}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
