import React, { useEffect, useRef, useState } from 'react';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import Flag from '../assets/Images/Flag.svg';
import Checked from '../assets/Images/checkVerified.svg';
import IconHeartLike from '../assets/Images/IconHeartLike.svg';
import PlaylistCommentLike from './PlaylistCommentLike';
import likes from '../assets/Images/likes.svg';
import api from '../api';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

function ShowMoreData({
  showMoreRef,
  playlistComments,
  fetchPlaylistComments,
  playlistDetailsById,
}) {
  const [reply, setReply] = useState('');
  const [comment, setComment] = useState('');
  const [replyFocus, setReplyFocus] = useState(false);
  const inputRef = useRef(null);
  const UserProfilePic = JSON.parse(localStorage.getItem('profilePicLink'));
  console.log('ssssreply', reply);
  console.log('playlistDetailidd', playlistDetailsById);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setReplyFocus(false);
        setReply('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (replyFocus === true) {
      inputRef?.current?.focus();
    }
  }, [replyFocus]);
  const handleAddComment = (e, com, id) => {
    if (e.keyCode === 13) {
      api('post', '/playlist-comments/', {
        text: com,
        playlist: id,
      })
        .then(() => {
          setComment('');
          fetchPlaylistComments(id);
        })
        .catch((err) => {
          errorLogCountAnalyticsEvent();
          console.log(err);
        });
    }
  };

  return (
    <div className="playList_showMore_innerData">
      <div ref={showMoreRef} />
      <div className="playList_showMore_header">
        <p className="discussion_txt">Discussion</p>
        <p className="discussion_txt">{playlistComments?.length}</p>
      </div>
      {playlistComments && playlistComments?.length === 0 ? (
        <p className="noPlaylistDatayetText">No discussion yet</p>
      ) : (
        playlistComments?.map((com) => (
          <PlaylistCommentLike
            ProfilePicInComments={UserProfilePic || dummyProfileImage}
            com={com}
            Checked={Checked}
            Flag={Flag}
            IconHeartLike={IconHeartLike}
            likes={likes}
            // commentsReplyText={commentsReplyText}
          />
        ))
      )}
      <div className="addCommentDivInput">
        <div className="AddCommentProfileImg">
          <img src={UserProfilePic || dummyProfileImage} alt="ss" />
        </div>
        <div className="addCommentInput">
          <input
            className="addCommentInputField"
            type="text"
            placeholder="Add comment..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyDown={(e) => {
              handleAddComment(e, e.target.value, playlistDetailsById?.id);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ShowMoreData;
