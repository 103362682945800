import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAMIT5n0tcXPNo-TA7zx0bNWFE3JjsK1Zw',
  authDomain: 'musotic-e6dc3.firebaseapp.com',
  projectId: 'musotic-e6dc3',
  storageBucket: 'musotic-e6dc3.appspot.com',
  messagingSenderId: '1044469040161',
  appId: '1:1044469040161:web:8a2d878b3d21ddd0018f60',
  measurementId: 'G-S6MKKF4KPP',
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export default messaging;
