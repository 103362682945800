/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import Rating from '@mui/material/Rating';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ShareSocial from './ShareSocial';
import VectorImg from '../assets/Images/SunImg.svg';
import api from '../api/index';
import noonImg from '../assets/Images/Noon.svg';
import eveningImg from '../assets/Images/Evening.svg';
import DotsBar from '../assets/Images/DotsBar.svg';
import Checked from '../assets/Images/checkVerified.svg';
import likes from '../assets/Images/likes.svg';
import IconHeartLike from '../assets/Images/IconHeartLike.svg';
import comments from '../assets/Images/Comment.svg';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import wave from '../assets/Images/Wave.svg';
import Flag from '../assets/Images/Flag.svg';
import share from '../assets/Images/share.svg';
import PostCommentLike from './PostCommentLike';
import OptionsReview from './OptionsReview';
import { errorLogCountAnalyticsEvent, postShareAnalyticsEvent } from '../service/analyticsService';

export default function PostComponent({
  ProfilePic,
  ProfilePic2,
  posterName,
  posterSubtitle,
  ProfilePicInComments,
  title,
  description,
  postedByUsername,
  hoursAgo,
  commentsReplyText,
  data,
  ratings,
  ratingsNumber,
  morning,
  evening,
  noon,
  reviewCommentsArray,
  handleComment,
  feedReviewSongDetails,
  fetchReviewData,
  handleEdit,
  backgroundColor,
  userProfileImage,
}) {
  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable react-hooks/exhaustive-deps */
  const [colorLike, setColorLike] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [linkkk, setLinkkk] = useState();
  const [comment, setComment] = useState('');
  const [openOption, setOpenOption] = useState(false);
  const musoticID = localStorage.getItem('userID');
  const Navigate = useNavigate();
  const ShareModal = () => {
    setShareModal(true);
  };

  const handleSongNameClicked = () => {
    if (feedReviewSongDetails?.song?.id) {
      Navigate(`/songDetails/${feedReviewSongDetails?.song?.id}`);
    } else {
      Navigate(`/albumDetails/${feedReviewSongDetails?.album?.id}`);
    }
  };

  const handleUserNameClicked = () => {
    const userId = feedReviewSongDetails?.user?.id;
    console.log('feddId', userId, musoticID);
    // eslint-disable-next-line eqeqeq
    if (userId == musoticID) {
      Navigate(`/profile`);
    } else {
      Navigate(`/user/${userId}`);
    }
  };
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const handleDelete = (id) => {
    setOpenOption(false);
    api('delete', `/reviews/${id}/`)
      .then(async () => {
        await fetchReviewData();
        toast.success('Deleted Successfully');
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err, 'err');
        toast.error('Something went wrong');
      });
  };
  const handleReport = (id) => {
    setOpenOption(false);
    const review = id;
    const body = { review };
    api('post', `/report-review/`, body)
      .then(async () => {
        toast.success('Review Reported');
        await fetchReviewData();
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err, 'err');
        toast.error('Something went wrong');
      });
  };
  const handleShareClick = async (id) => {
    setLinkkk(`https://musotic-dev.falconweb.app/reviews/${id}`);

    try {
      const data1 = {
        title: 'Song Review',
        text: 'Hey, sharing this song review with you !',
        url: `https://musotic-dev.falconweb.app/reviews/${id}`,
      };
      postShareAnalyticsEvent();
      await navigator.share(data1);
    } catch (error) {
      errorLogCountAnalyticsEvent();
      console.error('Copy failed:', error);
    }
  };
  console.log('revLink', linkkk, data);
  const handleOpen = () => {
    setOpenOption(true);
  };

  const handleLikeClick = async () => {
    setColorLike((prevState) => !prevState);
    const sample = data?.review?.id;
    const id = sample;

    try {
      const response = await api('post', `/reviews/${id}/like/`);
      console.log('resss', response);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };

  const handleKeyPress = (event, reviewId) => {
    if (event.keyCode === 13) {
      handleComment(reviewId, comment);
      setComment('');
    }
  };
  useEffect(() => {
    if (feedReviewSongDetails?.is_liked === true) {
      setColorLike(true);
    }
  }, [feedReviewSongDetails]);
  const handleClosshare = () => {
    setShareModal(false);
  };

  return (
    <div className="postDiv" style={{ backgroundColor: backgroundColor || ' rgba(45, 52, 71, 1)' }}>
      {shareModal && (
        <ShareSocial
          handleClosshare={handleClosshare}
          shareModal={shareModal}
          reviewId={data?.review?.id}
        />
      )}

      {openOption && (
        <OptionsReview
          handleDelete={handleDelete}
          handleReport={handleReport}
          handleShareClick={handleShareClick}
          openOption={openOption}
          setOpenOption={setOpenOption}
          id={data?.review?.id}
          data={data}
          handleEdit={handleEdit}
        />
      )}
      <div className="postTopData">
        <div className="postTopLeft">
          <div className="postPicHolder">
            <img src={ProfilePic || dummyProfileImage} alt="pic" style={{ objectFit: 'cover' }} />
          </div>
          <div className="posterNameDiv" onClick={handleSongNameClicked}>
            <p className="nameTxtt">{posterName}</p>
            <p className="nameTxtt2">{posterSubtitle}</p>
          </div>
        </div>
        <div className="iconsDiv">
          {morning && (
            <div className="vectorss">
              <img src={VectorImg} alt="vector" />
            </div>
          )}
          {evening && (
            <div className="vectorssNew">
              <img src={eveningImg} alt="vector" />
            </div>
          )}
          {noon && (
            <div className="vectorssNew" id="noonImgOnPost">
              <img src={noonImg} alt="vector" />
            </div>
          )}
          <div className="vectorss">
            <div
              style={{
                background: `linear-gradient(to bottom right, ${
                  data?.review?.intensity?.color_one_rgb
                    ? data?.review?.intensity?.color_one_rgb
                    : '#D30EE3'
                }, ${
                  data?.review?.intensity?.color_two_rgb
                    ? data?.review?.intensity?.color_two_rgb
                    : '#47CAC3'
                })`,
              }}
              className="wave"
            >
              <img src={wave} alt="" />
            </div>
          </div>
          <div
            className="vectorsDots"
            onClick={() => {
              handleOpen();
            }}
          >
            <img src={DotsBar} alt="vector" />
          </div>
        </div>
      </div>
      <div className="dashedDividerOnPost"> </div>
      <div className="post2ndDiv">
        <div className="titleAndReview">
          <p className="tittleTxts">{title}</p>
          <div className="postRatingsDivWithTxt">
            <p className="noOfRatingStars">{ratingsNumber}</p>
            <Rating
              name="custom-rating"
              defaultValue={ratings}
              precision={0.5}
              readOnly
              sx={{
                '& .MuiRating-icon': {
                  fontSize: '15px',
                  '&:hover': {
                    fontSize: '15px',
                  },
                },
              }}
            />
          </div>
        </div>

        {feedReviewSongDetails?.images?.length === 1 && (
          <div className="reviewImagesDivHolder">
            {feedReviewSongDetails.images[0] && (
              <img src={feedReviewSongDetails.images[0]} alt="reviewImg" />
            )}
          </div>
        )}
        {feedReviewSongDetails?.images?.length === 2 && (
          <div className="reviewImagesDivHolder2">
            {feedReviewSongDetails.images[0] && (
              <img src={feedReviewSongDetails.images[0]} alt="reviewImg" />
            )}
            {feedReviewSongDetails.images[1] && (
              <img src={feedReviewSongDetails.images[1]} alt="reviewImg" />
            )}
          </div>
        )}
        {feedReviewSongDetails?.images?.length === 3 && (
          <div className="reviewImagesDivHolder3">
            <div className="reviewImagesDivHolder3in1">
              {feedReviewSongDetails.images[0] && (
                <img src={feedReviewSongDetails.images[0]} alt="reviewImg" />
              )}
            </div>
            <div className="reviewImagesDivHolder3in2">
              {feedReviewSongDetails.images[1] && (
                <img src={feedReviewSongDetails.images[1]} alt="reviewImg" />
              )}
              {feedReviewSongDetails.images[2] && (
                <img src={feedReviewSongDetails.images[2]} alt="reviewImg" />
              )}
            </div>
          </div>
        )}
        {feedReviewSongDetails?.images?.length === 4 && (
          <div className="reviewImagesDivHolder3" id="reviewImagesDivHolder3id">
            <div className="reviewImagesDivHolder3in1">
              {feedReviewSongDetails.images[0] && (
                <img src={feedReviewSongDetails.images[0]} alt="reviewImg" />
              )}
            </div>
            <div className="reviewImagesDivHolder3in33">
              {feedReviewSongDetails.images[1] && (
                <img src={feedReviewSongDetails.images[1]} alt="reviewImg" />
              )}
              {feedReviewSongDetails.images[2] && (
                <img src={feedReviewSongDetails.images[2]} alt="reviewImg" />
              )}
              {feedReviewSongDetails.images[2] && (
                <img src={feedReviewSongDetails.images[3]} alt="reviewImg" />
              )}
            </div>
          </div>
        )}

        {/* <div className="postDescription">
          <p>{description}</p>
        </div> */}
        <div className="postDescription">
          <p>
            {showFullDescription ? description : description.split(' ').slice(0, 45).join(' ')}
            {description.split(' ').length > 45 && (
              <span className="showMoreLess">
                {showFullDescription ? <span> ... </span> : <span> ...</span>}
                <a onClick={toggleDescription}>{showFullDescription ? 'Show Less' : 'Show More'}</a>
              </span>
            )}
          </p>
        </div>
        <div className="postedBy">
          <div className="posterSinfos">
            <div className="postByUserImg">
              <img
                src={ProfilePic2}
                alt="pic"
                style={{ objectFit: 'cover', borderRadius: '50%' }}
              />
            </div>
            <div>
              <p className="postedbyUsername" onClick={handleUserNameClicked}>
                {postedByUsername}
              </p>
            </div>
            <div className="checked">
              {feedReviewSongDetails?.user?.is_verified === true && <img src={Checked} alt="pic" />}
            </div>
          </div>
          <div>
            <p className="hoursAgo">{hoursAgo}</p>
          </div>
        </div>
        <div className="dashedDividerOnPost"> </div>

        {feedReviewSongDetails?.liked_by?.length > 0 && (
          <div className="likedBy">
            {feedReviewSongDetails?.liked_by.slice(0, 3).map((item) => (
              <div className="likedByImgWrap">
                <img src={item.avatar ? item.avatar : dummyProfileImage} alt="pic" />
              </div>
            ))}
            {/* <div className="likedByImgWrap">
              <img
                src={feedReviewSongDetails?.liked_by[1]?.avatar || dummyProfileImage}
                alt="pic"
              />
            </div>
            <div className="likedByImgWrap">
              <img
                src={feedReviewSongDetails?.liked_by[2]?.avatar || dummyProfileImage}
                alt="pic"
              />
            </div> */}
            <p className="likedbyTxt">
              Liked by&nbsp;
              <span className="boldTxt">
                {feedReviewSongDetails?.liked_by[0]?.username ||
                  feedReviewSongDetails?.liked_by[0]?.name ||
                  'username'}
              </span>
              {feedReviewSongDetails?.liked_by.length > 1 && (
                <>
                  <span> and</span>
                  <span className="boldTxt">
                    &nbsp;
                    {feedReviewSongDetails?.liked_by
                      ? feedReviewSongDetails.liked_by.length - 1
                      : ''}
                    &nbsp;others
                  </span>
                </>
              )}
            </p>
          </div>
        )}
        <div className="likeCommentAndShare">
          <div className="likesAndComments">
            <div className="likeCommentImg" id="heartIconForLike" onClick={handleLikeClick}>
              {colorLike ? <img src={IconHeartLike} alt="." /> : <img src={likes} alt="." />}
            </div>
            <div className="likeCommentImg">
              <img src={comments} alt="pic" />
            </div>
          </div>
          <div className="shareIconDiv">
            <img src={share} role="presentation" alt="pic" onClick={() => ShareModal()} />
          </div>
        </div>
        <div>
          <p
            className="previousCommentsTxt"
            onClick={() => Navigate(`/reviews/${data?.review?.id}`)}
          >
            View previous comments
          </p>
        </div>
        {reviewCommentsArray?.slice(-2).map((com) => (
          <PostCommentLike
            key={com.id}
            ProfilePicInComments={ProfilePicInComments}
            com={com}
            Checked={Checked}
            Flag={Flag}
            IconHeartLike={IconHeartLike}
            likes={likes}
            commentsReplyText={commentsReplyText}
          />
        ))}
        <div className="addCommentDivInput">
          <div className="AddCommentProfileImg">
            <img src={userProfileImage || dummyProfileImage} alt="pic" />
          </div>
          <div className="addCommentInput">
            <input
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              name="comment"
              className="addCommentInputField"
              type="text"
              onKeyDown={(e) => {
                handleKeyPress(e, data?.review?.id);
              }}
              placeholder="Add comment..."
            />
          </div>
        </div>
      </div>
    </div>
  );
}
