/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { XYPlot, LineSeries, VerticalGridLines, HorizontalGridLines } from 'react-vis';

function IntensityGraphTwo({ frequency, color1, color2 }) {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  const calculateGraphData = () => {
    const numPoints = Math.floor(frequency) * 25; // Adjust the number of points based on the frequency
    const step = 25 / numPoints;
    const graphData1 = [];
    const graphData2 = [];

    for (let x = -10; x <= 10; x += step) {
      const y1 = Math.sin(1.9 * Math.PI * frequency * x); // First line
      const y2 = Math.cos(1.9 * Math.PI * frequency + 1.3 * x); // Second line

      graphData1.push({ x, y: y1 });
      graphData2.push({ x, y: y2 });
    }

    setData1(graphData1);
    setData2(graphData2);
  };
  useEffect(() => {
    calculateGraphData();
  }, [frequency]);
  console.log(frequency, 'fff');
  return (
    <div>
      <XYPlot width={90} height={15} margin={{ left: 0, right: 0, top: 0, bottom: 0 }}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <LineSeries data={data1} color={color1} style={{ fill: 'none' }} strokeWidth={1.5} />
        <LineSeries data={data2} color={color2} style={{ fill: 'none' }} strokeWidth={1} />
      </XYPlot>
    </div>
  );
}

export default IntensityGraphTwo;
