/* eslint-disable import/prefer-default-export */
export const queryParamsKey = {
  friendProfile: {
    url: '/user/:userId',
    userIdKey: 'userId',
    openTabKey: 'openTab',
    tabIdKey: 'tabId',
    openTabValues: {
      playlist: 'playlist',
    },
  },
  myProfile: {
    url: '/profile',
  },
};

export const localStorageKey = {
  userId: 'musoticID',
};

export const GOOGLE_ANALYTICS_KEY = 'G-HVZJ6R54MG';
export const GOOGLE_ANALYTICS_KEY_TEST = 'G-MYXV21PMS2';
export const chatMessageType = {
  reviews: 'reviews',
  songDetails: 'songDetails',
  albumDetails: 'albumDetails',
};
