import { useState } from 'react';

const usePageLoader = () => {
  const [isPageLoading, setIsPageLoading] = useState(false);

  const startPageLoader = () => {
    setIsPageLoading(true);
  };

  const stopPageLoader = () => {
    setIsPageLoading(false);
  };

  return {
    isPageLoading,
    startPageLoader,
    stopPageLoader,
  };
};

export default usePageLoader;
