/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useLayoutEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify';
import GeolocationDisplay from '../utils/GeoLocation';
import api from '../api';
import OpenSetting from '../assets/Images/OpenSetting.svg';
import LocationIMG from '../assets/Images/location.svg';
import EmailIMG from '../assets/Images/email.svg';
import SMSIMG from '../assets/Images/sms.svg';
import ArrowBackk from '../assets/Images/oppositeArrow.svg';
import dummyImg from '../assets/Images/dummyProfileImg.jpg';
import RightTop from '../assets/Images/RightTop.svg';
import { analyticsPages, errorLogCountAnalyticsEvent } from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';

function Settings() {
  const [option, setOption] = useState('Notifications');
  const allOptions = [
    'Notifications',
    'Privacy',
    'Terms of Use',
    'Account',
    'Activity',
    'Invite Friends',
    'About',
  ];
  const [blockedArray, setBlockedArray] = useState();
  const [userActivity, setUserActivity] = useState();
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [notificationOptions, setNotificationOptions] = useState({});
  const url = window.location.host;
  const completeUrl = `${url}/signup`;
  const message = `Hey! checkout this platform ${completeUrl}`;

  const fetchUserActivity = async () => {
    try {
      const response = await api('get', '/users/login-activities/');
      console.log('xActaa', response?.data?.results?.length);
      setUserActivity(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching initial state:', err);
    }
  };
  const handleRemoveActivity = async (activityId) => {
    try {
      const response = await api('delete', `/users/login-activities/?activity_id=${activityId}`);
      console.log('xdfdActaa', response?.data);
      fetchUserActivity();
      toast.success(response?.data);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching initial state:', err);
    }
  };
  const handleWhatsAppShare = () => {
    const whatsAppurl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsAppurl, '_blank');
  };
  const handleEmailShare = () => {
    const subject = encodeURIComponent('Inviting you to Musotic');
    const body = encodeURIComponent(message);
    const emailUrl = `mailto:?subject=${subject}&body=${body}`;
    window.open(emailUrl, '_blank');
  };

  const handleSMSclick = async () => {
    await navigator.clipboard.writeText(completeUrl);
    toast.info(`link copied to clipboard: ${completeUrl}`);
  };

  const handleToggleChange = (opt) => async (event) => {
    setNotificationOptions((prevOptions) => ({
      ...prevOptions,
      [opt]: event.target.checked,
    }));

    const requestBody = {
      ...notificationOptions,
      [opt]: event.target.checked,
    };

    try {
      const response = await api('put', '/users/notification-settings/', requestBody);
      console.log('notiii', response);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error updating notifications', err);
    }
  };

  const handleResetClick = async () => {
    if (!oldPassword) {
      toast.error('Old password required');
    } else if (!newPassword) {
      toast.error('New password required');
    } else if (confirmPass !== newPassword) {
      toast.error('password and confirm password not same');
    } else {
      const data = {
        old_password: oldPassword,
        new_password: newPassword,
      };
      try {
        const res = await api('post', '/change-password/', data);
        console.log(res);
        setOldPassword('');
        setNewPassword('');
        setConfirmPass('');
        toast.success('Password Changed');
      } catch (err) {
        errorLogCountAnalyticsEvent();
        console.log('error', err?.response?.data?.error_message);
        console.log('passsErr', err);
        toast.error(err?.response?.data?.error_message);
      }
    }
  };
  const handleLogoutClick = async () => {
    const token = localStorage.getItem('musoticRefreshToken');
    const body = { refresh_token: token };
    await api('get', '/rest-auth/logout/', body)
      .then((res) => {
        console.log(res);
        toast.success('You are logged out!');
        // localStorage.removeItem('hasSeenModal');
        // localStorage.removeItem('musoticID');
        // localStorage.removeItem('idToken');
        // localStorage.removeItem('musoticRefreshToken');
        // localStorage.removeItem('profileBioTxt');
        // localStorage.removeItem('idScore');
        // localStorage.removeItem('profileUsername');
        // localStorage.removeItem('profilePicLink');
        // localStorage.removeItem('userID');
        // localStorage.removeItem('spotifyAccessToken');
        // localStorage.removeItem('refresh_token');
        // localStorage.removeItem('SpotifyTokenExpireTime');
        localStorage.clear();
        window.location = '/login';
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log('logout error =>', err?.response?.message);
      });
  };
  const fetchBlockedUsers = async () => {
    try {
      const response = await api('get', '/users/blocked/');
      setBlockedArray(response?.data?.results);
      console.log('blockii', blockedArray);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching blocked users:', err);
    }
  };
  const handleUnblockUser = async (userId) => {
    try {
      const res = await api('get', `/users/${userId}/block-unblock/`);
      console.log(res);
      fetchBlockedUsers();
      toast.success('user unblocked');
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('error', err?.response?.data);
    }
  };
  const handleClick = (name) => {
    setOption(name);
    if (name === 'Account') {
      fetchBlockedUsers();
    }
    if (name === 'Activity') {
      fetchUserActivity();
    }
  };
  useLayoutEffect(() => {
    const fetchInitialState = async () => {
      try {
        const response = await api('get', '/users/notification-settings/');
        console.log('xxsssaaa', response);
        setNotificationOptions(response?.data);
      } catch (err) {
        errorLogCountAnalyticsEvent();
        console.log('Error fetching initial state:', err);
      }
    };
    fetchInitialState();
  }, []);

  usePageViewAnalyticsHook({
    title: analyticsPages.settings,
  });

  return (
    <div className="setting_container">
      <div className="leftbottomColor leftbottomColor_setting_top">
        <img src={RightTop} alt="x" />
      </div>
      <div className="setting_contain">
        <div className="handleDiv">
          <div className="leftDiv">
            <div className="setting">Settings</div>
            <div className="mainSideMenu">
              {allOptions?.map((item) => {
                return (
                  <div
                    onClick={() => handleClick(item)}
                    className={option === item ? 'sidemenuSelected' : 'sidemenu'}
                  >
                    <span>{item}</span>
                    <img src={option === item ? OpenSetting : ArrowBackk} alt="" />
                  </div>
                );
              })}
            </div>
            <div className="logout" onClick={handleLogoutClick}>
              Log Out
            </div>
          </div>

          <div className="rightDiv">
            {option === 'Privacy' && (
              <>
                <div className="InnerDiv">Privacy</div>

                <div className="sidemenu2">
                  <p className="paragraphText" id="specifyHeight">
                    <span className="boldTxt"> I. Scope of this Privacy Policy</span> <br /> We
                    believe in transparency, and we’re committed to being upfront about our privacy
                    practices, including how we treat your personal information. This Privacy Policy
                    describes the types of information we collect through our application,
                    https://musotic.com and services (collectively, the “App”), and how we collect,
                    use, and share that information. This Privacy Policy does not govern our
                    collection of information through any other web site or other means, other than
                    through the App. Our processing of personal information, such as your name,
                    address, e -mail address, or telephone number, shall be undertaken consistent
                    with the requirements o f applicable privacy laws, including, but not limited
                    to, the California Consumer Privacy Act (“CCPA”) and the General Data Protection
                    Regulation (“GDPR”). The purpose of this Privacy Policy is to provide to users
                    and potential users of our App information about the nature, scope, and purpose
                    of the personal information we collect, use and process and to advise data
                    subjects of their rights. Whether we serve as the data controller or processor,
                    we have implemented numerous technical and organizational measu res to ensure
                    the protection of personal information processed through the App. However,
                    Internet-based data transmissions may in principle have security gaps, so please
                    understand that absolute protection is not assured. By using the App, you accept
                    and expressly agree to our practices surrounding the collection, use, and
                    sharing of personal information (referring to any information that relates to an
                    identified or identifiable individual) and non-personal information (referring
                    to information that does not identify you as a specific individual). provided by
                    you in the manner described in this Privacy Policy. If you do not agree with the
                    terms of this Privacy Policy, you cannot, and we do not authorize you to,
                    access, browse, or use the App. <br />
                    <br />
                    <span className="boldTxt">II. Personal Information</span> <br /> A. Personal
                    Information You Give Us Except for physical location information and tracking
                    technologies (as described below), you do not have to give us any personal
                    information to browse this App. However, you may be asked to provide personal
                    information to submit or request information from us, or to use the services
                    offered through this App. Once you provide us with your personal information,
                    you are no longer anonymous to us. This information may include: Registration
                    and Account Setup Information: In order to use certain aspects of the App, you
                    will need to register for an account and submit a username and valid email
                    address. You may also provide your name and other personal information (such as
                    birthday, gender, location) in connection with your account and activity. You
                    can edit or remove this information through your account settings. The username
                    associated with your account (which you may modify in your account settings) is
                    publicly displayed and connected to your App activity. Other members may see the
                    date you joined; ratings, reviews and information relating to items you review,
                    and related photos you post. Communications: When you sign up to receive
                    communications and marketing materials from the App you are also required to
                    provide personal information such as your name and email address. Additional
                    Information: In addition, you may be asked to provide optional personal
                    information during registration to enhance the App experience. Additional
                    personal information that you may provide to us includes personal information
                    provided through feedback, pictures you upload, comments, ratings, reviews,
                    messages, emails, posts to public discussion boards, event registrations, and/or
                    answers to surveys or questionnaires that you may submit. Payment Information:
                    If you choose to purchase any of our products or services, you will be required
                    to provide personal information such as your name, email address, address, phone
                    number, and payment information.
                    <br />
                    <br />
                    <span className="boldTxt">B. Personal Information We Collect</span>
                    <br />
                    Location Information. In order to access and use certain areas or features of
                    the App, you consent to our collection and use of your personal information
                    about your physical location if you use the App on a device that has location
                    enabled (including GPS, cellular, and Wi-Fi networks) or from your browser.
                    Automated Information Collection. In order to access and use certain areas or
                    features of the App, you consent to our collection and use of certain non
                    -personal information about your use of the App through the use of tracking
                    technologies or by other passive means. Your consent to our access and use of
                    this “passively collected” personal information includes, but is not limited to,
                    the domain name of the website that allowed you to navigate to the App, search
                    engines used, the internet protocol (IP) address used, the length of time spent
                    on the App, the pages you looked at on the App, other webpages you visited
                    before and after visiting the App, the type of internet browser you have, the
                    frequency of your visits to the App, and other relevant statistics, including
                    the following: Log Information: When you access the App, you also consent to our
                    servers automatically recording information that your browser sends whenever you
                    visit a website. These server logs may include information such as your web
                    request, IP address, browser type, browser language, the date and time of your
                    request, your computer operating system, mobile device and mobile operating
                    system, mobile device identifier (i.e., IDFA or Google ID), name of your
                    internet service provider or your mobile carrier, and one or more cookies (small
                    text files containin g a string of characters) that may uniquely identify your
                    browser. Our servers also automatically record what you click on while visiting
                    the App. Location Information: You consent to our receipt of location data
                    passed to us from location - enabled devices or that you have enabled, from your
                    browser, or from third -party services. Links: The App may include links in a
                    format that enables us to keep track of the IP addresses from which these links
                    have been followed. You further consent to our collection and u se this
                    information to improve the quality of our App, product(s) and our business
                    operations. URLs: When you visit or access the App, you consent to our receipt
                    of the URL of the App from which you came and links you click on to leave the
                    App. Additionally, advertisers receive the URL of the page you were on when you
                    click on an ad on the App. Cookies: When you visit or access the App, you
                    consent to our use of one or more cookies (small text files containing a string
                    of characters) to your computer that u niquely identifies your browser. We use
                    cookies to improve the quality of the App by storing user preferences, tracking
                    user trends, and providing personalized content and ads. Most web browsers
                    accept cookies automatically, but can be configured not to do so or to notify
                    the user when a cookie is being sent. If you wish to disable cookies, refer to
                    your browser help menu to learn how to disable cookies. Please note that if you
                    disable cookies, you may not be able to use some customized features available t
                    hrough the App. For more information, including an opt-out, please visit our
                    Cookie Policy. Web Beacons: Our App may contain electronic images known as web
                    beacons (also called single - pixel gifs and transparent graphic images) that we
                    use to help deliver c ookies on our Apps, count users who have visited those
                    Apps, deliver services, and analyze the effectiveness of our promotional
                    campaigns, for example. We may also include web beacons in our marketing email
                    messages or newsletters to determine whether an email is opened or if links are
                    clicked. You consent to our use of web beacons to facilitate App administration
                    and navigation, to track the actions of users of the App, to compile aggregate
                    statistics about App usage and response rates, and to provide an enhanced online
                    experience for visitors to the App. Aggregate Information: We may compile and
                    use certain personal information collected through the App on an aggregate
                    basis. However, such aggregate information does not identify you individually.
                    <br />
                    <br />
                    <span className="boldTxt"> C. How We Use Personal Information</span>
                    <br />
                    We comply with our obligations under applicable privacy laws by: keeping
                    personal information up to date where needed based on the purposes for which the
                    personal information is being processed; by not collecting or retaining
                    excessive amounts of data; by ensuring that appropriate technical measures are
                    in place that are designed to protect personal information from loss,
                    alteration, misuse, unauthorized access and disclosure as it is transmitted,
                    stored, or otherwise p rocessed, and by using appropriate measures to securely
                    destroy personal information when it is no longer needed. Personal information
                    collected through the App may be used by us and our affiliates for purposes of:
                    o Responding to your questions and feedback; o Providing the services you select
                    through this App; o Tailoring and delivering targeted advertisements to you; o
                    Contacting you, whether by email, postal mail, or telephone with information
                    about this App, our products, or our services; o Auditing, research, and
                    analysis to maintain, protect, and improve this App and our services; o Ensuring
                    the technical functions of our network; o Improving and customizing the content
                    and layout of the App; o Developing new products and services; o Compiling
                    personal information and other information collected through the App on an
                    aggregate basis; and o For such purposes as you may authorize at the time you
                    submit the information. Although the sections above describe our primary purpose
                    in collecting your personal information, in many situations we have more than
                    one purpose. For example, if you complete an online purchase we may collect your
                    personal information to perform our contract with you, but we also collect your
                    personal information as we have a legitimate interest in maintaining your
                    information after your transaction is complete so that we can quickly and easily
                    respond to any questions about your order. As a result (and as permitted by
                    applicable law), our collection and processing of your p ersonal information is
                    based in different contexts upon your consent, our need to perform a contract,
                    our obligations under law, and/or our legitimate interest in conducting our
                    business.
                    <br />
                    <br />
                    <span className="boldTxt"> D. Personal Information We Share</span>
                    <br />
                    We may share your personal information collected through the App for any lawful
                    purpose, including in the following ways: In Connection with our Offerings. The
                    App involves the sharing of certain personal information collected through the
                    App with (i) other users of the App, and (ii) as you otherwise provide your
                    consent. Information which you upload, post, e-mail, submit or otherwise
                    transmit in connection with the App to a public message board, chat area or
                    other public area of the App will be made public to users of the App, including
                    certain metadata related to such activities (such as timestamps). You are solely
                    responsible for any such personal information you choose to post on or through
                    the App. All information that is posted to a message board, chat area, or other
                    publ ic area of the App may be shared by us with (i) other users of the App, and
                    (ii) as you otherwise provide your consent. Please be aware that anything you
                    share publicly may be further shared by other users of the App (for example, by
                    emailing a screenshot of your post to non-users). We are not responsible for any
                    such sharing of information you have shared publicly on the App. Subsidiaries
                    and Affiliates. We may share personal information with our subsidiaries and
                    affiliates for the purposes for which you provided the information or as
                    reasonably necessary for our internal administrative and business purposes.
                    Service Providers. We work with third parties that provide services on our
                    behalf. Among other things, business partners may help us to administer and host
                    our App, assist or support our business operations, conduct surveys, provide
                    technical support, conduct research and analysis, provide marketing services,
                    process payments, and assist in the fulfillment of orders. We may share personal
                    information and non-personal with these service providers, vendors, sales
                    brokers, and retail partners (and their service providers) for the purpose of
                    enabling them to provide these services. Partner Promotion. We may offer
                    contests, sweepstakes, or other promotions with third party partners. If you
                    decide to enter a contest, sweepstakes, or promotion that is sponsored by a
                    third party partner, the personal information that you provide will be disclosed
                    to such third party partners, and their use of your personal information is not
                    governed by this Privacy Policy. Marketing. We may disclose, sell, rent, loan,
                    transfer, and/or trade your personal information to unaffiliated third parties
                    for any of the purposes specified in this Privacy Policy, including for such
                    third parties to use your personal information for their own marketing purposes,
                    to send marketing solicitations to offer products or services, and to use the
                    personal information for data validation, data enhancement, data information
                    authentication/verification, and/or data suppression services. For example, we
                    may sell personal information to third party advertising service providers to
                    help facilitate their data marketplace offerings with data buyers, and to third
                    party data analytics service providers to help facilitate their data matching
                    offerings with data authentication customers. Required by Law. We may disclose
                    personal information or any information collected through this App if we are
                    required to do so by law or pursuant to legal process, in response to a request
                    from government officials or law enforcement authorities, or as necessary or
                    appropriate in connection with an investigation of illegal activity. Certain
                    Transactions. We may disclose or transfer personal information or any
                    information collected through this App to third parties who acquire all or a
                    portion of our business, whether such acquisition is by way of merger,
                    consolidation, or purchase of all or a portion of our assets, or in connection
                    with any bankruptcy or reorganization proceeding b rought by or against us.
                    Interest-Based Advertising. We participate in Interest-based advertising. This
                    means that you may see advertising on the App tailored to how you engage or
                    browse, or you may see advertising for us on other websites, applications and
                    social media based on your behavior across websites, applications and social
                    media. We may target ads based on data we have collected, or data provided by
                    third parties alone or in combination with the data we collect ourselves. If you
                    choose to interact with specific third parties who advertise through the App,
                    the information you provide to them is subject to the conditions of their
                    specific privacy policies. By using the App you agree to usage tracking and
                    targeted interest-based advertising. If you do not wish to have cookies track
                    your usage for these purposes, please visit each website’s respective Cookie
                    Policy page for information regarding how to refuse all or some browser cookies,
                    or do not use the App. Consent. We may share personal information in accordance
                    with any consent you provide.
                    <br />
                    <br />
                    <span className="boldTxt"> III. Non-Personal Information</span>
                    <br />
                    We may compile non-personal information and de-identify and use personal
                    information collected through the App on an aggregate basis. This information
                    may include, withou t limitation, the number of users who have registered for
                    the App and demographic information about users and usage of the App. Such
                    aggregate non-personal information does not identify you individually. We may
                    sell, rent, loan, transfer, and/or trade such non-personal information to third
                    parties for any of the purposes specified in this Privacy Policy, and for any
                    other lawful purpose. For example, we may sell non-personal information to third
                    party advertising service providers to help facilitate their data marketplace
                    offerings with data buyers. We may also provide our third party customers in the
                    music industry with non-personal information that they use for their own
                    measurement, communications and marketing purposes.
                    <br />
                    <br />
                    <span className="boldTxt"> IV. Your Choices</span>
                    <br />
                    A. Information You Provide You can always choose whether or not to provide
                    personal information on the App. However, if you choose not to disclose certain
                    personal information, you may not be able to register as a user of the App,
                    which may limit your access to certain portions of the App. However, we will not
                    discriminate against you for exercising any of your rights, including otherwise
                    denying you goods or services, providing you with a different level or quality
                    of services, or charging you different prices or rates for services. B. Privacy
                    Settings By default, certain types of information you choose to submit will be
                    made public to users of the App. This includes your account username, profile
                    picture, and any comments or posts you make to public posts or discussion
                    boards. You can control whether other profile and activity information as
                    described in Section II will be shared publicly with all users of the App, or
                    limited to users that you have friended. C. Communications From Us If at any
                    time you decide that you no longer wish to receive notices from us regarding the
                    App, you may indicate this preference by contacting us at privacy@musotic.com.
                    D. Do Not Track As described in more in our Cookie Policy located at each web
                    site’s respective Cookie Policy page, you can configure your browser not to
                    accept cookies or to notify you when a cookie is being sent. E. Your Rights and
                    Your Personal Data under the GDPR Unless subject to an exemption, if your
                    personal data (as defined under the GDPR as any information which are related to
                    an identified or identifiable natural person) is subject to the GDPR, you have
                    the following rights with respect to your personal data: o The right to request
                    a copy of your personal data which we hold about you; o The right to request
                    that we correct any personal data if it is found to be inaccurate or out of
                    date; o The right to request your personal data be erased where it is no longer
                    necessary for us to retain such data; o The right to withdraw your consent at
                    any time to the processing of personal data to which you provided consent for
                    processing; o The right to request that we provide you with a copy of your
                    personal data and where possible, to transmit that data directly to another data
                    controller (known as the right to data portability); o The right, where there is
                    a dispute in relation to the accuracy or processing of your personal data, to
                    request a restriction be placed on further processing; o The right to object to
                    the processing of personal data (where applicable); and o The right to lodge a
                    complaint with a data supervisory authority. Please note that we may verify your
                    identity before we are able to process any of the requests described in this
                    Section, and in our discretion, deny your request if we are unable to verify
                    your identity. As a part of this process, government or other identification may
                    be required. F. Transfer of Data Abroad If your personal data is subject to the
                    GDPR, we will transfer personal data from the European Economic Area (EEA) to a
                    location outside the EEA only when there has been a documented adequacy
                    determination, or where we have confirmed adequate privacy protections. If we
                    transfer personal data to a third party acting as our agent, we will also
                    obligate the third party to have adequate privacy protections in place. We may
                    transfer personal data to and on behalf of clients and third parties with whom
                    we have an existing service agreement or as part of our legal obligations, each
                    of which shall be subject to our policies, and only to the extent necessary for
                    purposes of legitimate interests pursued by the data controller (or by a third
                    party). G. Automated Decision Making Under the GDPR, data subjects have the
                    right not to be subject to a decision based solely on automated processing,
                    including profiling, wh ich produces legal effects concerning him or her, or
                    similarly significantly affects him or her, as long as the decision (1) is not
                    is necessary for entering into, or the performance of, a contract between the
                    data subject and a data controller, or (2) is not authorized by Union or Member
                    State law to which the controller is subject and which also lays down suitable
                    measures to safeguard the data subject’s rights and freedoms and legitimate
                    interests, or (3) is not based on the data subject’s explicit consent. We do not
                    engage in automated decision making. H. Further processing If we wish to use
                    your personal data for a new purpose, not covered by this Privacy Policy, then
                    we will provide you with a new notice explaining this new use prior to
                    commencing the processing and setting out the relevant purposes and processing
                    conditions. Where and whenever necessary, we will seek your prior consent to the
                    new processing. I. Data Controller If your personal data is subject to the GDPR,
                    the data controller for the pu rposes of GDPR or other data protection laws
                    applicable in Member states of the European Union and other provisions related
                    to data protection is: Musotic, LLC. Attn: privacy@musotic.com Any data subject
                    may, at any time, contact us directly with any quest ions and suggestions
                    concerning data protection. We encourage interested persons to raise any
                    concerns about the collection, use, or processing of your personal data using
                    the contact information provided above. In the event of a privacy related issue
                    or complaint, we will investigate and attempt to promptly resolve any complaints
                    and disputes regarding use and disclosure of personal data. For complaints that
                    cannot be resolved, if your personal data is subject to the GDPR, we commit to
                    cooperating with the panel established by the EU data protection authorities
                    (DPAs) or the Swiss Federal Data Protection and Information Commissioner
                    (FDPIC), as applicable, and comply with the advice given by the DPAs or FDPIC
                    about personal data transferred from the EU or S witzerland. In order to
                    facilitate the handling of complaints, individuals in the EU can choose to
                    contact their national DPA or use the form located at this link:
                    http://ec.europa.eu/newsroom/document.cfm?doc_id=42962 Individuals in
                    Switzerland can contact the Swiss FDPIC by visiting:
                    https://www.edoeb.admin.ch/edoeb/de/home.html This independent dispute
                    resolution process is provided at no cost to the individual. V. Information
                    Storage and Security We employ reasonable security precautions to help protect
                    against the loss, misuse, and alteration of personal information provided on or
                    through the App. These security measures include: storing passwords in hashed
                    form, anticross-site-request forgery measures, SSL encryption of CMS
                    communications, and a monitoring system to actively screen for unwanted
                    behaviors. However, no method of transmitting or storing data is completely
                    secure. As a result, although we strive to protect personal information about
                    you, we cannot guarantee the security of any information y ou transmit to us
                    through or in connection with the App. If you have reason to believe that
                    personal information is no longer secure, please notify us immediately by
                    contacting us in accordance with the last section below. VI. Special Notes about
                    Children and Certain Privacy Rights Individuals under the age of 18 may not use
                    the App or not submit any personal information to us. If you are a minor, you
                    can use the App only in conjunction with your parents or guardians. If you are a
                    California resident, the CCPA provides you with certain rights over your
                    personal information (defined under the CCPA as information which identifies,
                    relates to, describes, is reasonably capable of being associated with, or could
                    reasonably b e linked, directly or indirectly, with a particular consumer or
                    household). In addition, the term “sale” is defined broadly under the CCPA. To
                    the extent that “sale” under the CCPA is interpreted to include interest based
                    advertising or other data uses described in this Privacy Policy, we will comply
                    with applicable law as to those activities. You can read more about your rights
                    and choices with respect to your personal information that is held by us under
                    the CCPA at each website’s respective CCPA Notice page. If you are a Nevada
                    resident, you have the right to opt-out of the sale of your personal information
                    collected by us through the App. VII. External Links The App may contain links
                    to various websites that we do not control. When you click on one of these
                    links, you will no longer be transacting business through the App. Third party
                    websites maintain their own privacy policies, and we do not exercise any control
                    over any of the third party websites that may be linked to the App. If you visit
                    a website that is linked to the App, you should consult that website’s privacy
                    policy before providing any personal information. Please be aware that we are
                    not responsible for the privacy practices of such other websites, and we are not
                    liable for their misuse of personal information about you. VIII. Special
                    Admonitions for International Use The App is hosted in the United States. All
                    matters relating to the App are governed exclusively by the laws of the State of
                    Delaware in the United States of America and not the jurisdiction in which you
                    are located. If you are located outside of the United States of America and you
                    contact us, please be advised that any information you provide to us will be
                    transferred to the United States of America and that by submitting information,
                    you explicitly authorize such transfer. IX. Updates to this Privacy Policy We
                    may change or update the App or any of our policies and procedures without prior
                    notice, except that if any changes are likely to have an adverse impact on your
                    rights und er data protection law, we will use reasonable efforts to notify you
                    of the changes in advance in writing or by post mail, and, where required,
                    obtain your consent to our activities. We will post a notice on this App to
                    advise you of any significant changes to this Privacy Policy and indicate via
                    the “Last Updated” legend in this Privacy Policy when it was most recently
                    updated. Except to the extent that your express consent to any change or update
                    is required under data protection law, your continued use o f the App signifies
                    your continued assent to the terms of this Privacy Policy, as updated or amended
                    at that time. X. Questions Regarding this Privacy Policy If you have any
                    questions or comments regarding this Privacy Policy, please send us an email at
                    <span className="boldTxt"> privacy@musotic.com</span>
                    <br />
                  </p>
                </div>
              </>
            )}

            {option === 'Notifications' && (
              <>
                <div className="InnerDiv">Notifications</div>

                <div className="sidemenu2">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={notificationOptions?.pause_all}
                          onChange={handleToggleChange('pause_all')}
                        />
                      }
                      label="Pause All"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={notificationOptions?.likes}
                          onChange={handleToggleChange('likes')}
                        />
                      }
                      label="Likes"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={notificationOptions?.comments}
                          onChange={handleToggleChange('comments')}
                        />
                      }
                      label="Comments"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={notificationOptions?.new_followers}
                          onChange={handleToggleChange('new_followers')}
                        />
                      }
                      label="New Followers"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={notificationOptions?.account_suggestions}
                          onChange={handleToggleChange('account_suggestions')}
                        />
                      }
                      label="Account Suggestions"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={notificationOptions?.new_messages}
                          onChange={handleToggleChange('new_messages')}
                        />
                      }
                      label="New Messages"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={notificationOptions?.reminders}
                          onChange={handleToggleChange('reminders')}
                        />
                      }
                      label="Reminders"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={notificationOptions?.ads}
                          onChange={handleToggleChange('ads')}
                        />
                      }
                      label="Ads"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={notificationOptions?.feedback_emails}
                          onChange={handleToggleChange('feedback_emails')}
                        />
                      }
                      label="Feedback Emails"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={notificationOptions?.reminder_emails}
                          onChange={handleToggleChange('reminder_emails')}
                        />
                      }
                      label="Reminder Emails"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={notificationOptions?.support_emails}
                          onChange={handleToggleChange('support_emails')}
                        />
                      }
                      label="Support Emails"
                    />
                  </FormGroup>
                </div>
              </>
            )}

            {option === 'Terms of Use' && (
              <>
                <div className="InnerDiv">Terms of Use</div>
                <div className="sidemenu2">
                  <p className="paragraphText" id="specifyHeight">
                    <span className="boldTxt"> 1. Acceptance of Terms</span>
                    <br />
                    Welcome to Musotic (“Musotic,” “we” or “us”) and our Website Terms of Use (these
                    “Terms”). These Terms are important and affect your legal rights, so please read
                    them carefully. Note that Section 18 of these Terms contains a mandatory
                    arbitration provision that requires the use of arbitration on an individual
                    basis and limits the remedies available to you in the event of certain disputes.
                    By accessing or using https://www.musotic.com or the Musotic mobile applications
                    and various related services (collectively, the “Site”), you agree to be bound
                    by these Terms and all of the terms incorporated herein by reference. After
                    reviewing these Terms, please indicate your acceptance by providing information
                    regarding yourself, checking the box, and clicking the “I Agree” button, all at
                    the bottom of these Terms. By accepting these Terms, you represent that you are
                    18 years of age or older and that, if you have accepted these Terms on behalf of
                    any person or entity, you represent that you have legal authority to do so and
                    that such person or entity agrees to be responsible to us if you or such person
                    or entity violates these Terms. If you do not agree to every provision of these
                    Terms, you may not, and we do not authorize you to, access or use the Site or
                    any features provided on the Site. By accessing or using the Site, you represent
                    and warrant that you have not been previously suspended or removed from the
                    Site, or engaged in any activity that could result in suspension or removal from
                    the Site. These Terms may be revised at any time for any reason, and Musotic may
                    provide you notice of these changes by any reasonable means, including by
                    providing notice through the Site. You can determine when we last updated the
                    Site by referring to the “Last Updated” legend at the top of these Terms. Except
                    to the extent that your express consent to any revised Terms is required under
                    data protection law, by continuing to access, browse or use the Site, you
                    confirm your acceptance of the revised Terms and all the terms incorporated
                    herein by reference. We strongly recommend that you periodically visit this page
                    of the Site to review these Terms. I f you do not agree to the revised Terms,
                    you may not access or use the Site. 2. Registration and Personal Data In order
                    to access and use certain areas or features of the Site, you will need to
                    register for an account. Each registration is for a single user only. By
                    creating an account, you agree to (a) provide accurate, current and complete
                    account information, (b) maintain and promptly update, as necessary, your
                    account information, (c) maintain the security of your account credentials, (d)
                    be responsible for the acts or omissions of any third party who has authority to
                    access or use the Site on your behalf, and (e) immediat ely notify us if you
                    discover or otherwise suspect any security breaches related to the Site or your
                    account. Any changes to your registration information may be made by sending
                    such changes to support@musotic.com. If you provide information that is untrue,
                    inaccurate, not current or incomplete, we may suspend or terminate your account
                    and refuse any and all current or future use of the Site. By creating an
                    account, you also consent to receive electronic communications from Musotic
                    (e.g., via email or by posting notices to the Site). These communications may
                    include notices about your account (e.g., payment authorizations, password
                    changes and other transactional information) and are part of your relationship
                    with us. You agree that any notices, agreements, disclosures or other
                    communications that we send to you electronically will satisfy any legal
                    communication requirements, including, but not limited to, that such
                    communications be in writing. You should maintain copies of electronic
                    communications from us by printing a paper copy or saving an electronic copy. We
                    may also send you promotional communications via email, including, but not
                    limited to, newsletters, special offers, surveys and other news and information
                    we think will be of interest to you. You may opt out of receiving these
                    promotional emails at any time by following the unsubscribe instructions
                    provided therein.
                  </p>
                </div>
              </>
            )}

            {option === 'Account' && (
              <>
                <div className="InnerDiv">Account</div>
                <div className="sidemenu2">
                  <div className="fieldsContainer" id="fieldsContainer">
                    <div className="reset_pass">Reset Password</div>
                    <div className="inputFields">
                      <p className="lable">Old Password</p>
                      <div className="cont">
                        <input
                          className="input_field2"
                          type="password"
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="inputFields">
                      <p className="lable">New Password</p>
                      <div className="cont">
                        <input
                          className="input_field2"
                          type="password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="inputFields">
                      <p className="lable">Confirm New Password</p>
                      <div className="cont">
                        <input
                          className="input_field2"
                          type="password"
                          value={confirmPass}
                          onChange={(e) => setConfirmPass(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="OkayButton"
                        id="settingsOK"
                        onClick={handleResetClick}
                      >
                        Reset
                      </button>
                    </div>
                    <div className="blocked_User">Blocked Users</div>
                    <div className="blockedUsersContainer">
                      {blockedArray?.length === 0 ? (
                        <p className="noBlockedUserTxt">No blocked users</p>
                      ) : (
                        blockedArray?.map((user) => (
                          <div className="Locations_container" key={user?.id}>
                            <div className="sideImgs">
                              <img src={user?.avatar || dummyImg} alt="" />
                            </div>
                            <div style={{ marginLeft: '10px' }} className="main-user2">
                              <p className="username">{user?.name || user?.username}</p>
                            </div>
                            <div className="btnContainer">
                              <button
                                type="submit"
                                className="block_btn"
                                onClick={() => handleUnblockUser(user?.id)}
                              >
                                Unblock
                              </button>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {option === 'Activity' && (
              <>
                <div className="InnerDiv">Activity</div>
                <div className="sidemenu2">
                  <div className="location">
                    <div className="logged_in">Where You&apos;re Logged In </div>
                    <div className="ActivityDetialsWrap">
                      {userActivity?.length === 0 ? (
                        <p className="noBlockedUserTxt">No Data found</p>
                      ) : (
                        userActivity?.map((DataActivity, index) => (
                          <div className="Location_container" key={index?.id}>
                            <div className="LocationImg">
                              <img src={LocationIMG} alt="" />
                            </div>
                            <div className="locationName">
                              <p className="username">
                                <GeolocationDisplay ipAddress={DataActivity?.location} />
                              </p>

                              <p className="active">
                                Active Now <span>{DataActivity?.user_agent_info}</span>
                              </p>
                            </div>
                            <div
                              className="Unblockbtn"
                              onClick={() => handleRemoveActivity(DataActivity?.id)}
                            >
                              <button type="submit" className="block_btn">
                                Log Out
                              </button>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {option === 'Invite Friends' && (
              <div className="sidemenu2">
                <div className="invite">
                  <div className="logged">Invite Friends</div>
                  <div className="Locations_container">
                    <div className="LocationImg">
                      <img src={SMSIMG} alt="" />
                    </div>
                    <div className="locationNames" onClick={handleWhatsAppShare}>
                      <p className="descript">Invite By Whatsapp</p>
                    </div>
                  </div>

                  <div className="Locations_container">
                    <div className="LocationImg">
                      <img src={SMSIMG} alt="" />
                    </div>
                    <div className="locationNames" onClick={handleSMSclick}>
                      <p className="descript">Invite By SMS</p>
                    </div>
                  </div>
                  <div className="Locations_container">
                    <div className="LocationImg">
                      <img src={EmailIMG} alt="" />
                    </div>
                    <div className="locationNames" onClick={handleEmailShare}>
                      <p className="descript">Invite By Email</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {option === 'About' && (
              <div className="sidemenu2">
                <div className="invite" id="paddingBottom">
                  <div className="About">About</div>
                  <div className="head">Your home for music reviews, done socially</div>
                  <p className="paragraph">
                    Musotic is a brand-new, forward-looking platform dedicated to fostering the
                    conversation around music online. Our platform is designed for users to write
                    reviews on their favorite music and share them with their peers, facilitating a
                    community of passionate music lovers. <br />
                    Through our built-in reviewer cockpit, direct messaging, in-depth social
                    profiles, and more, it has never been easier to express your opinions on all of
                    your favorite (or least favorite) music and get it out into the world.
                  </p>
                  <div className="head">Search for anything. Literally, Anything.</div>
                  <p className="paragraph">
                    Our highly intricate machine learning technology allows users to search our
                    database for music based on related keywords, not just song/artist name. This
                    said, you can now, for the first time ever, search for things like
                    &quot;coffee&quot;, and we&apos;ll show you music that we determine sounds like
                    the flavor or setting of coffee.
                  </p>
                  <div className="head">View your in-depth music tastes and summary, anytime.</div>
                  <p className="paragraph">
                    With Musotic&apos;s user reports, you as the user can view your top songs,
                    artists, moods, and more at any given time, completely for free.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="leftbottomColor leftbottomColor_setting">
        <img src={RightTop} alt="x" />
      </div>
    </div>
  );
}

export default Settings;
