/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import AppleLogin from 'react-apple-login';
import { toast } from 'react-toastify';
import usePageLoader from './PageLoader/usePageLoader';
import PageLoader from './PageLoader';
import api from '../api';

function AppleSocialLogin() {
  const [nonce, setNonce] = useState(null);

  const { isPageLoading, startPageLoader, stopPageLoader } = usePageLoader();

  const handleAppleSignUpResponse = (response) => {
    console.log(response);
    try {
      if (response?.authorization?.code && response?.authorization?.id_token) {
        startPageLoader();
        api('post', '/apple/login/', {
          // nonce,
          // code: response.data.authorization.code,
          id_token: response.authorization.id_token,
        })
          .then((res) => {
            console.log(res);
            stopPageLoader();
            if (res?.data?.tokens?.accessToken) {
              toast.success('Login successfully');
              localStorage.setItem('appleSSO', JSON.stringify(true));
              localStorage.setItem('profileUsername', res?.data?.username);
              localStorage.setItem('profilePicLink', res?.data?.profile?.avatar_url);
              localStorage.setItem('profileBioTxt', res?.data?.profile.bio);
              localStorage.setItem('musoticID', res?.data?.profile.id);
              localStorage.setItem('idToken', res?.data?.tokens?.accessToken);
              localStorage.setItem('musoticRefreshToken', res?.data?.tokens?.refreshToken);
              localStorage.setItem('userID', res?.data?.id);
              localStorage.setItem('idScore', res?.data?.profile.score);
              window.location = '/dashboard';
            }
          })
          .catch((err) => {
            console.log(err);
            stopPageLoader();
          });
      }
    } catch (error) {
      console.log(error);
      stopPageLoader();
    }
  };

  useEffect(() => {
    startPageLoader();
    api('get', '/apple/get-new-nonce/', undefined, undefined, true)
      .then((res) => {
        if (res?.data?.payload?.nonce) {
          setNonce(res.data.payload.nonce);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        stopPageLoader();
      });
  }, []);
  return (
    <div>
      <PageLoader isLoading={isPageLoading} />
      {!!nonce && (
        <div className="apple_login_btn_wrapper">
          <AppleLogin
            clientId="com.musotic.app.service"
            redirectURI="https://musotic-37073-staging.botics.co/api/v2/apple/login-callback/"
            responseType="code id_token"
            responseMode="form_post"
            scope="name email"
            usePopup
            callback={handleAppleSignUpResponse}
            nonce={nonce}
          />
        </div>
      )}
    </div>
  );
}

export default AppleSocialLogin;
