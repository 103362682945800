/* eslint-disable react/button-has-type */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import formatTimeAgo from '../utils/services';
import PostCommentReplies from './PostCommentReplies';
import api from '../api';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import dell from '../assets/Images/Dell.svg';
import Report from '../assets/Images/ReportIcon.svg';

import editComm from '../assets/Images/EditComment.svg';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

export default function PostCommentLike({
  com,
  Checked,
  Flag,
  IconHeartLike,
  likes,
  commentsReplyText,
  reviewId,
}) {
  const [commentLike, setCommentLike] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [currentCommentText, setCurrentCommentText] = useState(com?.text);
  const [likeCount, setLikeCount] = useState(com?.like_count);
  const [commentReplies, setCommentReplies] = useState();
  const [comment, setComment] = useState('');
  const inputRef = useRef(null);
  const [replyFocus, setReplyFocus] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportCommentId, setreportCommentId] = useState('');

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const getCommentReplies = () => {
    api('get', `/review-comments/${com?.id}/replies/`)
      .then((res) => {
        console.log(res.data, 'RREE');
        console.log('datteee', com?.created_at);

        setCommentReplies(res?.data?.results);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
      });
  };

  useEffect(() => {
    getCommentReplies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);
  console.log('reviewIdxx', reviewId);
  useEffect(() => {
    if (replyFocus === true) {
      inputRef?.current?.focus();
    }
  }, [replyFocus]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setReplyFocus(false);
        setComment('');
        setEditComment(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (com?.is_liked === true) {
      setCommentLike(true);
    }
    setLikeCount(com?.like_count);
  }, [com]);

  const handleCommentsLiked = async (id) => {
    setCommentLike((prevState) => !prevState);
    setLikeCount((prevCount) => (commentLike ? prevCount - 1 : prevCount + 1));

    try {
      await api('post', `/review-comments/${id}/like/`);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user commentLikes:', err);
    }
  };

  const handleCommentDell = async (id) => {
    try {
      await api('delete', `/review-comments/${id}/`);
      setIsDeleted(true);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetchingLikes:', err);
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setreportCommentId('');
  };
  console.log(reportCommentId, '45678');
  const handleCommentReport = async (id) => {
    if (isModalOpen) {
      const comment = id;
      const body = { comment };
      try {
        await api('post', `/report-comment/`, body);
        toast.success('comment reported');
        setIsModalOpen(false);
        setreportCommentId('');
      } catch (err) {
        errorLogCountAnalyticsEvent();
        console.log('Error fetchingLikes:', err);
      }
    } else {
      setIsModalOpen(true);
      setreportCommentId(id);
    }
  };

  useEffect(() => {
    if (replyFocus || editComment) {
      inputRef?.current?.focus();
    }
  }, [replyFocus, editComment]);
  const handleEditComment = async (id) => {
    console.log('iddd', id);
    setEditComment((prevState) => !prevState);
  };

  const handleUpdateComment = async (id) => {
    console.log('iddd', id);
    try {
      await api('patch', `/review-comments/${id}/`, { text: currentCommentText });
      setEditComment(false);
      toast.success('Comment Edited');
    } catch (error) {
      errorLogCountAnalyticsEvent();
      console.error('Error editing comment:', error);
    }
  };
  const musoticID = localStorage.getItem('userID');
  const navigate = useNavigate();
  const handleUserNameClicked = () => {
    const userId = com?.user?.id;
    console.log('feddId', userId, musoticID);
    console.log('feddIds', com);

    // eslint-disable-next-line eqeqeq
    if (userId == musoticID) {
      navigate(`/profile`);
    } else {
      navigate(`/user/${userId}`);
    }
  };
  console.log(com, 'com');

  console.log(replyFocus, 'replyFocus');

  const handleReplyComment = (obj) => {
    setReplyFocus(true);
    setComment(`@${obj?.user?.name ?? obj?.user?.username}: `);
  };

  const handlePostReplyComment = () => {
    api('post', `/comment-replies/`, {
      text: comment,
      review_comment: com?.id,
    }).then(() => {
      getCommentReplies();
    });
  };

  const handleKeyPress = (event, commentId) => {
    if (event.keyCode === 13) {
      handlePostReplyComment(commentId);
      setComment('');
      setReplyFocus(false);
    }
  };

  if (isDeleted) {
    return null;
  }

  return (
    <>
      <div className="posterSinfos" onClick={handleUserNameClicked}>
        <div className="postByUserImg">
          <img src={com?.user?.avatar || dummyProfileImage} alt="pic" />
        </div>
        <div>
          <p className="postedbyUsername">{com?.user?.name || com?.user?.username}</p>
        </div>

        {com?.user?.is_verified === true && (
          <div className="checked">
            <img src={Checked} alt="pic" />
          </div>
        )}
      </div>
      <div className="commentReplyLikesDiv">
        <div id="CommentsTxtByuserDellToo">
          {editComment ? (
            <input
              ref={inputRef}
              className="editCommentInputField"
              value={currentCommentText}
              onChange={(e) => setCurrentCommentText(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleUpdateComment(com?.id);
                }
              }}
            />
          ) : (
            <p className="CommentsTxtByuser">
              {showFullDescription
                ? currentCommentText
                : currentCommentText.split(' ').slice(0, 25).join(' ')}
              {currentCommentText.split(' ').length > 25 && (
                <span className="showMoreLess">
                  {showFullDescription ? <span> ... </span> : <span> ...</span>}
                  <a onClick={toggleDescription}>
                    {showFullDescription ? 'Show Less' : 'Show More'}
                  </a>
                </span>
              )}
            </p>
          )}

          <div className="deleteAndEditComment">
            {com?.user?.id == musoticID ? (
              <div onClick={() => handleEditComment(com?.id)}>
                <img src={editComm} alt="." />
              </div>
            ) : null}
            {com?.user?.id == musoticID ? (
              <div className="dellCommentHolder" onClick={() => handleCommentDell(com?.id)}>
                <img src={dell} alt="." />
              </div>
            ) : null}
            {com?.user?.id != musoticID ? (
              <div className="dellCommentHolder" onClick={() => handleCommentReport(com?.id)}>
                <img src={Report} alt="." />
              </div>
            ) : null}
          </div>
        </div>

        <div className="comentTimeAndLikes">
          <div className="minorImgsFlag">
            <img src={Flag} alt="pic" />
          </div>
          <p className="likedHours">{formatTimeAgo(com?.created_at)}</p>
          <div
            onKeyDown={() => console.log('nothing')}
            tabIndex={0}
            role="button"
            className="minorImgsDiv"
            onClick={() => handleCommentsLiked(com?.id)}
          >
            {commentLike ? <img src={IconHeartLike} alt="." /> : <img src={likes} alt="." />}
          </div>
          <p className="commentNoOfLikes">
            {likeCount} <span /> {likeCount <= 1 ? 'Like' : 'Likes'}
          </p>
          <p className="commentsReplyTtxt">{commentsReplyText}</p>
          <button type="button" className="reply_btn" onClick={() => handleReplyComment(com)}>
            Reply
          </button>
        </div>
      </div>
      {commentReplies &&
        commentReplies?.slice(-2).map((replies) => <PostCommentReplies com={replies} />)}
      {replyFocus && (
        <div className="repliesToCommentsDic addCommentInput">
          <input
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            name="comment"
            className="addReplyCommentInput"
            type="text"
            onKeyDown={(e) => {
              handleKeyPress(e, com?.id);
            }}
            placeholder="Add reply..."
            ref={inputRef}
          />
        </div>
      )}
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#2D3447',
            width: '400px',
            padding: '20px',
            borderRadius: '8px',
          }}
        >
          <p className="firstPopuptxt">Are you sure you want to report this comment ?</p>
          <div className="firstPopupButtons">
            <button className="firstPopupBtn" onClick={() => handleCommentReport(reportCommentId)}>
              Report
            </button>
            <button className="firstPopupBtn2" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
