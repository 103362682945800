// Init
import React from 'react';
import 'typeface-quicksand';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import GroupSlider1 from '../assets/Images/GroupSlider1.svg';
import GroupSlider2 from '../assets/Images/GroupSlider2.svg';
import GroupSlider3 from '../assets/Images/GroupSlider3.svg';
import RightTop from '../assets/Images/RightTop.svg';
import LeftTop from '../assets/Images/LeftTop.svg';
import RightBottom from '../assets/Images/RightBottom.svg';

export default function CreateAccount() {
  const slides = [
    {
      id: 1,
      image: GroupSlider1,
      heading1: 'Welcome To The',
      heading2: 'Reviewer Cockpit',
      heading3:
        'Musotic is a social platform where you can lorem ipsum dolor sit amet, consectetur adipiscing',
    },
    {
      image: GroupSlider2,
      heading1: 'Share Your Love',
      heading2: 'For Music',
      heading3:
        'Musotic is a social platform where you can lorem ipsum dolor sit amet, consectetur adipiscing',
    },
    {
      image: GroupSlider3,
      heading1: 'A World Of Music Is',
      heading2: 'Waiting For You',
      heading3:
        'Musotic is a social platform where you can lorem ipsum dolor sit amet, consectetur adipiscing',
    },
  ];

  return (
    <div>
      <div className="WrapLeftPart">
        <div className="righttopColor">
          <img src={RightTop} alt="x" />
        </div>
        <div className="lefttopColor">
          <img src={LeftTop} alt="y" />
        </div>
        <div className="rightbottomColor">
          <img src={RightBottom} alt="cc" />
        </div>
        <div className="leftbottomColor">
          <img src={RightTop} alt="x" />
        </div>

        <div className="carouselContainer">
          <Slider
            dots
            infinite
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            autoplay
            autoplaySpeed={5000}
            arrows={false}
          >
            {slides.map((slide, index) => (
              <div className="sliderContainer" key={slide.id}>
                <div className="textOverlay">
                  <p className="txt1">{slide.heading1}</p>
                  <p className="txt2">{slide.heading2}</p>
                  <p className="txt3">{slide.heading3}</p>
                </div>
                <div className="sliderimgWraperrclass">
                  <img
                    src={slide.image}
                    alt={`Slide ${index + 1}`}
                    // style={slide.id === 1 ? { width: '100%' } : {}}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
