/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import Divider from '@material-ui/core/Divider';
import Rating from '@mui/material/Rating';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { formatDistanceToNow } from 'date-fns';
import SearchIcon from '../assets/Images/SearchIcon.svg';
import ProfilePic from '../assets/Images/SugarImg.svg';
import IconComments from '../assets/Images/IconComments.svg';
import sprinkles from '../assets/Images/Sprinkle.svg';
import MusoticSmallLogo from '../assets/Images/musoticsmallLogo.svg';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import api from '../api/index';
import VectorImg2 from '../assets/Images/Sun2Img.svg';
import Checked from '../assets/Images/checkVerified.svg';
import IconHeartLike from '../assets/Images/IconHeartLike.svg';
import commentsImg from '../assets/Images/Comment.svg';
import Flag from '../assets/Images/Flag.svg';
import share from '../assets/Images/share.svg';
import VerifiedTick from '../assets/Images/verified.svg';
import ScoreLevelBarr from './ScoreLevelBar';
import Review1 from '../assets/Images/review1.png';
import Review2 from '../assets/Images/review2.png';
import Review3 from '../assets/Images/review3.png';
import Review4 from '../assets/Images/review4.png';
import profilegirl from '../assets/Images/profilegirl.svg';
import rating from '../assets/Images/rating.svg';
import likes from '../assets/Images/likes.svg';
import likeboy from '../assets/Images/likebacha1.svg';
import likegirl from '../assets/Images/likebachi2.svg';
import likegirl3 from '../assets/Images/likebachi3.svg';
import commentgirl from '../assets/Images/commentgirl.svg';
import replyimg from '../assets/Images/replyfromme.svg';
import { analyticsPages, errorLogCountAnalyticsEvent } from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';

function PreLoginScreen() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };
  const handleSignUpClick = () => {
    navigate('/signup');
  };

  const [reviewPosts, setReviewPosts] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [specificReviews, setSpecificReviews] = useState([]);

  const fetchReviewData = async () => {
    try {
      const response = await api('get', '/reviews/');
      const reviewResults = response?.data?.results;
      setShowLoading(false);
      setReviewPosts(reviewResults);
    } catch (error) {
      errorLogCountAnalyticsEvent();
      console.error(error);
      setShowLoading(false);
    }
  };
  console.log(reviewPosts, 'sasdds');

  useEffect(() => {
    fetchReviewData();
  }, []);
  const fetchCopySearch = async () => {
    try {
      const response = await api('get', `/reviews/?type=song&search=${inputValue}`);
      console.log('mmmm', response?.data?.results);
      setSpecificReviews(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching copy search:', err);
    }
  };

  useEffect(() => {
    if (inputValue) {
      fetchCopySearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);
  const handleSearchSongInputChange = (e) => {
    setInputValue(e.target.value);
  };

  usePageViewAnalyticsHook({
    title: analyticsPages.preloginscreen,
  });

  return (
    <div className="preloginWrappperrr">
      <div className="preLoginScreen_navbar">
        <div className="preLoginScreen_inner_data_navbar">
          <div className="preLoginScreen_navbar_txt">
            <img src={MusoticSmallLogo} alt="Logo" />
            <p className="preLoginScreen_navbar_txt_p">
              A world of music is waiting for you. Create an account and start rating your favorite
              songs today.
            </p>
          </div>
          <div className="preLoginScreen_navbar_btns">
            <button
              className="preLoginScreen_navbar_login_btns"
              type="button"
              onClick={handleLoginClick}
            >
              Login
            </button>
            <button
              className="preLoginScreen_navbar_signin_btns"
              type="button"
              onClick={handleSignUpClick}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
      <div className="Main_PreLogin_Data">
        <div className="imgContainer">
          <div className="left_main_div">
            <div className="left_sub_div">
              <div className="leftinsideTop leftinsideTop_prelogin">
                <p className="mYplaylistTxt">My Playlists</p>
                <p className="seeAllTxt">see all</p>
              </div>
              <div className="leftSide_top_imgs">
                <img src={Review1} alt="" />
                <img src={Review2} alt="" />
                <img src={Review3} alt="" />
                <img src={Review4} alt="" />
                <img src={Review1} alt="" />
                <img src={Review2} alt="" />
              </div>
              <div className="myTopSongsTxt myTopSongsTxt_prelogin">
                <p className="topsongsTxts">My Top Songs</p>
                <p className="createdonTxts">Created on 26 July 2023</p>
                <p className="topSongsDescrib">asdfb</p>
                <div className="dashedDivider"> </div>
                <div className="likeAndCommentsCountDiv">
                  <div className="likesDiv likesDiv_prelogin">
                    <div className="heart_like">
                      <img src={IconHeartLike} alt="." />
                    </div>
                    <p className="commentsTxt">Likes</p>
                  </div>
                  <div className="commentsDiv likesDiv_prelogin">
                    <div>
                      <img src={IconComments} alt="comments" />
                    </div>
                    <p className="commentsTxt">
                      <span /> Comments
                    </p>
                  </div>
                </div>
                <div className="dividerDivs">
                  <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 1)', opacity: '0.2' }} />
                </div>
              </div>
            </div>
            <div className="left_sub_div left_sub_div_2nd">
              <div className="leftSide_top_imgs leftSide_top_imgs_2nd">
                <img src={Review1} alt="" />
                <img src={Review2} alt="" />
                <img src={Review3} alt="" />
                <img src={Review4} alt="" />
                <img src={Review1} alt="" />
                <img src={Review2} alt="" />
              </div>
              <div className="myTopSongsTxt myTopSongsTxt_prelogin">
                <p className="topsongsTxts">My Top Songs</p>
                <p className="createdonTxts">Created on 26 July 2023</p>
                <p className="topSongsDescrib">asdfb</p>
                <div className="dashedDivider"> </div>
                <div className="likeAndCommentsCountDiv">
                  <div className="likesDiv likesDiv_prelogin">
                    <div className="heart_like">
                      <img src={IconHeartLike} alt="." />
                    </div>
                    <p className="commentsTxt">Likes</p>
                  </div>
                  <div className="commentsDiv likesDiv_prelogin">
                    <div>
                      <img src={IconComments} alt="comments" />
                    </div>
                    <p className="commentsTxt">
                      <span /> Comments
                    </p>
                  </div>
                </div>
                <div className="dividerDivs">
                  <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 1)', opacity: '0.2' }} />
                </div>
              </div>
            </div>
            <div className="left_last_card">
              <div className="last_card_heading">
                <h4>Chat</h4>
                <p>See All</p>
              </div>
              <div className="person_info_div">
                <div className="person_info_prelogin">
                  <img src={Review1} alt="" />
                  <div className="person_name_prelogin">
                    John James <img src={Checked} alt="pic" />
                  </div>
                  <div className="msgCounterDiv">
                    <p className="chatMsgCounter">1</p>
                  </div>
                </div>
              </div>
              <div className="person_info_div">
                <div className="person_info_prelogin">
                  <img src={Review2} alt="" />
                  <div className="person_name_prelogin">
                    John Kim <img src={Checked} alt="pic" />
                  </div>
                  <div className="msgCounterDiv">
                    <p className="chatMsgCounter">2</p>
                  </div>
                </div>
              </div>
              <div className="person_info_div">
                <div className="person_info_prelogin">
                  <img src={Review3} alt="" />
                  <div className="person_name_prelogin">
                    Mark Henry <img src={Checked} alt="pic" />
                  </div>
                  <div className="msgCounterDiv">
                    <p className="chatMsgCounter">3</p>
                  </div>
                </div>
              </div>
              <div className="person_info_div">
                <div className="person_info_prelogin">
                  <img src={Review4} alt="" />
                  <div className="person_name_prelogin">
                    Demo Name <img src={Checked} alt="pic" />
                  </div>
                  <div className="msgCounterDiv">
                    <p className="chatMsgCounter">4</p>
                  </div>
                </div>
              </div>
              <div className="person_info_div person_info_div_last">
                <div className="person_info_prelogin">
                  <img src={Review1} alt="" />
                  <div className="person_name_prelogin">
                    Mr. Keounry
                    <img src={Checked} alt="pic" />
                  </div>
                  <div className="msgCounterDiv">
                    <p className="chatMsgCounter">5</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="preLoginScreen">
          <div className="searchIconDashboardRelative">
            <div className="searchIconDashboard">
              <img src={SearchIcon} alt="icon" />
            </div>
            <input
              className="searchFieldDashboard"
              type="search"
              placeholder="Search for a song..."
              value={inputValue}
              onChange={handleSearchSongInputChange}
            />
          </div>
          {showLoading && (
            <div className="loadingHolderDiv" style={{ marginTop: '35vh' }}>
              <Box sx={{ display: 'flex' }}>
                <CircularProgress sx={{ color: '#57BBEB' }} />
              </Box>
            </div>
          )}

          {(!inputValue || inputValue === 0 || inputValue === null) &&
            reviewPosts?.map((data, index) => (
              <div
                className="postDiv"
                id="preLoginPostDiv"
                key={index.id}
                style={{ backgroundColor: ' rgba(45, 52, 71, 1)' }}
                onClick={handleLoginClick}
              >
                <div className="postTopData">
                  <div className="postTopLeft">
                    <div className="postPicHolder">
                      <img
                        src={data?.song?.image || data?.album?.image}
                        alt="pic"
                        style={{ objectFit: 'cover' }}
                      />
                    </div>
                    <div className="posterNameDiv">
                      <p className="nameTxtt">{data?.song?.title || data?.album?.title}</p>
                      <p className="nameTxtt2">
                        {data?.song?.artist_name || data?.album?.artist_name}
                      </p>
                    </div>
                  </div>
                  <div className="iconsDiv">
                    <div className="vectorss">
                      <img src={VectorImg2} alt="vector" />
                    </div>
                  </div>
                </div>

                <div className="dashedDividerOnPost"> </div>
                <div className="post2ndDiv">
                  <div className="titleAndReview">
                    <p className="tittleTxts">{data?.title}</p>
                    <div className="postRatingsDivWithTxt">
                      <p className="noOfRatingStars">{data?.ratings}</p>
                      <Rating
                        name="custom-rating"
                        defaultValue={data?.ratings}
                        precision={0.5}
                        readOnly
                        sx={{
                          '& .MuiRating-icon': {
                            fontSize: '15px',
                            '&:hover': {
                              fontSize: '15px',
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                  {data?.images?.length === 1 && (
                    <div className="reviewImagesDivHolder">
                      {data.images[0] && <img src={data.images[0]} alt="reviewImg" />}
                    </div>
                  )}
                  {data?.images?.length === 2 && (
                    <div className="reviewImagesDivHolder2">
                      {data.images[0] && <img src={data.images[0]} alt="reviewImg" />}
                      {data.images[1] && <img src={data.images[1]} alt="reviewImg" />}
                    </div>
                  )}
                  {data?.images?.length === 3 && (
                    <div className="reviewImagesDivHolder3">
                      <div className="reviewImagesDivHolder3in1">
                        {data.images[0] && <img src={data.images[0]} alt="reviewImg" />}
                      </div>
                      <div className="reviewImagesDivHolder3in2">
                        {data.images[1] && <img src={data.images[1]} alt="reviewImg" />}
                        {data.images[2] && <img src={data.images[2]} alt="reviewImg" />}
                      </div>
                    </div>
                  )}
                  {data?.images?.length === 4 && (
                    <div className="reviewImagesDivHolder3" id="reviewImagesDivHolder3id">
                      <div className="reviewImagesDivHolder3in1">
                        {data.images[0] && <img src={data.images[0]} alt="reviewImg" />}
                      </div>
                      <div className="reviewImagesDivHolder3in33">
                        {data.images[1] && <img src={data.images[1]} alt="reviewImg" />}
                        {data.images[2] && <img src={data.images[2]} alt="reviewImg" />}
                        {data.images[2] && <img src={data.images[3]} alt="reviewImg" />}
                      </div>
                    </div>
                  )}

                  <div className="postDescription">
                    <p>{data?.body}</p>
                  </div>
                  <div className="postedBy">
                    <div className="posterSinfos">
                      <div className="postByUserImg">
                        <img
                          src={data?.user?.avatar}
                          alt="pic"
                          style={{ objectFit: 'cover', borderRadius: '50%' }}
                        />
                      </div>
                      <div>
                        <p className="postedbyUsername">
                          {data?.user?.username || data?.user?.name}
                        </p>
                      </div>
                      <div className="checked">
                        <img src={Checked} alt="pic" />
                      </div>
                    </div>
                    <div>
                      <p className="hoursAgo">
                        {formatDistanceToNow(new Date(data?.created_at), {
                          addSuffix: true,
                          includeSeconds: false,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="dashedDividerOnPost"> </div>

                  <div className="likedBy">
                    <div className="likedByImgWrap">
                      <img src={likeboy} alt="pic" />
                    </div>
                    <div className="likedByImgWrap">
                      <img src={likegirl} alt="pic" />
                    </div>
                    <div className="likedByImgWrap">
                      <img src={likegirl3} alt="pic" />
                    </div>
                    <p className="likedbyTxt">
                      Liked by&nbsp;
                      <span className="boldTxt">username</span>
                      &nbsp;and
                      <span className="boldTxt">&nbsp;125&nbsp;others</span>
                    </p>
                  </div>
                  <div className="likeCommentAndShare">
                    <div className="likesAndComments">
                      <div className="likeCommentImg" id="heartIconForLike">
                        <img src={IconHeartLike} alt="." />
                      </div>
                      <div className="likeCommentImg">
                        <img src={commentsImg} alt="pic" />
                      </div>
                    </div>
                    <div className="shareIconDiv">
                      <img src={share} role="presentation" alt="pic" />
                    </div>
                  </div>
                  <div>
                    <p className="previousCommentsTxt">View previous comments</p>
                  </div>
                  <div className="comment_section_main_div">
                    <div className="posterSinfos">
                      <div className="postByUserImg">
                        <img
                          src={profilegirl}
                          alt="pic"
                          style={{ objectFit: 'cover', borderRadius: '50%' }}
                        />
                      </div>
                      <div>
                        <p className="postedbyUsername">Stephany.cb</p>
                      </div>
                      <div className="checked">
                        <img src={Checked} alt="pic" />
                      </div>
                    </div>
                    <div className="firstcomment_div">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus
                        sit amet
                      </p>
                    </div>
                    <div className="comments_section_icons_maindiv">
                      <div className="comment_section_wapper">
                        <div className="comments_section_icons_subdiv">
                          <img src={Flag} alt="" />
                          <p>9h</p>
                        </div>
                        <div className="comment_section_like_div">
                          <img src={IconHeartLike} alt="" />
                          <p> 2 likes</p>
                        </div>
                        <div className="comment_section_reply_div">
                          <p>Reply</p>
                        </div>
                      </div>
                    </div>
                    <div className="person_comment_main_div">
                      <div className="person_comment_sub_div">
                        <div className="user_profile_comment">
                          <img src={commentgirl} alt="" />
                          <p>Username</p>
                        </div>
                      </div>
                    </div>
                    <div className="mention_person">
                      <p>
                        <span> @username </span>
                        love this
                      </p>
                      <div className="comments_section_icons_maindiv person_comment_reply">
                        <div className="comment_section_wapper">
                          <div className="comments_section_icons_subdiv person_icons_subdiv">
                            <img src={Flag} alt="" />
                            <p>9h</p>
                          </div>
                          <div className=" person_like_div">
                            <img src={likes} alt="" />
                            <p> 0 likes</p>
                          </div>
                          <div className="comment_section_reply_div person_reply_div">
                            <p>Reply</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="addCommentDivInput">
                    <div className="AddCommentProfileImg">
                      <img src={replyimg || dummyProfileImage} alt="pic" />
                    </div>

                    <div className="addCommentInput">
                      <input
                        name="comment"
                        className="addCommentInputField"
                        type="text"
                        placeholder="Add comment..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {inputValue && specificReviews && specificReviews.length === 0 ? (
            <p className="noDatayetText">No data found</p>
          ) : specificReviews && specificReviews.length > 0 ? (
            specificReviews?.map((data, index) => (
              <div
                className="postDiv"
                id="preLoginPostDiv"
                key={index.id}
                style={{ backgroundColor: ' rgba(45, 52, 71, 1)' }}
                onClick={handleLoginClick}
              >
                <div className="postTopData">
                  <div className="postTopLeft">
                    <div className="postPicHolder">
                      <img src={data?.song?.image} alt="pic" style={{ objectFit: 'cover' }} />
                    </div>
                    <div className="posterNameDiv">
                      <p className="nameTxtt">{data.song?.title}</p>
                      <p className="nameTxtt2">Ariana Grande | Track</p>
                    </div>
                  </div>
                  <div className="iconsDiv">
                    <div className="vectorss">
                      <img src={VectorImg2} alt="vector" />
                    </div>
                  </div>
                </div>

                <div className="dashedDividerOnPost"> </div>
                <div className="post2ndDiv">
                  <div className="titleAndReview">
                    <p className="tittleTxts">Lorem ipsum dolor sit amet</p>
                    <div className="postRatingsDivWithTxt">
                      <p className="noOfRatingStars">{data?.ratings}</p>
                      <Rating
                        name="custom-rating"
                        defaultValue={data?.ratings}
                        precision={0.5}
                        readOnly
                        sx={{
                          '& .MuiRating-icon': {
                            fontSize: '15px',
                            '&:hover': {
                              fontSize: '15px',
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                  {data?.images?.length === 1 && (
                    <div className="reviewImagesDivHolder">
                      {data.images[0] && <img src={data.images[0]} alt="reviewImg" />}
                    </div>
                  )}
                  {data?.images?.length === 2 && (
                    <div className="reviewImagesDivHolder2">
                      {data.images[0] && <img src={data.images[0]} alt="reviewImg" />}
                      {data.images[1] && <img src={data.images[1]} alt="reviewImg" />}
                    </div>
                  )}
                  {data?.images?.length === 3 && (
                    <div className="reviewImagesDivHolder3">
                      <div className="reviewImagesDivHolder3in1">
                        {data.images[0] && <img src={data.images[0]} alt="reviewImg" />}
                      </div>
                      <div className="reviewImagesDivHolder3in2">
                        {data.images[1] && <img src={data.images[1]} alt="reviewImg" />}
                        {data.images[2] && <img src={data.images[2]} alt="reviewImg" />}
                      </div>
                    </div>
                  )}
                  {data?.images?.length === 4 && (
                    <div className="reviewImagesDivHolder3" id="reviewImagesDivHolder3id">
                      <div className="reviewImagesDivHolder3in1">
                        {data.images[0] && <img src={data.images[0]} alt="reviewImg" />}
                      </div>
                      <div className="reviewImagesDivHolder3in33">
                        {data.images[1] && <img src={data.images[1]} alt="reviewImg" />}
                        {data.images[2] && <img src={data.images[2]} alt="reviewImg" />}
                        {data.images[2] && <img src={data.images[3]} alt="reviewImg" />}
                      </div>
                    </div>
                  )}

                  <div className="postDescription">
                    <p>{data?.body}</p>
                  </div>
                  <div className="postedBy">
                    <div className="posterSinfos">
                      <div className="postByUserImg">
                        <img
                          src={data?.user?.avatar}
                          alt="pic"
                          style={{ objectFit: 'cover', borderRadius: '50%' }}
                        />
                      </div>
                      <div>
                        <p className="postedbyUsername">
                          {data?.user?.username || data?.user?.name}
                        </p>
                      </div>
                      <div className="checked">
                        <img src={Checked} alt="pic" />
                      </div>
                    </div>
                    <div>
                      <p className="hoursAgo">
                        {formatDistanceToNow(new Date(data?.created_at), {
                          addSuffix: true,
                          includeSeconds: false,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="dashedDividerOnPost"> </div>

                  <div className="likedBy">
                    <div className="likedByImgWrap">
                      <img src={likeboy} alt="pic" />
                    </div>
                    <div className="likedByImgWrap">
                      <img src={likegirl} alt="pic" />
                    </div>
                    <div className="likedByImgWrap">
                      <img src={likegirl3} alt="pic" />
                    </div>
                    <p className="likedbyTxt">
                      Liked by&nbsp;
                      <span className="boldTxt">username</span>
                      &nbsp;and
                      <span className="boldTxt">&nbsp;125&nbsp;others</span>
                    </p>
                  </div>
                  <div className="likeCommentAndShare">
                    <div className="likesAndComments">
                      <div className="likeCommentImg" id="heartIconForLike">
                        <img src={IconHeartLike} alt="." />
                      </div>
                      <div className="likeCommentImg">
                        <img src={commentsImg} alt="pic" />
                      </div>
                    </div>
                    <div className="shareIconDiv">
                      <img src={share} role="presentation" alt="pic" />
                    </div>
                  </div>
                  <div>
                    <p className="previousCommentsTxt">View previous comments</p>
                  </div>
                  <div className="comment_section_main_div">
                    <div className="posterSinfos">
                      <div className="postByUserImg">
                        <img
                          src={profilegirl}
                          alt="pic"
                          style={{ objectFit: 'cover', borderRadius: '50%' }}
                        />
                      </div>
                      <div>
                        <p className="postedbyUsername">Stephany.cb</p>
                      </div>
                      <div className="checked">
                        <img src={Checked} alt="pic" />
                      </div>
                    </div>
                    <div className="firstcomment_div">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus
                        sit amet
                      </p>
                    </div>
                    <div className="comments_section_icons_maindiv">
                      <div className="comment_section_wapper">
                        <div className="comments_section_icons_subdiv">
                          <img src={Flag} alt="" />
                          <p>9h</p>
                        </div>
                        <div className="comment_section_like_div">
                          <img src={IconHeartLike} alt="" />
                          <p> 2 likes</p>
                        </div>
                        <div className="comment_section_reply_div">
                          <p>Reply</p>
                        </div>
                      </div>
                    </div>
                    <div className="person_comment_main_div">
                      <div className="person_comment_sub_div">
                        <div className="user_profile_comment">
                          <img src={commentgirl} alt="" />
                          <p>Username</p>
                        </div>
                      </div>
                    </div>
                    <div className="mention_person">
                      <p>
                        <span> @username </span>
                        love this
                      </p>
                      <div className="comments_section_icons_maindiv person_comment_reply">
                        <div className="comment_section_wapper">
                          <div className="comments_section_icons_subdiv person_icons_subdiv">
                            <img src={Flag} alt="" />
                            <p>9h</p>
                          </div>
                          <div className=" person_like_div">
                            <img src={likes} alt="" />
                            <p> 0 likes</p>
                          </div>
                          <div className="comment_section_reply_div person_reply_div">
                            <p>Reply</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="addCommentDivInput">
                    <div className="AddCommentProfileImg">
                      <img src={replyimg || dummyProfileImage} alt="pic" />
                    </div>

                    <div className="addCommentInput">
                      <input
                        name="comment"
                        className="addCommentInputField"
                        type="text"
                        placeholder="Add comment..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            ''
          )}
        </div>
        <div className="imgContainer">
          <div className="imgContainer_sub_Div">
            <div className="main_div_profile_left">
              <div className="rightProfilePicHolder rightProfilePicHolder_pre_login">
                <img src={ProfilePic} alt="profileImg" />
                <div className="blueTick_main_div">
                  <img src={VerifiedTick} alt="" />
                </div>
              </div>
              <div className="right_profile_text_main_div">
                <h3>Johan Mike</h3>
                <p>@johanmike</p>
              </div>
              <p className="para_profile_prelogin">
                Lorem ipsum dolor sit amet, consectetur adipiscin.
              </p>
              <div className="dividerDiv">
                <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 1)', opacity: '0.2' }} />
              </div>
              <div className="sprinkles sprinkles_prelogin">
                <img src={sprinkles} alt="pic" />
              </div>
              <p className="counterDashboard">306</p>
              <p className="counterPoints">Points</p>
              <div className="scoreLevelBarHolder">
                <ScoreLevelBarr />
              </div>
              <p className="certifiedTtxt">You have been certified</p>
              <p className="levelTxt_dashboard last_text_card">
                You have reached
                <span className="blueColored">
                  <p> Level 3</p>
                </span>
              </p>
            </div>
            <div className="myReviewsheadingTxts myReviewsheadingTxts_prelogin">
              <p className="leftMyReviewsTxt">My Reviews</p>
              <p className="seeAllTxt">See All</p>
            </div>
            <div className="main_review_div">
              <div className="prelogin_review">
                <div className="prelogin_review_img_div">
                  <img src={Review1} alt=".." />
                  <div className="rating_img_prelogin">
                    <img src={rating} alt=".." />
                  </div>
                  <div className="name_review_prelogin">
                    <p>Rewind</p>
                    <p className="text_with_opacity">Rascal Flatts</p>
                  </div>
                </div>
              </div>
              <div className="prelogin_review prelogin_review_2">
                <div className="prelogin_review_img_div">
                  <img src={Review2} alt=".." />
                  <div className="rating_img_prelogin">
                    <img src={rating} alt=".." />
                  </div>
                  <div className="name_review_prelogin">
                    <p>SOS</p>
                    <p className="text_with_opacity">SZA</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="main_review_div">
              <div className="prelogin_review">
                <div className="prelogin_review_img_div">
                  <img src={Review3} alt=".." />
                  <div className="rating_img_prelogin">
                    <img src={rating} alt=".." />
                  </div>
                  <div className="name_review_prelogin">
                    <p>Noman RockWell</p>
                    <p className="text_with_opacity">Lana Del Rey</p>
                  </div>
                </div>
              </div>
              <div className="prelogin_review prelogin_review_2">
                <div className="prelogin_review_img_div">
                  <img src={Review1} alt=".." />
                  <div className="rating_img_prelogin">
                    <img src={rating} alt=".." />
                  </div>
                  <div className="name_review_prelogin">
                    <p>GENESIS</p>
                    <p className="text_with_opacity">Peso Pluma</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreLoginScreen;
