/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';

function SingleUserDetailOnFollowersList({
  followerAvatar,
  followerName,
  followerUsername,
  followerDescription,
  userId,
  following,
  handleFollowBtnClick,
  status,
}) {
  const [followed, setFollowed] = useState('');
  useEffect(() => {
    if (following) {
      setFollowed('Unfollow');
    } else {
      setFollowed('Follow');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Navigate = useNavigate();
  const handleUserNameClick = () => {
    Navigate(`/user/${userId}`);
  };
  return (
    <div className="singleUserDetailOnFollowersList">
      <div className="followersImgAndDesc">
        <div className="followersImgHolder">
          <img src={followerAvatar || dummyProfileImage} alt="." />
        </div>
        <div className="oneFollowersNameDetail">
          <div onClick={handleUserNameClick}>
            <p className="followerName_popUp">
              {followerName}
              <span className="opacity_class"> @{followerUsername}</span>
            </p>
          </div>
          <div>
            <p className="followerDesc_popUp">{followerDescription || ''}</p>
          </div>
        </div>
      </div>
      <div>
        <button
          type="submit"
          className="follow_Unfollow"
          onClick={() => handleFollowBtnClick(userId, status)}
        >
          {followed}
        </button>
      </div>
    </div>
  );
}

export default SingleUserDetailOnFollowersList;
