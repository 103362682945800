import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import api from '../api/index';
import LOGO from '../assets/Images/LOGO.svg';
import PreLoginSliders from '../components/PreLoginSliders';
import check from '../assets/Images/VectorcheckMark.svg';
import { analyticsPages, errorLogCountAnalyticsEvent } from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const Navigate = useNavigate();

  const handleSubmitSend = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error('Please enter your email');
      return;
    }

    await api('get', `/forgot-password/?email=${email}`)
      .then((res) => {
        console.log(res);
        setEmail('');
        toast.success('Password reset e-mail has been sent.');
        Navigate('/accountVerification', {
          state: {
            forgotPassword: true,
            email,
          },
        });
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log('error', err?.response?.data);
      });
  };

  usePageViewAnalyticsHook({
    title: analyticsPages.forgotPassword,
  });

  return (
    <div className="accountVerificationContainer">
      <div className="preloginWrapper">
        <PreLoginSliders />
      </div>
      <div className="accountVerifyRightPartWrap">
        <div className="rightSideInsiderWrap">
          <div className="logoClassTop">
            <img src={LOGO} alt="logo" />
          </div>
          <div className="verificationTxtsWrap">
            <p className="topHeading_verification" id="forgotTxt">
              Forgot Password
            </p>
          </div>

          <div className="inputFields">
            <p className="lables">Enter Your Email</p>
            <div className="cont">
              <input
                className="input_field"
                type="text"
                placeholder="johanmike@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <span>
              <img className="emailImg" src={check} alt="pic" />
            </span>
          </div>
          <button type="submit" className="SendButton" onClick={handleSubmitSend}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
}
