/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import EditIcon from '../assets/Images/EditOption.svg';
import ShareIcon from '../assets/Images/ShareOption.svg';
import DeleteIcon from '../assets/Images/DeleteOption.svg';
import ReportIconSvg from '../assets/Images/ReportIcon.svg';

const style = {
  position: 'absolute',
  top: '20%',
  left: '30%',
  width: 500,
  bgcolor: 'transparent',
  border: 'none',
  '&:focus': {
    outline: 'none',
  },
};

function OptionsReview({
  setOpenOption,
  openOption,
  id,
  handleDelete,
  handleReport,
  data,
  handleEdit,
  handleShareClick,
}) {
  const handleClose = () => {
    setOpenOption(false);
  };
  const myId = localStorage.getItem('userID');

  const handleEditModal = (obj) => {
    // eslint-disable-next-line eqeqeq
    if (obj?.review?.user?.id != myId) {
      toast.error("you cant edit other user's review");
      return;
    }
    handleEdit(obj);
    handleClose();
  };
  console.log(data?.review?.user?.id, 'asdfghjk');
  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable react-hooks/exhaustive-deps */
  return (
    <Modal
      open={openOption}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="option_review_section">
          <div className="inner_option_review">
            <div className="options_txt_close">
              <p className="options_text_p">Options</p>
              <img onClick={handleClose} src={CloseIcon} alt="" />
            </div>
            <div className="options_icon_txt_section">
              {data?.review?.user?.id == myId && (
                <div
                  onKeyDown={() => {
                    console.log('nothing');
                  }}
                  tabIndex={0}
                  role="button"
                  className="options_icon_txt"
                  onClick={() => handleEditModal(data)}
                >
                  <img src={EditIcon} alt="edit" />
                  <p className="options_icon_text_p">Edit</p>
                </div>
              )}
              <div className="options_icon_txt" onClick={() => handleShareClick(id)}>
                <img src={ShareIcon} alt="share" />
                <p className="options_icon_text_p">Share</p>
              </div>

              {data?.review?.user?.id == myId && (
                <div
                  onKeyDown="nothing"
                  tabIndex={0}
                  role="button"
                  className="options_icon_txt"
                  onClick={() => handleDelete(id)}
                >
                  <img src={DeleteIcon} alt="delete" />
                  <p className="options_icon_text_p">Delete</p>
                </div>
              )}

              {data?.review?.user?.id != myId && (
                <div
                  onKeyDown="nothing"
                  tabIndex={0}
                  role="button"
                  className="options_icon_txt"
                  onClick={() => handleReport(id)}
                >
                  <img src={ReportIconSvg} alt="delete" />
                  <p className="options_icon_text_p">Report</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default OptionsReview;
