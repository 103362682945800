/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from '../containers/Dashboard';
import SongDetails from '../containers/SongDetails';
import AlbumDetails from '../containers/AlbumDetails';
import LoginScreen from '../containers/LoginScreen';
import CreateAccount from '../containers/CreateAccount';
import SpotifyConfirmation from '../containers/SpoityConfirmation';
import AccountVerification from '../containers/AccountVerification';
import ForgotPassword from '../containers/ForgotPassword';
import PreLoginScreen from '../components/PreLoginScreen';
import MusoticSpotify from '../components/MusoticSpotify';
import ResetPassword from '../containers/ResetPassword';
import UserProfile from '../containers/UserProfile';
import Settings from '../components/Settings';
import ChatScreen from '../containers/Chat';
import FriendsProfile from '../containers/FriendsProfile';
import FeedReviewModal from '../components/FeedReviewModal';
import AppleLoginScreen from '../containers/AppleConfirmation';
import AppleConnectScreen from '../containers/AppleConnect';

function Index({ loggedIn }) {
  return (
    <Routes>
      <Route path="/" element={loggedIn ? <Navigate to="/dashboard" /> : <PreLoginScreen />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/signup" element={<CreateAccount />} />
      <Route path="/ForgotPassword" element={<ForgotPassword />} />
      <Route path="/spotifyConfirmation" element={<SpotifyConfirmation />} />
      <Route path="/accountVerification" element={<AccountVerification />} />
      <Route path="/preloginscreen" element={<PreLoginScreen />} />
      <Route path="/appleLogin" element={<AppleLoginScreen />} />
      <Route path="/appleConnect" element={<AppleConnectScreen />} />

      {loggedIn ? (
        <>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Home />} />
          <Route path="/search" element={<MusoticSpotify />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/songDetails/:songId" element={<SongDetails />} />
          <Route path="/albumDetails/:albumId" element={<AlbumDetails />} />
          <Route path="/chat" element={<ChatScreen />} />
          <Route path="/user/:userId" element={<FriendsProfile />} />
          <Route path="/reviews/:reviewId" element={<FeedReviewModal />} />
          <Route path="/*" element={<Navigate to="/dashboard" />} />
        </>
      ) : (
        <Route path="/*" element={<Navigate to="/" />} />
      )}
    </Routes>
  );
}
export default Index;
