import { toast } from 'react-toastify';
import { localStorageKey, queryParamsKey } from './constant';

// eslint-disable-next-line import/prefer-default-export
export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

export function generateUniqueString() {
  // Generate a random string by converting a random number to base 36
  // and removing the '0.' prefix.
  const randomString = Math.random().toString(36).substring(2);

  // Add a timestamp to increase uniqueness
  const timestamp = new Date().getTime().toString(36);

  // Combine the random string and timestamp
  const uniqueString = randomString + timestamp;

  return uniqueString;
}

export function generateUniqueFileName(file) {
  const fileType = file.type.split('/');
  return `${generateUniqueString()}.${fileType[1]}`;
}

export const copyPlaylistUrl = (pathParams, playlistId) => {
  let userId;
  if (window.location.pathname === queryParamsKey.myProfile.url) {
    userId = localStorage.getItem(localStorageKey.userId);
  } else {
    userId = pathParams[queryParamsKey.friendProfile.userIdKey];
  }
  userId = Number(userId);
  if (!userId) {
    toast.error('Unable to copy playlist link.');
    return;
  }
  const playlistLink = `${queryParamsKey.friendProfile.url.replace(
    `:${queryParamsKey.friendProfile.userIdKey}`,
    userId,
  )}?${queryParamsKey.friendProfile.openTabKey}=${
    queryParamsKey.friendProfile.openTabValues.playlist
  }&${queryParamsKey.friendProfile.tabIdKey}=${playlistId}`;

  navigator.clipboard.writeText(window.location.origin + playlistLink);
  toast.success('Playlist link copied to clipboard.');
};

export function isUrlValid(url) {
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return urlPattern.test(url);
}

export function doesUserLoginFromAppleSSO() {
  try {
    const isSSO = localStorage.getItem('appleSSO');
    if (isSSO && JSON.parse(isSSO) === true) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }
  return false;
}

export function canShowAppleMusicLogo(appleMusicUrl, spotifyMusicUrl) {
  console.log(appleMusicUrl, spotifyMusicUrl);
  console.log(doesUserLoginFromAppleSSO(), 'doesUserLoginFromAppleSSO()');
  return !!(
    (doesUserLoginFromAppleSSO() && appleMusicUrl) ||
    (spotifyMusicUrl === '' && appleMusicUrl)
  );
}
