import { Modal } from '@mui/material';
import React, { useState } from 'react';
import { Box } from '@mui/system';
import { toast } from 'react-toastify';
import BackArrow from '../../assets/Images/BackArrow.svg';
import CloseIcon from '../../assets/Images/CloseIcon.svg';
import AddSongsIcon from '../../assets/Images/AddSongsIcon.svg';
import ShareIcon from '../../assets/Images/ShareOption.svg';
import PrivateIcon from '../../assets/Images/PrivateIcon.svg';
import DeleteIcon from '../../assets/Images/DeleteOption.svg';
import EditPlaylist from '../EditPlaylist';
import WhiteDotIcon from '../../assets/Images/WhieDotsSetting.svg';
import api from '../../api';
import { errorLogCountAnalyticsEvent } from '../../service/analyticsService';

function PlaylistOptionsModal({
  style,
  playlistDetailsById,
  selectedPlaylist,
  fetchPanelData,
  playlistDetailsClose,
}) {
  const [open, setOpen] = useState(false);
  const Userid = localStorage.getItem('musoticID');
  const currentHost = window.location.pathname;
  const optionsModelClose = () => {
    setOpen(false);
  };

  const handleSharePlaylist = () => {
    const baseUrl = window.location.origin;

    const completeUrl = `${baseUrl}/user/${Userid}`;
    navigator.clipboard
      .writeText(completeUrl)
      .then(() => {
        toast.success('URL copied to clipboard');
        setOpen(false);
      })
      .catch((error) => {
        errorLogCountAnalyticsEvent();
        console.error('Failed to copy URL to clipboard:', error);
        toast.error('Action Failed');
      });
  };

  const handleDeletePlaylist = () => {
    api('delete', `/playlists/${selectedPlaylist?.id}/`)
      .then((res) => {
        toast.success('Deleted Successfully');
        fetchPanelData();
        setOpen(false);
        playlistDetailsClose();
        console.log(res);
      })
      .catch(() => {
        errorLogCountAnalyticsEvent();
        toast.error('Something Went Wrong');
      });
  };

  const handleMakeItPrivate = () => {
    const isPrivate = 'true';
    const data = { isPrivate };
    api('patch', `/playlists/${selectedPlaylist?.id}/`, data)
      .then((res) => {
        toast.success('Playlist Privated');
        setOpen(false);
        console.log(res);
      })
      .catch(() => {
        errorLogCountAnalyticsEvent();
        toast.error('Something Went Wrong');
      });
  };

  return (
    <>
      <div className="playList_topSong_data">
        <p className="playList_topSong_txt">{playlistDetailsById?.name || ' playlist name'}</p>
        {currentHost === '/profile' && (
          <div
            role="button"
            tabIndex={-1}
            onKeyDown={() => console.log('nothing')}
            className="whiteDot_icon"
            onClick={() => setOpen(true)}
          >
            <img src={WhiteDotIcon} alt="edit" />
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={optionsModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="playList_option">
          <div>
            <div className="playList_option_header">
              <div className="playList_option_back_txt">
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => console.log(e)}
                  className="createPlaylist_backArrow"
                  onClick={optionsModelClose}
                >
                  <img src={BackArrow} alt="back" />
                </div>
                <p className="playlist_option_txt">Playlist Options</p>
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={(e) => console.log(e)}
                className="playlist_option_close"
                onClick={() => setOpen(false)}
              >
                <img src={CloseIcon} alt="setting" />
              </div>
            </div>
            <div className="playlist_options_list_data">
              <div className="options_icons">
                <img src={AddSongsIcon} alt="add" />
              </div>
              <p className="playlist_options__txt">Add Songs</p>
            </div>
            <EditPlaylist
              style={style}
              playlistDetailsById={playlistDetailsById}
              fetchPanelData={fetchPanelData}
              optionsModelClose={optionsModelClose}
              playlistDetailsClose={playlistDetailsClose}
            />
            <div
              role="button"
              tabIndex={0}
              onKeyDown={(e) => console.log(e)}
              className="playlist_options_list_data"
              onClick={handleSharePlaylist}
            >
              <div className="options_icons">
                <img src={ShareIcon} alt="sahre" />
              </div>
              <p className="playlist_options__txt">Share</p>
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={(e) => console.log(e)}
              className="playlist_options_list_data"
              onClick={handleMakeItPrivate}
            >
              <div className="options_icons">
                <img src={PrivateIcon} alt="private" />
              </div>
              <p className="playlist_options__txt">Make it private</p>
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={(e) => console.log(e)}
              onClick={handleDeletePlaylist}
              className="playlist_options_list_data playlistOptions_del"
            >
              <div className="options_icons">
                <img src={DeleteIcon} alt="delete" />
              </div>
              <p className="playlist_options__txt">Delete</p>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default PlaylistOptionsModal;
