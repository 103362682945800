/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import api from '../api/index';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import BackIcon from '../assets/Images/PathBlockBack.svg';
import SingleUserDetailOnFollowersList from './FollowUnfollow';
import { debounce } from '../utils/helpers';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

const style = {
  position: 'absolute',
  top: '20%',
  left: '27%',
  width: 650,
  bgcolor: 'transparent',
  border: 'none',
};

function ModalFollowers({ closeModal, specificUserId, specificUserName }) {
  const [activeTab, setActiveTab] = useState(0);
  const [userFollowerDetails, setUserFollowerDetails] = useState();
  const [userMutualFollowers, setUserMutualFollowers] = useState();
  const [suggestedUsers, setSuggestedUsers] = useState();
  const [userFollowingDetails, setUserFollowingDetails] = useState();
  const searchInputRef = useRef(null);
  const userID = localStorage.getItem('musoticID');

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
    searchInputRef.current.value = '';
  };
  const handleClose = () => {
    closeModal();
  };

  function TabPanel({ value, index, children }) {
    return (
      <div role="tabpanel" hidden={value !== index} className="tabPanelClass">
        {value === index && <div>{children}</div>}
      </div>
    );
  }

  const fetchUserFollowers = async () => {
    try {
      const response = await api('get', `/users/${specificUserId}/followers/`);
      setUserFollowerDetails(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  const fetchMutualFollowers = async () => {
    try {
      const response = await api('get', `/users/${specificUserId}/mutual-followers/`);
      setUserMutualFollowers(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  const fetchSuggestedUsers = async () => {
    try {
      const response = await api('get', `/users/suggested/`);
      setSuggestedUsers(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  const fetchUserFollowings = async () => {
    try {
      const response = await api('get', `/users/${specificUserId}/following/`);
      setUserFollowingDetails(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  const SearchFollowers = async (name) => {
    try {
      const response = await api('get', `/users/${userID}/followers/?q=${name}`);
      setUserFollowerDetails(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  const SearchFollowing = async (name) => {
    try {
      const response = await api('get', `/users/${userID}/following/?q=${name}`);
      setUserFollowingDetails(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  const SearchMutual = async (name) => {
    try {
      const response = await api('get', `/users/${userID}/mutual-followers/?q=${name}`);
      setUserMutualFollowers(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  const SearchSuggested = async (name) => {
    try {
      const response = await api('get', `/users/suggested/?q=${name}`);
      setSuggestedUsers(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };

  const handleFollowBtnClick = async (followId, section) => {
    try {
      const response = await api('get', `/users/${followId}/followership/`);
      console.log('follloww', response);
      if (section === 'mutual') {
        fetchMutualFollowers();
      } else if (section === 'followers') {
        fetchUserFollowers();
      } else if (section === 'suggested') {
        fetchSuggestedUsers();
      } else if (section === 'following') {
        fetchUserFollowings();
      }
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };

  const handleSearch = (value) => {
    // Your logic for handling the search term
    if (activeTab === 1) {
      SearchFollowers(value);
    }
    if (activeTab === 2) {
      SearchFollowing(value);
    }
    if (activeTab === 3) {
      SearchSuggested(value);
    }
    if (activeTab === 0) {
      SearchMutual(value);
    }
  };

  const debouncedSearch = debounce((value) => {
    handleSearch(value);
  }, 1500); // 1.5 seconds

  useEffect(() => {
    fetchUserFollowers();
    fetchUserFollowings();
    fetchMutualFollowers();
    fetchSuggestedUsers();
  }, [specificUserId, activeTab]);

  return (
    <Modal
      open
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="option_review_sectionNew2">
          <div className="followDetailsHead">
            <div className="cursorPointer" onClick={handleClose}>
              <img src={BackIcon} alt="." />
            </div>
            <div>{specificUserName?.name || specificUserName?.username}</div>
            <div className="cursorPointer" onClick={handleClose}>
              <img src={CloseIcon} alt="." />
            </div>
          </div>
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            centered
            sx={{
              color: 'white',
              textTransform: 'none',
              fontFamily: 'Quicksand',
              opacity: '0.8',
            }}
          >
            <Tab
              label={`${userMutualFollowers?.length} Mutual`}
              sx={{ color: 'white', textTransform: 'none', fontFamily: 'Quicksand' }}
            />
            <Tab
              label={`${userFollowerDetails?.length} Followers`}
              sx={{ color: 'white', textTransform: 'none', fontFamily: 'Quicksand' }}
            />
            <Tab
              label={`${userFollowingDetails?.length} Following`}
              sx={{ color: 'white', textTransform: 'none', fontFamily: 'Quicksand' }}
            />
            <Tab
              label={`${suggestedUsers?.length} Suggested`}
              sx={{
                color: 'white',
                textTransform: 'none',
                fontFamily: 'Quicksand',
                opacity: '0.6',
              }}
            />
          </Tabs>
          <div className="followersSearchHolder">
            <input
              type="search"
              className="follwersModalSearch"
              placeholder="Search for a user..."
              ref={searchInputRef}
              onChange={(e) => debouncedSearch(e.target.value)}
            />
          </div>
          <TabPanel value={activeTab} index={0} sx={{ width: '100%' }}>
            {userMutualFollowers?.length < 1 ? (
              <p className="noDatayetText">No user found</p>
            ) : (
              userMutualFollowers?.map((follower, index) => (
                <SingleUserDetailOnFollowersList
                  key={index?.id}
                  followerAvatar={follower?.avatar}
                  followerName={follower?.name}
                  followerUsername={follower?.username}
                  followerDescription={follower?.description}
                  userId={follower?.id}
                  following={follower?.following}
                  handleFollowBtnClick={handleFollowBtnClick}
                  status="mutual"
                />
              ))
            )}
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <div>
              {userFollowerDetails?.length < 1 ? (
                <p className="noDatayetText">No user found</p>
              ) : (
                userFollowerDetails?.map((follower, index) => (
                  <SingleUserDetailOnFollowersList
                    key={index?.id}
                    followerAvatar={follower?.avatar}
                    followerName={follower?.name}
                    followerUsername={follower?.username}
                    followerDescription={follower?.description}
                    userId={follower?.id}
                    following={follower?.following}
                    handleFollowBtnClick={handleFollowBtnClick}
                    status="followers"
                  />
                ))
              )}
            </div>
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <div>
              {userFollowingDetails?.length < 1 ? (
                <p className="noDatayetText">No user found</p>
              ) : (
                userFollowingDetails?.map((follower, index) => (
                  <SingleUserDetailOnFollowersList
                    key={index?.id}
                    followerAvatar={follower?.avatar}
                    followerName={follower?.name}
                    followerUsername={follower?.username}
                    followerDescription={follower?.description}
                    userId={follower?.id}
                    following={follower?.following}
                    handleFollowBtnClick={handleFollowBtnClick}
                    status="following"
                  />
                ))
              )}
            </div>
          </TabPanel>
          <TabPanel value={activeTab} index={3}>
            {suggestedUsers?.length < 1 ? (
              <p className="noDatayetText">No user found</p>
            ) : (
              suggestedUsers?.map((follower, index) => (
                <SingleUserDetailOnFollowersList
                  key={index?.id}
                  followerAvatar={follower?.avatar}
                  followerName={follower?.name}
                  followerUsername={follower?.username}
                  followerDescription={follower?.description}
                  userId={follower?.id}
                  following={follower?.following}
                  handleFollowBtnClick={handleFollowBtnClick}
                  status="suggested"
                />
              ))
            )}
          </TabPanel>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalFollowers;
