// Init
import React, { useEffect, useState } from 'react';
import { ToastContainer, Flip } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import MusoticLogoScreen from './components/MusoticLogoScreen';

// Files
import Index from './routes/index';
import './css/index.css';
import Header from './components/Header';
import { initilizeAnalytics } from './service/analyticsService';

initilizeAnalytics();

const token = localStorage.getItem('idToken');
// Component
function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [intro, setIntro] = useState(true);
  const location = useLocation();

  setTimeout(() => {
    setIntro(false);
  }, 2000);
  useEffect(() => {
    if (token) {
      setLoggedIn(true);
    }
  }, []);

  const handleHeader = () => {
    const validPaths = [
      '/',
      '/login',
      '/signup',
      '/accountVerification',
      '/ResetPassword',
      '/ForgotPassword',
      '/preloginscreen',
      '/spotifyConfirmation',
    ];

    const currentPath = location.pathname;
    console.log('currentPath', currentPath, validPaths.includes(currentPath));

    if (validPaths.includes(currentPath)) {
      return '';
    }

    return loggedIn ? <Header /> : '';
  };

  return intro ? (
    <MusoticLogoScreen />
  ) : (
    <div className="App">
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar transition={Flip} />

      {handleHeader()}
      <Index loggedIn={loggedIn} />
    </div>
  );
}

// Export
export default App;
