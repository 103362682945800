/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import LoverImg from '../assets/Images/Lover.svg';
import LanaImg from '../assets/Images/Lana.svg';
import BackArrow from '../assets/Images/BackArrow.svg';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import Img1 from '../assets/Images/popupimg1.svg';
import Img2 from '../assets/Images/popupimg2.svg';
import Img3 from '../assets/Images/popupimg3.svg';
import star1 from '../assets/Images/popupstar1.svg';
import star2 from '../assets/Images/popupstar2.svg';
import star3 from '../assets/Images/popupstar3.svg';

const Popupstyle = {
  position: 'absolute',
  top: '55%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 500,
  bgcolor: '#2D3447',
  border: 'none',
  boxShadow: 24,
  borderRadius: '8px',
  pt: 2,
};
const items = [
  {
    id: 1,
    imageSrc: LanaImg,
    heading: 'Track Name',
    description: 'Artist Name',
  },
  {
    id: 2,
    imageSrc: LoverImg,
    heading: 'Track Name',
    description: 'Artist Name',
  },
  {
    id: 3,
    imageSrc: LanaImg,
    heading: 'Track Name',
    description: 'Artist Name',
  },
  {
    id: 4,
    imageSrc: LoverImg,
    heading: 'Track Name',
    description: 'Artist Name',
  },
  {
    id: 5,
    imageSrc: LanaImg,
    heading: 'Track Name',
    description: 'Artist Name',
  },
  {
    id: 6,
    imageSrc: LoverImg,
    heading: 'Track Name',
    description: 'Artist Name',
  },
];

function MusoticSpotify({ open, onClose }) {
  const spotifyToken = localStorage.getItem('spotifyAccessToken');
  console.log('spotifyToken', spotifyToken);
  const [showMore, setShowMore] = useState(false);
  // const [open, setOpen] = React.useState(_open);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    onClose();
  };
  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  // useEffect(() => {
  //   setOpen(_open);
  // }, [_open]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="main_topDiv" sx={Popupstyle}>
          <div className="topDiv">
            <div className="topInnerDiv topInnerDivPopup">
              <div onClick={() => handleClose()} className="backArrowIMG">
                <img src={BackArrow} alt="" />
              </div>
              <div className="heading_text heading_textPopup">
                <p>Pop</p>
                <p className="heading_two">Most reviewed</p>
              </div>
              <div onClick={() => handleClose()} className="CloseIMG CloseIMGPopup">
                <img src={CloseIcon} alt="" />
              </div>
            </div>
          </div>
          <div className="three_images_main_div">
            <div className="three_images_sub-Div">
              <div className="first_img_main_div">
                <div className="popupimg_sub_div">
                  <img src={Img1} alt=".." />
                </div>
                <div className="star_div">
                  <img src={star1} alt="" />
                </div>
                <div className="popup_div_text1">
                  <h3>Celebrate</h3>
                  <p>Anderson Paak</p>
                </div>
              </div>
              <div className="first_img_main_div sec_img_main_div">
                <div className="popupimg_sub_div popupimg_sub_div_2">
                  <img src={Img2} alt=".." />
                </div>
                <div className="star_div star_div_2">
                  <img src={star2} alt="" />
                </div>
                <div className="popup_div_text1 popup_div_text1_2">
                  <h3>Sugar</h3>
                  <p>Maron 5</p>
                </div>
              </div>
              <div className="first_img_main_div first_img_main_div_3">
                <div className="popupimg_sub_div popupimg_sub_div_3">
                  <img src={Img3} alt=".." />
                </div>
                <div className="star_div star_div_3">
                  <img src={star3} alt="" />
                </div>
                <div className="popup_div_text1 popup_div_text1_3">
                  <h3>Dangerous Woman</h3>
                  <p>Ariana Grande</p>
                </div>
              </div>
            </div>
          </div>
          <div className="innerDiv innerDivpopup">
            <div className="InnerData">
              <div className="Most_Popular_Text ">
                <p>Most Popular</p>
              </div>

              <Grid sx={{ flexGrow: 1 }} container spacing={2} className="my-component">
                {items.map((item) => (
                  <Grid className="item" item xs={4} key={item.id}>
                    <img className="item-image" src={item.imageSrc} alt={item.heading} />
                    <div className="item_handle">
                      <h2 className="item-heading">{item.heading}</h2>
                      <p className="item-description">{item.description}</p>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <div className="playList_showMore_data prelogin_showMore_data">
                <button
                  className={
                    showMore
                      ? 'playList_showMore_btn blue_playList_showMore_btn prelogin_showmore_data'
                      : 'playList_showMore_btn '
                  }
                  type="button"
                  onClick={() => handleShowMore()}
                >
                  {showMore ? 'Show Less' : 'Show More'}
                </button>
                {showMore && (
                  <Grid sx={{ flexGrow: 1 }} container spacing={2} className="my-component">
                    {items.map((item) => (
                      <Grid className="item" item xs={4} key={item.id}>
                        <img className="item-image" src={item.imageSrc} alt={item.heading} />
                        <div className="item_handle">
                          <h2 className="item-heading">{item.heading}</h2>
                          <p className="item-description">{item.description}</p>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default MusoticSpotify;
