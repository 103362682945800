import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import BackArrow from '../assets/Images/BackArrow.svg';
import SearchIcon from '../assets/Images/SearchInput.svg';
import SearchInput from './SearchInput';
import api from '../api';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

function AddSongPlayList({
  style,
  selectedSong,
  handleSelectedSongClick,
  setSelectedSong,
  playlistDetailsById,
  handleGetPlaylistSongs,
}) {
  const [selected, setSelected] = useState('');
  const [songObj, setSongObj] = useState('');
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [addedSongs, setAddedSongs] = useState(false);
  const currentHost = window.location.pathname;
  const addSongToArray = () => {
    const songsArr = [...selectedSongs];
    songsArr.push(songObj);
    setSelectedSongs(songsArr);
  };

  const addedSongsOpen = () => {
    setAddedSongs(true);
  };
  const addedSongsClose = () => {
    setAddedSongs(false);
    setSelectedSong('');
    setSelected('');
  };

  const handleAddSong = () => {
    const res = selectedSongs?.map((item) => {
      return api('post', '/playlists/add-song/', {
        song: {
          artist_name: item?.artist_name,
          artist_spotify_id: item?.artist_spotify_id,
          title: item?.title,
          year: item?.year,
          image: item?.image,
          spotify_id: item?.spotify_id,
        },
        playlists: [playlistDetailsById?.id],
      });
    });
    Promise.all(res)
      .then(() => {
        setSelectedSongs([]);
        addedSongsClose();
        handleGetPlaylistSongs(playlistDetailsById.id);
        toast.success('Added to the playlist');
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log(err);
      });
  };
  const removeSong = (songId) => {
    const updatedSongs = selectedSongs?.filter((song) => song?.spotify_id !== songId);
    setSelectedSongs(updatedSongs);
  };
  useEffect(() => {
    if (songObj) addSongToArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [songObj]);
  return (
    <>
      <div className="btn_div">
        {currentHost === '/profile' && (
          <button className="playList_addSongs_btn" type="button" onClick={() => addedSongsOpen()}>
            + Add Songs
          </button>
        )}
      </div>
      <Modal
        open={addedSongs}
        onClose={addedSongsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="playList_model_suggest">
          <div className="suggest_addSongs_popup">
            <div className="back_close_icon" id="addsongsTitleStyle">
              <div
                onClick={addedSongsClose}
                tabIndex={0}
                role="button"
                onKeyDown={() => console.log('none')}
                className="createPlaylist_backArrow"
              >
                <img src={BackArrow} alt="back" />
              </div>
              <div>
                <p className="addSongText">Add Songs</p>
              </div>

              <div
                tabIndex={-1}
                role="button"
                onKeyDown={() => console.log('none')}
                onClick={addedSongsClose}
                className="createPlaylist_Close"
              >
                <img src={CloseIcon} alt="close" />
              </div>
            </div>
            {selectedSong?.name ? (
              <div
                tabIndex={-2}
                role="button"
                onKeyDown={() => console.log('none')}
                className="overAllsearchDivWrapSelected"
                onClick={handleSelectedSongClick}
              >
                <div className="searchIconInputSelected">
                  <img src={SearchIcon} alt="icon" />
                </div>
                <div className="selectedSongImgHolder">
                  <img
                    src={selectedSong && selectedSong?.album?.images[2]?.url}
                    alt="Selected Song"
                  />
                </div>
                <div className="selectedSongnameTxt">{selectedSong?.name}</div>
                <span className="colorWhite">|</span>
                <div className="selectedSongSubNameTxt">{selectedSong?.artists[0]?.name}</div>
              </div>
            ) : (
              <div className="overAllsearchDivWrapSelected">
                <SearchInput
                  inputBackgroundColor="#434854"
                  inputPlaceholder="Search for a song..."
                  selectedSong={selectedSong}
                  setSongObj={setSongObj}
                  setSelectedSong={setSelectedSong}
                  selected={selected}
                  setSelected={setSelected}
                  type="track"
                />
              </div>
            )}
            <div className="suggested_addSong">
              <h1 className="suggested_txt">Selected Songs</h1>
            </div>
            <div className="suggestSongs_data">
              {selectedSongs?.length > 0 ? (
                selectedSongs?.map((item) => (
                  <div className="songs_added_data" key={item?.spotify_id}>
                    <div className="songs_added_data_left">
                      <div className="added_song_img">
                        <img src={item?.image} alt="songs" />
                      </div>
                      <div className="added_song_data_txt">
                        <p className="added_song_title_txt">{item?.title}</p>
                        <p className="added_song_disc_txt">{item?.artist_name}</p>
                      </div>
                    </div>
                    <div
                      role="button"
                      onKeyDown={(e) => console.log(e)}
                      tabIndex={0}
                      onClick={() => removeSong(item?.spotify_id)}
                      className="blueDots_icon"
                    >
                      <img src={CloseIcon} alt="setting" />
                    </div>
                  </div>
                ))
              ) : (
                <p className="noDatayetText">Your selected songs will be shown here.</p>
              )}
            </div>
            {selectedSongs?.length > 0 && (
              <button type="button" className="playList_addSongs_btn" onClick={handleAddSong}>
                Add To Playlist
              </button>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AddSongPlayList;
