/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unescaped-entities */
import React, { useRef, useEffect, useState } from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import NoonIMG from '../assets/Images/Noon.svg';
import api from '../api/index';
import EveningIMG from '../assets/Images/Evening.svg';
import likes from '../assets/Images/likes.svg';
import comments from '../assets/Images/Comment.svg';
import Flag from '../assets/Images/Flag.svg';
import share from '../assets/Images/share.svg';
import PostCommentLike from './PostCommentLike';
import Checked from '../assets/Images/checkVerified.svg';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import IconHeartLike from '../assets/Images/IconHeartLike.svg';
import sun from '../assets/Images/SunImg.svg';
// import messenger from '../assets/Images/likemessenger.svg';
import wave from '../assets/Images/Wave.svg';
import left from '../assets/Images/leftArroww.svg';
import right from '../assets/Images/rightArroww.svg';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */

function Reviewdetailepopup({
  song,
  setShowFeedReview,
  commentsReplyText,
  handleComment,
  selectedReview,
}) {
  const songRef = useRef(null);
  const [render, setRender] = useState(false);
  const [comment, setComment] = useState('');
  const [reviewComments, setReviewComments] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const style = {
    position: 'absolute',
    top: '0%',
    left: '0%',
    width: 700,
    bgcolor: 'transparent',
    border: 'none',
  };

  const outSideHandler = () => {
    setShowFeedReview(false);
    setRender(false);
  };

  const handleCloseClick = (index) => {
    if (index >= 0 && index < selectedReview?.length) {
      setCurrentIndex(index);
    }
    setShowFeedReview(false);
  };
  const handleSongNameClick = () => {
    if (selectedReview[currentIndex]?.song?.id) {
      navigate(`/songDetails/${selectedReview[currentIndex]?.song?.id}`);
    } else {
      navigate(`/albumDetails/${selectedReview[currentIndex]?.album?.id}`);
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % selectedReview.length);
  };

  const handleBack = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + selectedReview.length) % selectedReview.length);
  };

  const fetchReviewComments = () => {
    api('get', `/reviews/${selectedReview?.[currentIndex]?.id}/comments/`)
      .then((response) => {
        setReviewComments(response?.data?.results);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        console.log('Error fetching user reviews:', err);
      });
  };
  const handleKeyPress = (event, reviewId) => {
    if (event.keyCode === 13) {
      handleComment(reviewId, comment);
      setComment('');
    }
  };
  console.log(selectedReview[currentIndex]?.song, 'asdfghjkl');
  useEffect(() => {
    fetchReviewComments();
  }, []);

  useEffect(() => {
    const handleClick = (event) => {
      if (songRef?.current) {
        if (!songRef?.current?.contains(event.target)) {
          if (render) {
            outSideHandler();
          }
          setRender(true);
        } else {
          setShowFeedReview(true);
        }
      }
    };
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [songRef, outSideHandler]);
  console.log(selectedReview, 'selectedReview');
  return (
    <div>
      <Modal
        open={setShowFeedReview}
        onClose={setShowFeedReview}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="feedReview_popupAbsolute">
            <div className="ReviewDetails">
              <div className="feedReveiw_inner_data" ref={songRef}>
                <div className="feedReveiw_data">
                  <div className="feedReview_profileANDcloseicon">
                    <div className="Review_profile_dp">
                      <img
                        src={
                          selectedReview[currentIndex]?.song?.image ||
                          selectedReview[currentIndex]?.album?.image
                        }
                        alt="Profile Pic"
                      />
                    </div>
                    <div className="feedReview_closeIcon">
                      <img
                        src={CloseIcon}
                        alt="Cross"
                        onClick={() => {
                          handleCloseClick();
                        }}
                      />
                    </div>
                  </div>
                  <div className="ratingWrapper">
                    <p className="ratingCount">
                      {selectedReview[currentIndex]?.song?.ratings_average ||
                        selectedReview[currentIndex]?.album?.ratings_average}
                    </p>
                    <Rating
                      name="custom-rating"
                      defaultValue={
                        selectedReview[currentIndex]?.song?.ratings_average ||
                        selectedReview[currentIndex]?.album?.ratings_average
                      }
                      precision={1}
                      readOnly
                      sx={{
                        '& .MuiRating-icon': {
                          fontSize: '15px',
                          '&:hover': {
                            fontSize: '15px',
                          },
                        },
                      }}
                    />
                  </div>
                  <p className="profile_name_txt">
                    {selectedReview[currentIndex]?.song?.title ||
                      selectedReview[currentIndex]?.album?.title}
                  </p>
                  <p className="profile_disc_txt">
                    {selectedReview[currentIndex]?.song?.artist_name ||
                      selectedReview[currentIndex]?.album?.artist_name}
                  </p>
                  <div className="post_comments_data" id="post_comments_data">
                    <div className="postTopData">
                      <div className="postTopLeft" id="reviewDetailRelative">
                        <div className="moveLeftBtn" onClick={handleBack}>
                          <img src={left} alt="." />
                        </div>
                        <div className="moveRightBtn" onClick={handleNext}>
                          <img src={right} alt="." />
                        </div>
                        <div className="postPicHolder">
                          <img
                            src={
                              selectedReview[currentIndex]?.song?.image ||
                              selectedReview[currentIndex]?.album?.image
                            }
                            alt="pic"
                          />
                        </div>
                        <div className="posterNameDiv" onClick={handleSongNameClick}>
                          <p className="nameTxtt">
                            {selectedReview[currentIndex]?.song?.title ||
                              selectedReview[currentIndex]?.album?.title}
                          </p>
                          <p className="nameTxtt2">
                            {selectedReview[currentIndex]?.song?.artist_name ||
                              selectedReview[currentIndex]?.album?.artist_name}
                          </p>
                        </div>
                        <div className="icons_div_in_popup">
                          <div style={{ display: 'flex' }}>
                            <img
                              src={
                                selectedReview[currentIndex]?.morning === true
                                  ? sun
                                  : selectedReview[currentIndex]?.evening === true
                                  ? EveningIMG
                                  : selectedReview[currentIndex]?.noon === true
                                  ? NoonIMG
                                  : null
                              }
                              alt=".."
                            />
                          </div>

                          <div
                            style={{
                              padding: '17%',
                              background: `linear-gradient(to bottom right, ${
                                selectedReview[currentIndex]?.intensity?.color_one_rgb
                                  ? selectedReview[currentIndex]?.intensity?.color_one_rgb
                                  : '#D30EE3'
                              }, ${
                                selectedReview[currentIndex]?.review?.intensity?.color_two_rgb
                                  ? selectedReview[currentIndex]?.review?.intensity?.color_two_rgb
                                  : '#47CAC3'
                              })`,
                            }}
                            className="wave"
                          >
                            <img src={wave} alt="" />
                          </div>

                          {/* <div>
                            <img src={messenger} alt=".." />
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="Dashed_line Dashed_line_popup" />
                    <div className="titleAndReview">
                      <p className="tittleTxts">
                        {selectedReview[currentIndex]?.song?.title ||
                          selectedReview[currentIndex]?.album?.title}
                      </p>
                      <div className="feedStarsAndNumber">
                        <p className="noOfStarsOnRatings">
                          {' '}
                          {selectedReview[currentIndex]?.song?.ratings_average ||
                            selectedReview[currentIndex]?.album?.ratings_average}
                        </p>
                        <Rating
                          name="custom-rating"
                          defaultValue={
                            selectedReview[currentIndex]?.song?.ratings_average ||
                            selectedReview[currentIndex]?.album?.ratings_average
                          }
                          precision={1}
                          readOnly
                          sx={{
                            '& .MuiRating-icon': {
                              fontSize: '15px',
                              '&:hover': {
                                fontSize: '15px',
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                    {selectedReview[currentIndex]?.images?.length === 1 && (
                      <div className="reviewImagesDivHolder">
                        {selectedReview[currentIndex]?.images[0] && (
                          <img src={selectedReview[currentIndex]?.images[0]} alt="reviewImg" />
                        )}
                      </div>
                    )}
                    {song?.review?.images?.length === 2 && (
                      <div className="reviewImagesDivHolder2">
                        {song?.review?.images[0] && (
                          <img src={song?.review?.images[0]} alt="reviewImg" />
                        )}
                        {song?.review?.images[1] && (
                          <img src={song?.review?.images[1]} alt="reviewImg" />
                        )}
                      </div>
                    )}
                    {song?.review?.images?.length === 3 && (
                      <div className="reviewImagesDivHolder3">
                        <div className="reviewImagesDivHolder3in1">
                          {song?.review?.images[0] && (
                            <img src={song?.review?.images[0]} alt="reviewImg" />
                          )}
                        </div>
                        <div className="reviewImagesDivHolder3in2">
                          {song?.review?.images[1] && (
                            <img src={song?.review?.images[1]} alt="reviewImg" />
                          )}
                          {song?.review?.images[2] && (
                            <img src={song?.review?.images[2]} alt="reviewImg" />
                          )}
                        </div>
                      </div>
                    )}
                    {song?.review?.images?.length === 4 && (
                      <div className="reviewImagesDivHolder3" id="reviewImagesDivHolder3id">
                        <div className="reviewImagesDivHolder3in1">
                          {song?.review?.images[0] && (
                            <img src={song?.review?.images[0]} alt="reviewImg" />
                          )}
                        </div>
                        <div className="reviewImagesDivHolder3in33">
                          {song?.review?.images[1] && (
                            <img src={song?.review?.images[1]} alt="reviewImg" />
                          )}
                          {song?.review?.images[2] && (
                            <img src={song?.review?.images[2]} alt="reviewImg" />
                          )}
                          {song?.review?.images[3] && (
                            <img src={song?.review?.images[3]} alt="reviewImg" />
                          )}
                        </div>
                      </div>
                    )}

                    <div className="postDescription">
                      <p>{selectedReview[currentIndex]?.body}</p>
                    </div>
                    <div className="postedBy">
                      <div className="posterSinfos">
                        <div className="postByUserImg">
                          <img
                            src={selectedReview[currentIndex]?.user?.avatar || dummyProfileImage}
                            alt="pic"
                          />
                        </div>
                        <div>
                          <p className="postedbyUsername">
                            {selectedReview[currentIndex]?.user?.username ||
                              selectedReview[currentIndex]?.user?.username}
                          </p>
                        </div>
                        {song?.review?.user?.is_verified === true && (
                          <div className="checked">
                            <img src={Checked} alt="pic" />
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="hoursAgo">
                          {formatDistanceToNow(new Date(selectedReview[currentIndex]?.created_at), {
                            addSuffix: true,
                            includeSeconds: false,
                          })}
                        </p>
                      </div>
                    </div>
                    <div className="Dashed_line Dashed_line_popup" />
                    {selectedReview[currentIndex]?.liked_by?.length > 0 && (
                      <div className="likedBy">
                        {selectedReview[currentIndex]?.liked_by?.slice(0, 3).map((item) => (
                          <div className="likedByImgWrap">
                            <img src={item?.avatar ? item?.avatar : dummyProfileImage} alt="pic" />
                          </div>
                        ))}
                        <p className="likedbyTxt">
                          Liked by&nbsp;
                          <span className="boldTxt">
                            {selectedReview[currentIndex]?.liked_by[0]?.username ||
                              selectedReview[currentIndex]?.liked_by[0]?.name ||
                              'username'}
                          </span>
                          {selectedReview[currentIndex]?.liked_by?.length > 1 && (
                            <>
                              <span> and</span>
                              <span className="boldTxt">
                                &nbsp;
                                {selectedReview[currentIndex]?.liked_by
                                  ? selectedReview[currentIndex].liked_by.length - 1
                                  : ''}
                                &nbsp;others
                              </span>
                            </>
                          )}
                        </p>
                      </div>
                    )}

                    <div className="likeCommentAndShare">
                      <div className="likesAndComments">
                        <div className="likeCommentImg">
                          <img src={likes} alt="pic" />
                        </div>
                        <div className="likeCommentImg">
                          <img src={comments} alt="pic" />
                        </div>
                      </div>
                      <div className="shareIconDiv">
                        <img src={share} alt="pic" />
                      </div>
                    </div>

                    <div>
                      <p className="previousCommentsTxt">View previous comments</p>
                    </div>
                    {reviewComments?.map((com) => (
                      <PostCommentLike
                        // ProfilePicInComments={ProfilePicInComments}
                        com={com}
                        Checked={Checked}
                        Flag={Flag}
                        IconHeartLike={IconHeartLike}
                        likes={likes}
                        commentsReplyText={commentsReplyText}
                      />
                    ))}
                    <div className="addCommentDivInput">
                      <div className="AddCommentProfileImg">
                        <img
                          src={selectedReview?.[currentIndex]?.user?.avatar || dummyProfileImage}
                          alt="pic"
                        />
                      </div>
                      <div className="addCommentInput">
                        <input
                          onChange={(e) => setComment(e.target.value)}
                          value={comment}
                          name="comment"
                          className="addCommentInputField"
                          type="text"
                          onKeyDown={(e) => {
                            handleKeyPress(e, selectedReview?.[currentIndex]?.id);
                          }}
                          placeholder="Add comment..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Reviewdetailepopup;
