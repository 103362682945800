/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { React } from 'react';
// import { toast } from 'react-toastify';
import 'typeface-quicksand';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// import api from '../api/index';

import PreLoginSliders from '../components/PreLoginSliders';
import LOGO from '../assets/Images/LOGO.svg';
import { analyticsPages } from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';

// const loggedInAlready = localStorage.getItem('idToken');

export default function sportifyConfirmation() {
  //   const Navigate = useNavigate();

  const handleOkayBtnClick = () => {
    // redirectToAuth();
  };

  usePageViewAnalyticsHook({
    title: analyticsPages.appleLogin,
  });

  return (
    <div>
      <div className="SignupScreenContainer">
        <div className="preloginWrapper">
          <PreLoginSliders />
        </div>
        <div className="signinRightPart">
          <div className="rightSideInnerWrapperConfirm">
            <div className="SignUpimgLogoNameWrap">
              <img src={LOGO} alt="logo" />
            </div>
            <p className="topHeading_confirm">Link With Apple Account</p>
            <div className="textContainer">
              <p className="confirmSubheadingtxt">THIS ALLOWS YOU TO:</p>
              <div className="bulletAndTxt">
                <span className="bullet">1</span>
                <p className="lineTxt">Play music from your account</p>
              </div>
              <div className="bulletAndTxt">
                <span className="bullet" id="bullet2">
                  2
                </span>
                <p className="lineTxt2">
                  Add songs from Musotic to an automated playlist on your Spotify
                </p>
              </div>
              <div className="bulletAndTxt">
                <span className="bullet">3</span>
                <p className="lineTxt">Access your Spotify playlists on Musotic</p>
              </div>
              <div>
                <p className="bottomTxt_confirm">
                  We Will Never Add Anything To Your Spotify. Only You Can Do That.
                </p>
              </div>
              <button type="submit" className="OkayButton" onClick={handleOkayBtnClick}>
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
