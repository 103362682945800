/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Rating from '@mui/material/Rating';

export default function ReviewList({ className, review, index, onReviewClick }) {
  const handleClick = () => {
    onReviewClick(review);
  };
  return (
    <div className="singleTrackDetails" key={index.id} onClick={handleClick}>
      <div className={className || 'myReviewsPics'}>
        <img src={review?.song?.image || review?.album?.image} alt="pic" />
        <div className="starsRatingDiv">
          <Rating
            name={`custom-rating-${index}`}
            defaultValue={review?.song?.ratings_average || review?.album?.ratings_average}
            precision={0.5}
            readOnly
            id="reviewed_star"
            sx={{
              '& .MuiRating-icon': {
                fontSize: '15px',
                '&:hover': {
                  fontSize: '15px',
                },
              },
            }}
          />
        </div>
        <p className="reviewsTrackName">{review?.song?.title || review?.album?.title}</p>
        <p className="reviewsArtistName">
          {review?.song?.artist_name || review?.album?.artist_name}
        </p>
      </div>
    </div>
  );
}
