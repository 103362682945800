/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { React, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'typeface-quicksand';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

// import api from '../api/index';

import PreLoginSliders from '../components/PreLoginSliders';
import LOGO from '../assets/Images/LOGO.svg';
import { analyticsPages, errorLogCountAnalyticsEvent } from '../service/analyticsService';
import usePageViewAnalyticsHook from '../hooks/usePageViewAnalyticsHook';

const loggedInAlready = localStorage.getItem('idToken');
console.log(loggedInAlready, 'stattetee');
let temp = window.location.hash.split('&').join('","');
if (temp) {
  temp = temp.split('=').join('":"');
  temp = `{"${temp}"}`;
  temp = JSON.parse(temp);
  console.log(temp, 'dfghjkjhgf');
  const spotifyAccessToken = temp['#access_token'];
  const authCode = temp['#code'];
  localStorage.setItem('spotifyCode', authCode);

  console.log('accessToken', spotifyAccessToken);
  localStorage.setItem('spotifyAccessToken', spotifyAccessToken);
}
const redirectToAuth = () => {
  const CLIENT_ID = '462f95dedc8e4c2388d7beccb4151206';
  // clientSecret: '302c413066aa44e5b7ffd16934e4075d',

  const REDIRECT_URI = `${window.location.origin}/spotifyConfirmation`;
  console.log('REDIRECT_URI', REDIRECT_URI);
  const AUTH_ENDPOINT = 'https://accounts.spotify.com/authorize';
  const RESPONSE_TYPE = 'code';
  const state = Math.random().toString(36).substring(2, 20);
  const scopes = [
    'user-read-email',
    'user-read-private',
    'user-read-recently-played',
    'user-top-read',
  ];
  scopes.join(' ');

  const url = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&state=${state}&scope=${scopes.join(
    ' ',
  )}`;

  window.location.href = url;
};

export default function sportifyConfirmation() {
  const Navigate = useNavigate();

  // const accessSpotifyToken = localStorage.getItem('spotifyAccessToken');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // -------------------------------DONT REMOVE THIS CODEEEEE----------------------------------------
  // const fetchData = async () => {
  //   const spotifyLogin = {
  //     access_token: accessSpotifyToken,
  //   };
  //   try {
  //     const response = await api('post', '/spotify/login/', spotifyLogin);
  //     console.log(response, 'spotRes');
  //     toast.success('Spotify Login Success');
  //     console.log(response?.data?.token);
  //     if (response?.data?.token) {
  //       localStorage.setItem('idToken', response?.data?.token);
  //       localStorage.setItem('profileUsername', response?.data?.name);
  //       localStorage.setItem('musoticID', response?.data?.profile?.id);
  //       localStorage.setItem('userrrr', response?.data?.id);
  //     }
  //     window.location = '/dashboard';
  //   } catch (err) {
  //     console.log('eerrrr', err);
  //     toast.error('Spotify Login Error');
  //   }
  // };
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const authCode = queryParams.get('code');
    const authorizationCode = authCode;

    const REDIRECT_URI = `${window.location.origin}/spotifyConfirmation`;

    const redirectUri = REDIRECT_URI;
    const clientId = '462f95dedc8e4c2388d7beccb4151206';
    const clientSecret = '302c413066aa44e5b7ffd16934e4075d';

    const requestData = new URLSearchParams();
    requestData.append('grant_type', 'authorization_code');
    requestData.append('code', authorizationCode);
    requestData.append('redirect_uri', redirectUri);
    requestData.append('client_id', clientId);
    requestData.append('client_secret', clientSecret);

    axios
      .post('https://accounts.spotify.com/api/token', requestData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        console.log('TokenResponse:', response?.data);
        const accessToken = response?.data?.access_token;
        const refreshToken = response?.data?.refresh_token;
        const expirationTime = response?.data?.expires_in;
        localStorage.setItem('spotifyAccessToken', accessToken);
        localStorage.setItem('refresh_token', refreshToken);
        localStorage.setItem('SpotifyTokenExpireTime', expirationTime);
        if (accessToken && loggedInAlready) {
          toast.success('Spotify Connected');
          Navigate('/dashhboard');
        } else if (accessToken && !loggedInAlready) {
          toast.success('Spotify Connected, Login Now');
          Navigate('/login');
        } else {
          redirectToAuth();
        }
      })
      .catch((error) => {
        errorLogCountAnalyticsEvent();
        console.error('Token Request Error:', error);
      });
  }, []);
  const handleOkayBtnClick = () => {
    redirectToAuth();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  usePageViewAnalyticsHook({
    title: analyticsPages.spotifyConfirmation,
  });
  return (
    <div>
      <div className="SignupScreenContainer">
        <div className="preloginWrapper">
          <PreLoginSliders />
        </div>
        <div className="signinRightPart">
          <div className="rightSideInnerWrapperConfirm">
            <div className="SignUpimgLogoNameWrap">
              <img src={LOGO} alt="logo" />
            </div>
            <p className="topHeading_confirm">Link With Spotify Account</p>
            <div className="textContainer">
              <p className="confirmSubheadingtxt">THIS ALLOWS YOU TO:</p>
              <div className="bulletAndTxt">
                <span className="bullet">1</span>
                <p className="lineTxt">Play music from your account</p>
              </div>
              <div className="bulletAndTxt">
                <span className="bullet" id="bullet2">
                  2
                </span>
                <p className="lineTxt2">
                  Add songs from Musotic to an automated playlist on your Spotify
                </p>
              </div>
              <div className="bulletAndTxt">
                <span className="bullet">3</span>
                <p className="lineTxt">Access your Spotify playlists on Musotic</p>
              </div>
              <div>
                <p className="bottomTxt_confirm">
                  We Will Never Add Anything To Your Spotify. Only You Can Do That.
                </p>
              </div>
              <button type="submit" className="OkayButton" onClick={handleOkayBtnClick}>
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
