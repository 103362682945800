/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Rating from '@mui/material/Rating';
import Checkbox from '@mui/material/Checkbox';
import { HuePicker } from 'react-color';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import MorningIMG from '../assets/Images/Morning.svg';
import NoonIMG from '../assets/Images/Noon.svg';
import api from '../api/index';
import EveningIMG from '../assets/Images/Evening.svg';
import CameraIcon from '../assets/Images/CameraIcon.svg';
import DropDpwnIcon from '../assets/Images/DropDown.svg';
import SearchInput from './SearchInput';
import BackArrow from '../assets/Images/BackArrow.svg';
import SearchIcon from '../assets/Images/SearchIcon.svg';
import createRevImg from '../assets/Images/createRevImg.svg';
import IntensityComponent from './IntensityGraphTest';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

const style = {
  position: 'absolute',
  top: '1%',
  bottom: '1%',
  left: '25%',
  right: '25%',
  // width: 700,
  // height:'99%',
  overflow: 'scroll',
  bgcolor: 'transparent',
  border: 'none',
  '@media (max-width: 1100px)': {
    // top: '14%',
    left: '19%',
    right: '19%',
  },
  '@media (max-width: 1024px)': {
    // top: '20%',
    left: '16%',
    right: '16%',
  },
};
const style2 = {
  position: 'absolute',
  top: '20%',
  left: '36%',
  width: 400,
  bgcolor: 'transparent',
  border: 'none',
  '@media (max-width: 1100px)': {
    top: '24%',
    left: '32%',
  },
  '@media (max-width:1024px)': {
    top: '29%',
  },
};

function CreateReviewModal({
  open,
  handleClose,
  // toggleNotification,
  handleReviewSubmit,
  handleOptionsslick,
  selectedOptionslist,
  setSongObj,
  songObj,
  handleImageChange,
  previewImage,
  handleSelectedImgClick,
  handleInputChange,
  formData,
  setFormData,
  selectedPlaylists,
  setSelectedPlaylists,
  handleTitleChange,
  selectedColor,
  setSelectedColor,
  selectedColorTwo,
  setSelectedColorTwo,
  frequency,
  setFrequency,
}) {
  const [selected, setSelected] = useState('');
  const [searchClick, setSearchClick] = useState();
  const [searchValue, setSearchValue] = useState();
  const [selectedSong, setSelectedSong] = useState({});
  const selectedSongImageToShow = (selectedSong && selectedSong?.images?.[2]?.url) || '';
  const [openn, setOpenn] = React.useState(false);
  const [playlistsName, setPlaylistsName] = useState([]);
  const [selectedPlaylistNames, setSelectedPlaylistNames] = useState([]);

  function rgbaToRgb(obj) {
    if (obj && typeof obj === 'object' && 'r' in obj && 'g' in obj && 'b' in obj) {
      const { r } = obj;
      const { g } = obj;
      const { b } = obj;
      return `rgb(${r}, ${g}, ${b})`;
    }
    console.error('Invalid object format. Object should have "r," "g," and "b" properties.');
    return '';
  }
  const handleColorChange = (color) => {
    console.log('loveee', color.rgb);
    setSelectedColor(color.rgb);
    setSelectedColor(rgbaToRgb(color.rgb));
  };
  const handleColorChangeTwo = (color) => {
    setSelectedColorTwo(color.rgb);
    setSelectedColorTwo(rgbaToRgb(color.rgb));
  };
  const handlePlaylistSearch = () => {
    setSearchClick((prevState) => !prevState);
  };

  const handlePlaylistSearchValue = async (value) => {
    setSearchValue(value);
    try {
      const response = await api('get', `/users/my-playlists/?q=${value}`);
      setPlaylistsName(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  console.log(searchValue, 'ssddwe');

  const handleCheckboxChange = (playlist) => {
    setSelectedPlaylistNames((previousPlaylist) => {
      if (previousPlaylist.some((item) => item.id === playlist.id)) {
        return previousPlaylist.filter((item) => item.id !== playlist.id);
      }
      return [...previousPlaylist, playlist];
    });
    setSelectedPlaylists((prevSelected) => {
      if (prevSelected.includes(playlist.id)) {
        return prevSelected.filter((id) => id !== playlist.id);
      }
      return [...prevSelected, playlist.id];
    });
  };
  console.log(selectedColor, selectedColorTwo, 'colors');
  const handleSelectedSongClick = () => {
    setSelectedSong({});
    setSongObj({
      artist_name: '',
      artist_image: '',
      artist_spotify_id: '',
      title: '',
      year: '',
      image: '',
      spotify_id: '',
      spotify_url: '',
      spotify_preview_url: '',
      apple_id: '',
      apple_url: '',
      apple_preview_url: '',
    });
  };
  const handleRatingChange = (e, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: newValue,
    }));
  };
  const handlePlaylistPopupOpen = async () => {
    setOpenn(true);
    try {
      const response = await api('get', '/users/my-playlists/');
      setPlaylistsName(response?.data?.results);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user reviews:', err);
    }
  };
  const handleClosee = () => setOpenn(false);
  const playlistNames = selectedPlaylistNames.map((item) => item.name);

  useEffect(() => {
    console.log(selectedSong, 'selectedSong create review');
    if (selectedSong?.album_type === 'single') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        type: 'song',
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        type: 'album',
      }));
    }
  }, [selectedSong]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      // disableScrollLock={true}
      // hideBackdrop
      sx={{
        padding: '10px',
      }}
    >
      <Box sx={style}>
        <div className="option_review_sectionNew">
          <div className="inner_option_reviewNew">
            <div className="reviewModalTopHeadingContainer">
              <div className="noSongSampleImgDiv">
                <img src={songObj?.image || selectedSongImageToShow || createRevImg} alt="." />
              </div>
              <div className="createReviewTxtWrapperClass">
                <p className="createReviewText1">create review</p>
                <p className="createReviewText2">Welcome To the</p>
                <p className="createReviewText3">Reviewer Cockpit</p>
                <p className="createReviewText4">lorem Lipsum dollar text istmp pistum</p>
              </div>
              <div className="buttonsAndCloseIcon">
                <div className="closeIconAddReviews" onClick={handleClose}>
                  <img src={CloseIcon} alt="." />{' '}
                </div>
                <div>
                  <button className="cancelReviewAdd" type="submit" onClick={handleClose}>
                    Cancel
                  </button>
                  <button className="submitReviewAdd" type="submit" onClick={handleReviewSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <div className="addReviewInputFieldsClass">
              <div className="songAndMorningHolderDiv">
                <div className="createReviewInputholderDiv" id="doubleInputFieldWithRating">
                  {selectedSong?.name || songObj?.artist_name ? (
                    <div
                      className="overAllsearchDivWrapSelected"
                      style={{ padding: '16px', backgroundColor: '#2D3447' }}
                      onClick={handleSelectedSongClick}
                    >
                      <div className="selectedSongImgHolder">
                        <img
                          src={selectedSong.images ? selectedSong?.images[2]?.url : songObj.image}
                          alt="Selected Song"
                        />
                      </div>
                      <div className="selectedSongnameTxt">
                        {selectedSong.name ? selectedSong?.name : songObj.title}
                      </div>
                      <span className="colorWhite">|</span>
                      <div className="selectedSongSubNameTxt">
                        {selectedSong.artists
                          ? selectedSong?.artists[0]?.name
                          : songObj.artist_name}
                      </div>
                    </div>
                  ) : (
                    <SearchInput
                      inputBackgroundColor="#2D3447"
                      inputPlaceholder="Song Name"
                      selectedSong={selectedSong}
                      setSelectedSong={setSelectedSong}
                      setSongObj={setSongObj}
                      selected={selected}
                      setSelected={setSelected}
                      noIcon
                      pdClass="16px"
                      noPdLeft
                    />
                  )}
                </div>
                <div className="createReviewInputholderDiv" id="divMorningDetailsOnAddReview">
                  <div
                    onClick={() => handleOptionsslick('morning')}
                    className={
                      selectedOptionslist === 'morning'
                        ? 'createReview__second_secoondData_btnsColored'
                        : 'songTimeOptions'
                    }
                  >
                    <div className="imgHolderAddReviewIn" id="morningImgReviews">
                      <img className="weather_img" src={MorningIMG} alt="." />
                    </div>
                    <div className="timeOptnTxtt">Morning</div>
                  </div>
                  <div
                    onClick={() => handleOptionsslick('Noon')}
                    className={
                      selectedOptionslist === 'Noon'
                        ? 'createReview__second_secoondData_btnsColored'
                        : 'songTimeOptions'
                    }
                  >
                    <div className="imgHolderAddReviewIn">
                      <img className="weather_img" src={NoonIMG} alt="." />
                    </div>
                    <div className="timeOptnTxtt">Afternoon</div>
                  </div>
                  <div
                    onClick={() => handleOptionsslick('Evening')}
                    className={
                      selectedOptionslist === 'Evening'
                        ? 'createReview__second_secoondData_btnsColored'
                        : 'songTimeOptions'
                    }
                  >
                    <div className="imgHolderAddReviewIn">
                      <img className="weather_img" src={EveningIMG} alt="." />
                    </div>
                    <div className="timeOptnTxtt">evening</div>
                  </div>
                </div>
              </div>
              <div>
                <Box
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Rating
                    value={formData?.ratings}
                    size="large"
                    name="ratings"
                    precision={0.1}
                    onChange={(e, newValue) => handleRatingChange(e, newValue)}
                    sx={{
                      '& .MuiRating-icon': {
                        fontSize: '35px',
                        '&:hover': {
                          fontSize: '15px',
                        },
                      },
                      '& .MuiRating-iconEmpty': {
                        color: 'white',
                      },
                      marginRight: '10px',
                    }}
                  />
                  <p className="colorWhite text-xl">
                    {formData?.ratings == 0 ? null : formData?.ratings}
                  </p>
                </Box>
              </div>
              <div className="songAndMorningHolderDiv">
                <div className="createReviewInputholderDiv">
                  <input
                    className="createReviewsActualInput"
                    placeholder="Artist Name"
                    value={songObj.artist_name}
                    // onChange={handleSongInput}
                    disabled
                  />
                </div>
                <div className="createReviewInputholderDiv">
                  <label
                    style={{ width: '100%' }}
                    className="upload-pic-label"
                    htmlFor="filePicker"
                  >
                    <input
                      type="file"
                      hidden
                      id="filePicker"
                      multiple
                      name="image"
                      onChange={handleImageChange}
                      accept="image/png, image/jpeg"
                    />
                    {previewImage?.length !== 0 ? (
                      <div className="addReviewPhotoSelectorDiv">
                        <div className="imagesHolderReviewAddd">
                          {previewImage?.map((image, index) => (
                            <div
                              className="imgHolderForReviews"
                              onClick={handleSelectedImgClick}
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                            >
                              <img src={URL.createObjectURL(image)} alt="Selected" />
                            </div>
                          ))}
                        </div>
                        <div className="imgHolderForReviewsIcon" onClick={handleSelectedImgClick}>
                          <img src={CameraIcon} alt="icon" />
                        </div>
                      </div>
                    ) : (
                      <div className="addReviewPhotoSelectorDiv2">
                        <div>
                          <p className="addPhotoTxt">Add photo</p>
                        </div>
                        <div className="imgHolderForReviewsIcon">
                          <img src={CameraIcon} alt="icon" />
                        </div>
                      </div>
                    )}
                  </label>
                </div>
              </div>
              <div className="songAndMorningHolderDiv">
                <div className="createReviewInputholderDiv">
                  <input
                    className="createReviewsActualInput"
                    placeholder="Title"
                    name="title"
                    value={formData.title}
                    // onChange={handleSongInput}
                    onChange={handleTitleChange}
                  />
                </div>
                <div className="createReviewInputholderDiv" id="relativeToCamIcon2">
                  <input
                    className="createReviewsActualInput"
                    style={{ paddingRight: '15%' }}
                    id="title"
                    name="title"
                    onChange={handleInputChange}
                    placeholder="Add Playlist"
                    onClick={handlePlaylistPopupOpen}
                    value={playlistNames.join(', ')}
                  />
                  <div className="cameraIconDiver2" onClick={handlePlaylistPopupOpen}>
                    <img src={DropDpwnIcon} alt="dropdown" />
                  </div>
                </div>
              </div>
              <div className="songAndMorningHolderDiv">
                <div className="createReviewInputholderDiv">
                  <textarea
                    name="body"
                    value={formData.body}
                    onChange={handleInputChange}
                    className="createReviewsActualInput"
                    id="addreviewTxtAreas"
                    placeholder="Add Review..."
                    style={{ resize: 'none' }}
                  />
                </div>
                <div className="createReviewInputholderDiv">
                  <div id="songIntensity" name="songIntensity" className="createReviewsActualInput">
                    <div className="intensityWrapperClass">
                      <IntensityComponent
                        color1={selectedColor}
                        color2={selectedColorTwo}
                        frequency={frequency}
                      />
                    </div>
                    <div className="colorPickerWrapper">
                      <HuePicker
                        color={selectedColor}
                        onChange={handleColorChange}
                        className="colorPickerSlider"
                      />
                      <HuePicker
                        color={selectedColorTwo}
                        onChange={handleColorChangeTwo}
                        className="colorPickerSlider"
                        // id="colorPickerTwo"
                      />
                      <input
                        type="range"
                        id="frequency"
                        min="5.33"
                        max="5.66"
                        step="0.01"
                        value={frequency}
                        className="intensitySlider"
                        onChange={(e) => setFrequency(parseFloat(e.target.value))}
                      />
                    </div>
                    <div>{/* <label htmlFor="frequency">intensity:</label> */}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={openn}
          onClose={handleClosee}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="addPLaylist">
              <div className="inner_addPlaylist">
                <div className="playlist_first_Section">
                  <div className={searchClick ? 'playlist_search' : 'playlist_txt_search'}>
                    <img src={BackArrow} alt="back" />
                    {!searchClick && <p className="playlist_txt">Add to Playlist</p>}
                  </div>

                  {searchClick && (
                    <input
                      className="searchPlaylistInput"
                      placeholder="Search Playlist..."
                      onChange={(e) => handlePlaylistSearchValue(e.target.value)}
                    />
                  )}

                  <img
                    className="cursorPointer"
                    src={SearchIcon}
                    alt="search"
                    onClick={handlePlaylistSearch}
                  />
                </div>
                <div className="playlist_second_Section">
                  {playlistsName?.map((playlist) => (
                    <div className="playlist_type_checkbox" key={playlist?.id}>
                      <p className="playlist_type_p">{playlist?.name}</p>
                      <Checkbox
                        style={{ color: '#57BBEB' }}
                        checked={selectedPlaylists.includes(playlist?.id)}
                        onChange={() => handleCheckboxChange(playlist)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </Box>
    </Modal>
  );
}

export default CreateReviewModal;
