/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Modal } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import formatTimeAgo from '../utils/services';
import BackArrow from '../assets/Images/BackArrow.svg';
import CloseIcon from '../assets/Images/CloseIcon.svg';
import Checked from '../assets/Images/checkVerified.svg';
import likes from '../assets/Images/likes.svg';
import comments from '../assets/Images/Comment.svg';
import share from '../assets/Images/share.svg';
import SettingsIconBlue from '../assets/Images/threeDotsSetting.svg';
import SongsOption from './SongsOption';
import api from '../api';
import IconHeartLike from '../assets/Images/IconHeartLike.svg';
import ShowMoreData from './ShowMoreData';
import PlaylistOptionsModal from './userProfile/PlaylistOptionsModal';
import AddSongPlayList from './AddSongPlayList';
import dummyProfileImage from '../assets/Images/dummyProfileImg.jpg';
import { queryParamsKey } from '../utils/constant';
import { copyPlaylistUrl } from '../utils/helpers';
import {
  errorLogCountAnalyticsEvent,
  playlistShareAnalyticsEvent,
} from '../service/analyticsService';
// const style = {
//   position: 'absolute',
//   top: '20%',
//   left: '20%',
//   transform: 'translate(-0%, -0%)',
//   width: 400,
//   bgcolor: 'white',
//   border: 'none',
//   boxShadow: 24,
// };

function SinglePlayListDetail({
  playlistDetails,
  playlistDetailsClose,
  selectedPlaylist,
  playlistSongs,
  playlistDetailsById,
  handleGetPlaylistSongs,
  playlistComments,
  fetchPlaylistComments,
  style,
  fetchPanelData,
}) {
  const [showMore, setShowMore] = useState(false);
  const [removePlaylist, setRemovePlaylist] = useState(false);
  const [editSong, setEditAddedSong] = useState(false);
  const [singleSong, setSingleSong] = useState();
  const [selectedSong, setSelectedSong] = useState({});
  const [likeCount, setLikeCount] = useState(playlistDetailsById?.like_count);
  const [commentLike, setCommentLike] = useState(false);
  const pathParams = useParams();

  const currentHost = window.location.pathname;
  const editaddedSongsOpen = (obj) => {
    if (currentHost === queryParamsKey.myProfile.url) {
      setSingleSong(obj);
      setEditAddedSong(true);
    } else {
      toast.error('unauthorized');
    }
  };
  const editaddedSongsClose = () => {
    setEditAddedSong(false);
  };

  const removePlaylistOpen = () => {
    setRemovePlaylist(true);
  };
  const removePlaylistClose = () => {
    setRemovePlaylist(false);
  };

  const handleSelectedSongClick = () => {
    setSelectedSong({});
  };

  const handleLikeClick = async (id) => {
    setCommentLike((prevState) => !prevState);
    setLikeCount((prevCount) => (commentLike ? prevCount - 1 : prevCount + 1));

    try {
      await api('post', `/playlists/${id}/like/`);
    } catch (err) {
      errorLogCountAnalyticsEvent();
      console.log('Error fetching user commentLikes:', err);
    }
  };
  console.log(selectedPlaylist, 'yuioiui');
  const showMoreRef = useRef(null);
  useEffect(() => {
    if (showMoreRef) showMoreRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [showMoreRef, showMore]);

  useEffect(() => {
    if (playlistDetailsById?.is_liked === true) {
      setCommentLike(true);
    }
    setLikeCount(playlistDetailsById?.like_count);
  }, [playlistDetailsById]);
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleDeleteFromPlaylist = (playlistId, songId) => {
    api('get', `/playlists/${playlistId}/remove-song/?song_id=${songId}`)
      .then((res) => {
        toast.success(res?.data.message);
        removePlaylistClose();
        editaddedSongsClose();
        handleGetPlaylistSongs(selectedPlaylist?.id);
      })
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        toast.error(err?.data.message);
      });
  };

  const handleSharePlaylist = () => {
    playlistShareAnalyticsEvent();
    copyPlaylistUrl(pathParams, selectedPlaylist.id);
  };
  return (
    <Modal
      open={playlistDetails}
      onClose={playlistDetailsClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="playList_model">
        <div className="profile_playList_popup">
          <div className="profile_playList_popup_first_sction">
            <div className="back_close_icon">
              <div className="createPlaylist_backArrow" onClick={playlistDetailsClose}>
                <img src={BackArrow} alt="back" />
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => console.log('nothing')}
                className="createPlaylist_Close"
                onClick={playlistDetailsClose}
              >
                <img src={CloseIcon} alt="close" />
              </div>
            </div>
          </div>
          {playlistDetailsById?.image && (
            <div className="HPimgprofiile">
              <div style={{ cursor: 'default' }} className="playList_Cover_img">
                <img src={playlistDetailsById?.image} alt="noImage" className="studentprofilepic" />
              </div>
            </div>
          )}
          <PlaylistOptionsModal
            selectedPlaylist={selectedPlaylist}
            style={style}
            playlistDetailsById={playlistDetailsById}
            fetchPanelData={fetchPanelData}
            playlistDetailsClose={playlistDetailsClose}
          />
          <div className="MyReviews_PlayList_txtData">
            <div className="postedBy">
              <div className="posterSinfos">
                <div className="postByUserImg">
                  <img
                    src={playlistDetailsById?.user?.avatar || dummyProfileImage}
                    alt="pic"
                    style={{ objectFit: 'cover', borderRadius: '50%' }}
                  />
                </div>
                <div>
                  <p className="postedbyUsername">
                    {playlistDetailsById?.user?.name || playlistDetailsById?.user?.username}
                  </p>
                </div>
                <div className="checked">
                  {playlistDetailsById?.user?.is_verified ? <img src={Checked} alt="pic" /> : null}
                </div>
              </div>
              <div>
                <p className="hoursAgo">{formatTimeAgo(playlistDetailsById?.created_at)}</p>
              </div>
            </div>
            <div className="postDescription" id="breakWordsWrap">
              <p>
                {playlistDetailsById?.desc ||
                  'lorem lipsum dollar istum pistum lisgub jstpis cetfix'}
              </p>
            </div>
            <div className="likeCommentAndShare_playlist">
              <div className="likesAndComments_playlist">
                <div className="commentAndLikesTextWrapp">
                  <div className="lkes_cmnts_data_txt">
                    <div
                      className="likeCommentImg"
                      id="heartIconForLike2"
                      onClick={() => handleLikeClick(playlistDetailsById?.id)}
                    >
                      {commentLike ? (
                        <img src={IconHeartLike} alt="." />
                      ) : (
                        <img src={likes} alt="." />
                      )}
                    </div>
                    <p className="playList_popup_likes_txt">
                      <span>
                        {likeCount} <span /> {likeCount <= 1 ? 'Like' : 'Likes'}
                      </span>
                    </p>
                  </div>
                  <div className="lkes_cmnts_data_txt_cmnt">
                    <div className="likeCommentImg">
                      <img src={comments} alt="pic" />
                    </div>
                    <p className="playList_popup_cmnts_txt playList_popup_likes_txt">
                      <span>{playlistDetailsById?.comment_count}</span> Comments
                    </p>
                  </div>
                </div>
                <div className="shareIconDiv" onClick={handleSharePlaylist}>
                  <img src={share} role="presentation" alt="pic" />
                </div>
              </div>
            </div>
            <AddSongPlayList
              style={style}
              selectedSong={selectedSong}
              handleSelectedSongClick={handleSelectedSongClick}
              setSelectedSong={setSelectedSong}
              playlistDetailsById={playlistDetailsById}
              handleGetPlaylistSongs={handleGetPlaylistSongs}
            />

            <div className="added_Songs_data_main">
              <p className="added_songs_txt">Added Songs</p>
              {currentHost === '/profile' && (
                <p className="added_songs_disc">Add songs based on your listening history</p>
              )}
              <div className="songs_added_data_list">
                {playlistSongs.length > 0 ? (
                  playlistSongs.map((adddata) => (
                    <div key={adddata?.id} className="songs_added_data">
                      <div className="songs_added_data_left">
                        <div className="added_song_img">
                          <img src={adddata?.image} alt="songs" />
                        </div>
                        <div className="added_song_data_txt">
                          <p className="added_song_title_txt">{adddata?.title}</p>
                          <p className="added_song_disc_txt">{adddata?.artist_name}</p>
                        </div>
                      </div>
                      <button
                        className="blueDots_icon"
                        type="button"
                        onClick={() => editaddedSongsOpen(adddata)}
                      >
                        <img src={SettingsIconBlue} alt="setting" />
                      </button>
                    </div>
                  ))
                ) : (
                  <div className="no_song_div">
                    <p>No Song Found</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="playList_showMore_data">
          <button className="playList_showMore_btn" type="button" onClick={() => handleShowMore()}>
            {showMore ? 'Hide Comments' : 'See Comments'}
          </button>
          {showMore && (
            <ShowMoreData
              showMoreRef={showMoreRef}
              playlistComments={playlistComments}
              fetchPlaylistComments={fetchPlaylistComments}
              playlistDetailsById={playlistDetailsById}
            />
          )}
        </div>
        <SongsOption
          editSong={editSong}
          editaddedSongsClose={editaddedSongsClose}
          singleSong={singleSong}
          removePlaylistOpen={removePlaylistOpen}
          removePlaylist={removePlaylist}
          removePlaylistClose={removePlaylistClose}
          playlistId={selectedPlaylist?.id}
          handleDeleteFromPlaylist={handleDeleteFromPlaylist}
        />
      </Box>
    </Modal>
  );
}

export default SinglePlayListDetail;
