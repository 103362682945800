// Init
import axios from 'axios';
import { toast } from 'react-toastify';
import { baseUrl, baseUrlV2 } from '../config';
import { errorLogCountAnalyticsEvent } from '../service/analyticsService';

const api = async (method, uri, body, headers = {}, isV2 = false) => {
  // API Call
  const url = (isV2 ? baseUrlV2 : baseUrl) + uri;

  const token = localStorage.getItem('idToken');

  if (token) {
    axios.defaults.headers = {
      Authorization: `Bearer ${token}`,
      // 'Spotify-Authorization': `Bearer ${spotifyToken}`,
    };
  }

  if (Object.keys(headers).length) {
    Object.assign(axios.defaults.headers, headers);
  }

  return new Promise((resolve, reject) => {
    axios[method](url, body)
      .then((res) => resolve(res))
      .catch((err) => {
        errorLogCountAnalyticsEvent();
        if (err?.response?.status === 403) {
          // localStorage.removeItem('token');
          console.log('API Error --------> ', err?.response?.data?.error_message);

          toast.error(
            err?.response?.data?.error_message
              ? err?.response?.data?.error_message
              : err?.response?.data?.message,
          );

          // window.location = '/';
        } else if (err?.response?.status === 401) {
          // Unauthorized
          localStorage.clear();
          window.location = '/login';
        } else {
          // eslint-disable-next-line no-console
          console.log('API Error --------> ', err?.response?.status);
          toast.error(err?.response?.data ? err.response.data.message : err?.message);
          reject(err);
        }
      });
  });
};
//
// Export
export default api;
